import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Button, TextField, Select, MenuItem, Checkbox, ListItemText, ListItemIcon, InputLabel } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { useStyles } from "../ModalStyle";
import { CircularProgress } from "@material-ui/core";
import {
  ERROR_ALERT,
} from "../../../../commonUtils/share";
import { addEmail, updateEmail } from "../../redux/actions/meetingEmailAction";
import { addMeeting } from "../../redux/actions/meetingAction"
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EmailModal = (props) => {
  const { modal, toggle, data } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [emailDetails, setEmailDetails] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data) {
      setEmailDetails({
        ...data,

      });
    }
  }, [data]);

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setEmailDetails({
      ...emailDetails,
      [name]: value,
    });
  };
  const submitHandler = async () => {
    try {
      if (
        emailDetails?.email &&
        emailDetails?.calendarId

      ) {
        if (emailDetails?.id) {
          dispatch(updateEmail(emailDetails, setLoading, toggle))
        }
        else {
          dispatch(addEmail(emailDetails, setLoading, toggle))
        }
      }
      else {
        dispatch({
          type: ERROR_ALERT,
          payload: "ALL Fields Are Required!",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Dialog
        fullScreen
        open={modal}
        onClose={() => toggle()}
        TransitionComponent={Transition}
      >
        <AppBar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => toggle()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </AppBar>

        <Container maxWidth="md" className={classes.container}>
          <h1 className="Details">Meeting Email Details</h1>
          <Grid container justify="center" alignItems="center" spacing={2}>
            <Grid item xs={9} md={9} sm={9}>
              <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid item md={12}>
                  <TextField
                    name="email"
                    className={classes.formControl}
                    fullWidth
                    placeholder="Email"
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    value={emailDetails?.email || ""}
                    onChange={onChangeHandler}

                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    name="calendarId"
                    className={classes.formControl}
                    fullWidth
                    placeholder="Calendar Id"
                    id="outlined-basic"
                    label="Calendar Id"
                    variant="outlined"
                    value={emailDetails?.calendarId || ""}
                    onChange={onChangeHandler}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3} className={classes.grid}>
            <Grid item md={12} xs={12}>
              {loading ? (
                <CircularProgress className={classes.loader} />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={submitHandler}
                    className={classes.button}
                  >
                    {emailDetails?.id ? "Update" : "Add"}
                  </Button>

                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </>
  );
};
const ConfirmModal = ({
  modal,
  toggle,
  message,
  action
}) => {
  return <Dialog
    open={modal}
    onClose={toggle}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {message}
    </DialogTitle>
    <DialogActions>
      <Button onClick={toggle} color="primary">
        Cancel
      </Button>
      <Button onClick={action} color="primary" autoFocus>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
}

const MeetingModal = (props) => {
  const { modal, toggle, data,
    emailOptions, defaultEmails } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [meeting, setMeeting] = useState({});
  const [loading, setLoading] = useState(false);
  const [calendarId, setCalendarId] = useState([...defaultEmails]);

  const isAllSelected =
    emailOptions.length > 0 && calendarId.length === emailOptions.length;
  let options = [];
  emailOptions.map((item) => options.push(item.calendarId))

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setCalendarId(calendarId.length === emailOptions.length ? [] : options);
      return;
    }
    setCalendarId(value);
  };

  useEffect(() => {
    if (data) {
      setMeeting({
        ...data,

      });
    }
  }, [data]);

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setMeeting({
      ...meeting,
      [name]: value,
    });
  };
  const submitHandler = async () => {
    try {
      if (
        meeting?.summary &&
        meeting?.startDate &&
        meeting?.endDate &&
        meeting?.joiningLink &&
        calendarId.length > 0

      ) {

        if (moment(new Date()).format('YYYY-MM-DDTHH:mm') > meeting?.startDate) {
          dispatch({
            type: ERROR_ALERT,
            payload: "Start time should not  previous time!",
          });
        }
        else if (meeting?.endDate < meeting?.startDate) {
          dispatch({
            type: ERROR_ALERT,
            payload: "End time should not before start time!",
          });
        }
        else {
          dispatch(addMeeting({ ...meeting, calendarId }, setLoading, toggle))
        }
      }
      else {
        dispatch({
          type: ERROR_ALERT,
          payload: "ALL Fields Are Required Expect Description!",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Dialog
        fullScreen
        open={modal}
        onClose={() => toggle()}
        TransitionComponent={Transition}
      >
        <AppBar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => toggle()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </AppBar>

        <Container maxWidth="md" className={classes.container}>
          <h1 className="Details">Meeting Details</h1>
          <Grid container justify="center" alignItems="center" spacing={2}>
            <Grid item xs={9} md={9} sm={9}>
              <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid item md={6}>
                  <TextField
                    name="summary"
                    className={classes.formControl}
                    fullWidth
                    placeholder="Title"
                    id="outlined-basic"
                    label="*Title"
                    value={meeting?.summary || ""}
                    onChange={onChangeHandler}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="startDate"
                    name="startDate"
                    label="*Start Time"
                    type="datetime-local"
                    className={classes.formControl}
                    fullWidth
                    value={meeting?.startDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    name="endDate"
                    id="endDate"
                    label="*End Time"
                    type="datetime-local"
                    className={classes.formControl}
                    fullWidth
                    value={meeting?.endDate}
                    onChange={onChangeHandler}

                    InputLabelProps={{
                      shrink: true,
                    }}

                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    name="joiningLink"
                    id="joiningLink"
                    label="*Joining Link"
                    type="text"
                    className={classes.formControl}
                    fullWidth
                    value={meeting?.joiningLink}
                    onChange={onChangeHandler}

                    InputLabelProps={{
                      shrink: true,
                    }}

                  />
                </Grid>
                <Grid item md={6}>
                  <InputLabel id="selectEmails">*Select Emails </InputLabel>
                  <Select
                    labelId="selectEmails"
                    multiple
                    value={calendarId}
                    onChange={handleChange}
                    renderValue={(calendarId) => calendarId.join(", ")}
                    MenuProps={classes.menuProps}
                    className={classes.formControl}
                    fullWidth
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelected ? classes.selectedAll : ""
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{ indeterminate: classes.indeterminateColor }}
                          checked={isAllSelected}
                          indeterminate={
                            calendarId.length > 0 && calendarId.length < emailOptions.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {emailOptions.map((option) => (
                      <MenuItem key={option} value={option?.calendarId}>
                        <ListItemIcon>
                          <Checkbox checked={calendarId.filter((item) => item === option?.calendarId).length} />
                        </ListItemIcon>
                        <ListItemText primary={option?.email} />
                      </MenuItem>
                    ))}
                  </Select>

                </Grid>
                <Grid item md={6}>


                </Grid>
                <Grid item md={12}>
                  <TextField
                    name="description"
                    className={classes.formControl}
                    fullWidth
                    placeholder="Description"
                    id="outlined-basic"
                    label="Description"
                    value={meeting?.description || ""}
                    onChange={onChangeHandler}
                    multiline
                    rows={3}
                    maxRows={4}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.grid}>
            <Grid item md={12} xs={12}>
              {loading ? (
                <CircularProgress className={classes.loader} />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={submitHandler}
                    className={classes.button}
                  >
                    {meeting?.id ? "Update" : "Add"}
                  </Button>

                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </>
  );
};
export { EmailModal, ConfirmModal, MeetingModal };
