import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  present: {
    // backgroundColor: theme.palette.success.dark,
    // backgroundColor: theme.palette.success.light,
    backgroundColor: "#DDF7E9",
    color: theme.palette.success.dark,
    // color: "#408E6D",
    marginRight: theme.spacing(2),
    borderRadius: 0,
    borderColor: "none",
    fontWeight: "900",
    border: "none",
  },
  presentIcon: {
    color: theme.palette.success.dark,
    fontWeight: "900",
  },
  iconDisabled: {
    // color: "#000000",
    color: "#808080",
  },
  disabled: {
    backgroundColor: "#F2F4F5",
    // color: "#000000",
    color: "#808080",
    marginRight: theme.spacing(2),
    borderRadius: 0,
    borderColor: "none",
    fontWeight: "900", // 900
    border: "none",
  },
  late: {
    // backgroundColor: theme.palette.warning.main,
    backgroundColor: "#F8F0DB",
    color: theme.palette.warning.dark,
    marginRight: theme.spacing(2),
    borderRadius: 0,
    borderColor: "none",
    fontWeight: "900",
    border: "none",
  },
  lateIcon: {
    color: theme.palette.warning.dark,
  },
  absent: {
    // backgroundColor: theme.palette.secondary.dark,
    backgroundColor: "#F8EBE9",
    color: theme.palette.secondary.dark,
    marginRight: theme.spacing(2),
    borderRadius: 0,
    borderColor: "none",
    fontWeight: "900",
    border: "none",
  },
  absentIcon: {
    color: theme.palette.secondary.dark,
  },
  generalLeave: {
    backgroundColor: "#ADD8E6",
    color: "#00008B",
    marginRight: theme.spacing(2),
    borderRadius: 0,
    borderColor: "none",
    fontWeight: "900",
    border: "none",
  },
  paidLeave: {
    backgroundColor: "#ADD8E6",
    color: "#00008B",
    marginRight: theme.spacing(2),
    borderRadius: 0,
    borderColor: "none",
    fontWeight: "900",
    border: "none",
  },
  leaveIcon: {
    color: "#00008B",
  },
  activeTab: {
    backgroundColor: "#ADD8E6",
    color: "#00008B",
    marginRight: theme.spacing(2),
    borderRadius: 0,
    borderColor: "none",
    fontWeight: "900",
    border: "none",
  },
  activeIcon: {
    color: "#00008B",
  },
}));
