import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateContactStatus } from "../redux/actions/contactActions";

export default function useContactRequests() {
  const { requests } = useSelector((state) => state.contactRequestsReducer);
  const [index, setindex] = useState(0);

  const dispatch = useDispatch();
  const users = useSelector((state) => state.commonReducer.allEmployees);
  const { payStub } = useSelector((state) => state.payStubReducer);
  const [rowopen, setRowOpen] = React.useState(false);
  const [loading, setloading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const allEvents = useSelector((state) => state.commonReducer.allEvents);
  const state = useSelector((state) => state);

  const handleChange = (status, userData, dataIndex) => {
    setindex(dataIndex);
    setloading(true);
    dispatch(updateContactStatus(setloading, status, userData));
  };

  return [
    open,
    rowopen,
    setRowOpen,
    requests,
    handleChange,
    loading,
    index,
    setindex,
  ];
}
