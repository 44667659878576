import React from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
const AttendanceReportGraph = () => {
  const { attendance } = useSelector((state) => state?.reportsReducer);
  const { graphData } = attendance;
  if (graphData && graphData?.length > 0) {
    return (
      <ResponsiveContainer width={"100%"} height={400}>
        <BarChart height={300} data={graphData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
  return null;
};
export default AttendanceReportGraph;
