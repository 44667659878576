import React from "react";
import CalendarApp from "./calendar/Calendar";

export default function Events() {
  return (
    <div>
      <CalendarApp />
    </div>
  );
}
