import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    mainButton: {
        marginLeft: "20px"
    },
    header: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'space-between'
    },
    item: {
        display: "flex",
        justifyContent: 'space-between'
    },
    addButton: {
        float: "right",
        margin: "10px"
    },
    textField: {
        margin: "10px"
    },
    fieldDiv: {
        margin: '20px 0',
        minWidth: "400px",
        maxWidth: '600px'
    }
}));
