import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Toolbar from "./attendanceCalendarToolBar";
import Calendar from "./Calendar";
import { fetchCalendarForBootcamp, fetchForCalendar } from "../redux/actions/attendanceAction";
import { ATTENDANCE_COLORS } from "../redux/constant/attendanceConstant";
import { useLocation } from "react-router-dom";

let selectTimeout;
export default function AttendanceCalendar() {
  const dispatch = useDispatch();
  const attendances = useSelector((state) => state.attendanceReducer.events);
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState("month");
  const [events, setEvents] = useState();
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    setEvents(attendances);
  }, [attendances]);

  useEffect(() => {
    path === "/attendance" ? dispatch(fetchForCalendar(new Date())) : dispatch(fetchCalendarForBootcamp(new Date()));
  }, []);

  const onNavigate = (newDate) => setDate(newDate);
  const onView = (newView) => setView(newView);

  const accessors = {
    draggableAccessor: (event) => !event.blocked,
    resizableAccessor: (event) => !event.blocked,
  };

  const onSelectSlot = ({ start, end, action }) => {
    selectTimeout && window.clearTimeout(selectTimeout);

    selectTimeout = setTimeout(() => {}, 250);
  };

  const onSelectEvent = (event) => {
    selectTimeout && window.clearTimeout(selectTimeout);

    selectTimeout = setTimeout(() => {}, 250);
  };

  const onDoubleClickEvent = (event) => {
    selectTimeout && window.clearTimeout(selectTimeout);

    selectTimeout = setTimeout(() => {}, 250);
  };

  const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    let allDay = event.allDay;

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }
  };
  const onKeyPressEvent = ({ event, ...other }) => {};

  const onSelecting = (range) => {};
  const EventFun = ({ event }) => {
    return (
      <div>
        <span>
          <strong>{event.fullName}</strong>
        </span>
      </div>
    );
  };
  const colors = ATTENDANCE_COLORS;
  function eventStyleGetter(event) {
    const style = {
      backgroundColor: colors[event.label],
      borderRadius: "10px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  }

  return (
    <div>
      <Calendar
        {...{
          events,
          date,
          onNavigate,
          onView,
          onSelectSlot,
          onSelectEvent,
          onSelecting,
          onDoubleClickEvent,
          onKeyPressEvent,
        }}
        onEventDrop={moveEvent}
        {...accessors}
        selectable="ignoreEvents"
        components={{
          event: EventFun,
          toolbar: Toolbar,
        }}
        eventPropGetter={(event, start, end, isSelected) =>
          eventStyleGetter(event, start, end, isSelected)
        }
      />
    </div>
  );
}
