import { GET_ALL_JOBS_FOR_MODEL, UPDATED_PROFILE } from "../constant";

const initialState = {
  jobs: {},
  updateProfile: [],
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_JOBS_FOR_MODEL:
      return {
        ...state,
        jobs: action.payload,
      };
    case UPDATED_PROFILE:
      return {
        ...state,
        jobs: { ...state.jobs, ...action.payload },
      };
    default:
      return state;
  }
};

export default userProfileReducer;
