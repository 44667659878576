import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  Item: {
    "&:hover": {
      borderBottom: '4px solid white!important'
    }
  },
  active: {
    borderBottom: '4px solid white!important'
  }
}));

export default function AppBars(props) {
  const classes = useStyles();
  const [activeStyle, setActiveStyle] = useState(1);

  const showItems = () => {
    props.showItem()
    setActiveStyle(1)
  }
  const blockPersons = () => {
    props.blockPerson()
    props.hideItem()
    setActiveStyle(2)
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography className={activeStyle === 1 ? classes.active : classes.Item} onClick={showItems} style={{ cursor: "pointer", fontWeight: "bold", fontSize: "20px", }}>New</Typography>
          <Typography className={activeStyle === 2 ? classes.active : classes.Item} onClick={blockPersons} style={{ cursor: "pointer", fontWeight: "bold", fontSize: "20px", marginLeft: "20px" }}>{props.title}</Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}