import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllJobs } from "../../../jobs/redux/actions/jobsActions";
import axiosTechloset from "../../../../config/axiosTechloset";
import { SUCCESS_ALERT, ERROR_ALERT } from "../../redux/constant";

export default function UseManualOnboard() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const allJobs = useSelector((state) => state.jobsReducer.allJobs);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const onChangeHandler = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;

    setData({
      ...data,
      [name]: value,
    });
  };
  const jobChangeHandler = (e) => {
    const id = e?.target?.value;
    const job = allJobs.find((item) => item.id === id);

    setData({
      ...data,
      appliedFor: job?.jobTitle,
      contractType: job?.contractType,
      jobId: id,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const submitHandle = async () => {
    try {
      const { name, email, appliedFor, jobId, contractType } = data;
      if (!name) {
        dispatch({
          type: ERROR_ALERT,
          payload: "Name is Required",
        });
      } else if (!email) {
        dispatch({
          type: ERROR_ALERT,
          payload: "Email is Required",
        });
      } else if (
        email &&
        !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ) {
        dispatch({
          type: ERROR_ALERT,
          payload: "Enter a Valid Email",
        });
      } else if (!appliedFor) {
        dispatch({
          type: ERROR_ALERT,
          payload: "OnBoard For is Required",
        });
      } else {
        setLoading(true);
        await axiosTechloset.post("emails/startOnBoarding", {
          email,
          name,
          appliedFor,
          contractType,
          jobId,
        });
        dispatch({
          type: SUCCESS_ALERT,
          payload: "OnBoard Invite Send Successfully",
        });
      }
      handleClose();
    } catch (error) {
      dispatch({
        type: SUCCESS_ALERT,
        payload: error,
      });
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (allJobs.length === 0) dispatch(getAllJobs(setLoading));
  }, []);
  return {
    open,
    allJobs,
    loading,
    data,
    handleClickOpen,
    handleClose,
    submitHandle,
    onChangeHandler,
    jobChangeHandler,
  };
}
