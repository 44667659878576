import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import { Grid, Box, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Select, DatePicker } from "antd";
import CircularLoader from "../utils/circularProgress";
import { useStyles, style } from "./style";
import {
  getAllJobsForModel,
  updateByAdmin,
} from "./redux/actions/updateAction";
import { getById } from "../profile/redux/actions/profileAction";
import moment from "moment";
import firebase from "firebase";
import { getAllJobTypes, getRanksbyTitle } from "../ranks/redux/actions";
import {
  accountTypes,
  city,
  countries,
  dualNationality,
  employeType,
  kinRelation,
  typesOfAddress,
} from "../profile/constants/constants";
import { getAllBanks } from "../banks/redux/actions/bankAction";
import { getAllContractTypes } from "../contract/redux/actions/contractTypeAction";
import ImageCrop from "../../components/imageCrop/ImageCrop";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";
const UpdateProfile = () => {
  const { id, type } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  var storageRef = firebase.storage().ref();

  const { userProfile } = useSelector((state) => state.profileReducer);
  const { allContractTypes } = useSelector(
    (state) => state?.contractTypeReducer,
  );

  const { jobCategory, jobLocation } = useSelector(
    (state) => state.userProfileReducer.jobs,
  );
  const { jobTypes, ranksData } = useSelector((state) => state.ranksReducer);
  const { allBanks } = useSelector((state) => state.bankReducer);
  const [userRecord, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [idCardFrontLoding, setidCardFrontLoding] = useState(false);
  const [idCardBackLoading, setidCardBackLoading] = useState(false);
  const [idCardFront, setidCardFront] = useState();
  const [idCardBack, setidCardBack] = useState();
  const [isProfileImg, setIsProfileImg] = useState();
  const [profileValue, setProfileValue] = useState(userProfile?.profilephoto);
  const [cropData, setCropData] = useState();
  const [idProfileImgLoading, setIsProfileImgLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [crop, setCrop] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const cropperRef = useRef(null);
  const inputRef = useRef(null);
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { Option } = Select;
  
  useEffect(() => {
    setData(userProfile);
    setidCardFront(userProfile?.idCardFront);
    setidCardBack(userProfile?.idCardBack);
    setIsProfileImg(userProfile?.profilephoto);
    dispatch(getRanksbyTitle(userProfile?.jobType, setLoading));
  }, [userProfile]);
  
  useEffect(() => {
    dispatch(getById(id, setLoading, type));
  }, [id]);
  
  useEffect(() => {
    dispatch(getAllJobsForModel(setLoading));
    dispatch(getAllJobTypes());
    if (allBanks.length === 0) {
      dispatch(getAllBanks(setLoading));
    }
    if (allContractTypes.length === 0)
      dispatch(getAllContractTypes(setLoading));
  }, []);
  
  const setState = (value) => {
    let updatedData = {
      ...userRecord,
      ...value,
    };
    setData(updatedData);
  };
  
  const selectJobType = (e) => {
    setState({ jobType: e });
    dispatch(getRanksbyTitle(e, setLoading));
  };
  
  function onSearch(val) {
    // console.log("search:", val);
  }
  const idCardHandler = async (file, handler, loader, profile = false) => {
    loader(true);
    if (profile) {
      setOpen(true);
    }
    const unique_id = parseInt(Math.random() * new Date().getTime());
    const fileName = `${userRecord?.email}-${unique_id}-${file?.name}`;
    let idCardRef = storageRef.child("IDCards/" + `${fileName}`);
    idCardRef.put(file).then(function (snapshot) {
      snapshot.ref
        .getDownloadURL()
        .then(function (downloadURL) {
          handler(downloadURL);
          loader(false);
        })
        .catch((err) => {
          alert(err.message);
          loader(false);
        });
    });
  };
  
  const handleUpdate = () => {
    const final = {
      ...userRecord,
      idCardFront,
      idCardBack,
      profilephoto: profileValue,
    };
    dispatch(updateByAdmin(id, final, setLoading, type));
    history.goBack();
  };
  
  let dob;
  if (userRecord?.DateOfBirth?.nanoseconds) {
    dob = moment(userRecord?.DateOfBirth?.toDate()).format("DD/MM/YYYY");
  } else {
    dob = moment(userRecord?.DateOfBirth).format("DD/MM/YYYY");
  }
  
  let nicIssue;
  if (userRecord?.nicIssueDate?.nanoseconds) {
    nicIssue = moment(userRecord?.nicIssueDate?.toDate()).format("DD/MM/YYYY");
  } else {
    nicIssue = moment(userRecord?.nicIssueDate).format("DD/MM/YYYY");
  }
  
  let nicExpiry;
  if (userRecord?.nicExpiryDate?.nanoseconds) {
    nicExpiry = moment(userRecord?.nicExpiryDate?.toDate()).format(
      "DD/MM/YYYY",
    );
  } else {
    nicExpiry = moment(userRecord?.nicExpiryDate).format("DD/MM/YYYY");
  }
  
  let jobStarted;
  if (userRecord?.JobStartedAt?.nanoseconds) {
    jobStarted = moment(userRecord?.JobStartedAt?.toDate()).format(
      "DD/MM/YYYY",
    );
  } else {
    jobStarted = moment(userRecord?.JobStartedAt).format("DD/MM/YYYY");
  }
  function compressImage(file) {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300, // max width
        300, // max height
        "JPEG", // format
        80, // quality
        0, // rotation
        (uri) => {
          resolve(uri);
        },
        "base64",
      );
    });
  }
  
  const MAX_SIZE = 1 * 1024 * 1024; // 1MB
  const onLoadImage = (e) => {
    const file = e.target.files && e.target.files[0];
    if (file.size > MAX_SIZE) {
      toast.error("Upload image less than 1MB");
      return;
    } 
    compressImage(file).then((uri) => {});
  
    if (file) {
      setIsProfileImg(URL.createObjectURL(file));
      setSelectedFile(file);
      setOpen(true);
    }
  
    e.target.value = "";
  };
  const onUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  
  const onPreview = () => {
    const cropper = cropperRef.current;
    if (cropper) {
      const canvas = cropper.getCanvas();
  
      setCropData(canvas.toDataURL());
    }
  };
  
  const onDone = () => {
    const cropper = cropperRef.current;
    if (cropper) {
      const canvas = cropper.getCanvas();

      setProfileValue(canvas.toDataURL());
      setIsProfileImgLoading(true);
      const unique_id = parseInt(Math.random() * new Date().getTime());
      const fileName = `${userRecord?.email}-${unique_id}-${selectedFile?.name}`;
      let profileRef = storageRef.child("profileImages/" + `${fileName}`);
      profileRef.putString(canvas.toDataURL(), "data_url").then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          setProfileValue(downloadURL);
          setIsProfileImgLoading(false);
        });
      });
    }
    setOpen(false);
  };

  return jobCategory && loading ? (
    <CircularLoader />
  ) : (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={style.paper}>
            <Typography style={style.text} variant="h3">
              Account
            </Typography>
            <Grid container spacing={1}>
              <Grid item md={2} sm={2} xs={12}>
                <img
                  style={style.image}
                  src={profileValue}
                  alt="profile"
                />
                {open && (
                  <>
                    <ImageCrop
                      src={isProfileImg}
                      open={open}
                      handleClose={handleClose}
                      handleOpen={handleOpen}
                      crop={crop}
                      setCrop={setCrop}
                      onDone={onDone}
                      cropperRef={cropperRef}
                      inputRef={inputRef}
                      onPreview={onPreview}
                      cropData={cropData}
                    />
                  </>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  className={"idCardFront"}
                  style={{ marginLeft: "30px" }}
                  component="label"
                  disabled={loading}
                  onClick={onUpload}
                >
                  {idProfileImgLoading ? "Updating..." : "Update"}
                  <input
                    type="file"
                    style={style.input}
                    hidden
                    onChange={onLoadImage}
                  />
                </Button>
              </Grid>
              <Grid item md={2} sm={2} xs={6}>
                <Typography style={style.first} component="div">
                  <label for="username">User Name</label>
                </Typography>
                <Typography style={style.middle} component="div">
                  <label for="email">Email</label>
                </Typography>
                <Typography style={style.middle} component="div">
                  <label for="phone">Mobile</label>
                </Typography>
                <Typography style={style.middle} component="div">
                  <label for="cnic">CNIC</label>
                </Typography>
                <Typography style={style.middle} component="div">
                  <label for="travelCard">Travel Card</label>
                </Typography>
                <Typography style={style.middle} component="div">
                  <label for="travelExpenditure">Travel Expenditure</label>
                </Typography>
                <Typography style={style.middle} component="div">
                  <label for="salary">Salary</label>
                </Typography>
                <Typography style={style.last} component="div">
                  <label for="jobStartedAt">Job Started</label>
                </Typography>
                <Typography style={style.last} component="div">
                  <label for="employmentType">Employment Type</label>
                </Typography>
              </Grid>

              <Grid item md={3} sm={2} xs={6}>
                <Typography component="div" style={style.detail}>
                  <Input
                    style={style.input}
                    id="username"
                    margin={"dense"}
                    value={userRecord.fullName}
                    onChange={(e) => setState({ fullName: e.target.value })}
                  />
                </Typography>
                <Typography component="div" style={style.detail}>
                  <Input
                    style={style.input}
                    id="email"
                    value={userRecord.email}
                    onChange={(e) => setState({ email: e.target.value })}
                    margin={"dense"}
                  />
                </Typography>
                <Typography style={style.detail} component="div">
                  <Input
                    style={style.input}
                    id="phone"
                    value={userRecord.phoneNumber}
                    onChange={(e) => setState({ phoneNumber: e.target.value })}
                    margin={"dense"}
                  />
                </Typography>
                <Typography component="div" style={style.detail}>
                  <Input
                    style={style.input}
                    onChange={(e) => setState({ idCardNumber: e.target.value })}
                    id="cnic"
                    value={userRecord.idCardNumber}
                  />
                </Typography>
                <Typography component="div" style={style.detail}>
                  <Input
                    style={style.input}
                    id="travelCard"
                    value={userRecord.travelCardNumber}
                    onChange={(e) =>
                      setState({ travelCardNumber: e.target.value })
                    }
                  />
                </Typography>
                <Typography component="div" style={style.detail}>
                  <Input
                    style={style.input}
                    value={userRecord.travelExpenditure}
                    id="travelExpenditure"
                    onChange={(e) =>
                      setState({ travelExpenditure: e.target.value })
                    }
                  />
                </Typography>
                <Typography style={style.detail} component="text">
                  <Input
                    style={style.input}
                    id="salary"
                    value={userRecord.basicPay}
                    onChange={(e) => setState({ basicPay: e.target.value })}
                    margin={"dense"}
                  />
                </Typography>
                <Typography
                  component="div"
                  style={{
                    width: "100%",
                    marginTop: "15px",
                    // marginBottom: "15px",
                  }}
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    onChange={(date, dateString) =>
                      setState({ JobStartedAt: date._d })
                    }
                    style={{ width: "90%" }}
                    placeholder={jobStarted}
                    size={"middle"}
                    id="jobStartedAt"
                  />
                </Typography>
                <Select
                  showSearch
                  style={{ width: "90%", marginTop: "17px" }}
                  placeholder={userRecord?.employmentType}
                  value={userRecord?.employmentType}
                  optionFilterProp="children"
                  onChange={(e) => setState({ employmentType: e })}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                   {employeType.map((item) => {
                      return <Option value={item}> {item}</Option>;
                    })}
                
                  
                </Select>
              </Grid>

              <Grid item md={2} sm={2} xs={6}>
                <Typography style={style.first} component="div">
                  <label for="education">Education</label>
                </Typography>
                <Typography style={style.middle} component="div">
                  <label for="experience">Experience</label>
                </Typography>
                <Typography style={style.middle} component="div">
                  <label for="jobLocation">Job Location</label>
                </Typography>
                <Typography style={style.middle} component="div">
                  <label for="jobType">Job Type</label>
                </Typography>
                <Typography style={style.middle} component="div">
                  <label for="jobCategory">Job Category</label>
                </Typography>
                <Typography style={style.last} component="div">
                  <label for="rankTitle">Rank Title</label>
                </Typography>
                <Typography style={style.last} component="div">
                  <label for="sortBy">Sort By</label>
                </Typography>
                <Typography style={style.last} component="div">
                  <label for="jobStartedAt">Contract Type</label>
                </Typography>
              </Grid>

              <Grid item md={3} sm={2} xs={6}>
                <Select
                  showSearch
                  style={style.input}
                  placeholder="Education"
                  optionFilterProp="children"
                  onChange={(e) => setState({ education: e })}
                  onSearch={onSearch}
                  value={userRecord.education}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Inter (OnGoing)">Inter (OnGoing)</Option>
                  <Option value="Inter (Completed)">Inter (Completed)</Option>
                  <Option value="Bachelor (OnGoing)">Bachelor (OnGoing)</Option>
                  <Option value="Bachelor (Completed)">Bachelor (Completed)</Option>
                  <Option value="Master (OnGoing)">Master (OnGoing)</Option>
                  <Option value="Master (Completed)">Master (Completed)</Option>
                </Select>

                <Select
                  showSearch
                  style={{ width: "90%", marginTop: "15px" }}
                  placeholder={userRecord.experience}
                  optionFilterProp="children"
                  onChange={(e) => setState({ experience: e })}
                  onSearch={onSearch}
                  value={userRecord.experience}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="One-Year">One Year</Option>
                  <Option value="Two-Year">Two Years</Option>
                  <Option value="Three-Year">Three Years</Option>
                  <Option value="Four-Year">Four Years</Option>
                  <Option value="Five-Year">Five Years</Option>
                  <Option value="Six-Year">Six Years</Option>
                </Select>

                <Select
                  showSearch
                  style={{ width: "90%", marginTop: "17px" }}
                  placeholder={userRecord.jobLocation}
                  value={userRecord.jobLocation}
                  optionFilterProp="children"
                  onChange={(e) => setState({ jobLocation: e })}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Faisalabad">Faisalabad</Option>
                </Select>
                <Select
                  showSearch
                  style={{ width: "90%", marginTop: "17px" }}
                  placeholder={userRecord.jobType}
                  optionFilterProp="children"
                  value={userRecord.jobType}
                  onChange={selectJobType}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {jobTypes?.map((job, index) => (
                    <Option value={job.type} key={job.type + index}>
                      {job.type}
                    </Option>
                  ))}
                </Select>

                <Select
                  showSearch
                  style={{ width: "90%", marginTop: "17px" }}
                  placeholder={userRecord.jobCategory}
                  value={userRecord.jobCategory}
                  optionFilterProp="children"
                  onChange={(e) => setState({ jobCategory: e })}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Onsite">Onsite </Option>
                  <Option value="Remote">Remote</Option>
                </Select>
                <Select
                  showSearch
                  style={{
                    width: "90%",
                    marginTop: "17px",
                    marginBottom: "17px",
                  }}
                  placeholder={userRecord.rankTitle}
                  value={userRecord.rankTitle}
                  optionFilterProp="children"
                  onChange={(e) => setState({ rankTitle: e })}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {ranksData?.ranks?.map((ranks, index) => (
                    <Option value={ranks.rank} key={index}>
                      {ranks.rank}
                    </Option>
                  ))}
                </Select>
                <Typography style={style.detail} component="text">
                  <Input
                    style={style.input}
                    id="sortBy"
                    type="number"
                    value={userRecord.sortBy}
                    onChange={(e) => setState({ sortBy: e.target.value })}
                    margin={"dense"}
                  />
                </Typography>
                <Select
                  showSearch
                  style={{ width: "90%", marginTop: "17px" }}
                  placeholder={userRecord?.contractType}
                  value={userRecord?.contractType}
                  optionFilterProp="children"
                  onChange={(e) => setState({ contractType: e })}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {allContractTypes?.map((contract, index) => (
                    <Option value={contract?.id} key={contract?.type + index}>
                      {contract?.type}
                    </Option>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={style.paper}>
            <Typography style={style.text} variant="h3">
              More Information
            </Typography>
            <Grid container>
              <Grid item md={4} xs={4}>
                <Typography style={style.first} variant="h6">
                  <label for="dateOfBirth">Date of Birth</label>
                </Typography>
                <Typography style={style.middle} variant="h6">
                  <label for="martialStatus">Martial Status</label>
                </Typography>
                <Typography style={style.middle} variant="h6">
                  <label for="gender">Gender</label>
                </Typography>
                <Typography style={style.last} variant="h6">
                  <label for="address">Address</label>
                </Typography>
              </Grid>
              <Grid item md={8} xs={8}>
                <Typography component="div" style={style.detail}>
                  <DatePicker
                    format="DD-MM-YYYY"
                    onChange={(date, dateString) =>
                      setState({ DateOfBirth: date._d })
                    }
                    style={{ width: "80%" }}
                    placeholder={dob}
                    size={"middle"}
                  />
                </Typography>
                <Select
                  showSearch
                  style={{ width: "80%", marginBottom: "15px" }}
                  placeholder={userRecord.martialStatus}
                  optionFilterProp="children"
                  onChange={(e) => setState({ martialStatus: e })}
                  onSearch={onSearch}
                  value={userRecord.martialStatus}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Single">Single</Option>
                  <Option value="Married">Married</Option>
                  <Option value="Widowed">Widowed</Option>
                  <Option value="Separated">Separated</Option>
                  <Option value="Divorced">Divorced</Option>
                </Select>
                <Select
                  showSearch
                  style={{ width: "80%", marginBottom: "15px" }}
                  placeholder={userRecord.gender}
                  optionFilterProp="children"
                  onChange={(e) => setState({ gender: e })}
                  onSearch={onSearch}
                  value={userRecord.gender}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </Select>
                <Typography style={style.detail} component="div">
                  <Input
                    disabled
                    style={{ width: "80%" }}
                    id="address"
                    onChange={(e) => setState({ address: e.target.value })}
                    margin={"dense"}
                    value={userRecord.address}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={style.paper}>
            <Typography style={style.text} variant="h3">
              Social Information
            </Typography>
            <Grid container>
              <Grid item md={4} xs={4}>
                <Typography style={style.first} variant="h6">
                  <label for="facebook">Facebook</label>
                </Typography>
                <Typography style={style.middle} variant="h6">
                  <label for="github">Github</label>
                </Typography>
                <Typography style={style.middle} variant="h6">
                  <label for="linkedIn">LinkedIn</label>
                </Typography>
                <Typography style={style.last} variant="h6">
                  <label for="skype">Skype</label>
                </Typography>
              </Grid>
              <Grid item md={8} xs={8}>
                <Typography style={style.detail} component="div">
                  <Input
                    style={style.input}
                    id="facebook"
                    margin={"dense"}
                    value={userRecord.facebook}
                    onChange={(e) => setState({ facebook: e.target.value })}
                  />
                </Typography>
                <Typography style={style.detail} component="div">
                  <Input
                    style={style.input}
                    id="github"
                    onChange={(e) => setState({ github: e.target.value })}
                    margin={"dense"}
                    value={userRecord.github}
                  />
                </Typography>
                <Typography style={style.detail} component="div">
                  <Input
                    style={style.input}
                    id="linkedIn"
                    onChange={(e) => setState({ linkedIn: e.target.value })}
                    margin={"dense"}
                    value={userRecord.linkedIn}
                  />
                </Typography>
                <Typography style={style.detail} component="div">
                  <Input
                    style={style.input}
                    id="skype"
                    margin={"dense"}
                    onChange={(e) => setState({ skype: e.target.value })}
                    value={userRecord.skype}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={style.paper}>
            <Typography style={style.text} variant="h3">
              Bank Details
            </Typography>
            <Grid container>
              <Grid item md={2} sm={2} xs={6}>
                <Typography style={style.first} component="div">
                  <label for="accountName">Bank Name</label>
                </Typography>
                <Typography style={style.middle} component="div">
                  <label for="branchCode"> Branch Code</label>
                </Typography>
                <Typography style={style.last} component="div">
                  <label for="accountIbn"> IBAN Number</label>
                </Typography>
              </Grid>

              <Grid item md={4} sm={4} xs={6}>
                <Typography style={style.detail} component="div">
                  <Select
                    id="bankName"
                    type="number"
                    showSearch
                    style={{ width: "90%" }}
                    optionFilterProp="children"
                    value={userRecord.accountName}
                    onChange={(e) => setState({ accountName: e })}
                  >
                    {allBanks && allBanks?.map((item) => {
                      return (
                        <Option value={item?.bankABR}> {item?.bankName}</Option>
                      );
                    })}
                  </Select>
                </Typography>
                <Typography style={style.detail} component="div">
                  <Input
                    id="accountBranchCode"
                    style={style.input}
                    placeholder="Account Branch Code"
                    type="number"
                    value={userRecord.accountBranchCode}
                    onChange={(e) =>
                      setState({ accountBranchCode: e.target.value })
                    }
                    margin={"dense"}
                  />
                </Typography>
                <Typography style={style.detail} component="text">
                  <Input
                    id="accountIbn"
                    style={style.input}
                    placeholder="Account IBAN"
                    value={userRecord.accountIbn}
                    onChange={(e) => setState({ accountIbn: e.target.value })}
                    margin={"dense"}
                  />
                </Typography>
              </Grid>

              <Grid item md={2} sm={2} xs={6}>
                <Typography style={style.first} component="div">
                  <label for="accontHolderName">Account Holder Name</label>
                </Typography>
                <Typography style={style.middle} component="div">
                  <label for="accountNumber">Account Number</label>
                </Typography>
                <Typography style={style.last} component="div">
                  <label for="accountType">Account Type</label>
                </Typography>
              </Grid>

              <Grid item md={4} sm={4} xs={6}>
                <Typography component="div" style={style.detail}>
                  <Input
                    id="accontHolderName"
                    style={style.input}
                    placeholder="Account Holder Name"
                    margin={"dense"}
                    value={userRecord.accountHolderName}
                    onChange={(e) =>
                      setState({ accountHolderName: e.target.value })
                    }
                  />
                </Typography>
                <Typography style={style.detail} component="div">
                  <Input
                    id="accountNumber"
                    style={style.input}
                    placeholder="Account Number"
                    value={userRecord.accountNumber}
                    onChange={(e) =>
                      setState({ accountNumber: e.target.value })
                    }
                    margin={"dense"}
                  />
                </Typography>
                <Typography style={style.detail} component="div">
                  <Select
                    id="accountType"
                    showSearch
                    placeholder="Account Type"
                    optionFilterProp="children"
                    value={userRecord.accountType}
                    onChange={(e) => setState({ accountType: e })}
                    style={{ width: "90%" }}
                  >
                    {accountTypes.map((item) => {
                      return <Option value={item}> {item}</Option>;
                    })}
                  </Select>
                </Typography>
              </Grid>

              <Grid item md={2} sm={2} xs={6}>
                <Typography style={style.first} component="div">
                  <label for="nationality">Nationality</label>
                </Typography>
                <Typography style={style.middle} component="div">
                  <label for="countryOfBirth">Country of Birth</label>
                </Typography>
                <Typography style={style.last} component="div">
                  <label for="countryOfResidence">Country of Residence</label>
                </Typography>
              </Grid>

              <Grid item md={4} sm={4} xs={6}>
                <Typography style={style.detail} component="div">
                  <Select
                    id="nationality"
                    showSearch
                    placeholder="Nationality"
                    optionFilterProp="children"
                    value={userRecord?.nationality}
                    onChange={(e) => setState({ nationality: e })}
                    style={{ width: "90%" }}
                  >
                    {countries.map((item) => {
                      return <Option value={item}> {item}</Option>;
                    })}
                  </Select>
                </Typography>
                <Typography style={style.detail} component="div">
                  <Select
                    id="countryOfBirth"
                    showSearch
                    placeholder="Country of Birth"
                    optionFilterProp="children"
                    value={userRecord?.countryOfBirth}
                    onChange={(e) => setState({ countryOfBirth: e })}
                    style={{ width: "90%" }}
                  >
                    {countries.map((item) => {
                      return <Option value={item}> {item}</Option>;
                    })}
                  </Select>
                </Typography>
                <Typography style={style.detail} component="div">
                  <Select
                    id="countryOfResidence"
                    showSearch
                    placeholder="Country of Residence"
                    optionFilterProp="children"
                    value={userRecord?.countryOfResidence}
                    onChange={(e) => setState({ countryOfResidence: e })}
                    style={{ width: "90%" }}
                  >
                    {countries.map((item) => {
                      return <Option value={item}> {item}</Option>;
                    })}
                  </Select>
                </Typography>
              </Grid>

              <Grid item md={2} sm={2} xs={6}>
                <Typography style={style.first} component="div">
                  <label for="dualNationality">Dual Nationality</label>
                </Typography>
                <Typography style={style.middle} component="div">
                  <label for="cityOfBirth">City of Birth</label>
                </Typography>
                <Typography style={style.last} component="div">
                  <label for="customerVisuallyImpaired">
                    Customer Visually Impaired
                  </label>
                </Typography>
              </Grid>

              <Grid item md={4} sm={4} xs={6}>
                <Typography style={style.detail} component="div">
                  <Select
                    id="dualNationality"
                    showSearch
                    placeholder="Dual Nationality"
                    optionFilterProp="children"
                    value={userRecord?.dualNational}
                    onChange={(e) => setState({ dualNational: e })}
                    style={{ width: "90%" }}
                  >
                    {dualNationality?.map((item) => {
                      return <Option value={item}> {item}</Option>;
                    })}
                  </Select>
                </Typography>
                <Typography style={style.detail} component="div">
                  <Select
                    id="cityOfBirth"
                    showSearch
                    placeholder="City of Birth"
                    optionFilterProp="children"
                    value={userRecord?.cityofBirth}
                    onChange={(e) => setState({ cityofBirth: e })}
                    style={{ width: "90%" }}
                  >
                    {city.map((item) => {
                      return <Option value={item}> {item}</Option>;
                    })}
                  </Select>
                </Typography>
                <Typography style={style.detail} component="div">
                  <Select
                    id="customerVisuallyImpaired"
                    showSearch
                    placeholder="Customer Visually Impaired"
                    optionFilterProp="children"
                    value={userRecord?.customerVisuallyImpaired}
                    onChange={(e) => setState({ customerVisuallyImpaired: e })}
                    style={{ width: "90%" }}
                  >
                    {dualNationality.map((item) => {
                      return <Option value={item}> {item}</Option>;
                    })}
                  </Select>
                </Typography>
              </Grid>

              <Grid item md={2} sm={2} xs={6}>
                <Typography style={style.first} component="div">
                  <label for="nicIssue">CNIC Issue Date</label>
                </Typography>
                <Typography style={style.middle} component="div">
                  <label for="motherName">Mother Maiden Name</label>
                </Typography>
                <Typography style={style.last} component="div">
                  <label for="citizenTax">
                    Citizen TaxRasident Other Than Pakistan
                  </label>
                </Typography>
              </Grid>

              <Grid item md={4} sm={4} xs={6}>
                <Typography component="div" style={style.detail}>
                  <DatePicker
                    format="DD-MM-YYYY"
                    onChange={(date, dateString) => {
                      const formattedDate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
                      setState({ nicIssueDate: formattedDate });
                    }}
                    style={{ width: "90%" }}
                    placeholder={nicIssue}
                    size={"middle"}
                  />
                </Typography>
                <Typography style={style.detail} component="div">
                  <Input
                    id="motherName"
                    style={style.input}
                    placeholder="Mother Maiden Name"
                    value={userRecord?.motherMaidenName}
                    onChange={(e) =>
                      setState({ motherMaidenName: e.target.value })
                    }
                    margin={"dense"}
                  />
                </Typography>
                <Typography style={style.detail} component="div">
                  <Select
                    id="citizenTax"
                    showSearch
                    placeholder="Citizen TaxRasident Other Than Pakistan"
                    optionFilterProp="children"
                    value={userRecord?.citizenTaxRasidenceOtherThanPakistan}
                    onChange={(e) =>
                      setState({ citizenTaxRasidenceOtherThanPakistan: e })
                    }
                    style={{ width: "90%" }}
                  >
                    {dualNationality.map((item) => {
                      return <Option value={item}> {item}</Option>;
                    })}
                  </Select>
                </Typography>
              </Grid>

              <Grid item md={2} sm={2} xs={6}>
                <Typography style={style.first} component="div">
                  <label for="nicExpiry">CNIC Expiry Date</label>
                </Typography>
                <Typography style={style.middle} component="div">
                  <label for="husbandName">Husband Name</label>
                </Typography>
                <Typography style={style.last} component="div">
                  <label for="pep">PEP</label>
                </Typography>
              </Grid>

              <Grid item md={4} sm={4} xs={6}>
                <Typography component="div" style={style.detail}>
                  <DatePicker
                    format="DD-MM-YYYY"
                    onChange={(date, dateString) => {
                      const formattedDate = moment(date, "DD-MM-YYYY").format(
                        "YYYY-MM-DD",
                      );
                      setState({ nicExpiryDate: formattedDate });
                    }}
                    style={{ width: "90%" }}
                    placeholder={nicExpiry}
                    size={"middle"}
                  />
                </Typography>
                <Typography style={style.detail} component="div">
                  <Input
                    id="husbandName"
                    style={style.input}
                    placeholder="Husband Name"
                    value={userRecord?.husbandName}
                    onChange={(e) => setState({ husbandName: e.target.value })}
                    margin={"dense"}
                  />
                </Typography>
                <Typography style={style.detail} component="div">
                  <Select
                    id="pep"
                    showSearch
                    placeholder="PEP"
                    optionFilterProp="children"
                    value={userRecord?.pep}
                    onChange={(e) => setState({ pep: e })}
                    style={{ width: "90%" }}
                  >
                    {dualNationality.map((item) => {
                      return <Option value={item}> {item}</Option>;
                    })}
                  </Select>
                </Typography>
              </Grid>

              <Grid item md={2} sm={2} xs={6}>
                <Typography style={style.first} component="div">
                  <label for="perCity">Permanent City</label>
                </Typography>
                <Typography style={style.middle} component="div">
                  <label for="kinName">Next of Kin Name</label>
                </Typography>
              </Grid>

              <Grid item md={4} sm={4} xs={6}>
                <Typography style={style.detail} component="div">
                  <Select
                    id="perCity"
                    showSearch
                    placeholder="Permanent City"
                    optionFilterProp="children"
                    value={userRecord?.permanentCity}
                    onChange={(e) => setState({ permanentCity: e })}
                    style={{ width: "90%" }}
                  >
                    {city.map((item) => {
                      return <Option value={item}> {item}</Option>;
                    })}
                  </Select>
                </Typography>
                <Typography style={style.detail} component="div">
                  <Input
                    id="kinName"
                    style={style.input}
                    placeholder="Next of Kin Name"
                    value={userRecord?.nextOfKinName}
                    onChange={(e) =>
                      setState({ nextOfKinName: e.target.value })
                    }
                    margin={"dense"}
                  />
                </Typography>
              </Grid>

              <Grid item md={2} sm={2} xs={6}>
                <Typography style={style.first} component="div">
                  <label for="addressTypes">Types of Address</label>
                </Typography>
                <Typography style={style.middle} component="div">
                  <label for="kinRelation">Next of Kin Relation</label>
                </Typography>
              </Grid>

              <Grid item md={4} sm={4} xs={6}>
                <Typography style={style.detail} component="div">
                  <Select
                    id="addressTypes"
                    showSearch
                    placeholder="Types of Address"
                    optionFilterProp="children"
                    value={userRecord?.typeOfAddress}
                    onChange={(e) => setState({ typeOfAddress: e })}
                    style={{ width: "90%" }}
                  >
                    {typesOfAddress.map((item) => {
                      return <Option value={item}> {item}</Option>;
                    })}
                  </Select>
                </Typography>
                <Typography style={style.detail} component="div">
                  <Select
                    id="kinRelation"
                    showSearch
                    placeholder="Next of Kin Relation"
                    optionFilterProp="children"
                    value={userRecord?.nextOfKinRelation}
                    onChange={(e) => setState({ nextOfKinRelation: e })}
                    style={{ width: "90%" }}
                  >
                    {kinRelation.map((item) => {
                      return <Option value={item}> {item}</Option>;
                    })}
                  </Select>
                </Typography>
              </Grid>

              <Grid item md={2} sm={2} xs={6}>
                <Typography style={style.first} component="div">
                  <label for="kinphone">Nex of kin Mobile</label>
                </Typography>
              </Grid>

              <Grid item md={4} sm={4} xs={6}>
                <Typography style={style.detail} component="div">
                  <Input
                    id="kinphone"
                    style={style.input}
                    placeholder="Nex of kin Mobile"
                    value={userRecord?.nextOfKinPhoneNumber}
                    onChange={(e) =>
                      setState({ nextOfKinPhoneNumber: e.target.value })
                    }
                    margin={"dense"}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={style.paper}>
            <Typography style={style.text} variant="h3">
              ID Card Front
              <Button
                variant="contained"
                color="primary"
                className={"idCardFront"}
                style={{ float: "right", marginRight: "15px" }}
                component="label"
                disabled={loading}
              >
                {idCardFrontLoding ? "Updating..." : "Update"}
                <input
                  type="file"
                  style={style.input}
                  hidden
                  onChange={(e) => {
                    idCardHandler(
                      e.target.files[0],
                      setidCardFront,
                      setidCardFrontLoding,
                    );
                  }}
                />
              </Button>
            </Typography>
            <Grid
              container
              alignItems="center"
              style={{ paddingBottom: "8%" }}
              justify="center"
            >
              <img
                src={idCardFront}
                width="80%"
                height="300px"
                alt="idCardFront"
              />
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <Paper style={style.paper}>
            <Typography style={style.text} variant="h3">
              ID Card Back
              <Button
                variant="contained"
                color="primary"
                className={"idCardBack"}
                style={{ float: "right", marginRight: "15px" }}
                component="label"
                value="Update"
              >
                {idCardBackLoading ? "Updating..." : "Update"}
                <input
                  type="file"
                  style={style.input}
                  hidden
                  onChange={(e) => {
                    idCardHandler(
                      e.target.files[0],
                      setidCardBack,
                      setidCardBackLoading,
                    );
                  }}
                />
              </Button>
            </Typography>
            <Grid
              container
              alignItems="center"
              style={{ paddingBottom: "8%" }}
              justify="center"
            >
              <img
                src={idCardBack}
                width="80%"
                height="300px"
                alt="idCardFront"
              />
            </Grid>
          </Paper>
        </Grid>
        <Grid item md={12} xs={12} sm={12}>
          <Box display="flex" justifyContent="flex-end" p={2}>
            {loading ? (
              <div className={classes.progress}>
                <CircularProgress />
              </div>
            ) : (
              <>
                {loading ? null : (
                  <Button
                    variant="contained"
                    style={style.BackBtn}
                    color="primary"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Back
                  </Button>
                )}
                <Button
                  variant="contained"
                  style={style.approveBtn}
                  color="primary"
                  onClick={handleUpdate}
                >
                  Update
                </Button>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateProfile;
