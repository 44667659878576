import { db } from "../../../../../config/firebase";
import axiosTechloset from "../../../../../config/axiosTechloset";
import moment from "moment";
import axios from 'axios';
import { recursionDates } from "../../utils";
import {
  FETCH_EVENTS,
  HANDLE_SIDEBAR,
  UPDATE_EVENT,
  UPDATE_DRAG,
  EVENT_RESIZE,
  ADD_EVENT,
  HANDLE_SELECTED_EVENT,
  ERROR_ALERT,
  SUCCESS_ALERT,
  DELETE_EVENT,
  UPDATE_REC_EVENT,
} from "../constants/constant";

export const fetchEvents = () => async (dispatch) => {
  try {
    // setloading(true);
    await db
      .collection("Events")
      .get()
      .then((querySnapshot) => {
        const allEvents = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        dispatch({ type: FETCH_EVENTS, payload: allEvents });
      });
    // setloading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    // setloading(false);
  }
};

export const handleSidebar = (bool) => {
  return (dispatch) => dispatch({ type: HANDLE_SIDEBAR, status: bool });
};

export const addEvent = (data) => async (dispatch) => {
  // console.log("data", data);
  try {
    // setloading(true);
    const { id, allDay, selectable, ...rest } = data;
    const docRef = db.collection("Events").doc();
    await docRef.set(rest);
    dispatch({
      type: ADD_EVENT,
      payload: { ...rest, id: docRef.id },
    });
    dispatch({ type: SUCCESS_ALERT, payload: "Event Added Successfully." });
    axios.post("http://localhost:4000/emails/zoominvetation",{zoomInvitation:"Yeah Test Is Working.."})
    .then((res)=>{
      dispatch({type: SUCCESS_ALERT})
    })
    .catch((err)=>{
      dispatch({ type: ERROR_ALERT });
      console.log(err);
    })
    axiosTechloset
      .post(`/slack/general`, {
        title: data.label,
        date: "work on it",
        description: data.description,
        type: data.label,
      })
      .then(
        (response) => {
          dispatch({ type: SUCCESS_ALERT, payload: response.data });
        },
        (error) => {
          dispatch({ type: ERROR_ALERT, payload: error.message });
          console.log(error);
        },
      );
      // const fetchData=async ()=>{
      //   var zoomInvitation="test",
      //             var res= await fetch("http://localhost:4000/emails/zoominvetation",{
      //               method:"POST",
      //               headers:{
      //                 'Content-Type': 'application/json',
      //               },
      //               body: JSON.stringify({zoomInvitation})
      //             });
                
                
      //             const data =await res.json();
      //             if(data.status===200){
      //               window.alert("Email Sent");
      //               console.log("Email  Sent")
      //             }else{
      //               window.alert("Failed");
      //               console.log("Failed")
      //             }
      //            }
      //           fetchData();
                
  } catch (error) {
    console.log(error);
    // const zoomInvitation="test"
    // axios.post(`/http://localhost:4000/emails/zoominvetation/${zoomInvitation}`)
    // .then(()=>{
    //   console.log("Test Email Send To Your Gmail")
    // })
    // .catch((err)=>{
    //   console.log(err)
    // })
    dispatch({ type: ERROR_ALERT, payload: error.message });
    // setloading(false);
  }
};

export const updateEvent = (data) => async (dispatch) => {
  try {
    // setloading(true);
    // console.log("action", data);
    const { id, ...rest } = data;
    await db.collection("Events").doc(data.id).update(rest);
    let test = [];
    if (data.recursion) {
      test = recursionDates(data);
      // console.log("test", test);
      dispatch({ type: UPDATE_REC_EVENT, payload: { data, test } });
    } else dispatch({ type: UPDATE_EVENT, payload: data });
    dispatch({ type: SUCCESS_ALERT, payload: "Event Updated Successfully." });
    // setloading(false);
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};

export const updateDrag = (event) => async (dispatch) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_DRAG, event });
  };
};

export const updateResize = (event) => async (dispatch) => {
  try {
    const { id, ...rest } = event;
    await db.collection("Events").doc(event.id).update(rest);
    let test = [];
    if (event.recursion) {
      test = recursionDates(event);
      // console.log("test", test);
      dispatch({ type: UPDATE_REC_EVENT, payload: { event, test } });
      dispatch({ type: EVENT_RESIZE, event });
    } else dispatch({ type: UPDATE_EVENT, payload: event });
    dispatch({ type: EVENT_RESIZE, event });
    dispatch({ type: SUCCESS_ALERT, payload: "Event Updated Successfully." });
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};

export const handleSelectedEvent = (event) => {
  return (dispatch) => dispatch({ type: HANDLE_SELECTED_EVENT, event });
};
export const handleRemoveEvent = (id) => async (dispatch) => {
  try {
    // setloading(true);
    await db.collection("Events").doc(id).delete();
    dispatch({ type: DELETE_EVENT, payload: id });
    dispatch({ type: SUCCESS_ALERT, payload: "Event Deleted Successfully." });
    // setloading(false);
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};
