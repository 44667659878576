export const GET_USER_DATA = "GET_USER_DATA";
export const GET_BOOTCAMPER_DATA = "GET_BOOTCAMPER_DATA";
export const ERROR_ALERT = "ERROR_ALERT";
export const FETCH_ALL_EVENTS = "FETCH_ALL_EVENTS";
export const SET_EMPLOYEES = "SET_EMPLOYEES";
export const SET_BOOTCAMPER_TEAM = "SET_BOOTCAMPER_TEAM";
export const ACCESS_KEY = {
  team: "team",
  contractType: "contractType",
};
export const permissions = [{ label: "Team" }];
export const sectionOptions = [
  {
    title: "Basic Information",
    value: "basicInformation",
  },
  {
    title: "Contact Detail",
    value: "contactDetail",
  },
  {
    title: "Education",
    value: "education",
  },
  {
    title: "Experience",
    value: "experience",
  },
];
export const fieldTypeOptions = [
  {
    title: "Text",
    value: "text",
  },
  {
    title: "File",
    value: "file",
  },
  {
    title: "Text Area",
    value: "textArea",
  },
  {
    title: "Select",
    value: "select",
  },
];
