import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchBootcampSpecificDate,
    updateBootcampAttendance,
} from "./redux/actions/attendanceAction";
import moment from "moment";
import { getBootcampUserData } from "../../commonRedux/common/action";

export default function useBootcampAttendance() {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const { allBootcampUser } = useSelector((state) => state.attendanceReducer);
  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const users = useSelector((state) => state.commonReducer.allBootcampTeam);
  const [updateLoading, setupdateLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [index, setindex] = useState(null);
  const [open, setOpen] = useState(false);


  useEffect(() => {
    if (users.length < 1) {
      dispatch(getBootcampUserData(setDataLoading));
    } else setDataLoading(false);
    dispatch(fetchBootcampSpecificDate(setloading, new Date()));
  }, []);

  const handleClickOpen = (i) => {
    setindex(i);
    setIsModalVisible(true);
    setOpen(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleStatusUpdate = (i, status) => {
    setIsModalVisible(false);
    let updateStart;
    let updateEnd;
    if (status === "late") {
      const splitStartTime = startTime.split(":");
      let startHours = Number(splitStartTime[0]);
      let startMinutes = Number(splitStartTime[1]);
      let startSeconds = Number(splitStartTime[2]);
      const splitEndTime = endTime.split(":");
      let endHours = Number(splitEndTime[0]);
      let endMinutes = Number(splitEndTime[1]);
      let endSeconds = Number(splitEndTime[2]);
      updateStart = new Date(
        moment(date)
          .toDate()
          .setHours(startHours, startMinutes, startSeconds, 0),
      );
      updateEnd = new Date(
        moment(date).toDate().setHours(endHours, endMinutes, endSeconds, 999),
      );
    } else {
      updateStart = new Date(moment(date).toDate().setHours(0, 0, 0, 0));
      updateEnd = new Date(moment(date).toDate().setHours(23, 59, 59, 999));
    }
    const updated = {
      ...allBootcampUser[i],
      date,
    };
    const userData = {
      ...updated,
      start: updateStart,
      end: updateEnd,
      title: status,
    };
    dispatch(
      updateBootcampAttendance(userData, status, setupdateLoading, moment(date)),
    );
  };

  const handleDateUpdate = (a) => {
    setDate(moment(a).toDate());
    setStartTime(new Date(moment(a).toDate().setHours(0, 0, 0, 0)));
    setEndTime(new Date(moment(a).toDate().setHours(23, 59, 59, 999)));
    dispatch(fetchBootcampSpecificDate(setloading, moment(a).toDate()));
  };


  return {
    loading,
    allBootcampUser,
    date,
    handleDateUpdate,
    handleStatusUpdate,
    updateLoading,
    handleCancel,
    handleClickOpen,
    isModalVisible,
    index,
  }
}
