import { db } from "../../config/firebase";
import {
  GET_USER_DATA,
  ERROR_ALERT,
  FETCH_ALL_EVENTS,
  SET_EMPLOYEES,
  GET_BOOTCAMPER_DATA,
  SET_BOOTCAMPER_TEAM,
} from "./constants";

export const fetchAllEvents = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    await db
      .collection("Events")
      .get()
      .then((querySnapshot) => {
        const allEvents = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        // console.log("allEvents", allEvents);
        dispatch({ type: FETCH_ALL_EVENTS, payload: allEvents });
      });
    // console.log("====================================");
    // console.log("fetchAllEvents");
    // console.log("====================================");
    setloading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setloading(false);
  }
};

export const getUserData = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    const userData = await db
      .collection("UserData")
      .where("employmentType", "!=", "Bootcamp")
      .get();
    const listItems = userData?.docs?.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    // console.log("listItems", listItems);
    const allEmployee = listItems.filter((el) => el.status === "approved");
    // console.log("====================================");
    // console.log("allEmployee", allEmployee);
    // console.log("====================================");
    dispatch({ type: GET_USER_DATA, payload: listItems });
    dispatch({ type: SET_EMPLOYEES, payload: allEmployee });
    setloading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setloading(false);
  }
};

export const getBootcampUserData = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    const bootcampUserData = await db
      .collection("UserData")
      .where("employmentType", "==", "Bootcamp")
      .get();
    const listItems = bootcampUserData?.docs?.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const allBootcampTeam = listItems.filter((el) => el.status === "approved");
    dispatch({ type: GET_BOOTCAMPER_DATA, payload: listItems });
    dispatch({ type: SET_BOOTCAMPER_TEAM, payload: allBootcampTeam });
    setloading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setloading(false);
  }
};
