import React, { useState } from 'react';
import { CircularProgress, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Add } from '@material-ui/icons'
import { useDispatch } from 'react-redux';
import { useStyles } from '../../style';
import { addQuestion } from '../../redux/action';

export default function AddQuestion({ data }) {
    const [loading, setLoading] = useState(false);
    const [additemValue, setAdditemValue] = useState("");
    const dispatch = useDispatch()
    const classes = useStyles();

    const addHandler = () => {
        if (additemValue) {
            dispatch(addQuestion(additemValue, data, setLoading))
        }
        dialogClose()
    }

    const dialogClose = () => {
        setAdditemValue('')
    }

    return (
        <div className={classes.fieldDiv}>
            <TextField
                autoFocus
                fullWidth
                multiline
                label="Add Question"
                variant='outlined'
                value={additemValue}
                onChange={(e) => { setAdditemValue(e.target.value) }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment>
                            {
                                loading ? <CircularProgress size={30} /> :
                                    <IconButton
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { addHandler() }}
                                    >
                                        <Add />
                                    </IconButton>
                            }
                        </InputAdornment>
                    )
                }}
            />
        </div>
    )
}