import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Button, FormGroup } from 'reactstrap';
import useStyles from '../style';
import PageTitle from '../../../components/PageTitle/PageTitle';
import techlosetImage from '../../../assets/JobApplication.png';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionsByType } from '../../interviewQuestions/redux/action';
import { Menu, TextField, Typography } from '@material-ui/core';
import { addQuestionAnswers, handleInterviewDetailSidebar } from '../redux/interviewAction/interviewAction';
import { endInterviewSlack, interviewed } from '../../jobsRequests/redux/actions/jobsRequestsAction';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function QuestionsList({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const { interviewQuestions, interviewAllData } = useSelector((state) => state.InterviewQuestionsReducer)
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(null);
  const [questions, setQuestions] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(handleInterviewDetailSidebar(false));
  };

  const handleInput = (oldData, answer) => {
    let array = questions.map((doc) => {
      if (doc.id === oldData.id) {
        return { ...oldData, answer }
      } else {
        return doc
      }
    })
    setQuestions(array)
  }

  const addAnswers = () => {
    dispatch(addQuestionAnswers(data, questions))
  }

  const EndHandler = () => {
    handleClose()
    setOpenDialog(null)
    dispatch(interviewed(data, questions))
    dispatch(endInterviewSlack(data, questions))
  }

  useEffect(() => {
    if (data?.user?.questions?.length) {
      setQuestions(data?.user?.questions)
      return;
    } else {
      if (data?.user.appliedFor) {
        const questionsFind = interviewAllData?.find((doc) => doc.interviewFor === data?.user.appliedFor);
        if (questionsFind) {
          dispatch(getQuestionsByType(data?.user.appliedFor));
          return;
        } else {
          dispatch(getQuestionsByType("General"));
          return;
        }
      } else {
        dispatch(getQuestionsByType("General"))
      }
    }
  }, [data?.user.appliedFor]);

  useEffect(() => {
    if (interviewQuestions?.questions?.length) {
      setQuestions(interviewQuestions?.questions)
    }
  }, [interviewQuestions?.interviewFor]);

  return (
    <div>
      <Button.Ripple color="primary" onClick={handleClickOpen}>
        Start Interview
      </Button.Ripple>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </AppBar>
        <div className={classes.dialogContainer}>
          <PageTitle title={data?.user.appliedFor ? `${data?.user.appliedFor} Interview Questions` : 'General Interview Questions'} />
          {
            questions?.length ?
              <>
                <div className={classes.formMain}>
                  {
                    questions?.map((doc, index) => {
                      return (
                        <div className={classes.singleField} key={doc.question + index}>
                          <FormGroup>
                            <h4 style={{ whiteSpace: 'break-spaces' }}><strong>Question:</strong>{` ${doc.question}`}</h4>
                            <TextField
                              fullWidth
                              multiline
                              variant='outlined'
                              type="text"
                              label="Answer"
                              value={doc?.answer}
                              onChange={(e) => handleInput(doc, e.target.value)}
                              onBlur={addAnswers}
                            />
                          </FormGroup>
                        </div>
                      )
                    })
                  }
                </div>
                <div className={classes.endButton}>
                  <Button.Ripple
                    color="primary"
                    onClick={(e) => { setOpenDialog(e.currentTarget) }}
                  >
                    End Interview
                  </Button.Ripple>
                  <Menu
                    id="simple-menu"
                    anchorEl={openDialog}
                    keepMounted
                    open={Boolean(openDialog)}
                    onClose={() => { setOpenDialog(null) }}
                  >
                    <div className={classes.dialogContain}>
                      <Typography>
                        <h4>Really want to end interview</h4>
                      </Typography>
                    </div>
                    <div className={classes.dialogButtons}>
                      <Button.Ripple
                        color="primary"
                        onClick={() => { setOpenDialog(null) }}
                      >
                        Cancel
                      </Button.Ripple>
                      <Button.Ripple
                        color="primary"
                        onClick={EndHandler}
                      >
                        End
                      </Button.Ripple>
                    </div>
                  </Menu>
                </div>
              </> :
              <center>
                <img src={techlosetImage} alt='Techloset Image' />
                <h1>No Questions Available!!!</h1>
              </center>
          }
        </div>
      </Dialog>
    </div>
  );
}
