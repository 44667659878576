import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllContractTypes } from "./redux/actions/contractTypeAction";
import CircularLoader from "../utils/circularProgress/index";
import TableComponent from "./ContractTypeTable";

export default function ContractTypes() {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  useEffect(() => {
    dispatch(getAllContractTypes(setloading));
  }, []);
  return <>{loading ? <CircularLoader /> : <TableComponent />}</>;
}
