import React, { Suspense, memo } from "react";
import { Switch, Redirect, BrowserRouter as Router } from "react-router-dom";
import { routes } from "../routes";
import PrivateRoute from "../hoc/PrivateRoute";
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getFirstTab } from "../utils/share";
const TheContent = () => {
  const { details } = useSelector((state) => state.userReducer);
  const tabs = details?.visibleTabs || [];
  return (
      <Suspense fallback={CircularProgress}>
        <Switch>
          {routes.map((route, idx) => {
            return (
              route.component && (
                <PrivateRoute
                  key={idx}
                  path={route.path}
                  exact={route?.exact}
                  name={route.name}
                  component={route.component}
                  protection={route.protection}
                />
              )
            );
          })}
          <Redirect exact from="/" to={getFirstTab(tabs)} />
          <Redirect from="*" to="/notfound" />
        </Switch>
      </Suspense>
  );
};
export default memo(TheContent);
