import React, { useState } from "react";
import ExpandButton from "./button";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useHistory } from "react-router-dom";
import {
  successMessage,
  declineLetter,
  approveLetter,
} from "./redux/actions/lettersRequestAction";
import { Chip, CircularProgress } from "@material-ui/core";
import useStyles from "./style";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const Table = () => {
  const { allLetters } = useSelector((state) => state.lettersRequestReducer);
  const classes = useStyles();

  const dispatch = useDispatch();
  const [copyValue, setCopyValue] = useState("");
  const [copy, setCopy] = useState(false);
  const [loading, setloading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [index, setindex] = useState(null);
  const [approveLoader, setapproveLoader] = useState(false);

  const history = useHistory();

  const handleClickOpen = (i) => {
    setindex(i);
    setOpen(true);
  };

  const handleClose = () => {
    setindex(null);
    setOpen(false);
  };

  const handleCopy = (value, fieldName) => {
    setCopyValue(value);
    setCopy(true);
    dispatch(successMessage(`${value} copied.`));
  };

  const states = {
    approved: "approved",
    request: "request",
    send: "send",
  };

  const handleMore = (i) => {
    setindex(i);
    dispatch(approveLetter(allLetters[i], setapproveLoader));
  };

  const handleDecline = () => {
    setOpen(false);
    dispatch(declineLetter(allLetters[index], setloading));
  };

  const columns = [
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let email = allLetters?.[dataIndex]?.email?.toLowerCase() || "";
          return (
            <CopyToClipboard
              text={email}
              onCopy={() => handleCopy(email, "email")}
            >
              <span>{email}</span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "letterType",
      label: "Type",
      options: {
        filter: true,
      },
    },
    {
      name: "reason",
      label: "Reason",
      options: {
        filter: true,
      },
    },

    {
      name: "requestStatus",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          let status = allLetters[dataIndex]?.requestStatus;
          return (
            <Chip
              label={status}
              classes={{
                root: classes[states[status]],
              }}
            />
          );
        },
      },
    },
    {
      name: "Actions",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = allLetters[dataIndex]?.requestStatus;
          return (
            <>
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={() => handleMore(dataIndex)}
                disabled={status === "approved" || status === "send"}
              >
                {approveLoader ? (
                  <CircularProgress
                    className={classes.circularProgress}
                    size={25}
                  />
                ) : (
                  "Approve"
                )}
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleClickOpen(dataIndex)}
                className={classes.btnMargin}
                disabled={status === "approved" || status === "send"}
              >
                {loading ? (
                  <CircularProgress
                    className={classes.circularProgress}
                    size={25}
                  />
                ) : (
                  "Decline"
                )}
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  history.replace(
                    `/experienceletter/${allLetters[dataIndex].userId}/${allLetters[dataIndex].id}`,
                  )
                }
                className={classes.btnMargin}
                disabled={status === "send" || status === "request"}
              >
                Manage
              </Button>
            </>
          );
        },
      },
    },
  ];

  const options = {
    searchPlaceholder: "Search here...",
    filter: false,
    searchAlwaysOpen: true,
    responsive: "standard",
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    selectableRows: false,

    isRowExpandable: (dataIndex, expandedRows) => {
      return true;
    },
    rowsExpanded: [0, 1],
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const reason = allLetters[rowMeta.dataIndex]?.reason;
      return (
        <TableRow>
          <TableCell
            style={{ paddingLeft: "5%" }}
            align="left"
            colSpan={colSpan}
          >
            Reason: {reason}
          </TableCell>
        </TableRow>
      );
    },
  };

  const theme = createTheme({
    overrides: {
      MUIDataTableHeadCell: {
        sortAction: {
          fontSize: "1rem",
          fontWeight: "bolder",
        },
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          // Soft hide the button.
          visibility: "hidden",
        },
      },
    },
  });

  const components = {
    ExpandButton: function (props) {
      if (props.dataIndex === 3 || props.dataIndex === 4)
        return <div style={{ width: "24px" }} />;
      return <ExpandButton {...props} />;
    },
  };

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          className="mainTable"
          data={allLetters}
          columns={columns}
          options={options}
          components={components}
        />
      </MuiThemeProvider>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure for decline this request?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If you decline the request it will erase from database permanently,
            Please consider it!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDecline} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Table;
