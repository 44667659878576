import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        minWidth: 300
    },
    media: {
        height: 70,
        textAlign: 'center'
    },
    alignCard: {
        display: 'flex',
        flexWrap: "wrap",
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        padding:"20px"
    },
    padding: {
        padding:"5px"
    },
    addButton: {
        float:"right",
        margin:"10px"
    },
    textField: {
        margin:"10px",
        width: '100%',
        border:"none"
    },
    label: {
        marginLeft: '10px',
        marginTop: '10px'
    },
    rankDiv: {
        width:"100%",
        padding: "20px",
        margin:"0 auto 10px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
        display:"flex",
        justifyContent:'space-evenly',
    },
    rankText: {
        width: "300px",
        margin: "20px"
    },
    defaultImage: {
        margin:'auto',
        width:"50%"
    },
    fieldDiv: {
        margin: '20px 0',
        minWidth: "400px",
        maxWidth: '600px'
    }
}));