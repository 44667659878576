import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllJobTypes, getRanksbyTitle } from "../ranks/redux/actions";
import {
  Modal,
  Select,
  DatePicker,
  Typography,
  Input,
  Checkbox,
  Button,
} from "antd";
import { useObjectState } from "../../commonUtils/commonState";
import { Grid } from "@material-ui/core";
import moment from "moment";
import {
  approvedUser,
  promotedUser,
  salaryIncreament,
} from "../update/redux/actions/updateAction";
import { ERROR_ALERT } from "../update/redux/constant";
import { getAllJobs } from "../jobs/redux/actions/jobsActions";
import { getDoc } from "../../commonUtils/share";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { employeType } from "./constants/constants";
export default function JobModal({
  visible,
  setVisible,
  id,
  action,
  dataForModel,
}) {
  const history = useHistory();
  const { jobTypes, ranksData } = useSelector((state) => state.ranksReducer);
  const { userProfile } = useSelector((state) => state.profileReducer);
  const { allContractTypes } = useSelector(
    (state) => state?.contractTypeReducer,
  );
  const [docLoading, setDocloading] = useState(false);
  const [state, setState] = useObjectState({
    jobCategory: "",
    jobLocation: "",
    jobType: "",
    rankTitle: "",
    rankId: "",
    basicPay: "",
    newSalary: "",
    JobStartedAt: "",
    promotedAt: "",
    increamentAt: "",
    note: "",
    travelCardNumber: "",
    travelExpenditure: "",
    employmentType: userProfile?.employmentType || "",
    sortBy: "",
    mailChecked: true,
    contractType: userProfile?.contractType || "",
  });
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const dispatch = useDispatch();
  function onSearch(val) {
    // console.log("search:", val);
  }
  useEffect(() => {
    dispatch(getAllJobTypes());
    dispatch(getAllJobs(setLoading));
    dispatch(getRanksbyTitle(dataForModel?.jobType, setLoading));
    setState({
      ...dataForModel,
    });
  }, [dataForModel]);

  const selectJobType = (type) => {
    setState({ jobType: type });
    dispatch(getRanksbyTitle(type, setLoading));
  };
  const selectEmployeeType = (type) => {
    setState({ employmentType: type });
  };
  

  const selectContractType = (type) => {
    setState({ contractType: type });
  };

  const verifyForApprove = () => {
    if (!state.jobType) {
      dispatch({ type: ERROR_ALERT, payload: "Job Type Is Required" });
    } else if (!state.rankTitle) {
      dispatch({ type: ERROR_ALERT, payload: "Rank Title  Is Required" });
    } else if (!state.jobCategory) {
      dispatch({ type: ERROR_ALERT, payload: "Job Category Is Required" });
    } else if (!state.jobLocation) {
      dispatch({ type: ERROR_ALERT, payload: "Job Location  Is Required" });
    } else if (!state.JobStartedAt) {
      dispatch({ type: ERROR_ALERT, payload: "Job Start Date Is Required" });
    } else if (!state.basicPay) {
      dispatch({ type: ERROR_ALERT, payload: "Salary Is Required" });
    } else if (!state?.contractType) {
      dispatch({ type: ERROR_ALERT, payload: "Contract Type Is Required" });
    } else {
      return true;
    }
  };

  const verifyForPromotion = () => {
    if (!state.promotedAt) {
      dispatch({ type: ERROR_ALERT, payload: "Promote Date Is Required" });
    } else if (state.rankTitle === dataForModel?.rankTitle) {
      dispatch({ type: ERROR_ALERT, payload: "Rank Title is not changed" });
    } else if (!state.newSalary) {
      dispatch({ type: ERROR_ALERT, payload: "New Salary Is Required" });
    } else if (Number(state?.newSalary) < Number(dataForModel?.basicPay)) {
      dispatch({
        type: ERROR_ALERT,
        payload: "New Salary is less then from old Salary",
      });
    } else if (!state.employmentType) {
      dispatch({ type: ERROR_ALERT, payload: "Employment Type Is Required" });
    } else {
      return true;
    }
  };
  const viewDoc = () => {
    if (action == "Promoted" && verifyForPromotion()) {
      const payload = {
        name: `${userProfile.fullName || ""}`,
        date: moment(state.promotedAt).format("MM/DD/YYYY"),
        promotedTo: state.rankTitle,
        address: `${userProfile.address || ""}`,
        salary: state.newSalary,
        docType: "promotionLetter",
        email: userProfile.email || "",
      };
      getDoc(payload, setDocloading);
    } else if (action === "Increment" && verifyForIncreament()) {
      const payload = {
        name: `${userProfile.fullName || ""}`,
        date: moment(state.increamentAt).format("MM/DD/YYYY"),
        salary: state.newSalary,
        docType: "incrementletter",
        address: `${userProfile.address || ""}`,
        email: userProfile.email || "",
      };
      getDoc(payload, setDocloading);
    }
  };

  const verifyForIncreament = () => {
    if (!state.increamentAt) {
      dispatch({ type: ERROR_ALERT, payload: "Increment Date Is Required" });
    } else if (!state.newSalary) {
      dispatch({ type: ERROR_ALERT, payload: "New Salary Is Required" });
    } else if (Number(state?.newSalary) < Number(dataForModel?.basicPay)) {
      dispatch({
        type: ERROR_ALERT,
        payload: "New Salary is less then from old Salary",
      });
    } else {
      return true;
    }
  };
  const handleOk = () => {
    if (action === "Approved") {
      let result = verifyForApprove();
      if (result) {
        dispatch(
          approvedUser(
            id,
            { ...userProfile, ...state },
            setApproveLoading,
            setVisible,
            allContractTypes,
            () => {
              history.replace(`/profile/employee/${id}`);
            },
          ),
        );
      }
    } else if (action == "Promoted") {
      let result = verifyForPromotion();
      if (result) {
        dispatch(promotedUser(id, userProfile, state, setLoading));
        dispatch(
          salaryIncreament(
            id,
            userProfile,
            state,
            setLoading,
            false,
            setVisible,
          ),
        );
      }
    } else if (action === "Increment") {
      let result = verifyForIncreament();
      if (result) {
        dispatch(
          salaryIncreament(
            id,
            userProfile,
            state,
            setLoading,
            state?.mailChecked,
            setVisible,
          ),
        );
      }
    }
  };

  const handleDate = (e) => {
    if (action === "Approved") {
      setState({ JobStartedAt: e });
    } else if (action === "Promoted") {
      setState({
        promotedAt: e,
        increamentAt: e,
      });
    } else if (action === "Increment") {
      setState({ increamentAt: e });
    }
  };

  return (
    <Modal
      title={
        action === "Promoted"
          ? "Promotion"
          : action === "Increment"
          ? "Increment"
          : "Choose Job Type, Rank Title, Salary etc"
      }
      centered
      visible={visible}
      onOk={handleOk}
      onCancel={() => setVisible(false)}
      width={700}
      okText={action}
    >
      {!loading && !approveLoading ? (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={3}
        >
          {action === "Approved" || action === "Promoted" ? (
            <>
              <Grid item xs={12} md={6} sm={6}>
                <Select
                  showSearch
                  style={{ width: 300, marginBottom: "20px" }}
                  optionFilterProp="children"
                  value={state.jobType ? state.jobType : "Choose Job Type"}
                  onChange={selectJobType}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {jobTypes?.map((job, index) => (
                    <Option value={job.type} key={job.type + index}>
                      {job.type}
                    </Option>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <Select
                  showSearch
                  style={{ width: 300, marginBottom: "20px" }}
                  optionFilterProp="children"
                  value={state.rankTitle ? state.rankTitle : "Choose Rank"}
                  onChange={(e) => {
                    setState({
                      rankTitle: ranksData?.ranks[e].rank,
                      rankId: ranksData?.ranks[e].id,
                    });
                  }}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {ranksData?.ranks?.map((rank, index) => {
                    return (
                      <Option key={rank.id} value={index}>
                        {rank.rank}
                      </Option>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <Select
                  showSearch
                  style={{ width: 300, marginBottom: "20px" }}
                  optionFilterProp="children"
                  value={
                    state.jobCategory
                      ? state.jobCategory
                      : "Select Job Category"
                  }
                  onChange={(e) => setState({ jobCategory: e })}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Onsite">Onsite</Option>
                  <Option value="Remote">Remote</Option>
                </Select>
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <Select
                  showSearch
                  style={{ width: 300, marginBottom: "20px" }}
                  optionFilterProp="children"
                  value={
                    state.jobLocation
                      ? state.jobLocation
                      : "Select Job Location"
                  }
                  onChange={(e) => setState({ jobLocation: e })}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Faisalabad">Faisalabad</Option>
                </Select>
              </Grid>
            </>
          ) : (
            ""
          )}
          <Grid item xs={12} md={6} sm={6}>
            <DatePicker
              format="YYYY-MM-DD"
              onChange={(e) => handleDate(e?._d)}
              style={{ width: 300 }}
              placeholder={
                action === "Promoted"
                  ? "Promote at"
                  : action === "Increment"
                  ? "Increment At"
                  : "Job started at"
              }
              size={"middle"}
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <Input
              disabled={action === "Approved" ? false : true}
              prefix="Rs. "
              type="number"
              placeholder="Salary"
              style={{ width: 300 }}
              value={state.basicPay}
              onChange={(e) => setState({ basicPay: e.target.value })}
            />
          </Grid>
          {action === "Approved" ? (
            <>
              <Grid item xs={12} md={6} sm={6}>
                <Input
                  type="number"
                  placeholder="Travel Card Number"
                  style={{ width: 300 }}
                  value={state.travelCardNumber}
                  onChange={(e) =>
                    setState({ travelCardNumber: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <Input
                  prefix="Rs. "
                  type="number"
                  placeholder="Travel Expenditure"
                  style={{ width: 300 }}
                  value={state.travelExpenditure}
                  onChange={(e) =>
                    setState({ travelExpenditure: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <Input
                  type="number"
                  placeholder="Sort By"
                  style={{ width: 300 }}
                  value={state.sortBy}
                  onChange={(e) => setState({ sortBy: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <Select
                  showSearch
                  style={{ width: 300, marginBottom: "20px" }}
                  optionFilterProp="children"
                  value={
                    state?.contractType
                      ? state?.contractType
                      : "Choose Contract Type"
                  }
                  onChange={selectContractType}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {allContractTypes?.map((contract, index) => (
                    <Option value={contract?.id} key={contract.type + index}>
                      {contract?.type}
                    </Option>
                  ))}
                </Select>
              </Grid>
            </>
          ) : (
            ""
          )}
          {action === "Promoted" || action === "Increment" ? (
            <>
              <Grid item xs={12} md={6} sm={6}>
                <Input
                  prefix="Rs. "
                  type="number"
                  placeholder="New Salary"
                  style={{ width: 300 }}
                  // value={state.newSalary}
                  onChange={(e) => setState({ newSalary: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <Input
                  type="text"
                  placeholder="Note"
                  style={{ width: 300 }}
                  // value={state.note}
                  onChange={(e) => setState({ note: e.target.value })}
                />
              </Grid>
            </>
          ) : (
            ""
          )}
          {
            action === "Promoted" && (
              <Grid item xs={12} md={6} sm={6}>
                <Select
                  showSearch
                  style={{ width: 300, marginBottom: "20px" }}
                  optionFilterProp="children"
                  value={state.employmentType ? state.employmentType : "Choose Employee Type"}
                  onChange={selectEmployeeType}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {employeType?.map((type, index) => (
                    <Option value={type} key={type + index}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </Grid>
            )
          }
          {
            action === "Promoted" || action === "Increment" ? (
             <>
             <Grid item xs={12} md={6} sm={6}>
             <Checkbox
               style={{ width: 300 }}
               checked={state.mailChecked}
               onChange={(e) => setState({ mailChecked: e.target.checked })}
             >
               Send Mail
             </Checkbox>
           </Grid>
           <Grid item xs={12} md={6} sm={6}>
             {docLoading ? (
               <CircularProgress />
             ) : (
               <Button onClick={viewDoc}>View pdf</Button>
             )}
           </Grid>
             </>
            ) :(
              ""
            )
          }
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <Grid item xs={12} md={6} sm={6}>
            <Typography component="div" align="center">
              Please Wait...
            </Typography>
          </Grid>
        </Grid>
      )}
    </Modal>
  );
}
