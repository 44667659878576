import { db } from "../../../config/firebase";
import {
    ADD_JOB_TYPE,
    ADD_RANK,
    DELETE_JOB_TYPE,
    GET_ALL_JOB_TYPES,
    GET_RANKS_BY_TITLE,
    UPDATE_JOB_TYPE,
    UPDATE_RANK
} from './constants';

export const getRanksbyTitle = (title, setLoading) => async (dispatch) => {
    try {
        setLoading(true)
        let res = await db
            .collection("JobTypes")
            .where("type", "==", `${title}`)
            .get();
        let data = []
        res.forEach((item) => {
            data.push({ docId: item.id, ...item.data() })
        })

        dispatch({
            type: GET_RANKS_BY_TITLE,
            payload: data[0]
        })

    } catch (error) {
        console.log(error.message);
    } finally {
        setLoading(false)
    }

}

export const indexChange = (data) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_RANK,
            payload: data
        })

        await db.collection("JobTypes").doc(data.docId).update({ ...data });

    } catch (error) {
        console.log(error.message);
    }

}

export const addRank = (rankVal, data, setAddLoading) => async (dispatch) => {
    try {
        setAddLoading(true)
        let uniqueId = Math.random() * 999999

        if (!data.ranks) {
            let array = [{
                id: uniqueId?.toString(),
                rank: rankVal
            }]

            await db
                .collection("JobTypes")
                .doc(data?.docId)
                .update({
                    ...data,
                    ranks: array
                });

            let newData = {
                ...data,
                ranks: array
            }
            dispatch({
                type: ADD_RANK,
                payload: newData
            })
        } else {
            let array = [
                ...data.ranks,
                {
                    id: uniqueId?.toString(),
                    rank: rankVal
                }]

            await db
                .collection("JobTypes")
                .doc(data?.docId)
                .update({
                    ...data,
                    ranks: array
                });

            let newData = {
                ...data,
                ranks: array
            }
            dispatch({
                type: ADD_RANK,
                payload: newData
            })
        }
    } catch (error) {
        console.log(error.message);
    } finally {
        setAddLoading(false)
    }

}

export const deleteRank = (data) => async (dispatch) => {
    try {

        dispatch({
            type: UPDATE_RANK,
            payload: data
        })

        await db.collection("JobTypes").doc(data.docId).update({ ...data });

    } catch (error) {
        console.log(error.message);
    }

}

export const updateRank = (rankId, rankVal, data) => async (dispatch) => {
    try {
        let updatedRank = data?.ranks?.map((rank) => {
            if (rank.id === rankId) {
                return { rank: rankVal, id: rankId }
            } else { return rank }
        })

        let newData = {
            ...data,
            ranks: updatedRank
        }

        await db.collection("JobTypes").doc(data?.docId).update({ ...newData });

        dispatch({
            type: UPDATE_RANK,
            payload: newData
        })

    } catch (error) {
        console.log(error.message);
    }

}

export const getAllJobTypes = () => async (dispatch) => {
    try {
        let Jobs = await db
            .collection("JobTypes")
            .get();
        let data = [];
        Jobs.forEach((item) => {
            data.push({ docId: item.id, ...item.data() });
        });
        dispatch({ type: GET_ALL_JOB_TYPES, payload: data });
    } catch (error) {
        console.log(error.message)
    }
};
export const addJobTypes = (type) => async (dispatch) => {
    try {
        if (type) {

            let Jobs = await db
                .collection("JobTypes")
                .add({ type });

            let data = {
                type,
                docId: Jobs.id
            }
            dispatch({ type: ADD_JOB_TYPE, payload: data });
        }

    } catch (error) {
        console.log(error.message)
    }
};
export const deleteJobType = (id) => async (dispatch) => {
    try {
        await db
            .collection("JobTypes")
            .doc(id)
            .delete();

        dispatch({ type: DELETE_JOB_TYPE, payload: id });

    } catch (error) {
        console.log(error.message)
    }
};
export const updateJobType = (id, type) => async (dispatch) => {
    try {
        if (type) {
            await db
                .collection("JobTypes")
                .doc(id)
                .update({ type });

            let data = {
                docId: id,
                type
            }

            dispatch({ type: UPDATE_JOB_TYPE, payload: data });
        }
    } catch (error) {
        console.log(error.message)
    }
};