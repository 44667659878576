import React, { useEffect, useState } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./components/rippleButton/RippleButton";
import { ToastContainer } from "react-toastify";
import "react-perfect-scrollbar/dist/css/styles.css";
import "prismjs/themes/prism-tomorrow.css";
// components
import Layout from "./Containers/TheLayout";
// login pages
import Login from "./module/auth/login/index";
import { getCurrentUser } from "./module/auth/redux/action";
import CircularLoader from "./module/utils/circularProgress/index.jsx";
import { SET_LOADING } from "./module/auth/redux/constant";

export default function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loading, setloading] = useState(true);

  const { loggedIn, SignInOutLoader } = useSelector((state) => state.userReducer);
  const path = location.pathname;

  useEffect(() => {
    dispatch(getCurrentUser(setloading));
  }, [dispatch]);

  useEffect(() => {
    if (!loading && loggedIn) {
      if (path.includes("login")) {
        history.replace("/");
      }
    }
    if (!loggedIn) {
      dispatch({
        type: SET_LOADING,
        payload: false
      })
    }

  }, [loggedIn]);

  return (loading || SignInOutLoader) ? (
    <CircularLoader />
  ) : (
    <>
      {loggedIn ? (
        <Route path="/" name="Home" component={Layout} />
      ) : (
        <Route path="*" name="Login Page" component={Login} />
      )}
      <ToastContainer />
    </>
  );
}

