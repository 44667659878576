import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  ListItemIcon,
  InputLabel,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { useStyles } from "../ModalStyle";
import { CircularProgress } from "@material-ui/core";
import { ERROR_ALERT } from "../../../../commonUtils/share";
import { addAdmin, updateAdmin } from "../../redux/actions/adminAction";
import { visibleTabOptions } from "../../../../constants/constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AdminModal = (props) => {
  const { modal, toggle, data, allAdmins } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [admin, setAdmin] = useState({});
  const [loading, setLoading] = useState(false);
  const [visibleTabs, setVisibleTabs] = useState([]);

  const isAllSelected =
    visibleTabOptions.length > 0 &&
    visibleTabs.length === visibleTabOptions.length;
  let options = [];
  visibleTabOptions.map((item) => options.push(item.value));

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setVisibleTabs(
        visibleTabs.length === visibleTabOptions.length ? [] : options,
      );
      return;
    }
    setVisibleTabs(value);
  };

  useEffect(() => {
    if (data) {
      const { visibleTabs, ...rest } = data;
      setAdmin({
        ...rest,
      });
      if (visibleTabs && visibleTabs.length) {
        setVisibleTabs(visibleTabs);
      }
    }
  }, [data]);

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAdmin({
      ...admin,
      [name]: value,
    });
  };
  const submitHandler = async () => {
    try {
      if (admin?.name && visibleTabs.length > 0) {
        if (admin?.id) {
          dispatch(updateAdmin({ ...admin, visibleTabs }, setLoading, toggle));
        } else if (
          allAdmins.filter((item) => item.email === admin.email).length > 0
        ) {
          dispatch({
            type: ERROR_ALERT,
            payload: "Email Already Exists!",
          });
        } else {
          dispatch(addAdmin({ ...admin, visibleTabs }, setLoading, toggle));
        }
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: "ALL Fields Are Required!",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRenderedValues = (value) => {
    let displayValues = [];
    value.map((item) => {
      const title = visibleTabOptions.filter((tab) => tab?.value === item)?.[0]
        ?.title;

      displayValues.push(title);
    });
    return displayValues.join(",");
  };
  return (
    <>
      <Dialog
        fullScreen
        open={modal}
        onClose={() => toggle()}
        TransitionComponent={Transition}
      >
        <AppBar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => toggle()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </AppBar>

        <Container maxWidth="md" className={classes.container}>
          <h1 className="Details">Admin Details</h1>
          <Grid container justify="center" alignItems="center" spacing={2}>
            <Grid item xs={9} md={9} sm={9}>
              <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid item md={6}>
                  <TextField
                    name="name"
                    className={classes.formControl}
                    fullWidth
                    placeholder="Name"
                    id="outlined-basic"
                    label="*Name"
                    value={admin?.name || ""}
                    onChange={onChangeHandler}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    name="email"
                    className={classes.formControl}
                    fullWidth
                    placeholder="Email"
                    id="outlined-basic"
                    label="*Email"
                    value={admin?.email || ""}
                    onChange={onChangeHandler}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <InputLabel id="selectEmails">*Select Tabs </InputLabel>
                  <Select
                    labelId="selectEmails"
                    multiple
                    value={visibleTabs}
                    onChange={handleChange}
                    renderValue={(visibleTabs) => {
                      return getRenderedValues(visibleTabs);
                    }}
                    MenuProps={classes.menuProps}
                    className={classes.formControl}
                    fullWidth
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelected ? classes.selectedAll : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: classes.indeterminateColor,
                          }}
                          checked={isAllSelected}
                          indeterminate={
                            visibleTabs.length > 0 &&
                            visibleTabs.length < visibleTabOptions.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {visibleTabOptions.map((option) => (
                      <MenuItem key={option} value={option?.value}>
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              visibleTabs.filter(
                                (item) => item === option?.value,
                              ).length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={option?.title} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item md={6}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.grid}>
            <Grid item md={12} xs={12}>
              {loading ? (
                <CircularProgress className={classes.loader} />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={submitHandler}
                    className={classes.button}
                  >
                    {admin?.id ? "Update" : "Add"}
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </>
  );
};
export default AdminModal;
