import {
  GET_ALL_REQUESTS,
  ERROR_ALERT,
  UPDATE_CONTACT_STATUS,
  SUCCESS_ALERT,
} from "../constant/index";
import { db } from "../../../../config/firebase";

export const contactRequestsAction = (setloading) => async (dispatch) => {
  try {
    setloading(true);

    let requestsQuery = await db.collection("ContactRequests").get();
    let allRequests = requestsQuery.docs.map((doc) => {
      let data = doc.data();
      const id = doc.id;

      return {
        ...data,
        id,
        seconds: data?.createdAt?.seconds,
      };
    });

    dispatch({
      type: GET_ALL_REQUESTS,
      payload: allRequests,
    });
    setloading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    console.log(error.message);
    setloading(false);
  }
};

export const updateContactStatus = (setloading, status, contactData) => async (
  dispatch,
) => {
  try {
    setloading(true);
    await db
      .collection("ContactRequests")
      .doc(contactData.id)
      .update({ status });
    dispatch({
      type: UPDATE_CONTACT_STATUS,
      payload: { ...contactData, status },
    });
    dispatch({
      type: SUCCESS_ALERT,
      payload: "Contact Request Status updated successfully.",
    });
    setloading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    console.log(error.message);
    setloading(false);
  }
};
