import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getLetters } from "./redux/actions/lettersAction";
import CircularLoader from "../utils/circularProgress/index";
import TableComponent from "./LettersTable";

export default function LettersRequest() {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);

  useEffect(() => {
    dispatch(getLetters(setloading));
  }, []);
  return <>{loading ? <CircularLoader /> : <TableComponent />}</>;
}
