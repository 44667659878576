import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  schedule: {
    backgroundColor: "#ADD8E6",
    color: "#00008B",
    marginRight: theme.spacing(2),
    borderRadius: 0,
    borderColor: "none",
    fontWeight: "900",
    border: "none",
  },
  scheduleIcon: {
    color: "#00008B",
    fontWeight: "900",
  },
  iconDisabled: {
    color: "#808080",
  },
  disabled: {
    backgroundColor: "#F2F4F5",
    color: "#808080",
    marginRight: theme.spacing(2),
    borderRadius: 0,
    borderColor: "none",
    fontWeight: "900", // 900
    border: "none",
  },
  reschedule: {
    backgroundColor: "#F8F0DB",
    color: theme.palette.warning.dark,
    marginRight: theme.spacing(2),
    borderRadius: 0,
    borderColor: "none",
    fontWeight: "900",
    border: "none",
  },
  rescheduleIcon: {
    color: theme.palette.warning.dark,
  },
  cancel: {
    backgroundColor: "#F8EBE9",
    color: theme.palette.secondary.dark,
    marginRight: theme.spacing(2),
    borderRadius: 0,
    borderColor: "none",
    fontWeight: "900",
    border: "none",
  },
  cancelIcon: {
    color: theme.palette.secondary.dark,
  },

  interviewed: {
    backgroundColor: "#DDF7E9",

    color: theme.palette.success.dark,
    marginRight: theme.spacing(2),
    borderRadius: 0,
    borderColor: "none",
    fontWeight: "900",
    border: "none",
  },
  interviewedIcon: {
    color: theme.palette.success.dark,
  },
  everyItem: {
    margin: "5px 10px",
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column'
  },
  dropdownButton: {
    border: "3px solid #7367f0",
    borderRadius: "10px"
  },
  dialogContainer: {
    margin: "50px auto 0",
    width: "60%",
  },
  formMain: {
    width: "100%",
    margin: "auto",
  },
  singleField: {
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    padding: '20px',
    borderRadius: "10px",
    marginBottom: "20px"
  },
  endButton: {
    margin: "20px 0",
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin:"10px"
  },
  dialogContain: {
    margin: '15px 15px 50px'
  }
}));