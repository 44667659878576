import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { Menu, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from '../../style';
import { updateInterviewQuestion } from '../../redux/action';

export default function UpdateQuestion({data}) {
    const { interviewQuestions } = useSelector((state) => state.InterviewQuestionsReducer)
    const [updateDialog, setUpdateDialog] = useState(null);
    const [updateItemValue, setUpdateItemValue] = useState("");
    const dispatch = useDispatch()
    const classes = useStyles();

    const updateHandler = () => {
        if (updateItemValue) {
            let updated = interviewQuestions?.questions?.map((doc)=>{
                if(doc.id === data.id){
                    return {...data, question: updateItemValue}
                } else {
                    return doc
                }
            })
            let newData = {
                ...interviewQuestions,
                questions: updated
            }
            dispatch(updateInterviewQuestion(newData))
        }
        dialogClose()
    }

    const dialogClose = () => {
        setUpdateDialog(null)
        setUpdateItemValue('')
    }

    const dialogOpen = (e) => {
        setUpdateItemValue(data.question)
        setUpdateDialog(e.currentTarget)
    }

    return (
        <div className={classes.item}>
            <Button
                onClick={dialogOpen}
                variant='contained'
                color='primary'
            >
                Update
            </Button>
            <div>
                <Menu
                    id="simple-menu"
                    anchorEl={updateDialog}
                    keepMounted
                    open={Boolean(updateDialog)}
                    onClose={dialogClose}
                >
                    <div>
                        <TextField
                            autoFocus
                            value={updateItemValue}
                            className={classes.textField}
                            label="Update Interview type"
                            multiline
                            minRows={4}
                            onChange={(e) => { setUpdateItemValue(e.target.value) }}
                        />
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.addButton}
                            onClick={() => { updateHandler() }}
                        >
                            Update
                        </Button>
                    </div>
                </Menu>
            </div>
        </div>
    )
}
