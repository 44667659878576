import {
  SUCCESS_ALERT,
  ERROR_ALERT,
  UPDATE_ATTENDANCE_STATUS,
  UPDATE_ATTENDANCE_STATUS_BOOTCAMP,
  GET_ALL_USER,
  DELETE_ATTENDANCE,
  DELETE_ATTENDANCE_BOOTCAMP,
  ALL_EMPLOYEE,
  ALL_BOOTCAMPER,
  CALENDAR_EVENTS,
  GET_BOOTCAMP_USER,
} from "../constant/attendanceConstant";
import { db } from "../../../../config/firebase";
import moment from "moment";

export const fetchSpecificDate =
  (setloading, date) => async (dispatch, getState) => {
    let { allEmployees } = getState().commonReducer;

    let start = new Date(date.setHours(0, 0, 0, 0));
    let end = new Date(date.setHours(23, 59, 59, 999));

    try {
      setloading(true);
      let userIds = [];
      let ids = [];
      let AttendanceQuery = await db
        .collection("Attendance")
        .where("start", ">=", start)
        .where("start", "<=", end)
        .get();

      if (allEmployees < 1) {
        let userDataquery = await db.collection("UserData").where("employmentType", "!=", "Bootcamp").get();
        allEmployees = userDataquery.docs.map((doc) => {
          userIds.push(doc.id);
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
      }

      // console.log("====================================");
      // console.log("allEmployees", allEmployees);
      const showEmployees = allEmployees.filter(
        (el) =>
          el.status === "approved" &&
          moment(el?.JobStartedAt?.toDate()).isBefore(date),
      );

      // console.log("====================================");
      let allAttendance = AttendanceQuery.docs.map((doc) => {
        let data = doc.data();
        ids.push(data.userId);
        return {
          ...data,
          attendanceId: doc.id,
        };
      });
      // console.log("allAttendance", allAttendance);
      dispatch({ type: ALL_EMPLOYEE, payload: allEmployees });

      let final = showEmployees.map((emp, i) => {
        if (ids.includes(emp.id)) {
          let att = allAttendance.filter((el) => el.userId === emp.id);
          let newItem = { ...emp, ...att[0]};
          return newItem;
        }
        return emp;
      });
      dispatch({
        type: GET_ALL_USER,
        // payload: final.length < 1 ? allEmployees : final,
        payload: final,
      });
      setloading(false);
    } catch (error) {
      dispatch({ type: ERROR_ALERT, payload: error.message });
      setloading(false);
    }
  };
  export const fetchBootcampSpecificDate =
  (setloading, date) => async (dispatch, getState) => {
    let { allBootcampTeam } = getState().commonReducer;

    let start = new Date(date.setHours(0, 0, 0, 0));
    let end = new Date(date.setHours(23, 59, 59, 999));

    try {
      setloading(true);
      let userIds = [];
      let ids = [];
      let AttendanceQuery = await db
        .collection("Attendance")
        .where("start", ">=", start)
        .where("start", "<=", end)
        .get();

      if (allBootcampTeam < 1) {
        let userDataquery = await db.collection("UserData").where("employmentType", "==", "Bootcamp").get();
        allBootcampTeam = userDataquery.docs.map((doc) => {
          userIds.push(doc.id);
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
      }
      const showEmployees = allBootcampTeam.filter(
        (el) =>
          el.status === "approved" &&
          moment(el?.JobStartedAt?.toDate()).isBefore(date),
      );
      let allAttendance = AttendanceQuery.docs.map((doc) => {
        let data = doc.data();
        ids.push(data.userId);
        return {
          ...data,
          attendanceId: doc.id,
        };
      });
      dispatch({ type: ALL_BOOTCAMPER, payload: allBootcampTeam });

      let final = showEmployees.map((emp, i) => {
        if (ids.includes(emp.id)) {
          let att = allAttendance.filter((el) => el.userId === emp.id);
          let newItem = { ...emp, ...att[0]};
          return newItem;
        }
        return emp;
      });
      dispatch({
        type: GET_BOOTCAMP_USER,
        // payload: final.length < 1 ? allEmployees : final,
        payload: final,
      });
      setloading(false);
    } catch (error) {
      dispatch({ type: ERROR_ALERT, payload: error.message });
      setloading(false);
    }
  };

export const fetchForCalendar =
  (date, userId = null) =>
  async (dispatch, getState) => {
    let { allEmployees } = getState().commonReducer;
    // console.log("date", date);
    try {
      // setloading(true);
      let userIds = [];
      let start = new Date(date.setHours(0, 0, 0, 0));
      let end = new Date(date.setHours(23, 59, 59, 999));
      const startOfMonth = moment(start).startOf("month").toDate();
      const endOfMonth = moment(end).endOf("month").toDate();
      let startQuery = await db
        .collection("Attendance")
        .where("start", ">=", startOfMonth)
        .where("start", "<=", endOfMonth)
        .get();
      let endQuery = await db
        .collection("Attendance")
        .where("end", ">=", startOfMonth)
        .where("end", "<=", endOfMonth)
        .get();

      let allAttendance = [];
      startQuery.docs.map((doc) => {
        let data = doc.data();
        const id = doc.id;
        let attend = {
          ...data,
          id,
        };
        allAttendance.push(attend);
      });
      endQuery.docs.map((doc) => {
        let data = doc.data();
        const id = doc.id;
        let attend = {
          ...data,
          id,
        };
        allAttendance.push(attend);
      });
      const unique = [];
      allAttendance.map((x) =>
        unique.filter((a) => a.id === x.id).length > 0 ? null : unique.push(x),
      );
      // console.log("unique", unique);
      // let attendanceQuery = await db
      //   .collection("Attendance")
      //   .where("createdAt", ">=", startOfMonth)
      //   .where("createdAt", "<=", endOfMonth)
      //   .get();
      // let attendanceDoc = [];
      // unique.map((doc) => {
      //   let data = doc.data();
      //   ids.push(data.userId);
      //   const id = doc.id;
      //   attendanceDoc.push({
      //     ...data,
      //     id,
      //   });
      // });
      // console.log("attendanceDoc", attendanceDoc);

      allEmployees.map((doc) => userIds.push(doc.id));
      let final = [];
      allEmployees.map((emp) => {
        let att = [];
        unique.forEach((el) => {
          if (
            el.userId === emp.id &&
            (el.label !== "leave" || el.label !== "halfDayLeave")
          ) {
            att.push({ ...el, authId: emp?.authId });
          }
        });
        att.map((el) => {
          const { fullName } = emp;
          final.push({ fullName, ...el });
        });
      });
      if (userId) {
        final = final?.filter((item) => item?.userId === userId);
      }
      const test = final.map((item) => {
        if (item.start.seconds) {
          start = item.start?.toDate();
          end = item.end?.toDate();
        } else {
          start = item.start;
          end = item.end;
        }
        let itm = {
          ...item,
          start,
          end,
        };
        return itm;
      });
      // console.log("test", test);
      dispatch({
        type: CALENDAR_EVENTS,
        payload: test,
      });
      // setloading(false);
    } catch (error) {
      dispatch({ type: ERROR_ALERT, payload: error.message });
      // setloading(false);
    }
  };

  export const fetchCalendarForBootcamp =
  (date, userId = null) =>
  async (dispatch, getState) => {
    let { allBootcampTeam } = getState().commonReducer;
    try {
      let userIds = [];
      let start = new Date(date.setHours(0, 0, 0, 0));
      let end = new Date(date.setHours(23, 59, 59, 999));
      const startOfMonth = moment(start).startOf("month").toDate();
      const endOfMonth = moment(end).endOf("month").toDate();
      let startQuery = await db
        .collection("Attendance")
        .where("start", ">=", startOfMonth)
        .where("start", "<=", endOfMonth)
        .get();
      let endQuery = await db
        .collection("Attendance")
        .where("end", ">=", startOfMonth)
        .where("end", "<=", endOfMonth)
        .get();

      let allAttendance = [];
      startQuery.docs.map((doc) => {
        let data = doc.data();
        const id = doc.id;
        let attend = {
          ...data,
          id,
        };
        allAttendance.push(attend);
      });
      endQuery.docs.map((doc) => {
        let data = doc.data();
        const id = doc.id;
        let attend = {
          ...data,
          id,
        };
        allAttendance.push(attend);
      });
      const unique = [];
      allAttendance.map((x) =>
        unique.filter((a) => a.id === x.id).length > 0 ? null : unique.push(x),
      );

      allBootcampTeam.map((doc) => userIds.push(doc.id));
      let final = [];
      allBootcampTeam.map((emp) => {
        let att = [];
        unique.forEach((el) => {
          if (
            el.userId === emp.id &&
            (el.label !== "leave" || el.label !== "halfDayLeave")
          ) {
            att.push({ ...el, authId: emp?.authId });
          }
        });
        att.map((el) => {
          const { fullName } = emp;
          final.push({ fullName, ...el });
        });
      });
      if (userId) {
        final = final?.filter((item) => item?.userId === userId);
      }
      const test = final.map((item) => {
        if (item.start.seconds) {
          start = item.start?.toDate();
          end = item.end?.toDate();
        } else {
          start = item.start;
          end = item.end;
        }
        let itm = {
          ...item,
          start,
          end,
        };
        return itm;
      });
      dispatch({
        type: CALENDAR_EVENTS,
        payload: test,
      });
    } catch (error) {
      dispatch({ type: ERROR_ALERT, payload: error.message });
    }
  };

export const updateAttendance =
  (data, status, setupdateLoading, date) => async (dispatch) => {
    try {
      setupdateLoading(true);
      let send = {
        userId: data.id,
        authId: data.authId,
        start: data.start,
        end: data.end,
        title: status,
      };

      if (data.hasOwnProperty("label")) {
        if (data.label !== undefined) {
          switch (status) {
            case "present":
              await db.collection("Attendance").doc(data.attendanceId).delete();
              dispatch({
                type: DELETE_ATTENDANCE,
                payload: data.attendanceId,
              });
              break;
            case "late":
              await db
                .collection("Attendance")
                .doc(data.attendanceId)
                .update(send);
              dispatch({
                type: UPDATE_ATTENDANCE_STATUS,
                payload: {
                  ...data,
                  ...send,
                  label: status,
                  attendanceId: data.attendanceId,
                },
              });
              break;

            default:
              console.log("action case default");
              let other = {
                userId: data.userId,
                authId: data.authId,
                label: status,
                title: status,
                // date: data.date,
              };
              await db
                .collection("Attendance")
                .doc(data.attendanceId)

                .update({
                  userId: data.userId,
                  label: status,
                  title: status,
                  authId: data.authId,
                });
              dispatch({
                type: UPDATE_ATTENDANCE_STATUS,
                payload: { ...data, ...other },
              });
          }
        }
      } else {
        // let start = new Date(data.start.setHours(0, 0, 0, 0));
        // let end = new Date(data.end.setHours(23, 59, 59, 999));
        let send = {
          userId: data.id,
          label: status,
          authId: data.authId,
          start: data.start,
          end: data.end,
          createdAt: new Date(),
          title: status,
        };
        console.log("late case else");
        const docRef = db.collection("Attendance").doc();
        await docRef.set(send);
        dispatch({
          type: UPDATE_ATTENDANCE_STATUS,
          payload: {
            ...data,
            ...send,
            label: status,
            title: status,
            attendanceId: docRef.id,
          },
        });
        dispatch({
          type: SUCCESS_ALERT,
          payload: "Attendance updated successfully.",
        });
      }
    } catch (error) {
      console.log("test", error);
      dispatch({ type: ERROR_ALERT, payload: error.message });
      setupdateLoading(false);
    }
  };

  export const updateBootcampAttendance =
  (data, status, setupdateLoading, date) => async (dispatch) => {
    try {
      setupdateLoading(true);
      let send = {
        userId: data.id,
        authId: data.authId,
        // date: data.date,
        start: data.start,
        end: data.end,
        title: status,
      };

      if (data.hasOwnProperty("label")) {
        if (data.label !== undefined) {
          switch (status) {
            case "present":
              await db.collection("Attendance").doc(data.attendanceId).delete();
              dispatch({
                type: DELETE_ATTENDANCE_BOOTCAMP,
                payload: data.attendanceId,
              });
              break;
            case "late":
              await db
                .collection("Attendance")
                .doc(data.attendanceId)
                .update(send);
              dispatch({
                type: UPDATE_ATTENDANCE_STATUS_BOOTCAMP, 
                payload: {
                  ...data,
                  ...send,
                  label: status,
                  attendanceId: data.attendanceId,
                },
              });
              break;

            default:
              console.log("action case default");
              let other = {
                userId: data.userId,
                authId: data.authId,
                label: status,
                title: status,
                // date: data.date,
              };
              await db
                .collection("Attendance")
                .doc(data.attendanceId)

                .update({
                  userId: data.userId,
                  label: status,
                  title: status,
                  authId: data.authId,
                });
              dispatch({
                type: UPDATE_ATTENDANCE_STATUS_BOOTCAMP,
                payload: { ...data, ...other },
              });
          }
        }
      } else {
        // let start = new Date(data.start.setHours(0, 0, 0, 0));
        // let end = new Date(data.end.setHours(23, 59, 59, 999));
        let send = {
          userId: data.id,
          label: status,
          authId: data.authId,
          start: data.start,
          end: data.end,
          createdAt: new Date(),
          title: status,
        };
        console.log("late case else");
        const docRef = db.collection("Attendance").doc();
        await docRef.set(send);
        dispatch({
          type: UPDATE_ATTENDANCE_STATUS_BOOTCAMP,
          payload: {
            ...data,
            ...send,
            label: status,
            title: status,
            attendanceId: docRef.id,
          },
        });
        dispatch({
          type: SUCCESS_ALERT,
          payload: "Attendance updated successfully.",
        });
      }
    } catch (error) {
      console.log("test", error);
      dispatch({ type: ERROR_ALERT, payload: error.message });
      setupdateLoading(false);
    }
  };

export const successMessage = (message) => async (dispatch) => {
  try {
    dispatch({ type: SUCCESS_ALERT, payload: message });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};

export const errorMessage = (message) => async (dispatch) => {
  try {
    dispatch({ type: ERROR_ALERT, payload: message });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};
