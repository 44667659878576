import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { Grid, Link as MuiLink, MuiThemeProvider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { GitHub } from "@material-ui/icons";
import { mdiSkypeBusiness } from "@mdi/js";
import Icon from "@mdi/react";
import useStyles from "./style";
import { Link } from "react-router-dom";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import ConfirmDialog from "./ConfirmDialog";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ReceiptIcon from "@material-ui/icons/Receipt";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import Tooltip from "@material-ui/core/Tooltip";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import WhatsApp from "@material-ui/icons/WhatsApp";
import { SUCCESS_ALERT } from "../redux/constant";
import { CircularProgress } from "@material-ui/core";
import {
  updateStatus,
  getTimeSuggestion,
  updateAppliedFor,
} from "../redux/actions/jobsRequestsAction";
import { GetMuiTheme } from "../../utils/TableHeadStyle/style";
import QuestionsHistory from "../../../components/questionsHistory/QuestionsHistory";
import OfferLetter from "../../../components/offerLetter/OfferLetter";
const MuiTable = ({ data, title }) => {
  const dispatch = useDispatch();
  const allJobs = useSelector((state) => state.jobsReducer.allJobs);
  const [copyValue, setCopyValue] = useState("");
  const [copy, setCopy] = useState(false);
  const [loading, setloading] = useState(false);
  const [appliedLoading, setAppliedLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [jobsData, setJobsData] = useState([]);
  const classes = useStyles();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const onboardingURL =
    process.env.REACT_APP_ONBOARDING_URL || "https://onboarding.techloset.com/";
  useEffect(() => {
    let unique = [];
    data.forEach((x) => {
      if (!unique.some((a) => a.docId === x.docId)) {
        unique.push(x);
      }
    });
    setJobsData(unique);
  }, [data]);
  useEffect(() => {
    if (copy) {
      dispatch({ type: SUCCESS_ALERT, payload: `copied to clickboard` });
    }
  }, [copy, copyValue]);
  const handleCopy = (value) => {
    setCopyValue(value);
    setCopy(true);
  };

  const setStatus = (status, jobData, dataIndex, setloading) => {
    setIndex(dataIndex);
    dispatch(updateStatus(status, jobData, setloading));
  };

  const setAppliedFor = (job, jobData, dataIndex, setAppliedLoading) => {
    setIndex(dataIndex);
    const updatedData = {
      appliedFor: job?.jobTitle,
      contractType: job?.contractType,
      jobId: job?.id,
    };

    dispatch(updateAppliedFor(updatedData, jobData, setAppliedLoading));
  };

  const getTime = (name, email, appliedFor) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to get time suggestion?",
      subTitle: "You can't undo this operation",
      onConfirm: () => {
        dispatch(getTimeSuggestion(name, email, appliedFor));
      },
    });
  };
  const startBoarding = (status, jobData, setloading) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to invite onBoarding?",
      subTitle: "You can't undo this operation",
      onConfirm: () => {
        dispatch(updateStatus(status, jobData, setloading));
      },
    });
  };
  const handleWhatsappMessage = (data) => {
    let message = "";
    const phone = data?.phone?.slice(0);
    const interview = data?.interview;
    const status = data?.status;
    const appliedFor = data?.appliedFor;
    const fname = data?.fname.toUpperCase();
    const newDate = interview?.start?.seconds
      ? new Date(interview?.start.seconds * 1000)
      : interview?.start;
    const date = moment(newDate).format("MMMM Do YYYY") || "";
    const time = moment(newDate).format("h:mm a") || "";
    let interviewScheduled = "";
    if (interview && status === "interviewSchedule") {
      interviewScheduled = "Schedule";
    } else {
      interviewScheduled = "Reschedule";
    }
    if (status === "applied") {
      message = `*Application Received*%0A%0ADear *${fname}*,%0A%0AThank you for applying for the *${appliedFor}* Position at TechloSet Solution Faisalabad. We appreciate your interest in our company.%0A%0AWe would like to know if you are available for a quick call to discuss your application and the position further. If so, please let us know and we will give you a call.%0A%0AIf you have any questions or feel that you've received this message in error, please contact *hr@techloset.com*! %0AYour very own Technology Closet, TechloSet Solutions PVT, LTD.`;
    } else if (status === "interviewed") {
      message = ` INTERVIEW DONE,%0A%0A*Hi ${fname}*%0A%0AYour Interview has been done%0AThanks for taking interest in working with us!%0A%0AIf you have any questions or feel that you've received this message in error, please contact %0A*info@techloset.com*! %0AYour very own Technology Closet, TechloSet Solutions PVT, LTD. `;
    } else if (status === "boardingInvitation" || status === "boarded") {
      message = `On Boarding Invitation For ${appliedFor}%0A%0A*Hi ${fname}*%0A%0ACongratulations! You have successfully passed the requirement criteria and interview evaluation. Please get yourself on-board from the provided link. Please note that you fill the form with correct information.%0A%0A*Link:${onboardingURL}*%0A%0AThanks  Regards,%0ATeam TechloSet.%0A%0AIf you have any questions or feel that you've received this message in error, please contact %0A*info@techloset.com*! %0AYour very own Technology Closet, TechloSet Solutions PVT, LTD.`;
    } else if (status === "hired") {
      message = `*YOU ARE HIRED*%0A%0A*Hi ${fname}*%0A%0ACONGRATULATIONS%0A%0AYou are hereby notified that, You have been selected as ${appliedFor}. We will be more than happy to get you in this session.%0A%0APlz check your Email.%0A%0AIf you have any questions or feel that you've received this message in error, please contact %0A*info@techloset.com*! %0AYour very own Technology Closet, TechloSet Solutions PVT, LTD.`;
    } else if (interview && interview?.location === "onsite") {
      message = `*Onsite Interview ${interviewScheduled}*%0A%0AHi *${fname}*,%0A%0AWe've ${interviewScheduled} your interview for the *${appliedFor}* position at TechloSet Solutions. The interview will be held at our office on *${date}* at *${time}*.%0A%0ADuring the interview, you might be asked to complete several technical challenges and exercises to evaluate your proficiency in relevant field and problem-solving skills. The interview will last approximately 30-40 minutes long and will be conducted by members of our technical team.%0A%0APlease arrive a few minutes early to check-in and get familiar with the office location. We look forward to meeting you in person and evaluating your technical skills.%0A%0A*Office Location:* https://goo.gl/maps/Cn19h5P5Gk7QUjBF7%0A%0AIf you have any questions or feel that you've received this message in error, please contact *hr@techloset.com*! %0AYour very own Technology Closet, TechloSet Solutions PVT, LTD.`;
    } else if (interview && interview?.location === "remote") {
      message = `*Zoom Interview ${interviewScheduled}*%0A%0AHi *${fname}*,%0A%0AWe've ${interviewScheduled} your interview for the *${appliedFor}* position at TechloSet Solutions.%0A%0ATechloSet is inviting you to a scheduled *Zoom meeting*.%0A*Time*: ${time}%0A*Date*: ${date}%0A*Meeting Id*: ${interview?.meetingId}%0A*Passcode*: ${interview?.password}%0A*Join Url*: ${interview?.join_url}%0A%0AIf you have any questions or feel that you've received this message in error, please contact *hr@techloset.com*! %0AYour very own Technology Closet, TechloSet Solutions PVT, LTD.`;
    }
    window.open(`https://wa.me/+92${phone}/?text=${message}`, "_blank");
  };
  const columns = [
    {
      name: "",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let ID = `${jobsData[dataIndex].docId}`;
          return (
            <CopyToClipboard text={ID} onCopy={() => handleCopy(ID, "id")}>
              <span>#</span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let createdAt = `${
            jobsData[dataIndex].createdAt ? jobsData[dataIndex].createdAt : ""
          }`;
          return <span>{createdAt}</span>;
        },
      },
    },
    {
      name: "fname",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let name = `${jobsData[dataIndex].fname}`;
          return <span>{name}</span>;
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let email = jobsData[dataIndex]?.email?.toLowerCase();
          return (
            <CopyToClipboard
              text={email}
              onCopy={() => handleCopy(email, "email")}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                {email}
              </span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "phone",
      label: "Number",
      indexColumn: "id",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let number = jobsData[dataIndex]?.phone;
          return (
            <CopyToClipboard
              text={number}
              onCopy={() => handleCopy(number, "number")}
            >
              <span>{number}</span>
            </CopyToClipboard>
          );
        },
      },
    },

    {
      name: "github",
      label: "Github",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let gitHub = jobsData[dataIndex].gitHub?.split("/").pop();
          return (
            <CopyToClipboard
              text={gitHub}
              onCopy={() => handleCopy(gitHub, "github username")}
            >
              <span className="gitIcon">
                <GitHub />
              </span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "CV",
      label: "CV",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let CV = jobsData[dataIndex].CV;

          return (
            <MuiLink target="_blank" href={CV}>
              <VisibilityIcon color="default" />
            </MuiLink>
          );
        },
      },
    },
    // {
    //   name: "skype",
    //   label: "Skype",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRenderLite: (dataIndex) => {
    //       let skype = jobsData[dataIndex].skype;
    //       return (
    //         <CopyToClipboard
    //           text={skype}
    //           onCopy={() => handleCopy(skype, "skype link")}
    //         >
    //           <span className="skypeIcon">
    //             <Icon
    //               path={mdiSkypeBusiness}
    //               size={1}
    //               horizontal
    //               vertical
    //             />
    //           </span>
    //         </CopyToClipboard>
    //       );
    //     },
    //   },
    // },

    {
      name: "jobId",
      label: "Applied For",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let jobId = `${jobsData[dataIndex]?.jobId}`;
          let jobData = jobsData[dataIndex];
          return appliedLoading && index === dataIndex ? (
            <CircularProgress size={25} />
          ) : (
            <Select
              value={jobId}
              native
              onChange={(e) => {
                const job = allJobs.find((job) => job?.id === e.target.value);
                setAppliedFor(job, jobData, dataIndex, setAppliedLoading);
              }}
            >
              <option value="">Change Applied For</option>
              {allJobs.map((jobs, index) => (
                <option key={jobs + index} value={jobs?.id}>
                  {jobs.jobTitle}
                </option>
              ))}
            </Select>
          );
        },
      },
    },
    {
      name: "appliedFor",
      label: "Job Title",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let appliedFor = `${jobsData[dataIndex].appliedFor}`;
          return <span>{appliedFor}</span>;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          const status = jobsData[dataIndex].status;
          const jobData = jobsData[dataIndex];
          return loading && index === dataIndex ? (
            <CircularProgress size={25} />
          ) : (
            <Select
              value={status}
              native
              onChange={(e) =>
                setStatus(e.target.value, jobData, dataIndex, setloading)
              }
            >
              <option disabled value="">
                Update Status
              </option>
              <option
                disabled={
                  status === "declined" || status === "future" ? false : true
                }
                value="applied"
              >
                Applied
              </option>
              <option value="declined">Declined</option>
              <option
                disabled={status === "interviewed" ? false : true}
                value="future"
              >
                Future
              </option>
              <option disabled value="interviewSchedule">
                Schedule
              </option>
              <option disabled value="interviewReschedule">
                Reschedule
              </option>
              <option disabled value="interviewCancel">
                Cancelled
              </option>
              <option disabled value="interviewed">
                Interviewed
              </option>
              <option disabled value="boardingInvitation">
                boardingInvitation
              </option>
              <option disabled value="onboarding">
                Boarded
              </option>
              <option disabled value="hired">
                Hired
              </option>
            </Select>
          );
        },
      },
    },
    {
      name: "source",
      label: "Source",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let source = `${jobsData[dataIndex].source}`;
          return <span>{source ? source : "Main Site"}</span>;
        },
      },
    },
    {
      name: "Actions",
      label: "Actions",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let status = "boardingInvitation";
          let job = jobsData[dataIndex];
          let docid = jobsData[dataIndex]?.docId;
          let email = jobsData[dataIndex]?.email;
          let name = jobsData[dataIndex]?.fname;
          let appliedFor = jobsData[dataIndex]?.appliedFor;
          return (
            <div className={classes.actionButtons}>
              <Tooltip title="Get Time Suggestion">
                <IconButton onClick={() => getTime(name, email, appliedFor)}>
                  <AccessAlarmsIcon color="default" />
                </IconButton>
              </Tooltip>
              <OfferLetter data={job} />
              <QuestionsHistory questionsHistory={job?.jobStatusHistory} />

              <Tooltip title="WhatsApp">
                <IconButton
                  onClick={() => handleWhatsappMessage(jobsData[dataIndex])}
                  aria-label="WhatsApp Message"
                  variant="outlined"
                >
                  <WhatsApp color="primary" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Interview Schedule">
                <Link to={{ pathname: `/interviews/${docid}` }}>
                  <IconButton aria-label="Interview Invitation">
                    <ReceiptIcon color="primary" />
                  </IconButton>
                </Link>
              </Tooltip>
              <Tooltip title="Onboarding Invitation">
                <IconButton
                  onClick={() => startBoarding(status, job, setloading)}
                  color="secondary"
                >
                  <AssignmentTurnedInIcon />
                </IconButton>
              </Tooltip>
              <CopyToClipboard
                text={docid}
                onCopy={() => handleCopy(docid, "docid")}
              >
                <Link to={`/JobProfile/${docid}`}>
                  <Tooltip title="More Details">
                    <IconButton variant="outlined" color="#0000">
                      <FullscreenIcon />
                    </IconButton>
                  </Tooltip>
                </Link>
              </CopyToClipboard>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    searchPlaceholder: "Search here...",
    searchAlwaysOpen: true,
    filter: false,
    responsive: "standard",
    selectableRows: false,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
      customizeData: (data, headers, displayData) => {
        const filteredData = displayData.map((row) => row.data);
        return { data: filteredData, headers };
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return buildHead(columns) + buildBody(data);
    },
  };

  return (
    <Grid container spacing={3} style={{ marginTop: "30px" }}>
      <Grid items xs={12}>
        <MuiThemeProvider theme={GetMuiTheme}>
          <MUIDataTable
            className="mainTable"
            title={title}
            data={jobsData}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Grid>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
  );
};

export default MuiTable;
