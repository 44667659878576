import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, TextField, FormControl } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { useStyles } from "./attendanceReportStyle";
import { CircularProgress } from "@material-ui/core";
import {
  getAttendanceReport,
  ResetAttendanceReport,
} from "../redux/actions/reportsAction";
import { getUserData } from "../../../commonRedux/common/action";
import { ERROR_ALERT } from "../redux/constants/reportsConstant";
import AttendanceReportTable from "./AttendanceReportTable";
import AttendanceReportGraph from "./AttendanceReportGraph";
import Autocomplete from "@material-ui/lab/Autocomplete";
const AttendanceReport = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const allEmployee = useSelector((state) => state.commonReducer.allEmployees);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  useEffect(() => {
    if (allEmployee.length === 0) dispatch(getUserData(setDataLoading));
    dispatch(ResetAttendanceReport());
  }, []);

  const onChangeHandler = (e) => {
    const value = e?.target?.value;
    const name = e?.target?.name;
    setData({ ...data, [name]: value });
  };
  const handleChange = (event, value) => {
    setData({
      ...data,
      userId: value?.id || "",

      fullName: value?.fullName || "",
    });
  };
  const submitHandler = async () => {
    try {
      dispatch(ResetAttendanceReport());

      if (data?.startDate && data?.endDate && data?.userId) {
        if (data?.startDate > data?.endDate) {
          dispatch({
            type: ERROR_ALERT,
            payload: "Start date should not be  after end date ",
          });
        } else {
          dispatch(getAttendanceReport(setLoading, data));
        }
      } else {
        dispatch({ type: ERROR_ALERT, payload: "All Fields Are Required" });
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        <h1 className="Details">Attendance Report</h1>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item xs={12} md={12} sm={12}>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item md={6}>
                <TextField
                  id="startDate"
                  name="startDate"
                  label="*Start date"
                  type="date"
                  className={classes.formControl}
                  fullWidth
                  value={data?.startDate || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={onChangeHandler}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  id="endDate"
                  name="endDate"
                  label="*End date"
                  type="date"
                  className={classes.formControl}
                  fullWidth
                  value={data?.endDate || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={onChangeHandler}
                />
              </Grid>
              <Grid item md={6}>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    id="Select User"
                    options={allEmployee}
                    getOptionLabel={(option) => option.fullName}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select User"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={data?.fullName || ""}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item md={12} xs={12}>
            {loading ? (
              <CircularProgress className={classes.loader} />
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={submitHandler}
                  className={classes.button}
                  disabled={dataLoading}
                >
                  Get Report
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <Grid>
          <AttendanceReportGraph />
          <AttendanceReportTable />
        </Grid>
      </Container>
    </>
  );
};

export default AttendanceReport;
