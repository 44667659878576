import {
  GET_ALL_EMAILS,
  SUCCESS_ALERT,
  ERROR_ALERT,
  ADD_EMAIL,
  DELETE_EMAIL,
  UPDATE_EMAIL
} from "../constant/index";
import { db } from "../../../../config/firebase";

export const getAllEmails = (setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    let res = await db.collection("MeetingEmails").get();
    let allEmails = [];
    res.forEach((doc) => {
      allEmails.push({ ...doc.data(), id: doc.id });
    });
    dispatch({ type: GET_ALL_EMAILS, payload: allEmails });
    setLoading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setLoading(false);
  }
};
export const addEmail = (data, setLoading, toggle) => async (dispatch) => {
  try {
    setLoading(true);
  const docRef=  await db.collection("MeetingEmails").add({
      ...data,
      default: false,
    });
    const id=docRef?.id;
    dispatch({ type: ADD_EMAIL, payload: {...data,id} });
    dispatch({ type: SUCCESS_ALERT, payload: "Email Added Successfully." });
    toggle();
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
  finally {
    setLoading(false);
  }
};
export const updateEmail = (data, setLoading, toggle) => async (dispatch) => {
  try {
    setLoading(true);
    const { id, ...rest } = data;
    await db
      .collection("MeetingEmails")
      .doc(id)
      .update({
        ...rest,
      });
    dispatch({ type: UPDATE_EMAIL, payload: { ...rest, id } });
    dispatch({ type: SUCCESS_ALERT, payload: "Email updated Successfully." });
    if (toggle) {
      toggle();
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
  finally {
    setLoading(false);
  }
};
export const deleteEmail =
  (id, setLoading) => async (dispatch) => {
    try {
      setLoading(true);
      await db.collection("MeetingEmails").doc(id).delete();
      dispatch({ type: DELETE_EMAIL, payload: id });
      dispatch({
        type: SUCCESS_ALERT,
        payload: "Email Deleted  Successfully.",
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: ERROR_ALERT, payload: error.message });
    }
    finally {
      setLoading(false);
    }
  };

