import React from "react";
import { Calendar as Cal, momentLocalizer } from "react-big-calendar";
import moment from "./moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

const DragAndDropCalendar = withDragAndDrop(Cal);

const Calendar = ({
  events = [],
  date = new Date(),
  onNavigate,
  view = "month",
  onView,
  views = {
    month: true,
  },
  getNow = () => new Date(),
  accessors,
  selectable = false,
  ...props
}) => {
  const localizer = momentLocalizer(moment);
  return (
    <DragAndDropCalendar
      {...{
        localizer,
        events,
        date,
        onNavigate,
        view,
        onView,
        getNow,
        accessors,
        selectable,
        popup: true,
      }}
      resizable
      {...props}
    />
  );
};

export default Calendar;
