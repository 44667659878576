import Icon from "@mdi/react";
import React from "react";
//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiYoutube as YoutubeIcon,
  mdiLinkedin as LinkedinIcon,
} from "@mdi/js";
import { Box, IconButton, Link } from "@material-ui/core";
import useStyles from "./styles";
const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.socialIcons}>
      <Box width={"100%"} display={"flex"} justifyContent="space-between">
        <Link href="https://www.facebook.com/techloset" target={"_blank"}>
          <IconButton aria-label="facebook">
            <Icon path={FacebookIcon} size={1} color="#6E6E6E99" />
          </IconButton>
        </Link>
        <Link href={"https://twitter.com/techloset"} target={"_blank"}>
          <IconButton aria-label="TwitterIcon">
            <Icon path={TwitterIcon} size={1} color="#6E6E6E99" />
          </IconButton>
        </Link>
        <Link
          href={"https://www.linkedin.com/company/techloset/about/"}
          target={"_blank"}
        >
          <IconButton aria-label="linkedin">
            <Icon path={LinkedinIcon} size={1} color="#6E6E6E99" />
          </IconButton>
        </Link>
        <Link
          href={"https://www.youtube.com/channel/UCPC0RXZrvh50scjAzJC-IWg"}
          target={"_blank"}
        >
          <IconButton aria-label="linkedin">
            <Icon path={YoutubeIcon} size={1} color="#6E6E6E99" />
          </IconButton>
        </Link>
      </Box>
    </div>
  );
};
export default Footer;
