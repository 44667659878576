import React, { useState } from "react";
import { ATTENDANCE_STATUS } from "./redux/constant/attendanceConstant";
import {
  Grid,
  Chip,
  MuiThemeProvider,
  Tooltip,
  Switch,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import ScheduleIcon from "@material-ui/icons/Schedule";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import InputIcon from "@material-ui/icons/Input";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import HomeWorkIcon from '@material-ui/icons/HomeWorkOutlined';
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import StarIcon from "@material-ui/icons/Star";
import CenterFocusWeakIcon from "@material-ui/icons/CenterFocusWeak";
import MUIDataTable from "mui-datatables";
import useStyles from "./style";
import moment from "moment";
import { getTimeDiff, states, options } from "./utils";
import { Shuffle } from "@material-ui/icons";
import CircularLoader from "../utils/circularProgress";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Modal, TimePicker, DatePicker } from "antd";
import AttendanceCalendar from "./calendar/AttendanceCalendar";
import { GetMuiTheme } from "../utils/TableHeadStyle/style";
import { useDispatch } from "react-redux";
import ImageDilog from "../../components/imageDilog/ImageDilog";

const AttendanceTable = ({ data, pageTitle, date, loading, dateUpdate, handleStatusUpdate, updateLoading, handleCancel, handleClickOpen, index, isModalVisible }) => {
  const classes = useStyles();
  const [state, setState] = useState(false);
  const { RangePicker } = TimePicker;
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const handleTime = (moment, string) => {
    const [start, end] = string;
    setStartTime(start);
    setEndTime(end);
  };

  const columns = [
    {
      name: "profilePhoto",
      label: "Photo",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <ImageDilog
              src={data[dataIndex]?.profilephoto}
            />
          );
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let email = data[dataIndex]?.email?.toLowerCase();
          return <b>{email}</b>;
        },
      },
    },
    {
      name: "fullName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let fullName = data[dataIndex]?.fullName?.toUpperCase();
          return <b>{fullName}</b>;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const a = [
            ATTENDANCE_STATUS?.PRESENT,
            ATTENDANCE_STATUS?.ABSENT,
            ATTENDANCE_STATUS?.LATE,
            ATTENDANCE_STATUS?.PAID_LEAVE,
            ATTENDANCE_STATUS?.CASUAL_LEAVE,
            ATTENDANCE_STATUS?.SICK_LEAVE,
            ATTENDANCE_STATUS?.ANNUAL_LEAVE,
            ATTENDANCE_STATUS?.MATERNITY_PATERNITY_LEAVE,
            ATTENDANCE_STATUS?.UNPAID_LEAVE,
            ATTENDANCE_STATUS?.HALF_LEAVE,
            ATTENDANCE_STATUS?.REMOTE,
          ];
          let prev = data[dataIndex].label;
          let status;
          a.includes(prev) ? (status = prev) : (status = "present");
          return (
            <>
              <Chip
                variant="outlined"
                size="medium"
                onClick={() =>
                  handleStatusUpdate(dataIndex, ATTENDANCE_STATUS?.PRESENT)
                }
                icon={
                  <Tooltip title="Present">
                    <DoneIcon
                      classes={{
                        root: classes[
                          states[
                            status === ATTENDANCE_STATUS?.PRESENT
                              ? "presentIcon"
                              : "iconDisabled"
                          ]
                        ],
                      }}
                    />
                  </Tooltip>
                }
                clickable={
                  status !== ATTENDANCE_STATUS?.PRESENT || !updateLoading
                }
                classes={{
                  root: classes[
                    states[
                      status === ATTENDANCE_STATUS?.PRESENT
                        ? ATTENDANCE_STATUS?.PRESENT
                        : "disabled"
                    ]
                  ],
                }}
              />
              <Chip
                variant="outlined"
                size="medium"
                onClick={() =>
                  handleStatusUpdate(dataIndex, ATTENDANCE_STATUS?.ABSENT)
                }
                icon={
                  <Tooltip title="Absent">
                    <CloseIcon
                      classes={{
                        root: classes[
                          states[
                            status === ATTENDANCE_STATUS?.ABSENT
                              ? "absentIcon"
                              : "iconDisabled"
                          ]
                        ],
                      }}
                    />
                  </Tooltip>
                }
                clickable={status !== ATTENDANCE_STATUS?.ABSENT}
                classes={{
                  root: classes[
                    states[
                      status === ATTENDANCE_STATUS?.ABSENT
                        ? "absent"
                        : "disabled"
                    ]
                  ],
                }}
              />
              <Chip
                variant="outlined"
                size="medium"
                onClick={() =>
                  handleClickOpen(dataIndex, ATTENDANCE_STATUS?.LATE)
                }
                icon={
                  <Tooltip title="Late">
                    <ScheduleIcon
                      classes={{
                        root: classes[
                          states[
                            status === ATTENDANCE_STATUS?.LATE
                              ? "lateIcon"
                              : "iconDisabled"
                          ]
                        ],
                      }}
                    />
                  </Tooltip>
                }
                clickable={status !== ATTENDANCE_STATUS?.LATE || !updateLoading}
                classes={{
                  root: classes[
                    states[
                      status === ATTENDANCE_STATUS?.LATE
                        ? ATTENDANCE_STATUS?.LATE
                        : "disabled"
                    ]
                  ],
                }}
              />
              <Chip
                variant="outlined"
                onClick={() =>
                  handleStatusUpdate(dataIndex, ATTENDANCE_STATUS?.CASUAL_LEAVE)
                }
                size="medium"
                icon={
                  <Tooltip title="Casual Leave">
                    <InputIcon
                      classes={{
                        root: classes[
                          states[
                            status === ATTENDANCE_STATUS?.CASUAL_LEAVE
                              ? "leaveIcon"
                              : "iconDisabled"
                          ]
                        ],
                      }}
                    />
                  </Tooltip>
                }
                clickable={
                  status !== ATTENDANCE_STATUS?.CASUAL_LEAVE || !updateLoading
                }
                classes={{
                  root: classes[
                    states[
                      status === ATTENDANCE_STATUS?.CASUAL_LEAVE
                        ? "paidLeave"
                        : "disabled"
                    ]
                  ],
                }}
              />
              <Chip
                variant="outlined"
                onClick={() =>
                  handleStatusUpdate(dataIndex, ATTENDANCE_STATUS?.SICK_LEAVE)
                }
                size="medium"
                icon={
                  <Tooltip title="Sick Leave">
                    <LocalHospitalIcon
                      classes={{
                        root: classes[
                          states[
                            status === ATTENDANCE_STATUS?.SICK_LEAVE
                              ? "leaveIcon"
                              : "iconDisabled"
                          ]
                        ],
                      }}
                    />
                  </Tooltip>
                }
                clickable={
                  status !== ATTENDANCE_STATUS?.SICK_LEAVE || !updateLoading
                }
                classes={{
                  root: classes[
                    states[
                      status === ATTENDANCE_STATUS?.SICK_LEAVE
                        ? "paidLeave"
                        : "disabled"
                    ]
                  ],
                }}
              />
              <Chip
                variant="outlined"
                onClick={() =>
                  handleStatusUpdate(dataIndex, ATTENDANCE_STATUS?.ANNUAL_LEAVE)
                }
                size="medium"
                icon={
                  <Tooltip title="Annual Leave">
                    <StarIcon
                      classes={{
                        root: classes[
                          states[
                            status === ATTENDANCE_STATUS?.ANNUAL_LEAVE
                              ? "leaveIcon"
                              : "iconDisabled"
                          ]
                        ],
                      }}
                    />
                  </Tooltip>
                }
                clickable={
                  status !== ATTENDANCE_STATUS?.ANNUAL_LEAVE || !updateLoading
                }
                classes={{
                  root: classes[
                    states[
                      status === ATTENDANCE_STATUS?.ANNUAL_LEAVE
                        ? "paidLeave"
                        : "disabled"
                    ]
                  ],
                }}
              />
              <Chip
                variant="outlined"
                onClick={() =>
                  handleStatusUpdate(
                    dataIndex,
                    ATTENDANCE_STATUS?.MATERNITY_PATERNITY_LEAVE,
                  )
                }
                size="medium"
                icon={
                  <Tooltip title="Maternity Paternity Leave">
                    <CenterFocusWeakIcon
                      classes={{
                        root: classes[
                          states[
                            status ===
                            ATTENDANCE_STATUS?.MATERNITY_PATERNITY_LEAVE
                              ? "paidLeave"
                              : "iconDisabled"
                          ]
                        ],
                      }}
                    />
                  </Tooltip>
                }
                clickable={
                  status !== ATTENDANCE_STATUS?.MATERNITY_PATERNITY_LEAVE ||
                  !updateLoading
                }
                classes={{
                  root: classes[
                    states[
                      status === ATTENDANCE_STATUS?.MATERNITY_PATERNITY_LEAVE
                        ? "paidLeave"
                        : "disabled"
                    ]
                  ],
                }}
              />
              <Chip
                variant="outlined"
                onClick={() =>
                  handleStatusUpdate(dataIndex, ATTENDANCE_STATUS?.PAID_LEAVE)
                }
                size="medium"
                icon={
                  <Tooltip title="Paid Leave">
                    <AttachMoneyIcon
                      classes={{
                        root: classes[
                          states[
                            status === ATTENDANCE_STATUS?.PAID_LEAVE
                              ? "paidLeave"
                              : "iconDisabled"
                          ]
                        ],
                      }}
                    />
                  </Tooltip>
                }
                clickable={
                  status !== ATTENDANCE_STATUS?.PAID_LEAVE || !updateLoading
                }
                classes={{
                  root: classes[
                    states[
                      status === ATTENDANCE_STATUS?.PAID_LEAVE
                        ? "paidLeave"
                        : "disabled"
                    ]
                  ],
                }}
              />
              <Chip
                variant="outlined"
                onClick={() => handleStatusUpdate(dataIndex, "unpaidLeave")}
                size="medium"
                icon={
                  <Tooltip title="UnPaid Leave">
                    <MoneyOffIcon
                      classes={{
                        root: classes[
                          states[
                            status === "unpaidLeave"
                              ? "leaveIcon"
                              : "iconDisabled"
                          ]
                        ],
                      }}
                    />
                  </Tooltip>
                }
                clickable={status !== "unpaidLeave" || !updateLoading}
                classes={{
                  root: classes[
                    states[status === "unpaidLeave" ? "paidLeave" : "disabled"]
                  ],
                }}
              />
              <Chip
                variant="outlined"
                onClick={() =>
                  handleStatusUpdate(dataIndex, ATTENDANCE_STATUS?.HALF_LEAVE)
                }
                size="medium"
                icon={
                  <Tooltip title="Half Leave">
                    <Shuffle
                      classes={{
                        root: classes[
                          states[
                            status === ATTENDANCE_STATUS?.HALF_LEAVE
                              ? "paidLeave"
                              : "iconDisabled"
                          ]
                        ],
                      }}
                    />
                  </Tooltip>
                }
                clickable={
                  status !== ATTENDANCE_STATUS?.HALF_LEAVE || !updateLoading
                }
                classes={{
                  root: classes[
                    states[
                      status === ATTENDANCE_STATUS?.HALF_LEAVE
                        ? "paidLeave"
                        : "disabled"
                    ]
                  ],
                }}
              />
              <Chip
              variant="outlined"
              onClick={() =>
                handleStatusUpdate(dataIndex, ATTENDANCE_STATUS?.REMOTE)
              }
              size="medium"
              icon={
                <Tooltip title="Remote">
                  <HomeWorkIcon
                    classes={{
                      root: classes[
                        states[
                          status === ATTENDANCE_STATUS?.REMOTE
                            ? "paidLeave"
                            : "iconDisabled"
                        ]
                      ],
                    }}
                  />
                </Tooltip>
              }
              clickable={
                status!== ATTENDANCE_STATUS?.REMOTE ||!updateLoading
              }
              classes={{
                root: classes[
                  states[
                    status === ATTENDANCE_STATUS?.REMOTE
                      ? "paidLeave"
                      : "disabled"
                  ]
                ],
              }}
            />
            </>
          );
        },
      },
    },
    {
      name: "attendanceHours",
      label: "Total Attd. Hours",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let { start, end } = data[dataIndex];
          return (
            <b>
              {data[dataIndex]?.label === "late"
                ? getTimeDiff(date, start, end)
                : "08:00"}
            </b>
          );
        },
      },
    },
  ];
  return loading ? (
    <CircularLoader />
  ) : (
    <>
      <PageTitle title={pageTitle} />
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={3}
      >
        {!state && (
          <Grid item xs={2}>
            <DatePicker
              allowClear={false}
              onChange={(a) => dateUpdate(a)}
              format="DD/MM/YYYY"
              value={moment(date)}
            />
          </Grid>
        )}
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                style={{ marginLeft: "0px" }}
                checked={state}
                onChange={() => setState(!state)}
                name="Calendar"
              />
            }
            label="Calendar"
          />
        </Grid>
        <Grid item xs={12}>
          {state ? (
            <AttendanceCalendar />
          ) : (
            <MuiThemeProvider theme={GetMuiTheme}>
              <MUIDataTable
                className="mainTable"
                data={data}
                columns={columns}
                options={options}
              />
            </MuiThemeProvider>
          )}
        </Grid>
      </Grid>
      <Modal
        title="Select Start and End time!"
        centered
        visible={isModalVisible}
        onOk={() => handleStatusUpdate(index, "late")}
        onCancel={handleCancel}
      >
        <RangePicker
          format="HH:mm:ss"
          onChange={(moment, string) => handleTime(moment, string)}
        />
      </Modal>
    </>
  );
};

export default AttendanceTable;
