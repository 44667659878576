import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Button, TextField } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { useStyles } from "../ModalStyle";
import { CircularProgress } from "@material-ui/core";
import { ERROR_ALERT } from "../../../../commonUtils/share";
import {
  addContractType,
  updateContractType,
} from "../../redux/actions/contractTypeAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ContractTypeModal = (props) => {
  const { modal, toggle, data } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [contractType, setContractType] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data) {
      setContractType({
        ...data,
      });
    }
  }, [data]);

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setContractType({
      ...contractType,
      [name]: value,
    });
  };
  const submitHandler = async () => {
    try {
      if (contractType?.type) {
        if (contractType?.id) {
          dispatch(updateContractType(contractType, setLoading, toggle));
        } else {
          dispatch(addContractType(contractType, setLoading, toggle));
        }
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: "ALL Fields Are Required!",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Dialog
        fullScreen
        open={modal}
        onClose={() => toggle()}
        TransitionComponent={Transition}
      >
        <AppBar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => toggle()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </AppBar>

        <Container maxWidth="md" className={classes.container}>
          <h1 className="Details">Contract Type </h1>
          <Grid container justify="center" alignItems="center" spacing={2}>
            <Grid item xs={9} md={9} sm={9}>
              <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid item md={12}>
                  <TextField
                    name="type"
                    className={classes.formControl}
                    fullWidth
                    placeholder="Title"
                    id="outlined-basic"
                    label="Title"
                    variant="outlined"
                    value={contractType?.type || ""}
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    name="emailSubject"
                    className={classes.formControl}
                    fullWidth
                    placeholder="Contract Subject"
                    id="outlined-basic"
                    label="Contract Subject"
                    variant="outlined"
                    value={contractType?.emailSubject || ""}
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    name="emailMessage"
                    className={classes.formControl}
                    fullWidth
                    placeholder="Contract Message"
                    id="outlined-basic"
                    label="Contract Message"
                    variant="outlined"
                    value={contractType?.emailMessage || ""}
                    onChange={onChangeHandler}
                    multiline
                    maxRows={10}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3} className={classes.grid}>
            <Grid item md={12} xs={12}>
              {loading ? (
                <CircularProgress className={classes.loader} />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={submitHandler}
                    className={classes.button}
                  >
                    {contractType?.id ? "Update" : "Add"}
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </>
  );
};
const ConfirmModal = ({ modal, toggle, message, action }) => {
  return (
    <Dialog
      open={modal}
      onClose={toggle}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
      <DialogActions>
        <Button onClick={toggle} color="primary">
          Cancel
        </Button>
        <Button onClick={action} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ContractTypeModal, ConfirmModal };
