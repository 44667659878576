import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import useStyles from "./style";
import TableBody from "@material-ui/core/TableBody";
import Collapse from "@material-ui/core/Collapse";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

export default function TableRowComponent({ userId, userleaves, data }) {
  const classes = useStyles();
  let startOfMonth;
  let endOfMonth;
  if (data?.startOfMonth.seconds) {
    startOfMonth = data?.startOfMonth?.toDate();
    endOfMonth = data?.endOfMonth?.toDate();
  } else {
    startOfMonth = data?.startOfMonth;
    endOfMonth = data?.endOfMonth;
  }
  let itm = {
    ...data,
    startOfMonth,
    endOfMonth,
  };
  return (
    <>
      <TableRow>
        <TableCell colSpan={12}>
          <Collapse in={true} timeout="auto" unmountOnExit>
            <div className={classes.gridRoot}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                spacing={3}
              >
                <Grid item colSpan={3}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item>
                      <Typography
                        align="center"
                        variant="h6"
                        // gutterBottom
                        component="span"
                      >
                        &nbsp; &nbsp; Pay Period :
                      </Typography>
                    </Grid>
                    <Typography
                      align="center"
                      variant="subtitle1"
                      // gutterBottom
                      component="span"
                    >
                      {moment(itm.startOfMonth).format("LL")} &nbsp; to &nbsp;
                    </Typography>
                    <Typography
                      align="center"
                      variant="subtitle1"
                      // gutterBottom
                      component="span"
                    >
                      {moment(itm.endOfMonth).format("LL")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item colSpan={8}>
                  {/* For Earnings */}
                  <h3>Earnings</h3>
                  {data?.earnings?.map((n, i) => {
                    return (
                      <Grid
                        key={i}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="flex-end"
                        spacing={3}
                      >
                        <TextField
                          id="standard-basic_"
                          size="small"
                          label="Title"
                          value={n.title}
                          type="text"
                          disabled
                          className={classes.margin}
                        />

                        <TextField
                          id="standard-basic__"
                          size="small"
                          label="Amount"
                          value={n.amount}
                          type="number"
                          className={classes.margin}
                          disabled
                        />
                      </Grid>
                    );
                  })}
                  {/* End earning */}

                  {/* For Deduction */}

                  <h3 style={{ paddingTop: 20 }}>Deductions</h3>

                  {data?.deductions?.map((n, i) => {
                    return (
                      <Grid
                        key={i}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="flex-end"
                        spacing={3}
                      >
                        <TextField
                          id="standard-basic_"
                          size="small"
                          label="Title"
                          value={n.title}
                          disabled
                          type="text"
                          className={classes.margin}
                        />

                        <TextField
                          id="standard-basic__"
                          size="small"
                          label="Amount"
                          value={n.amount}
                          disabled
                          type="number"
                          className={classes.margin}
                        />
                      </Grid>
                    );
                  })}

                  {/* End Deduction */}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item colSpan={4}>
                  <Typography variant="h6" gutterBottom component="div">
                    &nbsp; &nbsp; Leaves History
                  </Typography>
                </Grid>
              </Grid>
            </div>

            {userleaves.length > 0 ? (
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>start Date</TableCell>
                    <TableCell>End Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userleaves.map((leave, i) => {
                    let start;
                    let end;
                    if (leave.start.seconds) {
                      start = leave.start?.toDate();
                      end = leave.end?.toDate();
                    } else {
                      start = leave.start;
                      end = leave.end;
                    }
                    let itm = {
                      ...leave,
                      start,
                      end,
                    };
                    // console.log("itm", itm);
                    return (
                      <TableRow key={itm.id}>
                        <TableCell>{++i}</TableCell>
                        <TableCell>{itm.title}</TableCell>
                        <TableCell align="left">{itm.description}</TableCell>
                        <TableCell component="th" scope="row">
                        {moment(itm.start, "DD/MM/YYYY").format("ll")}
                        </TableCell>
                        <TableCell component="th" scope="row">
                        {moment(itm.end, "DD/MM/YYYY").format("ll")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        // backgroundColor: "black",
                        paddingBottom: 0,
                        // marginTop: 10,
                      }}
                      colSpan={12}
                      align="center"
                    >
                      <Typography
                        align="center"
                        variant="h6"
                        gutterBottom
                        component="div"
                      >
                        No Leave
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
