import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useJobRequest } from "./customHooks/useJobRequest";
import MuiTable from "./components/MuiTable";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddJobApplication from "./components/addJobApplication/AddJobApplication";
import ManualOnBoard from "./components/manualOnBoardInvite/ManualOnboard";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import AllSearchTable from "./components/AllSearchTable";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  AddSearchDiv: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
  },
  searchField: {
    marginTop: "5px",
  },
}));

export default function JobRequest() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const {
    showDeclinedJob,
    showFutureJob,
    showInterviewScheduleJob,
    showInterviewRescheduleJob,
    showCancelInterviewJob,
    showInterviewedJob,
    showHiredJob,
    showOnBoardJob,
    filteredSearch,
    appliedJobs,
    declinedJobs,
    futureJobs,
    interviewScheduleJobs,
    interviewRescheduleJobs,
    interviewedJobs,
    interviewCancelJobs,
    hiredJobs,
    loading,
    onBoardJobs,
    searchValue,
    onChangeHandler,
    searchHandler,
    foucsHandler,
  } = useJobRequest();
  const sortedAppliedJobs = appliedJobs.sort((a, b) => {
    return new Date(b?.createdAt) - new Date(a?.createdAt);
  });

  return (
    <div className={classes.root}>
      <div className={classes.AddSearchDiv}>
        <AddJobApplication />
        <ManualOnBoard />
        <div>
          <TextField
            autoFocus
            placeholder="Search..."
            className={classes.searchField}
            value={searchValue}
            onFocus={foucsHandler}
            onChange={(e) => {
              onChangeHandler(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={searchHandler}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      {searchValue ? (
        <AllSearchTable searchedJobs={filteredSearch} />
      ) : (
        <>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Applied" {...a11yProps(0)} />
              <Tab
                label="Interview Schedule"
                {...a11yProps(1)}
                onClick={() => showInterviewScheduleJob()}
              />
              <Tab
                label="Interview Reschedule"
                {...a11yProps(2)}
                onClick={() => showInterviewRescheduleJob()}
              />
              <Tab
                label="Cancelled Interview"
                {...a11yProps(3)}
                onClick={() => showCancelInterviewJob()}
              />
              <Tab
                label="Interviewed"
                {...a11yProps(4)}
                onClick={() => showInterviewedJob()}
              />
              <Tab
                label="OnBoarding"
                {...a11yProps(5)}
                onClick={() => showOnBoardJob()}
              />
              <Tab
                label="Hired"
                {...a11yProps(6)}
                onClick={() => showHiredJob()}
              />
              <Tab
                label="Declined"
                {...a11yProps(7)}
                onClick={() => showDeclinedJob()}
              />
              <Tab
                label="Future"
                {...a11yProps(8)}
                onClick={() => showFutureJob()}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            {loading ? (
              <CircularProgress />
            ) : (
              <MuiTable data={sortedAppliedJobs} title="Applied" />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {loading ? (
              <CircularProgress />
            ) : (
              <MuiTable
                data={interviewScheduleJobs}
                title="Interview Shecdule"
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <MuiTable
                data={interviewRescheduleJobs}
                title="Interview Reshedule"
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={3}>
            {loading ? (
              <CircularProgress />
            ) : (
              <MuiTable
                data={interviewCancelJobs}
                title="Interview Cancelled"
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={4}>
            {loading ? (
              <CircularProgress />
            ) : (
              <MuiTable data={interviewedJobs} title="Interviewed" />
            )}
          </TabPanel>
          <TabPanel value={value} index={5}>
            {loading ? (
              <CircularProgress />
            ) : (
              <MuiTable data={onBoardJobs} title="OnBoarding" />
            )}
          </TabPanel>
          <TabPanel value={value} index={6}>
            {loading ? (
              <CircularProgress />
            ) : (
              <MuiTable data={hiredJobs} title="Hired" />
            )}
          </TabPanel>
          <TabPanel value={value} index={7}>
            {loading ? (
              <CircularProgress />
            ) : (
              <MuiTable data={declinedJobs} title="Declined" />
            )}
          </TabPanel>
          <TabPanel value={value} index={8}>
            {loading ? (
              <CircularProgress />
            ) : (
              <MuiTable data={futureJobs} title="Future" />
            )}
          </TabPanel>
        </>
      )}
    </div>
  );
}
