import React, { useState } from "react";
import { Button } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { ToastContainer } from "react-toastify";
// import { useStyles } from "./style.js";
import { useDispatch } from "react-redux";
import { deleteJob, editJobAction } from "../redux/actions/jobsActions.js";
import JobUpdateModal from "./jobUpdateModal.js";
import { useHistory } from "react-router-dom";

export default function JobList({ job }) {
  // const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const HandleDeleteJob = () => {
    dispatch(deleteJob(job.id));
  };

  const handleEdit = () => {
    dispatch(editJobAction(job));
    history.push(`/job/edit/${job.id}`);
  };
  return (
    <>
      {/* <TableContainer component={Paper}> */}
      {/* <Table className={classes.table} aria-label="simple table"> */}
      <TableBody>
        <TableRow>
          <TableCell align="left">{job.jobTitle}</TableCell>
          <TableCell align="left">{job.jobType}</TableCell>
          <TableCell align="left">{job.jobCategory}</TableCell>
          <TableCell align="left">{job.jobLocation}</TableCell>
          <TableCell align="left">
            <Button
              variant="contained"
              color="primary"
              // className={classes.button}
              size="small"
              // onClick={() => setVisible(true)}
              onClick={handleEdit}
            >
              Edit
            </Button>
          </TableCell>
          {/* <TableCell align="left">
            <Button
              variant="contained"
              color="secondary"
              // className={classes.button}
              size="small"
              onClick={HandleDeleteJob}
            >
              <ToastContainer />
              Remove
            </Button>
          </TableCell> */}
        </TableRow>
      </TableBody>
      {/* </Table> */}
      {/* </TableContainer> */}
      <JobUpdateModal
        job={job}
        visible={visible}
        setVisible={(value) => setVisible(value)}
      />
    </>
  );
}
