import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllLetters } from "./redux/actions/lettersRequestAction";
import CircularLoader from "../utils/circularProgress/index";
import TableComponent from "./table";

export default function LettersRequest() {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);

  useEffect(() => {
    dispatch(getAllLetters(setloading));
  }, []);
  return <>{loading ? <CircularLoader /> : <TableComponent />}</>;
}
