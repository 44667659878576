import { ADD_IMAGES, GET_IMAGES, DELETE_IMAGE } from "../constant";

const initialState = {
  allImages: [],

};

const galleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_IMAGES:
      return {
        ...state,
        allImages: action.payload
      };

    case DELETE_IMAGE:
      const imagesUpdated = state.allImages.filter((image) => image.id !== action.payload);
      // console.log("imagesUpdated", imagesUpdated);
      return {
        ...state,
        allImages: imagesUpdated,
      };

    case GET_IMAGES:
      return {
        ...state,
        allImages: action.payload,
      };

    default:
      return state;
  }

};

export default galleryReducer;
