import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        minWidth: 300
    },
    media: {
        height: 70,
        textAlign: 'center'
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    padding: {
        padding:"5px"
    },
    mainButton: {
        marginLeft: "20px"
    },
    header: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'space-between'
    },
    item: {
        display: "flex",
        justifyContent: 'space-between'
    },
    addButton: {
        float:"right",
        margin:"10px"
    },
    textField: {
        margin:"10px",
    },
    selectField: {
        minWidth: "150px",
    },
    alignCard: {
        display: 'flex',
        flexWrap: "wrap",
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        padding:"20px"
    },
    alignQuestions:{
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        padding:"20px",
    },
    questionMainDiv:{
        width:"100%",
        padding: "20px",
        margin:"0 auto 10px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
        display:"flex",
        justifyContent:'space-evenly',
    },
    questionDiv: {
        width: '80%',
    },
    fieldDiv: {
        margin: '20px 0',
        minWidth: "400px",
        maxWidth:'600px'
    }
}));
