import {
    ADD_JOB_TYPE,
    ADD_RANK,
    DELETE_JOB_TYPE,
    GET_ALL_JOB_TYPES,
    GET_RANKS_BY_TITLE, 
    UPDATE_JOB_TYPE, 
    UPDATE_RANK
} from './constants';

let initialState = {
    ranksData: {},
    jobTypes: []
}

const ranksReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_RANKS_BY_TITLE:
            return {
                ...state,
                ranksData: action.payload
            }
        case UPDATE_RANK:
            return {
                ...state,
                ranksData: action.payload
            }
        case ADD_RANK:
            return {
                ...state,
                ranksData: action.payload
            }
        case GET_ALL_JOB_TYPES:
            return {
                ...state,
                jobTypes: action.payload,
            };
        case ADD_JOB_TYPE:
            return {
                ...state,
                jobTypes: [...state.jobTypes, action.payload],
            };
        case DELETE_JOB_TYPE:
            let filtered = state.jobTypes.filter((doc) => doc.docId !== action.payload)
            return {
                ...state,
                jobTypes: filtered,
            };
        case UPDATE_JOB_TYPE:
            let updatedJobs = state.jobTypes.map((doc) => {
                if (doc.docId === action.payload.docId) {
                    return { ...action.payload, ...action.payload.docId }
                } else { return doc }
            })
            return {
                ...state,
                jobTypes: updatedJobs,
            };

        default:
            return state;
    }
}

export default ranksReducer;