export const GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES";
export const GET_ALL_BOOTCAMP_TEAM = "GET_ALL_BOOTCAMP_TEAM";
export const GET_ALL_ONBOARDING = "GET_ALL_ONBOARDING";
export const GET_ALL_BOOTCAMP_ONBOARDING = "GET_ALL_BOOTCAMP_ONBOARDING";
export const CURRENT_EMPLOYEE = "CURRENT_EMPLOYEE";
export const CURRENT_ONBOARDING = "CURRENT_ONBOARDING";
export const SUCCESS_ALERT = "SUCCESS_ALERT";
export const ERROR_ALERT = "ERROR_ALERT";
export const ALL_EMPLOYEE = "ALL_EMPLOYEE";
export const GET_BLOCK_EMPLOYEE = "GET_BLOCK_EMPLOYEE";
export const GET_DECLIEND_ONBOARDING = "GET_DECLIEND_ONBOARDING";
export const GET_BLOCK_BOOTCAMP_TEAM = "GET_BLOCK_BOOTCAMP_TEAM";
export const GET_DECLIEND_BOOTCAMP_ONBOARDING = "GET_DECLIEND_BOOTCAMP_ONBOARDING";
export const ALL_TEAM = "ALL_TEAM";
export const GET_ALL_TEAM = "GET_ALL_TEAM";
export const GET_MAINSITE_TEAM = "GET_MAINSITE_TEAM"
export const UPDATE_MAINSITE_TEAM = "UPDATE_MAINSITE_TEAM"
