export const GET_ALL_JOBS_REQUESTS = "GET_ALL_JOBS_REQUESTS";
export const SUCCESS_ALERT = "SUCCESS_ALERT";
export const ERROR_ALERT = "ERROR_ALERT";
export const GET_ALL_APPLIED_JOBS = "GET_ALL_APPLIED_JOBS";
export const APPLY_FOR_JOB = "APPLY_FOR_JOB";
export const GET_ALL_DECLINED_JOBS = "GET_ALL_DECLINED_JOBS";
export const GET_ALL_FUTURE_JOBS = "GET_ALL_FUTURE_JOBS";
export const GET_ALL_INTERVIEW_SCHEDULE_JOBS = "GET_ALL_INTERVIEW_SHEDULE_JOBS";
export const GET_ALL_INTERVIEW_RESCHEDULE_JOBS =
  "GET_ALL_INTERVIEW_RESHEDULE_JOBS";
export const GET_ALL_INTERVIEW_CANCEL_JOBS = "GET_ALL_INTERVIEW_CANCEL_JOBS";
export const GET_ALL_INTERVIEWED_JOBS = "GET_ALL_INTERVIEWED_JOBS";
export const GET_ALL_ONBOARD_JOBS = "GET_ALL_ONBOARD_JOBS";
export const GET_ALL_HIRED_JOBS = "GET_ALL_HIRED_JOBS";
export const UPDATE_JOB_STATUS = "UPDATE_JOB_STATUS";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const SORT_CARDS = "SORT_CARDS";
export const INTERVIEW_SHECDULE = "INTERVIEW_SHECDULE";
export const UPDATE_INTERVIEW_SHECDULE = "UPDATE_INTERVIEW_SHECDULE";
export const UPDATE_STATUS_HISTORY = "UPDATE_STATUS_HISTORY";
export const UPDATE_APPLIED_HISTORY = "UPDATE_APPLIED_HISTORY";
export const SEARCHED_JOBS = "SEARCHED_JOBS";
export const GET_SELECTED_INTERVIEWS = "GET_SELECTED_INTERVIEWS";
export const FILTER_SELECTED_INTERVIEWS = "FILTER_SELECTED_INTERVIEWS";