import React, { useState } from 'react'
import { Button, InputLabel, Menu, TextField } from '@material-ui/core'
import { useStyles } from '../style'
import { useDispatch, useSelector } from 'react-redux'
import { updateRank } from '../redux/actions'

export default function UpdateRank({ rank, data }) {
    const [updateDialog, setUpdateDialog] = useState(null)
    const [rankVal, setRankVal] = useState('')
    const classes = useStyles()
    const dispatch = useDispatch()

    const dialogClose = () => {
        setUpdateDialog(null)
        setRankVal('')
    }
    const dialogOpen = (e) => {
        setUpdateDialog(e.currentTarget)
        setRankVal(rank?.rank)
    }

    const rankUpdateHandler = () => {
        if (rankVal) {
            dispatch(updateRank(rank?.id, rankVal, data))
        }
        dialogClose()
    }

    return (
        <div>
            <Button
                color='primary'
                variant='contained'
                onClick={dialogOpen}
            >
                Update
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={updateDialog}
                keepMounted
                open={Boolean(updateDialog)}
                onClose={dialogClose}
            >
                <div className={classes.padding}>
                    <h3 className={classes.label}><strong> Update Rank </strong></h3>
                    <div>
                        <TextField
                            id='name'
                            autoFocus
                            placeholder="e.g. Team Lead"
                            value={rankVal}
                            className={classes.textField}
                            onChange={(e) => { setRankVal(e.target.value) }}
                        />
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.addButton}
                            onClick={rankUpdateHandler}
                        >
                            Update
                        </Button>
                    </div>
                </div>
            </Menu>
        </div>
    )
}
