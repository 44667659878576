import React, { useState } from "react";
import { Grid, Typography, Button, TextField } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import logo from "../../../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../redux/action/index";
import useStyles from "./styles";
import ErrorAlert from "../../../components/alert/errorAlert";
import SuccessAlert from "../../../components/alert/successAlert";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { SET_LOADING } from "../redux/constant";

function Login() {
  var classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  let [validateError, setValidateError] = useState("");
  const { errorMessage, successMessage } = useSelector(
    (state) => state.employeesReducer,
  );
  const history = useHistory();



  const signInHandler = () => {
    if (!email) {
      setValidateError("Email Can't be Empty");
    } else if (!password) {
      setValidateError("Password Can't be Empty");
    } else {
      const datasoft = {
        email,
        password,
      };

      dispatch(signIn(datasoft, setLoading, history));
    }
  };
  return (
    <Grid container className={classes.container}>
      {validateError ? <ErrorAlert message={validateError} /> : null}
      {errorMessage ? <ErrorAlert message={errorMessage} /> : null}
      {successMessage ? <SuccessAlert message={successMessage} /> : null}

      <div className={classes.logotypeContainer}>
        <img className={classes.logo} src={logo} alt="logo" />
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <Typography variant="h1" className={classes.greeting}>
              Admin Login
            </Typography>

            <TextField
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              placeholder="Email Address"
              type="email"
              fullWidth
            />
            <TextField
              id="password"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              placeholder="Password"
              type="password"
              fullWidth
            />
            <div className={classes.formButtons}>
              {loading ? (
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  disabled
                >
                  Authenticating...
                </Button>
              ) : (
                <Button
                  onClick={signInHandler}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Login
                </Button>
              )}
            </div>
          </React.Fragment>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
