import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllEmails } from "./redux/actions/meetingEmailAction";
import CircularLoader from "../utils/circularProgress/index";
import TableComponent from "./EmailTable";

export default function Email() {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  useEffect(() => {
    dispatch(getAllEmails(setloading));
  }, []);
  return <>{loading ? <CircularLoader /> : <TableComponent />}</>;
}
