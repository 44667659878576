import {
  SUCCESS_ALERT,
  ERROR_ALERT,
  GET_ALL_PAYROLL,
  UPDATE_PAYROLL,
  RUN_PAYROLL,
} from "../constant/index";
import { db } from "../../../../config/firebase";
import axiosTechloset from "../../../../config/axiosTechloset";

import { getSalary } from "../../utils";
import moment from "moment";
import { UPDATE_PAYSTUB } from "../../../payStub/redux/constant";

export const getAllPayroll = (setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    let payrollQuery = await db
      .collection("Payroll")
      .orderBy("basicPay", "desc")
      .get();
    let id = [];
    let payroll = payrollQuery.docs.map((doc) => {
      let data = doc.data();
      id.push(doc.id);
      return {
        ...data,
        id: doc.id,
      };
    });

    dispatch({ type: GET_ALL_PAYROLL, payload: payroll });
    setLoading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setLoading(false);
  }
};

export const updatePayroll = (updated, setBonusLoading) => async (dispatch) => {
  try {
    const { id, ...rest } = updated;
    await db.collection("Payroll").doc(updated.id).update(rest);
    dispatch({
      type: UPDATE_PAYROLL,
      payload: updated,
    });
    setBonusLoading(false);
  } catch (error) {
    alert(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setBonusLoading(false);
  }
};

export const updateExpAction =
  (updated, setTravelExpLoading) => async (dispatch) => {
    try {
      setTravelExpLoading(true);
      const { id, ...rest } = updated;
      await db.collection("Payroll").doc(updated.id).update(rest);
      dispatch({ type: UPDATE_PAYROLL, payload: updated });
      dispatch({
        type: SUCCESS_ALERT,
        payload: "Travel Expenditure updated successfully!",
      });
      setTravelExpLoading(false);
    } catch (error) {
      dispatch({ type: ERROR_ALERT, payload: error.message });
      setTravelExpLoading(false);
    }
  };


export const createPayrollAction =
  (setLoading) => async (dispatch, getState) => {
    const { allEmployees } = getState().commonReducer;
    try {
      setLoading(true);
      const date = new Date();
      let start = new Date(date.setHours(0, 0, 0, 0));
      let end = new Date(date.setHours(23, 59, 59, 999));
      const startOfPrevMonth = moment(start)
        ?.subtract(1, "months")
        ?.startOf("month")
        ?.toDate();
      const endOfPrevMonth = moment(end)
        ?.subtract(1, "months")
        ?.endOf("month")
        ?.toDate();
      const currentMonth = moment(moment(date)?.toDate())
        ?.startOf("month")
        ?.toDate();


      let filteredEmployee = allEmployees.filter(
        (el) =>
          moment(el.JobStartedAt?.toDate()).isBefore(currentMonth) &&
          el.status === "approved",
      );

      let ids = [];
      let AttendanceQuery = await db
        .collection("Attendance")
        .where("start", ">=", startOfPrevMonth)
        .where("start", "<=", endOfPrevMonth)
        .get();

      let allAttendance = AttendanceQuery.docs.map((doc) => {
        let data = doc.data();
        ids.push(data.userId);
        return {
          ...data,
          attendanceId: doc.id,
        };
      });

      const batch = db.batch();
      const payroll = [];
      if (filteredEmployee.length > 0) {
        filteredEmployee.map((emp, i) => {
          const docRef = db.collection("Payroll").doc();
          let id = docRef.id;
          let updated = getSalary(
            allAttendance,
            emp,
            endOfPrevMonth,
          );
          payroll.push({ id, ...updated, createdAt: new Date() });
          return batch.set(docRef, updated);
        });
        await batch.commit();
        dispatch({
          type: SUCCESS_ALERT,
          payload: "Payroll created successfully!.",
        });
        payroll.sort((a, b) => {
          return b?.basicPay - a?.basicPay;
        });
        dispatch({ type: GET_ALL_PAYROLL, payload: payroll });
      } else
        dispatch({
          type: SUCCESS_ALERT,
          payload: "No Employee found for last month!",
        });
      setLoading(false);
    } catch (error) {
      dispatch({ type: ERROR_ALERT, payload: error.message });
      setLoading(false);
    }
  };
export const deletePayRoll = (setLoading) => async (dispatch, getState) => {
  const { payroll } = getState().payrollReducer;
  try {
    var r = window.confirm("are you sure to delete payroll?");
    if (!r) {
      return;
    }
    setLoading(true);
    const batch = db.batch();
    payroll.map((payrol) => {
      let docId = payrol.id;
      const docRef = db.collection("Payroll").doc(docId);
      batch.delete(docRef);
      return payrol;
    });
    await batch.commit();
    dispatch({ type: GET_ALL_PAYROLL, payload: [] });
    dispatch({ type: SUCCESS_ALERT, payload: "Payroll deleted successfully!" });
    setLoading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setLoading(false);
  }
};
export const runPaystubAction = (setLoading) => async (dispatch, getState) => {
  const { payroll } = getState().payrollReducer;
  const { allEmployees } = getState().commonReducer;
  try {
    var r = window.confirm("Are you sure to run payroll?");
    if (!r) {
      return;
    }
    setLoading(true);
    const paystubs = [];
    const sendDataArr = [];
    const allUsers = allEmployees.reduce((result, item) => {
      result[item.id] = item;
      return result;
    }, {});
    const batch = db.batch();
    payroll.forEach((payrol) => {
      let docId = payrol.id;
      const updated = { ...payrol, createdAt: new Date() };
      if (parseInt(payrol.netSalary) > 0) {
        let endOfMonth = updated?.endOfMonth;
        let startOfMonth = updated?.startOfMonth;
        if (startOfMonth?.seconds) {
          startOfMonth = updated?.startOfMonth?.toDate();
        }
        if (endOfMonth?.seconds) {
          endOfMonth = updated?.endOfMonth?.toDate();
        }
        const filteredUser = allUsers[updated.userId];

        if (filteredUser) {
          const user = {
            jobTitle: filteredUser?.rankTitle || "",
            accountName: filteredUser?.accountName || "",
            accountBranchCode: filteredUser?.accountBranchCode || "",
            accountNumber: filteredUser?.accountNumber || "",
            jobType: filteredUser?.jobType || "",
            authId: filteredUser?.authId,
          };
          paystubs.push({
            ...updated,
            user: user,
            endOfMonth,
            startOfMonth,
          });
          const { id, ...rest } = payrol;
          sendDataArr.push({ ...rest, user });
        }
      }
      const docRef = db.collection("Payroll").doc(docId);
      batch.delete(docRef);
    });
    const startOfMonth = moment(new Date())?.subtract(1, "months")?.startOf("month")?.toDate();
    const endOfMonth = moment(new Date())?.subtract(1, "months")?.endOf("month")?.toDate();

    const checkExistingPaystubs = sendDataArr.map(async (doc) => {
      const authId = doc.user.authId;
      const userPaystubRef = db.collection("Paystubs").doc(authId).collection("UserPaystubs");
      const existingPaystubs = await userPaystubRef
        .where("startOfMonth", "==", startOfMonth)
        .where("endOfMonth", "==", endOfMonth)
        .get();
      if (!existingPaystubs.empty) {
        throw new Error(`Paystub for this month already exists.`);
      }
    });

    await Promise.all(checkExistingPaystubs);
    const createPaystubs = sendDataArr.map(async (doc) => {
      const authId = doc.user.authId;
      const userPaystubRef = db.collection("Paystubs").doc(authId).collection("UserPaystubs").doc();
      await userPaystubRef.set({
        ...doc,
        authId,
        createdAt: new Date(),
        startOfMonth,
        endOfMonth,
      });
    });

    await Promise.all(createPaystubs);
    await batch.commit();
    dispatch({
      type: RUN_PAYROLL,
      payload: {
        createdAt: new Date(),
        startOfMonth,
        endOfMonth,
        docs: paystubs,
      },
    });
    dispatch({ type: GET_ALL_PAYROLL, payload: [] });
    dispatch({ type: SUCCESS_ALERT, payload: "Paystub created successfully!" });
    setLoading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setLoading(false);
  }
};
export const sendTaxEmail = (taxData, setLoading, startMonth, endMonth) => async (dispatch) => {
  try {
    setLoading(true);
    const startMonthFormatted = moment(startMonth).format("MMMM YYYY");
    const endMonthFormatted = moment(endMonth).format("MMMM YYYY");
    await axiosTechloset({
      method: "POST",
      url: "/emails/taxFile",
      data: { 
        taxData, 
        startMonth: startMonthFormatted, 
        endMonth: endMonthFormatted 
      },
    });
    dispatch({
      type: SUCCESS_ALERT,
      payload: "Tax Email send successfully",
    });
  } catch (error) {
    dispatch({
      type: ERROR_ALERT,
      payload: error.message,
    });
  } 
  finally {
    setLoading(false);
  }
}
export const sendPaystub =
  (data, index, setLoading, payStub, setIndex) => async (dispatch) => {
    try {
      setLoading(true);
      setIndex(index);
      let userData = {
        ...data,
        startOfMonth: data?.startOfMonth?.toDate(),
        endOfMonth: data?.endOfMonth?.toDate(),
        profilephoto: "",
      };
      const payperiod = `${moment(data?.startOfMonth?.toDate()).format(
        "LL",
      )} to ${moment(data?.endOfMonth?.toDate()).format("LL")}`;
      await axiosTechloset({
        method: "POST",
        url: "/emails/payslip",
        data: {
          ...userData,
          payperiod,
        },
      });

      const paystubDocRef = db.collection("Paystubs").doc(data.authId).collection("UserPaystubs").doc(data.id);
      await paystubDocRef.update({
        slipSended: true, paymentSend: true
      });

      dispatch({
        type: UPDATE_PAYSTUB,
        payload: {docId:payStub?.id},
      });

      dispatch({
        type: SUCCESS_ALERT,
        payload: "Paystub sent successfully",
      });
    } catch (error) {
      dispatch({
        type: ERROR_ALERT,
        payload: error.message,
      });
    } finally {
      setLoading(false);
      setIndex(null);
    }
  };
export const sendPayment =
  (docId, authId, setLoading) => async (dispatch) => {
    try {
      if (!docId || !authId) {
        return "DocId or AuthId is missing"
      }
      setLoading(true);
      const userPaystubRef = db
        .collection("Paystubs")
        .doc(authId)
        .collection("UserPaystubs")
        .doc(docId);
      await userPaystubRef.update({
        paymentSend: true,
      });

      dispatch({
        type: UPDATE_PAYSTUB,
        payload: { docId },
      });

      dispatch({
        type: SUCCESS_ALERT,
        payload: "Payment sent successfully",
      });
    } catch (error) {
      console.error("Error sending payment: ", error);
      dispatch({
        type: ERROR_ALERT,
        payload: error.message,
      });
    } finally {
      setLoading(false);
    }
  };
