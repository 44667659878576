import {
  SUCCESS_ALERT,
  ERROR_ALERT,
  GET_ALL_MEETING,
  ADD_MEETING,
  DELETE_MEETING,
} from "../constant/index";
import { db } from "../../../../config/firebase";
import axiosTechloset from "../../../../config/axiosTechloset";
import moment from "moment";

export const getAllMeetings = (setLoading, index) => async (dispatch) => {
  try {
    setLoading(true);
    let res;
    const date = moment(new Date()).format("DD/MM/YYYY hh:mm:a");
    if (index === 0) {
      res = await db.collection("Meeting").where("endDate", ">=", date).get();
    }
    if (index === 1) {
      res = await db.collection("Meeting").where("endDate", "<", date).get();
    }
    let allMeetings = [];
    res.forEach((doc) => {
      allMeetings.push({ ...doc.data(), id: doc.id });
    });
    dispatch({ type: GET_ALL_MEETING, payload: allMeetings });
    setLoading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setLoading(false);
  }
};
export const addMeeting = (data, setLoading, toggle) => async (dispatch) => {
  try {
    setLoading(true);
    const { calendarId, ...rest } = data;
    const timeZone = "Asia/Karachi";
    const event = {
      summary: rest?.summary,
      description: rest?.description || "",
      start: {
        dateTime: `${moment(rest?.startDate).format(
          "YYYY-MM-DDTHH:mm:ss",
        )}+05:00`,
        timeZone,
      },
      end: {
        dateTime: `${moment(rest?.endDate).format(
          "YYYY-MM-DDTHH:mm:ss",
        )}+05:00`,
        timeZone,
      },
      reminders: {
        useDefault: true,
      },
    };
    const googleEventpayload = {
      event,
      calendarId,
    };

    const response = await axiosTechloset({
      method: "POST",
      url: "/event/create",
      data: { ...googleEventpayload },
    });
    const events = response?.data?.events;
    const startDate = moment(rest?.startDate).format("DD/MM/YYYY hh:mm:a");
    const endDate = moment(rest?.endDate).format("DD/MM/YYYY hh:mm:a");

    const docRef = await db.collection("Meeting").add({
      ...data,
      events,
      startDate,
      endDate,
    });
    const id = docRef?.id;
    await axiosTechloset({
      method: "POST",
      url: "/slack/meeting",
      data: { ...rest, startDate, endDate },
    });
    dispatch({
      type: ADD_MEETING,
      payload: { ...data, events, id, startDate, endDate },
    });
    dispatch({ type: SUCCESS_ALERT, payload: "Meeting Added Successfully." });
    toggle();
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setLoading(false);
  }
};

export const deleteMeeting = (data, setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    const { events, id, ...rest } = data;
    await axiosTechloset({
      method: "DELETE",
      url: "event/delete",
      data: { events },
    });
    await db.collection("Meeting").doc(id).delete();
    await axiosTechloset({
      method: "POST",
      url: "/slack/meeting",
      data: {
        ...rest,
        summary: `Cancelled (${rest?.summary})`,
        startDate: moment(rest?.startDate).format("DD/MM/YYYY hh:mm:a"),
        endDate: moment(rest?.endDate).format("DD/MM/YYYY hh:mm:a"),
      },
    });
    dispatch({ type: DELETE_MEETING, payload: id });
    dispatch({
      type: SUCCESS_ALERT,
      payload: "Meeting Deleted  Successfully.",
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setLoading(false);
  }
};
