import React, { useState, useEffect } from "react";
import { Paper, Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import JobList from "./components/jobList.js";
import { useDispatch, useSelector } from "react-redux";
import { getAllJobs } from "./redux/actions/jobsActions.js";
import CircularLoader from "../../module/utils/circularProgress/index";
import { useHistory } from "react-router-dom";
import { useStyles } from "./style";
import "./style.css";

const Jobs = () => {
  const allJobs = useSelector((state) => state.jobsReducer.allJobs);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const handleClickOpen = () => {
    history.replace({
      pathname: "/job/add",
    });
  };

  useEffect(() => {
    // if (allJobs.length < 1) {
    dispatch(getAllJobs(setloading));
    // } else setloading(false);
  }, []);
  return (
    <div>
      <Button
        className={classes.addButton}
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        Add Job
      </Button>

      {loading ? <CircularLoader /> : null}

      <TableContainer component={Paper}>
        <Table className={classes.paper} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Job Title</TableCell>
              <TableCell align="left">Job Type</TableCell>
              <TableCell align="left">Job Category</TableCell>
              <TableCell align="left">Job Location</TableCell>
              <TableCell align="left">Edit</TableCell>
              {/* <TableCell align="left">Delete</TableCell> */}
            </TableRow>
          </TableHead>
          {allJobs.map((job, index) => (
            <JobList key={index} job={job} />
          ))}
        </Table>
      </TableContainer>
    </div>
  );
};
export default Jobs;
