import React, { useEffect } from 'react'
import PageTitle from '../../../../components/PageTitle/PageTitle'
import { useParams } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { addGeneral, getQuestionsByType, questionsIndexChange, updateInterviewQuestion } from '../../redux/action';
import AddQuestion from './AddQuestion';
import { useStyles } from '../../style';
import { Button } from '@material-ui/core';
import UpdateQuestion from './UpdateQuestion';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

export default function Questions() {
    const { interviewFor } = useParams();
    const dispatch = useDispatch()
    const classes = useStyles()
    const { interviewQuestions } = useSelector((state) => state.InterviewQuestionsReducer)

    useEffect(() => {
        dispatch(getQuestionsByType(interviewFor))
    }, [])

    const deleteHandler = (id) => {
        let filtered = interviewQuestions?.questions?.filter((doc) => doc.id !== id)
        let newData = {
            ...interviewQuestions,
            questions: filtered
        }
        dispatch(updateInterviewQuestion(newData))
    }
    const RenderQuestions = React.memo(function RenderQuestions() {
        return interviewQuestions?.questions?.map((doc, index) => (
            <Draggable
                draggableId={doc?.id?.toString()} index={index} >
                {provided => (
                    <div
                        key={doc.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={classes.questionMainDiv}
                    >
                        <div className={classes.questionDiv}>
                            <h3 style={{ whiteSpace: 'break-spaces' }}>{doc.question}</h3>
                        </div>
                        <div>
                            <UpdateQuestion data={doc} />
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={() => { deleteHandler(doc.id) }}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                )}
            </Draggable>
        ));
    });

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const questions = reorder(
            interviewQuestions?.questions,
            result.source.index,
            result.destination.index
        );

        dispatch(questionsIndexChange(interviewQuestions?.docId, questions))

    }

    return (
        <>
            <PageTitle title={`${interviewFor} Interview Questions`} />
            <AddQuestion data={interviewQuestions} />
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                    {provided => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}>
                            <RenderQuestions />
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    )
}
