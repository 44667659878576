import React, { useState } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import { deleteAdmin } from "./redux/actions/adminAction";
import { CircularProgress } from "@material-ui/core";
import useStyles from "./AdminTableStyle";
import { Button } from "@material-ui/core/";
import { ConfirmModal } from "../meetings/components/modals/Modals";
import AdminModal from "./components/modal/AdminModals";
const AdminTable = () => {
  const { allAdmins } = useSelector((state) => state.adminReducer);

  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [index, setIndex] = useState(null);
  const [modal, setModal] = useState(false);
  const [details, setDetails] = useState({});

  const toggleModal = (data) => {
    setModal(!modal);
    if (data) {
      setDetails(data);
    } else {
      setDetails({});
    }
  };

  const toggleDeleteModal = (i) => {
    setDeleteModal(!deleteModal);
    setIndex(i);
  };

  const handleRemove = () => {
    setDeleteModal(false);
    dispatch(deleteAdmin(allAdmins?.[index], setLoading));
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let name = allAdmins[dataIndex]?.name;
          return <span>{name}</span>;
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let email = allAdmins[dataIndex]?.email;
          return <span>{email}</span>;
        },
      },
    },

    {
      name: "Actions",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const data = allAdmins[dataIndex];
          return (
            <>
              {loading && index === dataIndex ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="Primary"
                    onClick={() => toggleModal(data)}
                    className={classes.btnMargin}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => toggleDeleteModal(dataIndex)}
                    className={classes.btnMargin}
                  >
                    Delete
                  </Button>
                </>
              )}
            </>
          );
        },
      },
    },
  ];

  const options = {
    searchPlaceholder: "Search here...",
    filter: false,
    searchAlwaysOpen: true,
    responsive: "standard",
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    selectableRows: false,
  };

  const theme = createTheme({
    overrides: {
      MUIDataTableHeadCell: {
        sortAction: {
          fontSize: "1rem",
          fontWeight: "bolder",
        },
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          // Soft hide the button.
          visibility: "hidden",
        },
      },
    },
  });

  return (
    <>
      <Button
        className={"mb-2"}
        variant="contained"
        color="primary"
        onClick={() => toggleModal()}
      >
        Add Admin
      </Button>
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          className="mainTable"
          data={allAdmins}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
      <ConfirmModal
        message="Are you sure for delete this Admin ?"
        modal={deleteModal}
        toggle={toggleDeleteModal}
        action={handleRemove}
      />
      <AdminModal
        modal={modal}
        toggle={toggleModal}
        data={details}
        allAdmins={allAdmins}
      />
    </>
  );
};

export default AdminTable;
