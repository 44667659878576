import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { CircularProgress, Menu, MenuItem, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from '../style';
import { addInterviewType } from '../redux/action';
import ErrorAlert from '../../../components/alert/errorAlert';
import { ERROR_ALERT } from '../../../commonRedux/common/constants';

export default function AddInterviewType() {
    const [addDialog, setAddDialog] = useState(null);
    const [loading, setLoading] = useState(false);
    const [additemValue, setAdditemValue] = useState("");
    const dispatch = useDispatch()
    const classes = useStyles();
    const allJobs = useSelector((state) => state.jobsReducer.allJobs);
    const [select, setSelect] = useState(null)
    const { interviewAllData } = useSelector((state) => state.InterviewQuestionsReducer)

    const addHandler = () => {
        let check = interviewAllData.find(doc => doc.interviewFor === additemValue)
        if (additemValue && !check) {
            dispatch(addInterviewType(additemValue, setLoading))
            dialogClose()
        } else {
            dispatch({
                type: ERROR_ALERT,
                payload: "Interview type already exist"
            })
        }
    }

    const selectValue = (val) => {
        setAdditemValue(val)
        setSelect(null)
    }

    const dialogClose = () => {
        setAddDialog(null)
        setAdditemValue('')
    }

    return (
        <div className={classes.item}>
            {
                loading ? <CircularProgress />
                    :
                    <Button
                        onClick={(e) => { setAddDialog(e.currentTarget) }}
                        variant='contained'
                        color='primary'
                    >
                        Add Interview type
                    </Button>
            }
            <div>
                <Menu
                    id="simple-menu"
                    anchorEl={addDialog}
                    keepMounted
                    open={Boolean(addDialog)}
                    onClose={dialogClose}
                >
                    <div>
                        <MenuItem className={classes.selectField} onClick={(e) => { setSelect(e.currentTarget) }}>
                            {additemValue ? additemValue : 'Choose interview type'}
                        </MenuItem>
                        <Menu
                            id="simple-menu"
                            anchorEl={select}
                            keepMounted
                            open={Boolean(select)}
                            onClose={() => { setSelect(null) }}
                        >
                            {
                                allJobs?.map((job, index) => {
                                    return <MenuItem key={index} onClick={() => { selectValue(job?.jobTitle) }}>
                                        {job?.jobTitle}
                                    </MenuItem>
                                })
                            }
                        </Menu>
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.addButton}
                            onClick={() => { addHandler() }}
                        >
                            Add
                        </Button>
                    </div>
                </Menu>
            </div>
        </div>
    )
}
