export const GET_ALL_LEAVES = "GET_ALL_LEAVES";
export const GET_BOOTCAMP_LEAVES = "GET_BOOTCAMP_LEAVES";
export const GET_ALL_ONBOARDING = "GET_ALL_ONBOARDING";
export const CURRENT_EMPLOYEE = "CURRENT_EMPLOYEE";
export const CURRENT_ONBOARDING = "CURRENT_ONBOARDING";
export const SUCCESS_ALERT = "SUCCESS_ALERT";
export const ERROR_ALERT = "ERROR_ALERT";
export const APPROVE_LEAVE = "APPROVE_LEAVE";
export const DECLINE_LEAVE = "DECLINE_LEAVE";
export const UPDATE_LEAVES = "UPDATE_LEAVES";
export const UPDATE_BOOTCAMP_LEAVES = "UPDATE_BOOTCAMP_LEAVES";