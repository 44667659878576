import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { Grid, Chip, MuiThemeProvider } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PageTitle from "../../components/PageTitle/PageTitle";
import useStyles from "./style";
import CircularLoader from "../utils/circularProgress";
import { GitHub } from "@material-ui/icons";
import { mdiSkypeBusiness } from "@mdi/js";
import Icon from "@mdi/react";
import AppBars from "./AppBars";
import Table from "./Table";
import { GetMuiTheme } from "../utils/TableHeadStyle/style";
import { useDispatch, useSelector } from "react-redux";
import { successMessage } from "./redux/actions/employeeAction";
import ImageDilog from "../../components/imageDilog/ImageDilog";

const TeamListTableController = ({data, handleMore, location, history, exportFile, loading, appBarTitle, blockTitle, title, blocks}) => {
    
  const [copyValue, setCopyValue] = useState("");
  const [copy, setCopy] = useState(false);
  const [show, setShow] = useState(true);
  const [block, setBlock] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  
  

   const showItem = () => {
    setShow(true);
    setBlock(false);
  };

  const hideItem = () => {
    setShow(false);
  };

  const blockPerson = () => {
    setBlock(true);
  };


  const handleCopy = (value, fieldName) => {
    setCopyValue(value);
    setCopy(true);
    dispatch(successMessage(`${value} copied.`));
  };

  const states = {
    approved: "approved",
    blocked: "blocked",
    onboarding: "onboarding",
    declined: "declined",
  };

  const options = {
    searchPlaceholder: "Search here...",
    searchAlwaysOpen: true,
    filter: false,
    responsive: "standard",
    selectableRows: false,
  };

  const columns = [
    {
      name: "",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let ID = data[dataIndex]?.id
          return (
            <CopyToClipboard text={ID} onCopy={() => handleCopy(ID, "id")}>
              <span>#</span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "profilePhoto",
      label: "Photo",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <ImageDilog
              src={data[dataIndex]?.profilephoto}
            />
          );
        },
      },
    },
    {
      name: "fullName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let fullName = data[dataIndex].fullName?.toUpperCase()
          return <span>{fullName}</span>;
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let email =  data[dataIndex]?.email?.toLowerCase()
          return (
            <CopyToClipboard
              text={email}
              onCopy={() => handleCopy(email, "email")}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                {email}
              </span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "phoneNumber",
      label: "Mobile No.",
      indexColumn: "id",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let phoneNumber = data[dataIndex]?.phoneNumber;
          return (
            <CopyToClipboard
              text={phoneNumber}
              onCopy={() => handleCopy(phoneNumber, "mobileNumber")}
            >
              <span>{phoneNumber}</span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "github",
      label: "Github",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let github = data[dataIndex]?.github?.split("/").pop()
          return (
            <CopyToClipboard
              text={github}
              onCopy={() => handleCopy(github, "github username")}
            >
              <span className="gitIcon">
                <GitHub />
              </span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "skype",
      label: "Skype",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let skype = data[dataIndex]?.skype
          return (
            <CopyToClipboard
              text={skype}
              onCopy={() => handleCopy(skype, "skype link")}
            >
              <span className="skypeIcon">
                <Icon path={mdiSkypeBusiness} size={1} horizontal vertical />
              </span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          let status = data[dataIndex]?.status;
          return (
            <Chip
              label={status}
              classes={{
                root: classes[states[status]],
              }}
            />
          );
        },
      },
    },
    {
      name: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <Button
              className={classes.moreButton}
              variant="outlined"
              color="primary"
              onClick={() => handleMore(dataIndex)}
            >
              More
            </Button>
          );
        },
      },
    },
  ];


  return (
    <>
      <AppBars
        blockPerson={blockPerson}
        hideItem={hideItem}
        showItem={showItem}
        title={appBarTitle}
      />

      {block ? (
        <PageTitle
          title={blockTitle}
        />
      ) : (
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <PageTitle title={title} />
           {
            exportFile && (
              <Button
                variant="contained"
                color="primary"
                onClick={exportFile}
              >
                Export Travel Expenditure
              </Button>
            )
           }
        </div>
      )}
      {show ? (
        <div>
          {loading ? (
            <CircularLoader />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MuiThemeProvider theme={GetMuiTheme}>
                  <MUIDataTable
                    className="mainTable"
                    data={data}
                    columns={columns}
                    options={options}
                  />
                </MuiThemeProvider>
              </Grid>
            </Grid>
          )}
        </div>
      ) : null}
      {loading ? (
        <CircularLoader />
      ) : block ? (
        <div>
          <Table blocks={blocks} location={location} history={history} />
        </div>
      ) : null}
    </>
  );
};

export default TeamListTableController;
