import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAll, getBlockEmployee } from "./redux/actions/employeeAction";
import { exportToExcel } from "../../utils/exportExcel";
import TeamListTableController from "./TeamListTableController";

export default function Employees({ location, history }) {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const { allEmployees, blockEmployee } = useSelector((state) => state.employeesReducer); 

  useEffect(() => {
    if (allEmployees.length < 1) {
      dispatch(getAll(setloading));
    } else setloading(false);
  }, []);

  useEffect(() => {
    if(blockEmployee.length < 1){
      dispatch(getBlockEmployee(setloading));
    } else setloading(false);
  }, []);

  const travelData = [
    ...allEmployees
      ?.filter((item) => item?.travelExpenditure > 0)
      ?.map((item) => ({
        CardNumber: item?.travelCardNumber,
        TopUpAmount: item?.travelExpenditure,
      })),
  ];

  const exportTravelExpenditure = () => {
    exportToExcel(travelData, "Travel Expenditure.xlsx");
  };

  const handleMore = (i) => {
    const id = allEmployees[i]?.id;
    history.push({
      pathname: `/profile/employee/${id}`,
      id: id,
    });
  };
 
  return (
    <TeamListTableController
      data={allEmployees}
      handleMore={handleMore}
      exportFile={exportTravelExpenditure}
      loading={loading}
      location={location}
      history={history}
      appBarTitle= "Blocked"
      blockTitle= "Blocked Members"
      title = "Techloset Team"
      blocks={blockEmployee}
    />
  );
}
