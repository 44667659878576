// interview type constants
export const ADD_INTERVIEW_TYPE = "ADD_INTERVIEW_TYPE";
export const GET_INTERVIEW_TYPE = "GET_INTERVIEW_TYPE";
export const DELETE_INTERVIEW_TYPE = "DELETE_INTERVIEW_TYPE";
export const UPDATE_INTERVIEW_TYPE = "UPDATE_INTERVIEW_TYPE";

// interview Questions constants
export const ADD_INTERVIEW_QUESTION = "ADD_INTERVIEW_QUESTION";
export const GET_INTERVIEW_QUESTION = "GET_INTERVIEW_QUESTION";
export const EMPTY_INTERVIEW_QUESTION = "EMPTY_INTERVIEW_QUESTION";
export const UPDATE_INTERVIEW_QUESTION = "UPDATE_INTERVIEW_QUESTION";
export const INDEX_CHANGE = "INDEX_CHANGE";