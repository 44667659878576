import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Grid, Box, Button, Link, AppBar, Toolbar } from "@material-ui/core";
import { useStyles } from "./style";
import { style } from "./style.js";
import moment from "moment";
import jobApplicationImage from "../../assets/JobApplication.png";
import AppliedForTable from "./components/AppliedForTable";
import StatusTable from "./components/StatusTable";
import { getSearchedJobs } from "../jobsRequests/redux/actions/jobsRequestsAction";

const JobProfileInfo = () => {
  const {
    searchedJobs,
    appliedJobs,
    declinedJobs,
    futureJobs,
    interviewScheduleJobs,
    interviewRescheduleJobs,
    interviewCancelJobs,
    interviewedJobs,
    hiredJobs,
  } = useSelector((state) => state.jobsRequestsReducer);
  const dispatch = useDispatch();
  const { id } = useParams();
  let Jobs = [
    ...searchedJobs,
    ...appliedJobs,
    ...declinedJobs,
    ...futureJobs,
    ...interviewScheduleJobs,
    ...interviewRescheduleJobs,
    ...interviewCancelJobs,
    ...interviewedJobs,
    ...hiredJobs,
  ];
  const [activeStyle, setActiveStyle] = useState(1);
  const classes = useStyles();
  const history = useHistory();
  const [jobProfile, setJobProfile] = useState(
    Jobs.filter((job) => job.docId === id)[0],
  );
  const [activeTab, setActiveTab] = useState("account");
  const showAccountDetails = () => {
    setActiveStyle(1);
    setActiveTab("account");
  };
  const statusHistory = () => {
    setActiveStyle(2);
    setActiveTab("statusHistory");
  };
  const appliedHistory = () => {
    setActiveStyle(3);
    setActiveTab("appliedHistory");
  };

  useEffect(() => {
    if (Jobs?.length) {
      setJobProfile(Jobs.filter((job) => job.docId === id)[0]);
    }
  }, [Jobs]);

  useEffect(() => {
    dispatch(getSearchedJobs());
  }, [id]);

  return (
    <div>
      <Grid container spacing={3}>
        {jobProfile?.jobAppliedHistory || jobProfile?.jobStatusHistory ? (
          <AppBar position="static">
            <Toolbar variant="dense">
              <Typography
                className={activeStyle === 1 ? classes.active : classes.Item}
                onClick={showAccountDetails}
                style={style.appBarTitle}
              >
                Account
              </Typography>
              <Typography
                className={activeStyle === 2 ? classes.active : classes.Item}
                onClick={statusHistory}
                style={style.appBarTitle}
              >
                Status History
              </Typography>
              <Typography
                className={activeStyle === 3 ? classes.active : classes.Item}
                onClick={appliedHistory}
                style={style.appBarTitle}
              >
                Applied History
              </Typography>
            </Toolbar>
          </AppBar>
        ) : null}
        {jobProfile ? null : (
          <center>
            <img
              src={jobApplicationImage}
              alt="Job Application Image"
              className={classes.noDetailImage}
            />
            <Typography component="h1" className={classes.noDetailText}>
              No Details Found!
            </Typography>
          </center>
        )}
        {activeTab === "statusHistory" && jobProfile?.jobStatusHistory ? (
          <Grid item xs={12} md={12}>
            <Paper>
              {<StatusTable jobStatusHistory={jobProfile?.jobStatusHistory} />}
            </Paper>
          </Grid>
        ) : null}
        {activeTab === "appliedHistory" && jobProfile?.jobAppliedHistory ? (
          <Grid item xs={12} md={12}>
            <Paper style={style.paper}>
              {
                <AppliedForTable
                  jobAppliedHistory={jobProfile?.jobAppliedHistory}
                />
              }
            </Paper>
          </Grid>
        ) : null}
        {activeTab === "account" ? (
          <>
            {jobProfile?.fname || jobProfile?.CV ? (
              <Grid item xs={12}>
                <Paper style={style.paper}>
                  <Typography style={style.text} variant="h3">
                    Application Details
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    spacing={3}
                  >
                    <Grid item md={2} xs={6}>
                      <Typography style={style.title} component="div">
                        Name
                      </Typography>
                      <Typography style={style.title} component="div">
                        Email
                      </Typography>
                      <Typography style={style.title} component="div">
                        Mobile No.
                      </Typography>
                      <Typography style={style.title} component="div">
                        Applied For
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <Typography component="div" style={style.detail}>
                        {jobProfile?.fname || "NA"}
                      </Typography>
                      <Typography component="div" style={style.detail}>
                        {jobProfile?.email || "NA"}
                      </Typography>
                      <Typography style={style.detail} component="div">
                        {jobProfile?.phone || "NA"}
                      </Typography>
                      <Typography style={style.detail} component="div">
                        {jobProfile?.appliedFor || "NA"}
                      </Typography>
                    </Grid>

                    <Grid item md={2} xs={6}>
                      <Typography style={style.title} component="div">
                        Skype ID
                      </Typography>
                      <Typography style={style.title} component="div">
                        Github
                      </Typography>
                      <Typography style={style.title} component="div">
                        Source
                      </Typography>
                      <Typography style={style.title} component="div">
                        CV
                      </Typography>
                    </Grid>
                    <Grid item md={2} xs={6}>
                      <Typography component="div" style={style.detail}>
                        {jobProfile?.skype || "NA"}
                      </Typography>
                      <Typography component="div" style={style.detail}>
                        {jobProfile?.gitHub || "NA"}
                      </Typography>
                      <Typography component="div" style={style.detail}>
                        {jobProfile?.source || "NA"}
                      </Typography>
                      <Typography component="div" style={style.detail}>
                        <Link target={"_blank"} href={jobProfile?.CV}>
                          Click Here
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item md={1} xs={6}></Grid>
                  </Grid>
                </Paper>
              </Grid>
            ) : (
              ""
            )}
            {jobProfile?.education || jobProfile?.country ? (
              <Grid item xs={12}>
                <Paper style={style.paper}>
                  <Typography style={style.text} variant="h3">
                    Personal Information
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    spacing={3}
                  >
                    <Grid item md={2} xs={6}>
                      <Typography style={style.title} component="div">
                        Education
                      </Typography>
                      <Typography style={style.title} component="div">
                        City
                      </Typography>
                      <Typography style={style.title} component="div">
                        Country
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <Typography component="div" style={style.detail}>
                        {jobProfile?.education || "NA"}
                      </Typography>
                      <Typography component="div" style={style.detail}>
                        {jobProfile?.city || "NA"}
                      </Typography>
                      <Typography style={style.detail} component="div">
                        {jobProfile?.country || "NA"}
                      </Typography>
                    </Grid>

                    <Grid item md={2} xs={6}>
                      <Typography style={style.title} component="div">
                        Field
                      </Typography>
                      <Typography style={style.title} component="div">
                        Address One
                      </Typography>
                      <Typography style={style.title} component="div">
                        Address Two
                      </Typography>
                    </Grid>
                    <Grid item md={2} xs={6}>
                      <Typography component="div" style={style.detail}>
                        {jobProfile?.field || "NA"}
                      </Typography>
                      <Typography component="div" style={style.detail}>
                        {jobProfile?.addressOne || "NA"}
                      </Typography>
                      <Typography component="div" style={style.detail}>
                        {jobProfile?.addressTwo || "NA"}
                      </Typography>
                    </Grid>
                    <Grid item md={1} xs={6}></Grid>
                  </Grid>
                </Paper>
              </Grid>
            ) : null}
            {jobProfile?.status === "interviewSchedule" ||
            jobProfile?.status === "interviewReschedule" ? (
              <>
                <Grid item xs={12}>
                  <Paper style={style.paper}>
                    <Typography style={style.text} variant="h3">
                      {jobProfile?.status === "interviewSchedule"
                        ? "Interview Schedule Information"
                        : "Interview Reschedule Information"}
                    </Typography>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="flex-start"
                      spacing={3}
                    >
                      <Grid item md={2} xs={6}>
                        <Typography style={style.title} component="div">
                          Location
                        </Typography>
                        <Typography style={style.title} component="div">
                          Title
                        </Typography>
                        {jobProfile?.interview?.location === "remote" ? (
                          <>
                            <Typography style={style.title} component="div">
                              Meeting Id
                            </Typography>
                            <Typography style={style.title} component="div">
                              Start Url
                            </Typography>
                          </>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item md={3} xs={6}>
                        <Typography component="div" style={style.detail}>
                          {jobProfile?.interview?.location.toUpperCase()}
                        </Typography>
                        <Typography component="div" style={style.detail}>
                          Meeting With {`${jobProfile?.fname}`}
                        </Typography>
                        {jobProfile?.interview?.location === "remote" ? (
                          <>
                            <Typography component="div" style={style.detail}>
                              {jobProfile?.interview?.meetingId}
                            </Typography>
                            <Link href={`${jobProfile?.interview.start_url}`}>
                              Start Meeting
                            </Link>
                          </>
                        ) : (
                          ""
                        )}
                      </Grid>

                      <Grid item md={2} xs={6}>
                        <Typography style={style.title} component="div">
                          Start Time
                        </Typography>
                        {jobProfile?.interview?.location === "remote" ? (
                          <>
                            <Typography style={style.title} component="div">
                              Password
                            </Typography>
                            <Typography style={style.title} component="div">
                              Join Url
                            </Typography>
                          </>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item md={2} xs={6}>
                        <Typography component="div" style={style.detail}>
                          {moment(jobProfile?.interview?.start.toDate()).format(
                            "MMMM Do YYYY, h:mm a",
                          )}
                        </Typography>
                        {jobProfile?.interview?.location === "remote" ? (
                          <>
                            <Typography component="div" style={style.detail}>
                              {jobProfile?.interview?.password}
                            </Typography>
                            <Typography component="div" style={style.detail}>
                              <Link href={`${jobProfile?.interview?.join_url}`}>
                                Join Meeting
                              </Link>
                            </Typography>
                          </>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item md={1} xs={6}></Grid>
                    </Grid>
                  </Paper>
                </Grid>{" "}
              </>
            ) : (
              ""
            )}
            {jobProfile?.coverLetter ? (
              <Grid item xs={12} md={12}>
                <Paper style={style.paper}>
                  <Typography style={style.text} variant="h3">
                    Cover Letter
                  </Typography>

                  <Typography style={style.text} variant="h6">
                    {jobProfile?.coverLetter}
                  </Typography>
                </Paper>
              </Grid>
            ) : null}
          </>
        ) : (
          ""
        )}

        <Grid item md={12} xs={12} sm={12}>
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              variant="contained"
              style={style.BackBtn}
              color="primary"
              onClick={() => {
                // dispatch(clearProfile())
                history.goBack();
              }}
            >
              Back
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default JobProfileInfo;
