import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  approved: {
    backgroundColor: theme.palette.success.main,
    color: "#fff",
  },
  request: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  send: {
    backgroundColor: "#03fcc6",
    color: "#fff",
  },
  input: {
    display: "none",
  },
  circularProgress: {
    marginLeft: 0,
    color: "#fff",
  },

  btnMargin: {
    marginLeft: "5px;",
  },
  cancelIcon: {
    color: 'red',
  },
  checkIcon: {
    color: 'green'
  }
}));
