import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import { getAllJobs } from "../jobs/redux/actions/jobsActions";
import AddInterviewType from "./component/AddInterviewType";
import UpdateInterviewType from "./component/UpdateInterviewType";
import {
  addGeneral,
  deleteInterviewType,
  getInterviewType,
} from "./redux/action";
import { useStyles } from "./style";

export default function InterviewQuestions() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { interviewAllData } = useSelector(
    (state) => state.InterviewQuestionsReducer,
  );

  useEffect(() => {
    dispatch(getInterviewType());
    dispatch(getAllJobs(setLoading));
    dispatch(addGeneral());
  }, []);

  const deleteHandler = (docId) => {
    dispatch(deleteInterviewType(docId));
  };

  return (
    <>
      <PageTitle title="Interview Types" />
      {loading ? <CircularProgress /> : <AddInterviewType />}

      <div className={classes.alignCard}>
        <div className={classes.padding}>
          <Card className={classes.root}>
            <Link to={{ pathname: `/interviewquestions/General` }}>
              <CardActionArea>
                <CardContent className={classes.media}>
                  <Typography gutterBottom variant="h5" component="h1">
                    General Questions
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Link>
            <CardActions></CardActions>
          </Card>
        </div>
        {interviewAllData?.map((doc, index) => {
          return (
            <>
              {doc.interviewFor !== "General" ? (
                <div key={doc.interviewFor + index} className={classes.padding}>
                  <Card className={classes.root}>
                    <Link
                      to={{
                        pathname: `/interviewquestions/${doc.interviewFor}`,
                      }}
                    >
                      <CardActionArea>
                        <CardContent className={classes.media}>
                          <Typography gutterBottom variant="h5" component="h1">
                            {doc.interviewFor}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Link>
                    <CardActions>
                      <UpdateInterviewType data={doc} />
                      <Button
                        onClick={() => {
                          deleteHandler(doc.docId);
                        }}
                        size="small"
                        color="secondary"
                      >
                        Delete
                      </Button>
                    </CardActions>
                  </Card>
                </div>
              ) : (
                ""
              )}
            </>
          );
        })}
      </div>
    </>
  );
}
