import Dashboard from "./pages/dashboard/Dashboard";
import { Info } from "./module/profile/info";
import JobProfileInfo from "./module/JobProfile/JobProfileInfo";
import UpdateProfile from "./module/update/update";
import Employees from "./module/employees/Employees";
import Jobs from "./module/jobs/jobs";
import Events from "./module/events/events";
import Attendance from "./module/attendance/attendance";
import ContactRequest from "./module/contactRequests/contactRequest";
import Payroll from "./module/payroll/Payroll";
import PayStub from "./module/payStub/payStub";
import JobRequests from "./module/jobsRequests/jobsRequest";
import JobsBoard from "./module/jobsBoard/jobsBoard";
import ExperienceLetter from "./module/lettersRequest/components/experienceLetter/ExperienceLetter";
import UpdateJobComponent from "./module/jobs/components/editJob/editJob";
import LeaveRequest from "./module/leaveRequest/leaveRequest";
import LettersRequest from "./module/lettersRequest/lettersRequest";
import Letters from "./module/letters/Letters";
import Interviews from "./module/interviews/Interviews";
import Ranks from "./module/ranks/Ranks";
import PositionRanks from "./module/ranks/component/PositionRanks";
import InterviewQuestions from "./module/interviewQuestions/InterviewQuestions";
import Questions from "./module/interviewQuestions/component/questions/Questions";
import TeamRank from "./module/teamRank/TeamRank";
import MeetingEmail from "./module/meetings/Email";
import Meetings from "./module/meetings/Meeting";
import Banks from "./module/banks/Banks";
import Contract from "./module/contract/Contract";
import ContractTypes from "./module/contract/ContractType";
import Admins from "./module/admins/Admins";
import LeavePolicy from "./module/leavePolicy/LeavePolicy";
// import Gallery from "../pages/gallery/Gallery";
import { ROUTE_NAME } from "./constants/constants";
import AttendanceReport from "./module/reports/components/AttendanceReport";
import NotFound from "./components/NotFound";
import BootcampAttendance from "./module/attendance/bootcampAttendance";
import BootcampLeaveRequest from "./module/leaveRequest/bootcampLeaveRequest";
import Onboarding from "./module/employees/Onboarding";
import BootcampOnboarding from "./module/employees/BootcampOnboarding";
import TeamMembers from "./module/employees/TeamMembers";
import BootcampTeam from "./module/employees/BootcampTeam";

export const routes = [
  { path: "/", exact: true, name: "Home" },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    protection: ROUTE_NAME?.DASHBOARD,
    module: "dashboard",
  },
  {
    path: "/teamRank",
    name: "Team Rank",
    component: TeamRank,
    protection: ROUTE_NAME?.TEAM_RANKS,
    module: "Team Rank",
  },
  {
    path: "/ranks/:type?",
    name: "Position ranks",
    component: PositionRanks,
    protection: ROUTE_NAME?.RANKS,
    module: "Ranks",
  },
  {
    path: "/positions/:type?",
    name: "Ranks",
    component: Ranks,
    protection: ROUTE_NAME?.RANKS,
    module: "ranks",
  },
  {
    path: "/interviewquestions/:interviewFor?",
    name: "Questions",
    component: Questions,
    protection: ROUTE_NAME?.INTERVIEW_QUESTIONS,
    module: "questions",
  },
  {
    path: "/interviewtype/:interviewFor?",
    name: "Interview Type",
    component: InterviewQuestions,
    protection: ROUTE_NAME?.INTERVIEW_QUESTIONS,
    module: "interviewType",
  },
  {
    path: "/onboarding",
    name: "OnBoarding",
    component: Onboarding,
    protection: ROUTE_NAME?.ONBOARDING,
    module: "onboarding",
  },
  {
    path: "/bootcampOnboarding",
    name: "Bootcamp OnBoarding",
    component: BootcampOnboarding,
    protection: ROUTE_NAME?.BOOTCAMP_ONBOARDING,
    module: "bootcampOnboarding",
  },
  {
    path: "/employees",
    name: "Team",
    component: Employees,
    protection: ROUTE_NAME?.TEAM,
    module: "team",
  },
  {
    path: "/teamMembers",
    name: "Team Information",
    component: TeamMembers,
    protection: ROUTE_NAME?.TEAM_INFO,
    module: "teamInfo",
  },
  {
    path: "/bootcampTeam",
    name: "Bootcamp Team",
    component: BootcampTeam,
    protection: ROUTE_NAME?.BOOTCAMP_TEAM,
    module: "bootcampTeam",
  },
  {
    path: "/profile/:type/:id",
    name: "Profile",
    component: Info,
    protection: ROUTE_NAME?.USER_PROFILE,
    module: "userProfile",
  },
  {
    path: "/teamProfile/:id",
    name: "Profile",
    component: Info,
    protection: ROUTE_NAME?.TEAM_INFO,
    module: "userProfile",
  },
  {
    path: "/update/:type/:id?",
    name: "Profile",
    component: UpdateProfile,
    protection: ROUTE_NAME?.USER_PROFILE,
    module: "userProfile",
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs,
    protection: ROUTE_NAME?.JOBS,
    module: "jobs",
  },
  {
    path: "/job/add",
    name: "Add Job",
    component: UpdateJobComponent,
    protection: ROUTE_NAME?.JOBS,
    module: "jobs",
  },
  {
    path: "/job/edit/:id?",
    name: "Edit Job",
    component: UpdateJobComponent,
    protection: ROUTE_NAME?.JOBS,
    module: "jobs",
  },
  {
    path: "/events",
    name: "Events",
    component: Events,
    protection: ROUTE_NAME?.EVENTS,
    module: "events",
  },
  {
    path: "/letters",
    name: "Letters",
    component: Letters,
    protection: ROUTE_NAME?.LETTERS,
    module: "letters",
  },
  {
    path: "/experienceletter/:id/:rId",
    name: "Experience Letter",
    component: ExperienceLetter,
    protection: ROUTE_NAME?.LETTERS,
    module: "letters",
  },
  {
    path: "/leaverequest",
    name: "Leave Request",
    component: LeaveRequest,
    protection: ROUTE_NAME?.LEAVE_REQUEST,
    module: "leaverequest",
  },
  {
    path: "/bootcampLeaveRequest",
    name: "Bootcamp Leave Request",
    component: BootcampLeaveRequest,
    protection: ROUTE_NAME?.BOOTCAMP_LEAVE_REQUEST,
    module: "bootcampLeaveRequest",
  },
  {
    path: "/lettersrequest",
    name: "Letter Request",
    component: LettersRequest,
    protection: ROUTE_NAME?.LETTERS,
    module: "letterRequest",
  },
  {
    path: "/contactrequest",
    name: "Contact Request",
    component: ContactRequest,
    protection: ROUTE_NAME?.CONTACT_REQUEST,
    module: "contactRequest",
  },
  {
    path: "/attendance",
    name: "Attendance",
    exact: true,
    component: Attendance,
    protection: ROUTE_NAME?.ATTENDANCE,
    module: "attendance",
  },
  {
    path: "/bootcampAttendance",
    name: "Bootcamp Attendance",
    exact: true,
    component: BootcampAttendance,
    protection: ROUTE_NAME?.BOOTCAMP_ATTENDANCE,
    module: "attendance",
  },
  // {
  //   path: "/jobBoard",
  //   name: "Jobs Board",
  //   component: JobsBoard,
  //   protection: ROUTE_NAME?.JOB_REQUEST,
  //   module: "JobsBoard",
  // },
  
  {
    path: "/jobrequests",
    name: "Job Request",
    component: JobRequests,
    protection: ROUTE_NAME?.JOB_REQUEST,
    module: "jobRequest",
  },
  {
    path: "/JobProfile/:id?",
    name: "Job Profile",
    component: JobProfileInfo,
    protection: ROUTE_NAME?.JOB_REQUEST,
    module: "jobRequest",
  },
  {
    path: "/payroll",
    name: "Pay Roll",
    component: Payroll,
    protection: ROUTE_NAME?.PAYROLL,
    module: "payRoll",
  },
  {
    path: "/paystub",
    name: "Paystub",
    component: PayStub,
    protection: ROUTE_NAME?.PAYSTUB,
    module: "paystub",
  },
  {
    path: "/interviews/:id?",
    name: "Interviews",
    component: Interviews,
    protection: ROUTE_NAME?.INTERVIEWS,
    module: "interview",
  },
  {
    path: "/meetingEmail",
    name: "Meeting Email",
    component: MeetingEmail,
    protection: ROUTE_NAME?.MEETINGS,
    module: "meetings",
  },
  {
    path: "/meeting",
    name: "Meetings",
    component: Meetings,
    protection: ROUTE_NAME?.MEETINGS,
    module: "meetings",
  },
  {
    path: "/banks",
    name: "Banks",
    component: Banks,
    protection: ROUTE_NAME?.BANKS,
    module: "Banks",
  },
  {
    path: "/contract",
    name: "Contract",
    component: Contract,
    protection: ROUTE_NAME?.CONTRACT,
    module: "contract",
  },
  {
    path: "/contractType",
    name: "Contract Types",
    component: ContractTypes,
    protection: ROUTE_NAME?.CONTRACT,
    module: "contractTypes",
  },
  {
    path: "/admins",
    name: "Admins",
    component: Admins,
    protection: ROUTE_NAME?.ADMINS,
    module: "Admins",
  },
  {
    path: "/leavePolicy",
    name: "Leave Policy",
    component: LeavePolicy,
    protection: ROUTE_NAME?.LEAVE_POLICY,
    module: "Leave Policy",
  },
  {
    path: "/reports/attendance",
    exact: true,
    name: "Attendance Report",
    component: AttendanceReport,
    protection: ROUTE_NAME?.ATTENDANCE_REPORT,
    module: "Attendance Report",
  },
  {
    path: "/notfound",
    exact: true,
    name: "notFound",
    component: NotFound,
    module: "notFound",
  },
];
