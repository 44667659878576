import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory, useLocation } from "react-router-dom";
import {
  changeStatus,
  createBankAccount,
  getById,
} from "../profile/redux/actions/profileAction";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Grid, Box, Button, AppBar, Toolbar } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import CircularLoader from "../utils/circularProgress";
import { useStyles } from "./style";
import { style } from "./style.js";
import JobModal from "./modal.js";
import RankHistoryTable from "./components/RankHistoryTable";
import EmployStatusHistory from "./components/EmployStatusHistory";
import IncreamentHistoryTable from "./components/IncreamentHistoryTable";
import OfferLetter from "../../components/offerLetter/OfferLetter";
import { getAllBanks } from "../banks/redux/actions/bankAction";
import { getAllContractTypes } from "../contract/redux/actions/contractTypeAction";
import { exportToExcel } from "../../utils/exportExcel.js";

export const Info = () => {
  const location = useLocation();
  const path = location.pathname;
  const isTeamProfile = path.includes("teamProfile");
  const { userProfile } = useSelector((state) => state.profileReducer);
  const { allBanks } = useSelector((state) => state.bankReducer);
  const { allContractTypes } = useSelector(
    (state) => state?.contractTypeReducer,
  );
  const [userRecord, setUserRecord] = useState({});
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [load, setLoad] = useState(false);
  const [action, setAction] = useState("Approved");
  const { id, type } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const createAccountDetail = [
    {
      Document_No: userRecord?.idCardNumber?.replace(/-/g, "") || "",
      Nationality: userRecord?.nationality || "",
      Dual_Nationality: userRecord?.dualNational || "",
      Country_Of_Birth: userRecord?.countryOfBirth || "",
      City_Of_Birth: userRecord?.cityofBirth || "",
      Country_Of_Residence: userRecord?.countryOfResidence || "",
      Issue_Date: userRecord?.nicIssueDate || "",
      Expiry_Date: userRecord?.nicExpiryDate || "",
      Customer_Visually_Imapired: userRecord?.customerVisuallyImpaired || "",
      Full_Name: userRecord?.fullName || "",
      Father_Name: userRecord?.fatherName || "",
      Marital_Status: userRecord?.martialStatus || "",
      Mother_Maiden_Name: userRecord?.motherMaidenName || "",
      Citizen_TaxRasident_Other_Than_Pakistan:
        userRecord?.citizenTaxRasidenceOtherThanPakistan || "",
      Date_Of_Birth: userRecord?.DateOfBirth || "",
      Husband_Name: userRecord?.husbandName || "",
      Gender: userRecord?.gender || "",
      PEP: userRecord?.pep || "",
      Mobile_Number: userRecord?.phoneNumber || "",
      Email_Address: userRecord?.email || "",
      Expected_Monthly_Income:
        userRecord?.basicPay && parseInt(userRecord.basicPay) >= 41000
          ? userRecord.basicPay
          : 42000,
      Type_Of_Address: userRecord?.typeOfAddress || "",
      Permanent_Address_Line: userRecord?.address || "",
      Per_City: userRecord?.permanentCity || "",
      Office_Address_Line:
        "P681 West Canal Rd, Amin Town, Faisalabad, Pakistan",
      Off_City: "Faisalabad",
      Next_Of_Kin_Name: userRecord?.nextOfKinName || "",
      Relation: userRecord?.nextOfKinRelation || "",
      Next_Of_Kin_Mobile_Number:
        userRecord?.nextOfKinPhoneNumber?.replace(/-/g, "") || "",
      Branch_Code: "0143",
      Account_Category: "Current",
    },
  ];

  const exportBankInfo = () => {
    exportToExcel(
      createAccountDetail,
      `${userRecord?.fullName} Bank Info.xlsx`,
    );
  };
  // const sendBankInfo = () => {
  //   dispatch(createBankAccount(createAccountDetail, setLoading));
  // };

  useEffect(() => {
    setUserRecord(userProfile);
  }, [userProfile]);
  useEffect(() => {
    dispatch(getById(id, setLoad, type));
  }, [dispatch, id, type]);
  useEffect(() => {
    if (allBanks?.length === 0) {
      dispatch(getAllBanks(setLoad));
    }
    if (allContractTypes?.length === 0) dispatch(getAllContractTypes(setLoad));
  }, []);

  const onClickHandler = (status) => {
    dispatch(changeStatus(id, userRecord, status, setLoading, type));
  };
  const updateHandler = () => {
    history.push({
      pathname: `/update/employee/${id}`,
      id,
    });
  };

  let dob;
  if (userRecord?.DateOfBirth?.nanoseconds) {
    dob = moment(userRecord?.DateOfBirth?.toDate()).format("DD/MM/YYYY");
  } else {
    dob = moment(userRecord?.DateOfBirth).format("DD/MM/YYYY");
  }
  let { jobCategory, jobLocation, jobType } = userRecord;
  const [activeTab, setActiveTab] = useState("account");
  const [dataForModel, setDataForModel] = useState(null);
  const [activeStyle, setActiveStyle] = useState(1);

  const showAccountDetails = () => {
    setActiveStyle(1);
    setActiveTab("account");
  };
  const statusHistory = () => {
    setActiveStyle(2);
    setActiveTab("statusHistory");
  };
  const rankHistory = () => {
    setActiveStyle(3);
    setActiveTab("rankHistory");
  };
  const increamentHistory = () => {
    setActiveStyle(4);
    setActiveTab("increamentHistory");
  };
  return !userRecord || load ? (
    <CircularLoader />
  ) : (
    <div>
      {userRecord?.rankHistory || userRecord?.employStatusHistory ? (
        <AppBar position="static">
          <Toolbar variant="dense">
            <Typography
              className={activeStyle === 1 ? classes.active : classes.Item}
              onClick={showAccountDetails}
              style={style.appBarTitle}
            >
              Account
            </Typography>
            <Typography
              className={activeStyle === 2 ? classes.active : classes.Item}
              onClick={statusHistory}
              style={style.appBarTitle}
            >
              Status History
            </Typography>
            {!isTeamProfile ? (
              <>
                <Typography
                  className={activeStyle === 3 ? classes.active : classes.Item}
                  onClick={rankHistory}
                  style={style.appBarTitle}
                >
                  Rank History
                </Typography>
                <Typography
                  className={activeStyle === 4 ? classes.active : classes.Item}
                  onClick={increamentHistory}
                  style={style.appBarTitle}
                >
                  Increment History
                </Typography>
              </>
            ) : null}
          </Toolbar>
        </AppBar>
      ) : null}
      {activeTab === "statusHistory" && userRecord?.employStatusHistory ? (
        <EmployStatusHistory
          employStatusHistory={userRecord?.employStatusHistory}
        />
      ) : (
        ""
      )}
      {activeTab === "rankHistory" && userRecord?.rankHistory ? (
        <RankHistoryTable rankHistory={userRecord?.rankHistory} />
      ) : (
        ""
      )}
      {activeTab === "increamentHistory" && userRecord?.increamentHistory ? (
        <IncreamentHistoryTable
          increamentHistory={userRecord?.increamentHistory}
        />
      ) : (
        ""
      )}
      {activeTab === "account" ? (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper style={style.paper}>
                <Typography style={style.text} variant="h3">
                  Account
                </Typography>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                  spacing={3}
                >
                  <Grid item md={2} xs={12}>
                    <img
                      style={style.image}
                      src={userRecord.profilephoto}
                      alt="profile"
                    />
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Typography style={style.title} component="div">
                      Username
                    </Typography>
                    <Typography style={style.title} component="div">
                      Email
                    </Typography>
                    <Typography style={style.title} component="div">
                      Mobile
                    </Typography>
                    {userRecord.status === "onboarding" ? null : (
                      <>
                        <Typography style={style.title} component="div">
                          CNIC
                        </Typography>
                        {!isTeamProfile && (
                          <>
                            <Typography style={style.title} component="div">
                              Salary
                            </Typography>
                            <Typography style={style.title} component="div">
                              Travel Card
                            </Typography>{" "}
                            <Typography style={style.title} component="div">
                              Travel Expenditure
                            </Typography>{" "}
                          </>
                        )}
                        {isTeamProfile && (
                          <Typography style={style.title} component="div">
                            Job Started At
                          </Typography>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <Typography component="div" style={style.detail}>
                      {userRecord.fullName}
                    </Typography>
                    <Typography component="div" style={style.detail}>
                      {userRecord.email}
                    </Typography>
                    <Typography style={style.detail} component="div">
                      {userRecord.phoneNumber}
                    </Typography>
                    {userRecord.status === "onboarding" ? null : (
                      <>
                        <Typography style={style.detail} component="div">
                          {userRecord.idCardNumber}
                        </Typography>
                        {!isTeamProfile && (
                          <>
                            <Typography style={style.detail} component="div">
                              {userRecord.basicPay}
                            </Typography>
                            <Typography style={style.detail} component="div">
                              {userRecord.travelCardNumber || "N/A"}
                            </Typography>
                            <Typography style={style.detail} component="div">
                              {userRecord.travelExpenditure}
                            </Typography>{" "}
                          </>
                        )}

                        {isTeamProfile && (
                          <Typography style={style.detail} component="div">
                            {JSON.stringify(userRecord.JobStartedAt)?.includes(
                              "seconds",
                            )
                              ? moment(
                                  userRecord.JobStartedAt?.toDate(),
                                )?.format("MMMM Do YYYY, h:mm a")
                              : moment(userRecord.JobStartedAt)?.format(
                                  "MMMM Do YYYY, h:mm a",
                                )}
                          </Typography>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Typography style={style.title} component="div">
                      Education
                    </Typography>
                    <Typography style={style.title} component="div">
                      Experience
                    </Typography>
                    {userRecord.status === "onboarding" ? (
                      <Typography style={style.title} component="div">
                        CNIC
                      </Typography>
                    ) : (
                      <>
                        <Typography style={style.title} component="div">
                          Job Location
                        </Typography>
                        <Typography style={style.title} component="div">
                          Job Type
                        </Typography>
                        <Typography style={style.title} component="div">
                          Job Category
                        </Typography>
                        <Typography style={style.title} component="div">
                          Rank Title
                        </Typography>
                        {!isTeamProfile && (
                          <Typography style={style.title} component="div">
                            Job Started At
                          </Typography>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Typography component="div" style={style.detail}>
                      {userRecord.education}
                    </Typography>
                    <Typography component="div" style={style.detail}>
                      {userRecord.experience}
                    </Typography>
                    {userRecord.status === "onboarding" ? (
                      <Typography style={style.detail} component="div">
                        {userRecord.idCardNumber}
                      </Typography>
                    ) : (
                      <>
                        <Typography component="div" style={style.detail}>
                          {jobLocation}
                        </Typography>
                        <Typography component="div" style={style.detail}>
                          {jobType}
                        </Typography>
                        <Typography style={style.detail} component="div">
                          {jobCategory}
                        </Typography>
                        <Typography style={style.detail} component="div">
                          {userRecord.rankTitle}
                        </Typography>
                        {!isTeamProfile && (
                          <Typography style={style.detail} component="div">
                            {JSON.stringify(userRecord.JobStartedAt)?.includes(
                              "seconds",
                            )
                              ? moment(
                                  userRecord.JobStartedAt?.toDate(),
                                )?.format("MMMM Do YYYY, h:mm a")
                              : moment(userRecord.JobStartedAt)?.format(
                                  "MMMM Do YYYY, h:mm a",
                                )}
                          </Typography>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item md={1} xs={6}></Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Paper style={style.paper}>
                <Typography style={style.text} variant="h3">
                  Information
                </Typography>
                <Grid container>
                  <Grid item md={4} xs={4}>
                    <Typography style={style.titleIn} variant="h6">
                      Date of Birth
                    </Typography>
                    <Typography style={style.titleIn} variant="h6">
                      Martial Status
                    </Typography>
                    <Typography style={style.titleIn} variant="h6">
                      Gender
                    </Typography>
                    <Typography style={style.titleIn} variant="h6">
                      Address
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={8}>
                    <Typography style={style.detail} component="div">
                      {dob}
                    </Typography>
                    <Typography style={style.detail} component="div">
                      {userRecord.martialStatus}
                    </Typography>
                    <Typography style={style.detail} component="div">
                      {userRecord.gender}
                    </Typography>
                    <Typography style={style.detail} component="div">
                      {userRecord.address}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid style={{ height: "40vh" }} item xs={12} md={6} lg={6}>
              <Paper style={style.paper}>
                <Typography style={style.text} variant="h3">
                  Social Information
                </Typography>
                <Grid container>
                  <Grid item md={4} xs={4}>
                    <Typography style={style.titleIn} variant="h6">
                      Facebook
                    </Typography>
                    <Typography style={style.titleIn} variant="h6">
                      Github
                    </Typography>
                    <Typography style={style.titleIn} variant="h6">
                      LinkedIn
                    </Typography>
                    <Typography style={style.titleIn} variant="h6">
                      Skype
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={8} style={{ maxWidth: "98%" }}>
                    <Typography style={style.detail} component="div">
                      {userRecord.facebook}
                    </Typography>
                    <Typography style={style.detail} component="div">
                      {userRecord.github}
                    </Typography>
                    <Typography style={style.detail} component="div">
                      {userRecord.linkedIn}
                    </Typography>
                    <Typography style={style.detail} component="div">
                      {userRecord.skype}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Paper style={style.paper}>
                <Grid
                  container
                  style={{
                    alignItems: "center",
                    height: "100px",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item md={4} xs={6}>
                    <Typography style={style.bankInfoText} variant="h3">
                      Bank Information
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 5,
                      paddingRight: 5,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={style.downloadBtn}
                      onClick={exportBankInfo}
                    >
                      Download Bank Information
                    </Button>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      style={style.downloadBtn}
                      onClick={sendBankInfo}
                    >
                      {loading ? (
                        <CircularProgress
                          size={20}
                          color="primary"
                          className={classes.progressBtn}
                        />
                      ) : (
                        "Send Bank Information"
                      )}
                    </Button> */}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={4} xs={4}>
                    <Typography style={style.titleIn} variant="h6">
                      Bank Name
                    </Typography>
                    <Typography style={style.titleIn} variant="h6">
                      Account Holder Name
                    </Typography>
                    <Typography style={style.titleIn} variant="h6">
                      Branch Code
                    </Typography>
                    <Typography style={style.titleIn} variant="h6">
                      Account Number
                    </Typography>
                    <Typography style={style.titleIn} variant="h6">
                      IBAN Number
                    </Typography>
                    <Typography style={style.titleIn} variant="h6">
                      Account Type
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={8}>
                    <Typography style={style.detail} component="div">
                      {
                        allBanks?.filter(
                          (item) => item?.bankABR === userRecord?.accountName,
                        )[0]?.bankName
                      }
                    </Typography>

                    <Typography style={style.detail} component="div">
                      {userRecord?.accountHolderName}
                    </Typography>
                    <Typography style={style.detail} component="div">
                      {userRecord?.accountBranchCode}
                    </Typography>

                    <Typography style={style.detail} component="div">
                      {userRecord?.accountNumber}
                    </Typography>
                    <Typography style={style.detail} component="div">
                      {userRecord?.accountIbn}
                    </Typography>

                    <Typography style={style.detail} component="div">
                      {userRecord?.accountType}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6} style={{ height: "40vh" }}>
              <Paper style={style.paper}>
                <Typography style={style.text} variant="h3">
                  ID Card Front
                </Typography>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={style.imageGrid}
                >
                  <img
                    src={userRecord.idCardFront}
                    width="80%"
                    height="85%"
                    style={{ paddingBottom: "30px" }}
                    alt="idCardFront"
                  />
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper style={style.paper}>
                <Typography style={style.text} variant="h3">
                  ID Card Back
                </Typography>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={style.imageGrid}
                >
                  <img
                    src={userRecord.idCardBack}
                    width="80%"
                    height={"85%"}
                    style={{ paddingBottom: "30px" }}
                    alt="idCardback"
                  />
                </Grid>
              </Paper>
            </Grid>

            <Grid item md={12} xs={12} sm={12}>
              <Box display="flex" justifyContent="flex-end" p={2}>
                {loading ? null : (
                  <Button
                    variant="contained"
                    style={style.BackBtn}
                    color="primary"
                    onClick={() => {
                      // dispatch(clearProfile())
                      history.goBack();
                    }}
                  >
                    Back
                  </Button>
                )}
                {(() => {
                  switch (isTeamProfile || userRecord.status) {
                    case "approved":
                      return (
                        <>
                          {loading ? (
                            <div className={classes.progress}>
                              <CircularProgress />
                            </div>
                          ) : (
                            <>
                              <Button
                                color="primary"
                                variant="contained"
                                style={style.promoteBtn}
                                onClick={() => {
                                  setVisible(true);
                                  setAction("Promoted");
                                  setDataForModel(userRecord);
                                }}
                              >
                                Promote
                              </Button>

                              <Button
                                color="primary"
                                variant="contained"
                                style={style.promoteBtn}
                                onClick={() => {
                                  setVisible(true);
                                  setAction("Increment");
                                  setDataForModel(userRecord);
                                }}
                              >
                                Increment
                              </Button>

                              <Link
                                to={{
                                  pathname: `/update/employee/${id}`,
                                  id,
                                }}
                              >
                                <Button
                                  onClick={() => updateHandler()}
                                  variant="contained"
                                  style={style.approveBtn}
                                  color="primary"
                                >
                                  Update
                                </Button>
                              </Link>

                              <Button
                                variant="contained"
                                style={style.blockBtn}
                                color="secondary"
                                onClick={() => onClickHandler("blocked")}
                              >
                                Block
                              </Button>

                              <OfferLetter
                                data={{ team: true, ...userRecord, id }}
                              />
                            </>
                          )}
                        </>
                      );
                    case "blocked":
                      return loading ? (
                        <div className={classes.progress}>
                          <CircularProgress />
                        </div>
                      ) : (
                        <>
                          <Link
                            to={{
                              pathname: `/update/employee/${id}`,
                              id,
                            }}
                          >
                            <Button
                              variant="contained"
                              style={style.updateBtn}
                              color="primary"
                              onClick={() => updateHandler()}
                            >
                              Update
                            </Button>
                          </Link>
                          <Button
                            color="primary"
                            variant="contained"
                            style={style.approveBtn}
                            onClick={() => onClickHandler("approved")}
                          >
                            Unblock
                          </Button>
                        </>
                      );
                    case "onboarding":
                      return loading ? (
                        <div className={classes.progress}>
                          <CircularProgress />
                        </div>
                      ) : (
                        <>
                          <Link
                            to={{
                              pathname: `/update/onboard/${id}`,
                              id,
                            }}
                          >
                            <Button
                              variant="contained"
                              style={style.updateBtn}
                              color="primary"
                            >
                              Update
                            </Button>
                          </Link>
                          <Button
                            color="primary"
                            variant="contained"
                            style={style.approveBtn}
                            onClick={() => {
                              setVisible(true);
                              setDataForModel(null);
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            color="secondary"
                            variant="contained"
                            style={style.updateBtn}
                            onClick={() => onClickHandler("declined")}
                          >
                            Decline
                          </Button>
                        </>
                      );
                    case "declined":
                      return loading ? (
                        <div className={classes.progress}>
                          <CircularProgress />
                        </div>
                      ) : (
                        <>
                          <Link
                            to={{
                              pathname: `/update/onboard/${id}`,
                              id,
                            }}
                          >
                            <Button
                              variant="contained"
                              style={style.updateBtn}
                              color="primary"
                            >
                              Update
                            </Button>
                          </Link>

                          <Button
                            color="primary"
                            variant="contained"
                            style={style.approveBtn}
                            onClick={() => setVisible(true)}
                          >
                            Approve
                          </Button>
                        </>
                      );
                    default:
                      return null;
                  }
                })()}
              </Box>
            </Grid>
          </Grid>
          <JobModal
            id={id}
            visible={visible}
            setVisible={(value) => setVisible(value)}
            action={action}
            dataForModel={dataForModel}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};
