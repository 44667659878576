import {
  GET_ALL_EMPLOYEES,
  GET_ALL_BOOTCAMP_TEAM,
  GET_ALL_BOOTCAMP_ONBOARDING,
  GET_ALL_ONBOARDING,
  SUCCESS_ALERT,
  ERROR_ALERT,
  CURRENT_ONBOARDING,
  CURRENT_EMPLOYEE,
  ALL_EMPLOYEE,
  GET_BLOCK_EMPLOYEE,
  GET_DECLIEND_ONBOARDING,
  GET_BLOCK_BOOTCAMP_TEAM,
  GET_DECLIEND_BOOTCAMP_ONBOARDING,
  GET_MAINSITE_TEAM,
  UPDATE_MAINSITE_TEAM,
} from "../constant/index";
import { db } from "../../../../config/firebase";

export const getTeam =
  ({ setLoading, teamData, allEmployees }) =>
  async (dispatch) => {
    setLoading(true);
    const promises = allEmployees.map((ite) => {
      let empData = {
        fullName: ite?.fullName || "",
        rankTitle: ite?.rankTitle || "",
        jobType: ite?.jobType || "",
        status: ite?.status,
        rankId: ite?.rankId || "",
        profilephoto: ite?.profilephoto || "",
        sortBy: ite?.sortBy,
        id: ite?.id,
      };
      if (ite?.status === "approved") {
        let alreadyInTeam = teamData.filter((item) => item?.id === ite.id)?.[0];
        empData.isExecutive = !!alreadyInTeam?.isExecutive;
        empData.hide = !!alreadyInTeam?.hide;

        if (alreadyInTeam) {
          return db
            .collection("Team")
            .doc(ite?.id)
            .update(empData)
            .then(() => empData)
            .catch((error) => {
              console.error("Error writing document: ", error);
              return null;
            });
        } else {
          return db
            .collection("Team")
            .doc(ite?.id)
            .set(empData)
            .then(() => empData)
            .catch((error) => {
              console.error("Error writing document: ", error);
              return null;
            });
        }
      } else {
        return db
          .collection("Team")
          .doc(ite?.id)
          .delete()
          .then(() => ({}))
          .catch((error) => {
            console.error("Error deleting document: ", error);
            return null;
          });
      }
    });

    Promise.all(promises)
      .then((results) => {
        const team = results?.filter((item) => item?.id);
        dispatch({ type: GET_MAINSITE_TEAM, payload: team });
      })
      .catch((error) => {
        console.error("Error performing operations: ", error);
        dispatch({ type: ERROR_ALERT, payload: error.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

export const getAllTeam = (setloading) => async (dispatch) => {
  try {
    setloading(true);
   const snapshot = await db.collection("Team").where("employmentType", "!=", "Bootcamp").get()
      const listItems = snapshot?.docs?.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dispatch({ type: GET_MAINSITE_TEAM, payload: listItems });
    
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setloading(false);
  }
};

export const getAll = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    const userDataSnapshot = await db.collection("UserData").where("status", "==", "approved").where("employmentType", "!=", "Bootcamp").get()
      const userData = userDataSnapshot?.docs?.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dispatch({ type: ALL_EMPLOYEE, payload: userData });

      var allEmployee = userData.filter(function (el) {
        return el.status === "approved";
      });

      dispatch({ type: GET_ALL_EMPLOYEES, payload: allEmployee });

      const onboardingDataSnapshot = await db.collection("Onboarding")
      .where("status", "==", "onboarding").where("employmentType", "!=", "Bootcamp").get()
        const onboardingData = onboardingDataSnapshot?.docs?.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch({ type: GET_ALL_ONBOARDING, payload: onboardingData });

      } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setloading(false);
  }
};
export const getAllBootcamp = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    const userDataSnapshot = await db.collection("UserData").where("status", "==", "approved").where("employmentType", "==", "Bootcamp").get()
      const userData = userDataSnapshot?.docs?.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dispatch({ type: GET_ALL_BOOTCAMP_TEAM, payload: userData });
      
      const onboardingDataSnapshot = await db.collection("Onboarding")
      .where("status", "==", "onboarding").where("employmentType", "==", "Bootcamp").get()
        const onboardingData = onboardingDataSnapshot?.docs?.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch({ type: GET_ALL_BOOTCAMP_ONBOARDING, payload: onboardingData });

      } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setloading(false);
  }
};

export const updateSortBy = (arrangedList) => async (dispatch) => {
  try {
    dispatch({ type: GET_MAINSITE_TEAM, payload: arrangedList });
    let batch = db.batch();
    arrangedList.forEach((doc) => {
      let docRef = db.collection("Team").doc(doc?.id);
      let docRefUser = db.collection("UserData")?.doc(doc?.id);
      batch.update(docRef, {
        sortBy: doc?.sortBy,
      });
      batch.update(docRefUser, {
        sortBy: doc?.sortBy,
      });
    });
    batch.commit();
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};

export const updateMainsiteTeam = (data) => async (dispatch) => {
  try {
    await db.collection("Team")
      .doc(data?.id)
      .update(data)
      .then(() => {
        dispatch({ type: UPDATE_MAINSITE_TEAM, payload: data });
      })
      .catch((e) => dispatch({ type: ERROR_ALERT, payload: e.message }));
  } catch (error) {
    console.log(error);
  }
};

export const getBlockEmployee = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    const blockedUsersDataSnapshot = await db
      .collection("UserData")
      .where("status", "==", "blocked").where("employmentType", "!=", "Bootcamp").get()
        const allBlockedUsers = blockedUsersDataSnapshot.docs.map((doc) => ({
          docId: doc.id,
          ...doc.data(),
        }));

        dispatch({ type: GET_BLOCK_EMPLOYEE, payload: allBlockedUsers });
  } catch (error) {
    console.log("Failed to get blocked users:", error.message);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setloading(false);
  }
};
export const getBlockBootcamp = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    const blockedUsersDataSnapshot = await db
      .collection("UserData")
      .where("status", "==", "blocked").where("employmentType", "==", "Bootcamp").get()
        const allBlockedUsers = blockedUsersDataSnapshot.docs.map((doc) => ({
          docId: doc.id,
          ...doc.data(),
        }));

        dispatch({ type: GET_BLOCK_BOOTCAMP_TEAM, payload: allBlockedUsers });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setloading(false);
  }
};

export const getDeclineOnboard = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    const declinedOnboardingSnapshot = await db
      .collection("Onboarding")
      .where("status", "==", "declined")
      .where("employmentType", "!=", "Bootcamp")
      .get()
        const allDeclined = declinedOnboardingSnapshot.docs.map((doc) => ({
          docId: doc.id,
          ...doc.data(),
        }));
        dispatch({ type: GET_DECLIEND_ONBOARDING, payload: allDeclined });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setloading(false);
  }
};
export const getDeclineBootcampOnboard = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    const declinedOnboardingSnapshot = await db
      .collection("Onboarding")
      .where("status", "==", "declined")
      .where("employmentType", "==", "Bootcamp")
      .get()
        const allDeclined = declinedOnboardingSnapshot.docs.map((doc) => ({
          docId: doc.id,
          ...doc.data(),
        }));
        dispatch({ type: GET_DECLIEND_BOOTCAMP_ONBOARDING, payload: allDeclined });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setloading(false);
  }
};


export const setCurrentEmployee = (id, setLoad) => async (dispatch) => {
  try {
    setLoad(true);
    dispatch({ type: CURRENT_EMPLOYEE, payload: id });
    setLoad(false);
  } catch (error) {
    console.log(error);
    setLoad(false);
  }
};

export const setCurrentOnboarding = (id, setLoad) => async (dispatch) => {
  try {
    setLoad(true);
    dispatch({ type: CURRENT_ONBOARDING, payload: id });
    setLoad(false);
  } catch (error) {
    console.log(error);
    setLoad(false);
  }
};

export const successMessage = (message) => async (dispatch) => {
  try {
    dispatch({ type: SUCCESS_ALERT, payload: message });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};

export const errorMessage = (message) => async (dispatch) => {
  try {
    dispatch({ type: ERROR_ALERT, payload: message });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};
