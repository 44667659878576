import {
  GET_ALL_JOBS,
  ADD_JOB,
  EDIT_JOB,
  DELETE_JOB,
  SUCCESS_ALERT,
  ERROR_ALERT,
  UPDATE_JOB,
  ADD_JOB_FIELD,
  GET_ALL_FIELDS,
  EDIT_JOB_FIELD,
  DELETE_JOB_FIELD,
} from "../constant/index";
import { db } from "../../../../config/firebase";
import moment from "moment";

export const getAllJobs = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    const jobsSnapshot = await db
      .collection("Jobs")
      .orderBy("timestamp", "desc").get()
        const allJobs = jobsSnapshot?.docs?.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // console.log("allJobs", allJobs);
        dispatch({ type: GET_ALL_JOBS, payload: allJobs });
        setloading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setloading(false);
  }
};

export const addJobAction = (data, setloading) => async (dispatch) => {
  try {
    setloading(true);
    await db.collection("Jobs").doc().set(data);
    dispatch({ type: ADD_JOB, payload: data });
    dispatch({ type: SUCCESS_ALERT, payload: "Job Added Successfully." });
    setloading(false);
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setloading(false);
  }
};

export const deleteJob = (id) => async (dispatch) => {
  try {
    // setLoad(true);
    await db.collection("Jobs").doc(id).delete();
    dispatch({ type: DELETE_JOB, payload: id });
    dispatch({ type: SUCCESS_ALERT, payload: "Job Deleted Successfully." });
    // setLoad(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    // setLoad(false);
  }
};

export const updateJob = (id, data, setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    await db
      .collection("Jobs")
      .doc(id)
      .update({
        ...data,
      });
    dispatch({ type: UPDATE_JOB, payload: data });
    dispatch({ type: SUCCESS_ALERT, payload: "Job updated Successfully." });
    setLoading(false);
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setLoading(false);
  }
};

export const editJobAction = (job) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_JOB, payload: job });
    // dispatch({ type: SUCCESS_ALERT, payload: "Job updated Successfully." });
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};

export const setCurrentJob = () => async (dispatch) => {
  try {
    dispatch({ type: EDIT_JOB, payload: {} });
  } catch (error) {
    console.log(error);
  }
};

export const getJobById = (id, setloading) => async (dispatch) => {
  try {
    setloading(true);
    let doc = await db.collection("Jobs").doc(id).get();
    if (doc.exists) {
      const data = doc.data();
      const payload = {
        ...data,
        openAt: moment(data?.openAt)?.format("DD/MM/YYYY"),
      };
      dispatch({ type: EDIT_JOB, payload });
    } else {
      dispatch({ type: ERROR_ALERT, payload: "No such document!" });
    }
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setloading(false);
  }
};

export const addJobField = (data, setloading, setModal) => async (dispatch) => {
  try {
    setloading(true);
    if (!data || !data.key) {
      throw new Error("Field key is missing.");
    }
    const existingField = await db.collection("JobFields").where("key", "==", data.key).get();
    if (!existingField.empty) {
      throw new Error("Field with the same key already exists.");
    }
    await db.collection("JobFields").doc().set(data);
    setModal(false);
    dispatch({ type: ADD_JOB_FIELD, payload: data });
    dispatch({ type: SUCCESS_ALERT, payload: "Field Added Successfully." });
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setloading(false);
  }
};
export const updateJobField = (id, data, setloading, setModal) => async (dispatch) => {
  try {
    setloading(true);
    await db.collection("JobFields").doc(id).update(data);
    setModal(false);
    dispatch({ type: EDIT_JOB_FIELD, payload: data });
    dispatch({ type: SUCCESS_ALERT, payload: "Field Updated Successfully." });
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setloading(false);
  }
};
export const deleteField = (id) => async (dispatch) => {
  try {
    await db.collection("JobFields").doc(id).delete();
    dispatch({ type: DELETE_JOB_FIELD, payload: id });
    dispatch({ type: SUCCESS_ALERT, payload: "Field Deleted Successfully." });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
}
export const getAllFields = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    await db.collection("JobFields")
      .get()
      .then((snapshot) => {

        const allFields = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        dispatch({ type: GET_ALL_FIELDS, payload: allFields });
      });
  } catch (error) {
    console.log("allJobs", error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setloading(false);
  }
};
export const updateSortBy = (arrangedList) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_FIELDS, payload: arrangedList });
    let batch = db.batch();
    arrangedList.forEach((doc, i) => {
      let docRef = db.collection("JobFields").doc(doc?.id);
      batch.update(docRef, {
        sortBy: i,
      });
    });
    batch.commit();
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};