import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useStyles } from './style';
import { addJobTypes } from '../../redux/actions';
import { Add } from '@material-ui/icons';

export default function AddJobType() {
    const [additemValue, setAdditemValue] = useState("");
    const dispatch = useDispatch()
    const classes = useStyles();

    const addHandler = () => {
        dispatch(addJobTypes(additemValue))
        dialogClose()
    }

    const dialogClose = () => {
        setAdditemValue('')
    }

    return (
        <div className={classes.fieldDiv}>
            <TextField
                autoFocus
                fullWidth
                multiline
                label="Add Job Type"
                variant='outlined'
                value={additemValue}
                onChange={(e) => { setAdditemValue(e.target.value) }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment>
                            <IconButton
                                variant="contained"
                                color="primary"
                                onClick={() => { addHandler() }}
                            >
                                <Add />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </div>
    )
}
