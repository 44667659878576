import {
  GET_ALL_JOBS_FOR_MODEL,
  SUCCESS_ALERT,
  ERROR_ALERT,
  CURRENT_PROFILE,
} from "../constant/index";
import moment from "moment";
import { db, auth } from "../../../../config/firebase";
import axiosTechloset from "../../../../config/axiosTechloset";
import { UPDATE_PROFILE } from "../../../profile/redux/constant";
import { getENVConfig } from "../../../../utils/share";

const getContract = async (
  contractType,
  joiningDate,
  employeeName,
  gender,
  martialStatus,
) => {
  try {
    const doc = await db.collection("contract").doc(contractType).get();
    if (doc?.exists) {
      const { contract, date } = doc.data();
      const updatedAt = moment(date).format("DD/MM/YYYY");
      let details = contract;
      details = details.replace("joiningDate", joiningDate);
      details = details.replaceAll("employeeName", employeeName);
      details = details.replaceAll("updatedAt", updatedAt);
      if (gender !== "Male") {
        if (martialStatus === "Married") {
          details = details.replaceAll("Mr", "Mrs");
        } else {
          details = details.replaceAll("Mr", "Ms");
        }
      }
      return details;
    } else {
      return "";
    }
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const getAllJobsForModel = (setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    const jobSnapshot = await db.collection("Jobs").get()
      const Jobs = jobSnapshot?.docs?.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      let jobCategory = [];
      let jobLocation = [];
      let jobType = [];

      Jobs.map((job) => jobCategory.push(job.jobCategory));
      Jobs.map((job) => jobLocation.push(job.jobLocation));
      Jobs.map((job) => jobType.push(job.jobType));

      const uniquejobCategory = jobCategory.filter(
        (item, index) => jobCategory.indexOf(item) === index,
      );
      const uniquejobLocation = jobLocation.filter(
        (item, index) => jobLocation.indexOf(item) === index,
      );
      const uniquejobType = jobType.filter(
        (item, index) => jobType.indexOf(item) === index,
      );

      let jobs = {
        jobCategory: uniquejobCategory,
        jobLocation: uniquejobLocation,
        jobType: uniquejobType,
      };
      dispatch({ type: GET_ALL_JOBS_FOR_MODEL, payload: jobs });
      setLoading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setLoading(false);
  }
};
export const createEvent = async (data, type) => {
  try {
    let title = type == "Interview" ? data.fname : data?.fullName;
    let relevantDate;
    if (type == "Birthday" && data?.DateOfBirth?.nanoseconds) {
      relevantDate = moment(data?.DateOfBirth?.toDate()).format(
        "YYYY-MM-DDTHH:mm:ss",
      );
    } else if (type == "Birthday") {
      relevantDate = moment(data?.DateOfBirth).format("YYYY-MM-DDTHH:mm:ss");
    } else if (type == "Anniversary" && data?.JobStartedAt?.nanoseconds) {
      relevantDate = moment(data?.JobStartedAt?.toDate()).format(
        "YYYY-MM-DDTHH:mm:ss",
      );
    } else if (type == "Anniversary") {
      relevantDate = moment(data?.JobStartedAt).format("YYYY-MM-DDTHH:mm:ss");
    } else if (type == "Interview") {
      relevantDate = moment(data?.startDate).format("YYYY-MM-DDTHH:mm:ss");
    }

    let month = moment(relevantDate).format("MM");
    let end = moment(relevantDate).format("DD");
    let date = moment(relevantDate).format("DD");
    let time = moment(relevantDate).format("THH");
    let minutes = moment(relevantDate).format("mm");
    let doAddYearInCurrentYear = type == "Anniversary" ? 1 : 0;
    let currenYear = new Date().getFullYear() + doAddYearInCurrentYear;
    let EventDate = currenYear + month + date + time + minutes;
    let EndDate = currenYear + month + end + time + minutes;

    const timeZone = "Asia/Karachi";
    let gapi = window.gapi;
    let attendeesArr = data?.isChecked
      ? [
          { email: `${data.email}` },
          { email: process.env.REACT_APP_ADD_PARTICIPANT_EMAIL },
        ]
      : [{ email: `${data.email}` }];

    const event = {
      summary: `${title}  ${type} at TechloSet`,
      description: `${title}  ${type}`,
      start: {
        dateTime: `${moment(EventDate).format("YYYY-MM-DDTHH:mm:ss")}`,
        timeZone,
      },
      end: {
        dateTime: `${moment(EndDate).format("YYYY-MM-DDTHH:mm:ss")}`,
        timeZone,
      },
      attendees: type == "Interview" ? attendeesArr : [],
      reminders: {
        useDefault: false,
        overrides: [
          { method: "email", minutes: 24 * 60 },
          { method: "popup", minutes: 40 },
        ],
      },
      recurrence: type == "Interview" ? false : ["RRULE:FREQ=YEARLY;"],
    };
    const googleEventpayload = {
      event,
    };
    const response = await axiosTechloset({
      method: "POST",
      url: "/event/create",
      data: googleEventpayload,
    });
    const events = response?.data.eventData.data.id;
    return events;
  } catch (err) {
    console.log(err);
    return "";
  }
};
export const updateGoogleEvent = async (data, type) => {
  console.log(data);
  let title = data.fname;
  let relevantDate = data.startDate;
  let month = moment(relevantDate).format("MM");
  let end = moment(relevantDate).format("DD");
  let date = moment(relevantDate).format("DD");
  let time = moment(relevantDate).format("THH");
  let minutes = moment(relevantDate).format("mm");
  let currenYear = new Date().getFullYear();

  let EventDate = currenYear + month + date + time + minutes;
  let EndDate = currenYear + month + end + time + minutes;
  const timeZone = "Asia/Karachi";
  let gapi = window.gapi;
  let attendeesArr = data?.isChecked
    ? [
        { email: `${data.email}` },
        { email: process.env.REACT_APP_ADD_PARTICIPANT_EMAIL },
      ]
    : [{ email: `${data.email}` }];
  const event = {
    summary: type + " at Techloset",
    description: `${title}  ${type}`,
    start: {
      dateTime: `${moment(EventDate).format("YYYY-MM-DDTHH:mm:ss")}`,
      timeZone,
    },
    end: {
      dateTime: `${moment(EndDate).format("YYYY-MM-DDTHH:mm:ss")}`,
      timeZone,
    },
    attendees: attendeesArr,
    reminders: {
      useDefault: false,
      overrides: [
        { method: "email", minutes: 24 * 60 },
        { method: "popup", minutes: 40 },
      ],
    },
    recurrence: false,
  };
  const googleEventpayload = {
    event,
  };
  console.log(event, "event");
  const response = await axiosTechloset({
    method: "PUT",
    url: "/event/update",
    data: { googleEventpayload, id: data.googleCalendarInterviewEventId },
  });
  const events = response?.data?.eventData?.data?.id;
  return events;
};

export const approvedUser =
  (id, data, setLoading, setVisible, allContractTypes, callback) =>
  async (dispatch) => {
    try {
      setLoading(true);
      const onboardDate = moment(data?.onboardingAt).format(
        "DD/MM/YYYY",
      );
      const contract = await getContract(
        data?.contractType,
        onboardDate,
        data?.fullName,
        data?.gender,
        data?.martialStatus,
      );
      let jobTypeHistory = [
        {
          jobType: data.jobType,
          rankTitle: data.rankTitle,
        },
      ];
      let rankHistory = [
        {
          rankTitle: data?.rankTitle,
          rankId: data?.rankId,
          newSalary: data?.basicPay,
          promotedAt: moment(data?.JobStartedAt)?.format(
            "MMMM Do YYYY, h:mm a",
          ),
          date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
        },
      ];
      let increamentHistory = [
        {
          newSalary: data?.basicPay,
          date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
          increamentAt: moment(data?.JobStartedAt)?.format(
            "MMMM Do YYYY, h:mm a",
          ),
        },
      ];
      let tempPassword, newUserRes, authId;
      // create temp password for user 10 digit
      tempPassword = Math.floor(
        1000000000 + Math.random() * 9000000000,
      ).toString();
      newUserRes = await axiosTechloset({
        method: "POST",
        url: "/users/create",
        data: {
          email: data?.email,
          password: tempPassword,
        },
      });
      authId = newUserRes?.data?.user?.uid;

      if (!data.employStatusHistory) {
        let employStatusHistory = [
          {
            date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
            status: "approved",
          },
        ];

        let newData = {
          JobStartedAt: data.JobStartedAt,
          jobCategory: data.jobCategory,
          sortBy: data.sortBy,
          jobLocation: data.jobLocation,
          jobType: data.jobType,
          rankTitle: data.rankTitle,
          basicPay: data.basicPay,
          travelCardNumber: data.travelCardNumber,
          travelExpenditure: data.travelExpenditure,
          status: "approved",
          employStatusHistory,
          rankHistory,
          increamentHistory,
          jobTypeHistory,
          authId,
          tempPassword,
        };
        const birthdayEventId = await createEvent(data, "Birthday");
        const AniversaryEventId = await createEvent(data, "Anniversary");
        let events = {
          birthdayEventId,
          AniversaryEventId,
        };
        await db
          .collection("UserData")
          .doc(id)
          .set({ ...data, ...newData, events }, { merge: true });

        dispatch({ type: UPDATE_PROFILE, payload: newData });
      } else {
        const birthdayEventId = await createEvent(data, "Birthday");
        const AniversaryEventId = await createEvent(data, "Anniversary");

        let events = {
          birthdayEventId,
          AniversaryEventId,
        };
        let employStatusHistory = [
          {
            date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
            status: "approved",
          },
          ...data.employStatusHistory,
        ];

        let newData = {
          JobStartedAt: data.JobStartedAt,
          jobCategory: data.jobCategory,
          sortBy: data.sortBy,
          jobLocation: data.jobLocation,
          jobType: data.jobType,
          rankTitle: data.rankTitle,
          basicPay: data.basicPay,
          travelCardNumber: data.travelCardNumber,
          travelExpenditure: data.travelExpenditure,
          status: "approved",
          employStatusHistory,
          rankHistory,
          increamentHistory,
          jobTypeHistory,
          authId,
          tempPassword,
        };
        await db
          .collection("UserData")
          .doc(id)
          .set({ ...data, ...newData, events }, { merge: true });
        const filteredContract = allContractTypes.find(
          (item) => item?.id === data?.contractType,
        );
        const message = filteredContract?.emailMessage || "";
        const subject = filteredContract?.emailSubject;
        let reqData = {
          password: tempPassword,
          name: data?.fullName,
          email: data?.email,
          message,
          subject: subject
            ? subject?.replace("name", data?.fullName)
            : `Congratulations ${data?.fullName}`,
        };
        await axiosTechloset.post(`/emails/congrats`, reqData);
        await axiosTechloset({
          method: "POST",
          url: "/emails/terms",
          data: {
            name: data?.fullName,
            email: data?.email,
            contract,
            sign: data?.sign,
          },
        });
        dispatch({ type: UPDATE_PROFILE, payload: newData });
      }

      await db.collection("Onboarding").doc(id).delete();

      let Jobs = await db
        .collection("JobApplication")
        .where("email", "==", `${data.email}`)
        .get();
      let jobData = [];
      Jobs.forEach((item) => {
        jobData.push({ docId: item.id, ...item.data() });
      });
      if (jobData.length > 0) {
        let id = jobData[jobData.length - 1].docId;

        await db
          .collection("JobApplication")
          .doc(id)
          .update({ status: "hired" });
      }
      dispatch({ type: SUCCESS_ALERT, payload: "Approved successfully!" });

      callback && callback();
    } catch (error) {
      dispatch({
        type: ERROR_ALERT,
        payload: error.message || error.response.data,
      });
    } finally {
      setLoading(false);
      setVisible(false);
    }
  };

const jobTypeChanged = (docId, oldData, newData) => async (dispatch) => {
  try {
    if (!oldData.jobTypeHistory) {
      let jobTypeHistory = [
        {
          jobType: newData.jobType,
          rankTitle: newData.rankTitle,
        },
      ];

      await db.collection("UserData").doc(docId).update({
        jobTypeHistory,
      });

      dispatch({ type: UPDATE_PROFILE, payload: { jobTypeHistory } });
    } else {
      let filtered = oldData?.jobTypeHistory?.filter(
        (doc) => doc.jobType !== newData.jobType,
      );
      let jobTypeHistory = [
        {
          jobType: newData.jobType,
          rankTitle: newData.rankTitle,
        },
        ...filtered,
      ];

      await db.collection("UserData").doc(docId).update({
        jobTypeHistory,
      });

      dispatch({ type: UPDATE_PROFILE, payload: { jobTypeHistory } });
    }
  } catch (error) {
    console.log(error.message);
  }
};

export const promotedUser =
  (id, user, promotion, setLoading) => async (dispatch) => {
    try {
      setLoading(true);

      dispatch(jobTypeChanged(id, user, promotion));

      if (!user.rankHistory) {
        let updatedRanks = [
          {
            rankTitle: promotion?.rankTitle,
            rankId: promotion?.rankId,
            oldSalary: user?.basicPay,
            note: promotion?.note,
            newSalary: promotion?.newSalary,
            promotedAt: moment(promotion?.promotedAt)?.format(
              "MMMM Do YYYY, h:mm a",
            ),
            date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
          },
        ];

        let newData = {
          jobType: promotion.jobType,
          rankTitle: promotion.rankTitle,
          jobCategory: promotion.jobCategory,
          jobLocation: promotion.jobLocation,
          basicPay: promotion?.newSalary,
          rankHistory: updatedRanks,
          employmentType: promotion?.employmentType,
        };

        await db.collection("UserData").doc(id).update(newData);

        dispatch({ type: UPDATE_PROFILE, payload: newData });
      } else {
        let updatedRanks = [
          {
            rankTitle: promotion?.rankTitle,
            rankId: promotion?.rankId,
            oldSalary: user?.basicPay,
            note: promotion?.note,
            newSalary: promotion?.newSalary,
            promotedAt: moment(promotion?.promotedAt)?.format(
              "MMMM Do YYYY, h:mm a",
            ),
            date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
          },
          ...user.rankHistory,
        ];

        let newData = {
          jobType: promotion.jobType,
          rankTitle: promotion.rankTitle,
          jobCategory: promotion.jobCategory,
          jobLocation: promotion.jobLocation,
          promotedAt: promotion?.promotedAt,
          basicPay: promotion?.newSalary,
          rankHistory: updatedRanks,
          employmentType: promotion?.employmentType,
        };

        await db.collection("UserData").doc(id).update(newData);

        dispatch({ type: UPDATE_PROFILE, payload: newData });
      }

      dispatch({ type: SUCCESS_ALERT, payload: "Promoted successfully!" });
      setLoading(false);
      await axiosTechloset.post("/slack/promotions", {
        name: user.fullName,
        email: user.email,
        promotedFrom: user.rankTitle,
        promotedTo: promotion.rankTitle,
      });

      const date = moment(promotion.promotedAt).format("MM/DD/YYYY");

      if (promotion.mailChecked) {
        await axiosTechloset.post("/emails/promotionLetter", {
          name: user.fullName,
          date: date,
          promotedTo: promotion.rankTitle,
          address: user.address,
          salary: promotion.newSalary,
          email: promotion.email,
        });
      }

      dispatch({ type: SUCCESS_ALERT, payload: "Message send successfully!" });
    } catch (error) {
      dispatch({ type: ERROR_ALERT, payload: error.message });
      setLoading(false);
    }
  };

export const salaryIncreament =
  (docId, user, data, setLoading, sendIncrementMail, setVisible) =>
  async (dispatch) => {
    try {
      setLoading(true);
      if (!user.increamentHistory) {
        let increamentHistory = [
          {
            oldSalary: user?.basicPay,
            note: data?.note,
            newSalary: data?.newSalary,
            increamentAt: moment(data?.increamentAt)?.format(
              "MMMM Do YYYY, h:mm a",
            ),
            date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
          },
        ];

        await db.collection("UserData").doc(docId).update({
          basicPay: data?.newSalary,
          increamentHistory,
        });

        dispatch({
          type: UPDATE_PROFILE,
          payload: { increamentHistory, basicPay: data?.newSalary },
        });
      } else {
        let increamentHistory = [
          {
            oldSalary: user?.basicPay,
            note: data?.note,
            newSalary: data?.newSalary,
            increamentAt: moment(data?.increamentAt)?.format(
              "MMMM Do YYYY, h:mm a",
            ),
            date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
          },
          ...user?.increamentHistory,
        ];

        await db.collection("UserData").doc(docId).update({
          basicPay: data?.newSalary,
          increamentHistory,
        });

        dispatch({
          type: UPDATE_PROFILE,
          payload: { increamentHistory, basicPay: data?.newSalary },
        });
      }
      const date = moment(data?.increamentAt).format("MM/DD/YYYY");

      if (sendIncrementMail) {
        await axiosTechloset.post("/emails/incrementLetter", {
          name: user.fullName,
          date: date,
          address: user?.address,
          salary: data?.newSalary,
          email: user?.email,
        });
      }
      dispatch({
        type: SUCCESS_ALERT,
        payload: "Successfully! Increament in Salary",
      });
    } catch (error) {
      dispatch({ type: ERROR_ALERT, payload: error.message });
    } finally {
      setLoading(false);
      if (setVisible) setVisible(false);
    }
  };

export const updateByAdmin =
  (id, data, setLoading, type) => async (dispatch) => {
    try {
      let Collection = type === "onboard" ? "Onboarding" : "UserData";
      setLoading(true);
      await db
        .collection(Collection)
        .doc(id)
        .update({
          ...data,
        });
      dispatch({ type: CURRENT_PROFILE, payload: data });
      dispatch({ type: SUCCESS_ALERT, payload: "Updated successfully!" });
      setLoading(false);
    } catch (error) {
      dispatch({ type: ERROR_ALERT, payload: error.message });
      setLoading(false);
    }
  };
export const updateUserEvents =
  (id, data, setLoading, type) => async (dispatch) => {
    try {
      let Collection = "UserData";
      setLoading(true);
      await db
        .collection(Collection)
        .doc(id)
        .update({
          ...data,
        });
      dispatch({ type: SUCCESS_ALERT, payload: "Updated successfully!" });
      setLoading(false);
    } catch (error) {
      dispatch({ type: ERROR_ALERT, payload: error.message });
      setLoading(false);
    }
  };
