import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "5%",
  },
  grid: {
    marginTop: "5%",
  },
  input: {
    width: "100%",
  },
  button: {
    marginLeft: "5px",
    float: "right",
    bottom: "30px",
  },
  loader: {
    float: "right",
    bottom: "30px",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  wrapper: {
    padding: "1rem",
  },
  editor: {
    padding: "1rem",
    border: "1px solid #ccc",
    minHeight: "80vh",
  },
  toolbar: {
    border: "1px solid #ccc",
  },
}));