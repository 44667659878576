import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "100%",
    paddingBottom: "20px",
  },
  progress: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(5),
    },
  },
  inputStyle: {
    "&:focus": {
      border: "none",
      backgroundColor: "red",
    },
  },
  width: "90%",
}));

export const style = {
  BackBtn: {
    marginRight: "10px",
    color: "white",
    backgroundColor: "rgb(164,164,168)",
  },
  first: {
    fontWeight: "600",
    fontSize: "1rem",
    lineHieght: "2",
    color: "#626262",
    marginLeft: "20px",
    marginTop: "2px",
  },
  middle: {
    fontWeight: "600",
    fontSize: "1rem",
    lineHieght: "2",
    color: "#626262",
    marginLeft: "20px",
    marginTop: "27px",
  },
  last: {
    fontWeight: "600",
    fontSize: "1rem",
    lineHieght: "2",
    color: "#626262",
    marginLeft: "20px",
    marginTop: "33px",
  },
  approveBtn: {
    marginRight: "10px",
    // backgroundColor: "rgb(76,175,80)",
    color: "white",
  },
  text: {
    paddingBottom: "40px",
    paddingLeft: "20px",
    paddingTop: "30px",
    fontWeight: "300",
    letterSpacing: "0.05rem",
    fontSize: "1.32rem",
    color: "#2c2c2c",
    fontFamily: "Helvetica",
  },
  image: {
    width: "112px",
    height: "112px",
    borderRadius: "0.5rem",
    marginLeft: "20px",
    marginBottom: "30px",
  },
  title: {
    fontWeight: "600",
    fontSize: "1rem",
    lineHieght: "2",
    color: "#626262",
    marginTop: "10px",
  },
  titleIn: {
    fontWeight: "600",
    fontSize: "1rem",
    // lineHieght: "2",
    color: "#626262",
    marginLeft: "20px",
    marginTop: "17px",
  },
  detail: {
    paddingBottom: "22px",
    // border: "none",
    // borderColor: "#fff",
    // "&:hover": {
    //   borderColor: "#fff",
    // },
  },
  input: {
    width: "90%",
    "&:focus": {
      border: "none",
    },
  },
};
