import {
  GET_ALL_CONTRACT_TYPES,
  ERROR_ALERT,
  ADD_CONTRACT_TYPES,
  SUCCESS_ALERT,
  UPDATE_CONTRACT_TYPES,
  DELETE_CONTRACT_TYPES,
} from "../constants/contractConstant";
import { db } from "../../../../config/firebase";

export const getAllContractTypes = (setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    let res = await db.collection("ContractTypes").get();
    let allContracts = [];
    res.forEach((doc) => {
      allContracts.push({ ...doc.data(), id: doc.id });
    });
    dispatch({ type: GET_ALL_CONTRACT_TYPES, payload: allContracts });
    setLoading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setLoading(false);
  }
};
export const addContractType =
  (data, setLoading, toggle) => async (dispatch) => {
    try {
      setLoading(true);
      const docRef = await db.collection("ContractTypes").add({
        ...data,
      });
      const id = docRef?.id;
      dispatch({ type: ADD_CONTRACT_TYPES, payload: { ...data, id } });
      dispatch({
        type: SUCCESS_ALERT,
        payload: "Contract Type Added Successfully.",
      });
      toggle();
    } catch (error) {
      console.log(error);
      dispatch({ type: ERROR_ALERT, payload: error.message });
    } finally {
      setLoading(false);
    }
  };
export const updateContractType =
  (data, setLoading, toggle) => async (dispatch) => {
    try {
      setLoading(true);
      const { id, ...rest } = data;
      await db
        .collection("ContractTypes")
        .doc(id)
        .update({
          ...rest,
        });
      dispatch({ type: UPDATE_CONTRACT_TYPES, payload: { ...rest, id } });
      dispatch({
        type: SUCCESS_ALERT,
        payload: "Contract Type Updated Successfully.",
      });
      if (toggle) {
        toggle();
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: ERROR_ALERT, payload: error.message });
    } finally {
      setLoading(false);
    }
  };
  
export const deleteContractType = (id, setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    await db.collection("ContractTypes").doc(id).delete();
    dispatch({ type: DELETE_CONTRACT_TYPES, payload: id });
    dispatch({
      type: SUCCESS_ALERT,
      payload: "Contract Type Deleted  Successfully.",
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setLoading(false);
  }
};
