import { db } from '../../../config/firebase'
import { ADD_INTERVIEW_QUESTION, ADD_INTERVIEW_TYPE, DELETE_INTERVIEW_TYPE, GET_INTERVIEW_QUESTION, GET_INTERVIEW_TYPE, INDEX_CHANGE, UPDATE_INTERVIEW_QUESTION, UPDATE_INTERVIEW_TYPE } from './constants'

export const addInterviewType = (interviewFor, setLoading) => async (dispatch) => {
    try {
        setLoading(true)
        let generalRes = await db
            .collection("interviewQuestions")
            .where("interviewFor", "==", "General")
            .get();
        let data = [];
        generalRes.forEach((item) => {
            data.push({ docId: item.id, ...item.data() });
        });

        let questions = data[0]?.questions


        let res = await db
            .collection("interviewQuestions")
            .add({
                interviewFor,
                questions
            })

        dispatch({
            type: ADD_INTERVIEW_TYPE,
            payload: { interviewFor, docId: res.id, questions }
        })

    } catch (error) {
        console.log(error.message);

    } finally {
        setLoading(false)
    }
}
export const getInterviewType = () => async (dispatch) => {
    try {
        let res = await db
            .collection("interviewQuestions")
            .get();
        let data = [];
        res.forEach((item) => {
            data.push({ docId: item.id, ...item.data() });
        });

        dispatch({
            type: GET_INTERVIEW_TYPE,
            payload: data
        })
    } catch (error) {
        console.log(error.message);
    }
}
export const deleteInterviewType = (docId) => async (dispatch) => {
    try {
        await db
            .collection("interviewQuestions")
            .doc(docId)
            .delete()

        dispatch({
            type: DELETE_INTERVIEW_TYPE,
            payload: docId
        })
    } catch (error) {
        console.log(error.message);
    }
}
export const updateInterviewType = (interviewFor, docId) => async (dispatch) => {
    try {
        await db
            .collection("interviewQuestions")
            .doc(docId)
            .update({
                interviewFor
            })

        dispatch({
            type: UPDATE_INTERVIEW_TYPE,
            payload: { interviewFor, docId }
        })

    } catch (error) {
        console.log(error.message);
    }
}
export const getQuestionsByType = (interviewFor) => async (dispatch) => {
    try {
        let res = await db
            .collection("interviewQuestions")
            .where("interviewFor", "==", interviewFor)
            .get();
        let data = [];
        res.forEach((item) => {
            data.push({ docId: item.id, ...item.data() });
        });

        dispatch({
            type: GET_INTERVIEW_QUESTION,
            payload: data[0]
        })

    } catch (error) {
        console.log(error.message);
    }
}
export const addQuestion = (question, data, setLoading) => async (dispatch) => {
    try {
        setLoading(true)
        if (!data.questions) {
            let questions = [{
                question,
                id: Math.random() * 8345?.toString()
            }]

            await db
                .collection("interviewQuestions")
                .doc(data.docId)
                .update({
                    questions
                })

            dispatch({
                type: ADD_INTERVIEW_QUESTION,
                payload: { ...data, questions }
            })
        } else {
            let questions = [...data.questions, {
                question,
                id: Math.random() * 8345?.toString()
            }]

            await db
                .collection("interviewQuestions")
                .doc(data.docId)
                .update({
                    questions
                })

            dispatch({
                type: ADD_INTERVIEW_QUESTION,
                payload: { ...data, questions }
            })
        }

    } catch (error) {
        console.log(error.message);

    } finally {
        setLoading(false)
    }
}
export const updateInterviewQuestion = (data) => async (dispatch) => {
    try {
        await db
            .collection("interviewQuestions")
            .doc(data.docId)
            .update({
                ...data
            })

        dispatch({
            type: UPDATE_INTERVIEW_QUESTION,
            payload: data
        })

    } catch (error) {
        console.log(error.message);
    }
}
export const questionsIndexChange = (docId, questions) => async (dispatch) => {
    try {
        dispatch({
            type: INDEX_CHANGE,
            payload: questions
        })

        await db.collection("interviewQuestions").doc(docId).update({ questions });

    } catch (error) {
        console.log(error.message);
    }

}

export const addGeneral = () => async (dispatch) => {
    try {
        let interviewFor = "General"

        let res = await db
            .collection("interviewQuestions")
            .where("interviewFor", "==", interviewFor)
            .get();
        let data = [];
        res.forEach((item) => {
            data.push({ docId: item.id, ...item.data() });
        });

        if (data?.length == 0) {
            let res = await db.collection("interviewQuestions").add({
                interviewFor
            })

            dispatch({
                type: ADD_INTERVIEW_TYPE,
                payload: { interviewFor, docId: res.id }
            })

        }


    } catch (error) {
        console.log(error.message);
    }
}