import React, { useEffect,useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  handleSidebar,
  handleSelectedInterview,
} from "./redux/interviewAction/interviewAction";
import {
  addInterview,
  interviewReschedule,
} from "../jobsRequests/redux/actions/jobsRequestsAction";
import { errorMessage } from "../lettersRequest/redux/actions/lettersRequestAction";
import { X, Tag } from "react-feather";
import { FormGroup, Input, Label, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import "./css/flatpickr.scss";
import { useObjectState } from "../../commonUtils/commonState";
 function AddInterview(props) {
  const [state, setState] = useObjectState({
    startDate: new Date(),
    title: "",
    location: props?.user?.location || "",
    appliedFor: props?.user?.appliedFor || "",
    fname: props?.user?.fname || "",
    email: props?.user?.email || "",
  });
  const [isChecked,setIsChecked]=useState(false)
  const dispatch = useDispatch();
  const { sidebar, selectedInterview } = useSelector(
    (state) => state.interviewReducer,
  );
  const handleDateChange = (date) => {
    setState({
      startDate: date[0],
    });
  };

  const handleStartDateClose = (date) => {
    setState({
      startDate: date,
    });
  };

  const validate = () => {
    const start = new Date(state.startDate);
    const today = new Date();
    let diff = start.getTime() - today.getTime();
    let minutes = Math.floor(diff / 1000 / 60);
    if (state.email == "") {
      dispatch(errorMessage("Email is required "));
    } else if (state.fname == "") {
      dispatch(errorMessage("Full Name is required "));
    } else if (moment(start).isBefore(moment(today), "day")) {
      dispatch(errorMessage("Interview date should not past date"));
    } else if (minutes <= 0) {
      dispatch(errorMessage("Interview Time should not past Time"));
    } else return true;
  };

  const handleAddInterview = () => {
    if (validate()) {
    
      dispatch(
        addInterview(
          {
            isChecked,
            ...state,
            start: state.startDate,
          },
          props.user,
        ),
      );
      setState({
        startDate: new Date(),
        appliedFor: props?.user !== null ? props?.user?.appliedFor : "",
        title: "",
        location: "",
        fname: props?.user !== null ? props?.user?.fname : "",
        email: props?.user !== null ? props?.user?.email : "",
      });

      dispatch(handleSidebar(false));
    }
  };
  useEffect(() => {
    if (selectedInterview !== null) {
      setState({
        title: selectedInterview.title,
        location: selectedInterview.location || "",
        email: selectedInterview.email,
        fname: selectedInterview.fname,
        appliedFor:
          selectedInterview?.user?.appliedFor || props?.user?.appliedFor || "",
        startDate: selectedInterview.start,
        id: selectedInterview.id,
      });
    } else {
      setState({
        startDate: new Date(),
        endDate: new Date(),
        appliedFor: props?.user?.appliedFor || "",
        title: "",
        location: "",
        fname: props?.user?.fname || "",
        email: props?.user?.email || "",
      });
    }
  }, [selectedInterview, props?.user]);
  return (
    <div className={`add-event-sidebar ${sidebar ? "show" : "hidden"}`}>
      <div className="header d-flex justify-content-between">
        <h3 className="text-bold-600 mb-0"></h3>
        <div
          className="close-icon cursor-pointer"
          onClick={() => dispatch(handleSidebar(false))}
        >
          <X size={20} />
        </div>
      </div>
      <div className="add-event-body">
        <div className="add-event-fields mt-2">
          <FormGroup>
            <Label for="interviewLocation">Location*</Label>

            <Input
              type="select"
              id="interviewLocation"
              placeholder="Onsite/Remote"
              value={state.location}
              onChange={(e) => setState({ location: e.target.value })}
            >
              <option disabled value={""}>
                Select Location
              </option>
              <option value={"onsite"}>Onsite</option>
              <option value={"remote"}>Remote</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="interviewTitle">InterView Title*</Label>

            <Input
              type="text"
              id="interviewTitle"
              placeholder="InterView Title"
              value={state.title}
              onChange={(e) => setState({ title: e.target.value })}
            />
          </FormGroup>

          <FormGroup>
            <Label for="interviewTitle"> Email*</Label>
            <Input
              type="email"
              id="email"
              placeholder="Canidate Email"
              value={state.email}
              onChange={(e) => setState({ email: e.target.value })}
            />
          </FormGroup>

          <FormGroup>
            <Label for="First Name">Full Name*</Label>
            <Input
              type="text"
              id="name"
              placeholder="First Name"
              value={state.fname}
              onChange={(e) => setState({ fname: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="startDate">Interview Date*</Label>
            <Flatpickr
              id="startDate"
              className="form-control"
              value={state.startDate}
              onChange={(date) => handleDateChange(date)}
              onClose={(date) => handleStartDateClose(date[0])}
              options={{
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d",
                enableTime: true,
                hourIncrement: 1,
                minuteIncrement: 5,
              }}
            />
          </FormGroup>
         
          <FormGroup check>
    <Input type="checkbox" className="checkbox" value={isChecked} onChange={(e)=>setIsChecked(e.target.checked)} />
    {' '}
    <Label check className="checkbox_label">
   Add Naveed
    </Label>
  </FormGroup>
        </div>
     
        <div className="add-event-actions text-right">
          {
            <Button.Ripple
              variant="outlined"
              className="ml-1"
              color="primary"
              disabled={
                state.location.length > 0 && state.title.length > 0
                  ? false
                  : true
              }
              onClick={() => {
                if (
                  selectedInterview !== null &&
                  selectedInterview.title.length > 0 &&
                  validate()
                ) {                
            dispatch(handleSidebar(false));
                  dispatch(
                    interviewReschedule({
                      isChecked,
                      googleCalendarInterviewEventId: selectedInterview.googleCalendarInterviewEventId,
                      ...state,
                      user: props.interviewInfo.user,
                      start: state.startDate,
                      meetingId: props.interviewInfo.meetingId,
                      start_url: props.interviewInfo.start_url,
                      join_url: props.interviewInfo.join_url,
                      password: props.interviewInfo.password,
                    }),
                  );
                } else {
                  handleAddInterview();
                }
              }}
            >
              {selectedInterview !== null && selectedInterview.title.length > 0
                ? "Update"
                : "Add Interview"}
            </Button.Ripple>
          }
          <Button.Ripple
            variant="outlined"
            color="primary"
            className="ml-1"
            onClick={() => {
              dispatch(handleSidebar(false));

              dispatch(handleSelectedInterview(null));
            }}
          >
            Cancel
          </Button.Ripple>
        </div>
      </div>
    </div>
  );
}

export default AddInterview;
