import {
    SUCCESS_ALERT,
    ERROR_ALERT,
    ADD_MEETING,
    GET_ALL_MEETING,
    DELETE_MEETING
} from "../constant/index";

const initialState = {
    allMeetings: [],
    successMessage: "",
    errorMessage: "",
};

const meetingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_MEETING:
            return {
                ...state,
                allMeetings: action.payload,
            };
        case ADD_MEETING:
            return {
                ...state,
                allMeetings: [...state.allMeetings, action.payload],
            };
        case DELETE_MEETING:
            const updated = state.allMeetings.filter((el) => el.id !== action.payload);
            return {
                allMeetings: updated,
            };
        case SUCCESS_ALERT:
            return {
                ...state,
                successMessage: action.payload,
            };
        case ERROR_ALERT:
            return {
                ...state,
                errorMessage: action.payload,
            };
        default:
            return state;
    }
};
export default meetingReducer;
