import React, { useEffect } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./style";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { deleteJobType, getAllJobTypes } from "./redux/actions";
import AddJobType from "./component/addJobType/AddJobType";
import UpdateDialog from "./component/UpdateDialog";
import image from "../../assets/logo2.png";

export default function Ranks() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { jobTypes } = useSelector((state) => state.ranksReducer);

  useEffect(() => {
    dispatch(getAllJobTypes());
  }, []);

  const deleteHandler = (id) => {
    dispatch(deleteJobType(id));
  };

  return (
    <>
      <PageTitle title="Job Types" />
      <AddJobType />
      <div className={classes.alignCard}>
        {jobTypes?.map((doc, index) => {
          return (
            <div key={doc.type + index} className={classes.padding}>
              <Card className={classes.root}>
                <Link to={{ pathname: `/ranks/${doc.type}` }}>
                  <CardActionArea>
                    <CardContent className={classes.media}>
                      <Typography gutterBottom variant="h5" component="h1">
                        {doc.type}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Link>
                <CardActions>
                  <UpdateDialog item={doc} />
                  <Button
                    onClick={() => {
                      deleteHandler(doc.docId);
                    }}
                    size="small"
                    color="secondary"
                  >
                    Delete
                  </Button>
                </CardActions>
              </Card>
            </div>
          );
        })}
        {jobTypes?.length == 0 ? (
          <img
            className={classes.defaultImage}
            src={image}
            alt="No job type found"
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}
