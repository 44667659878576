import firebase from "firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var storageRef = firebase.storage().ref();

export const documentHandler = async (file, handler, setImgLoaing) => {
  try {
    setImgLoaing(true);
    let documentRef = storageRef.child("Documents/" + file.name);
    await documentRef.put(file).then(function (snapshot) {
      snapshot.ref.getDownloadURL().then(function (downloadURL) {
        handler(downloadURL);
      });
    });
    setImgLoaing(false);
  } catch (error) {
    console.log(error.message);
  }
};

export const notify = () =>
  toast.info(`🚀 Jobs Save Now`, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const Remove = () =>
  toast.error("🦄 Job Remove easy!", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
