import React, { useState } from "react";
import ExpandButton from "./button";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { useDispatch } from "react-redux";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  successMessage,
  declineLeave,
  approveLeave,
} from "./redux/actions/leaveRequestAction";
import { Chip, CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import useStyles from "./style";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputIcon from "@material-ui/icons/Input";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import StarIcon from "@material-ui/icons/Star";
import { ATTENDANCE_STATUS } from "../attendance/redux/constant/attendanceConstant";
import CenterFocusWeakIcon from "@material-ui/icons/CenterFocusWeak";
import { Shuffle } from "@material-ui/icons";

const Table = ({ data, handleMore }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [copyValue, setCopyValue] = useState("");
  const [copy, setCopy] = useState(false);
  const [loading, setloading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [index, setindex] = useState(null);
  const [approveLoader, setapproveLoader] = useState(false);

  const handleClickOpen = (i) => {
    setindex(i);
    setOpen(true);
  };

  const handleClose = () => {
    setindex(null);
    setOpen(false);
  };

  const handleCopy = (value, fieldName) => {
    setCopyValue(value);
    setCopy(true);
    dispatch(successMessage(`${value} copied.`));
  };

  const states = {
    approved: "approved",
    blocked: "blocked",
    leave: "leave",
    declined: "declined",
  };

  const handleDecline = () => {
    setOpen(false);
    dispatch(declineLeave(data[index], setloading));
  };

  const columns = [
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let email = data[dataIndex]?.email.toLowerCase();
          return (
            <CopyToClipboard
              text={email}
              onCopy={() => handleCopy(email, "email")}
            >
              <span>{email}</span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let name = data[dataIndex]?.name;
          return (
            <CopyToClipboard
              text={name}
              onCopy={() => handleCopy(name, "name")}
            >
              <span>{name}</span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
      },
    },
    {
      name: "start",
      label: "From",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const start = data[dataIndex]?.start;
          const updatedStart = moment(start?.toDate()).format(
            "DD/MM/YYYY hh:mm A",
          );
          return (
            <span style={{ display: "flex", alignItems: "center" }}>
              {updatedStart}
            </span>
          );
        },
      },
    },
    {
      name: "end",
      label: "To",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const end = data[dataIndex]?.end;
          const updatedEnd = moment(end?.toDate()).format("DD/MM/YYYY hh:mm A");
          return (
            <span style={{ display: "flex", alignItems: "center" }}>
              {updatedEnd}
            </span>
          );
        },
      },
    },
    {
      name: "label",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          let status = data[dataIndex]?.label;
          return (
            <Chip
              label={status}
              classes={{
                root: classes[states[status]],
              }}
            />
          );
        },
      },
    },
    {
      name: "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <Tooltip title="Casual Leave">
                <IconButton
                  onClick={() =>
                    handleMore(
                      dataIndex,
                      ATTENDANCE_STATUS?.CASUAL_LEAVE,
                      setapproveLoader,
                    )
                  }
                  disabled={data[dataIndex]?.label === "approved"}
                >
                  <InputIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Half Leave">
                <IconButton
                  onClick={() =>
                    handleMore(
                      dataIndex,
                      ATTENDANCE_STATUS?.HALF_LEAVE,
                      setapproveLoader,
                    )
                  }
                  disabled={data[dataIndex]?.label === "approved"}
                >
                  <Shuffle />
                </IconButton>
              </Tooltip>
              <Tooltip title="Sick Leave">
                <IconButton
                  onClick={() =>
                    handleMore(
                      dataIndex,
                      ATTENDANCE_STATUS?.SICK_LEAVE,
                      setapproveLoader,
                    )
                  }
                  disabled={data[dataIndex]?.label === "approved"}
                >
                  <LocalHospitalIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Annual Leave">
                <IconButton
                  onClick={() =>
                    handleMore(
                      dataIndex,
                      ATTENDANCE_STATUS?.ANNUAL_LEAVE,
                      setapproveLoader,
                    )
                  }
                  disabled={data[dataIndex]?.label === "approved"}
                >
                  <StarIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Paid Leave">
                <IconButton
                  onClick={() =>
                    handleMore(
                      dataIndex,
                      ATTENDANCE_STATUS?.PAID_LEAVE,
                      setapproveLoader,
                    )
                  }
                  disabled={data[dataIndex]?.label === "approved"}
                >
                  <AttachMoneyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="UnPaid Leave">
                <IconButton
                  onClick={() =>
                    handleMore(
                      dataIndex,
                      ATTENDANCE_STATUS?.UNPAID_LEAVE,
                      setapproveLoader,
                    )
                  }
                  disabled={data[dataIndex]?.label === "approved"}
                >
                  <MoneyOffIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Maternity Paternity Leave">
                <IconButton
                  onClick={() =>
                    handleMore(
                      dataIndex,
                      ATTENDANCE_STATUS?.MATERNITY_PATERNITY_LEAVE,
                      setapproveLoader,
                    )
                  }
                  disabled={data[dataIndex]?.label === "approved"}
                >
                  <CenterFocusWeakIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Remote">
                <IconButton
                  onClick={() =>
                    handleMore(
                      dataIndex,
                      ATTENDANCE_STATUS?.REMOTE,
                      setapproveLoader,
                    )
                  }
                  disabled={data[dataIndex]?.label === "approved"}
                >
                  <HomeWorkIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleClickOpen(dataIndex)}
            >
              {loading && dataIndex === index ? (
                <CircularProgress
                  className={classes.circularProgress}
                  size={25}
                />
              ) : (
                "Decline"
              )}
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    searchPlaceholder: "Search here...",
    filter: false,
    searchAlwaysOpen: true,
    responsive: "standard",
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    selectableRows: false, // <===== will turn off checkboxes in rows

    isRowExpandable: (dataIndex, expandedRows) => {
      return true;
    },
    rowsExpanded: [0, 1],
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const desc = data[rowMeta.dataIndex]?.description || '';
      return (
        <TableRow>
          <TableCell
            style={{ paddingLeft: "5%" }}
            align="left"
            colSpan={colSpan}
          >
            Description: {desc}
          </TableCell>
        </TableRow>
      );
    },
  };

  const theme = createTheme({
    overrides: {
      MUIDataTableHeadCell: {
        sortAction: {
          fontSize: "1rem",
          fontWeight: "bolder",
        },
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          visibility: "hidden",
        },
      },
    },
  });

  const components = {
    ExpandButton: function (props) {
      if (props.dataIndex === 3 || props.dataIndex === 4)
        return <div style={{ width: "24px" }} />;
      return <ExpandButton {...props} />;
    },
  };

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          className="mainTable"
          data={data}
          columns={columns}
          options={options}
          components={components}
        />
      </MuiThemeProvider>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure for decline this request?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If you decline the request it will erase from database permanently,
            Please consider it!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDecline} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Table;
