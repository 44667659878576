import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, CircularProgress, IconButton, InputAdornment, InputLabel, Menu, TextField } from "@material-ui/core";
import PageTitle from '../../../components/PageTitle/PageTitle'
import { useParams } from "react-router";
import { useStyles } from "../style";
import { useDispatch, useSelector } from "react-redux";
import { addRank, deleteRank, getRanksbyTitle, indexChange } from "../redux/actions";
import CircularLoader from '../../utils/circularProgress/index'
import UpdateRank from './UpdateRank'
import { Add } from "@material-ui/icons";

export default function PositionRanks() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { type } = useParams()
    const ranksData = useSelector((state) => state.ranksReducer.ranksData)
    const [loading, setLoading] = useState(false)
    const [addLoading, setAddLoading] = useState(false)
    const [rankVal, setRankVal] = useState('')

    useEffect(() => {
        dispatch(getRanksbyTitle(type, setLoading))
    }, [])

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const deleteRankHandler = (rank) => {
        let filteredRanks = ranksData?.ranks.filter((allRank) => allRank.id !== rank.id)
        const data = {
            ...ranksData,
            ranks: filteredRanks,
        }
        dispatch(deleteRank(data))
    }

    const Rank = React.memo(function Rank({ ranks, docId, type }) {
        return ranks?.map((rank, index) => (
            <Draggable
                draggableId={rank.id} index={index} >
                {provided => (
                    <div
                        className={classes.rankDiv}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <h4 className={classes.rankText}>
                            <strong>
                                {`Level ${index + 1}`}
                            </strong>
                        </h4>
                        <h4 className={classes.rankText}>
                            {rank?.rank}
                        </h4>
                        <div>
                            <UpdateRank rank={rank} data={ranksData} />
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => { deleteRankHandler(rank) }}
                            >Delete
                            </Button>
                        </div>
                    </div>
                )}
            </Draggable>
        ));
    });

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const ranks = reorder(
            ranksData?.ranks,
            result.source.index,
            result.destination.index
        );
        const data = {
            ranks,
            type: ranksData?.type,
            docId: ranksData?.docId
        }

        dispatch(indexChange(data))

    }
    const dialogClose = () => {
        setRankVal('')
    }
    const rankAddHandler = () => {
        if (rankVal) {
            dispatch(addRank(rankVal, ranksData, setAddLoading))
        }

        dialogClose()
    }

    return (
        <>
            <PageTitle title={`${type} Ranks`} />

            <div className={classes.fieldDiv}>
                <TextField
                    autoFocus
                    fullWidth
                    multiline
                    label="Add New Rank"
                    variant='outlined'
                    value={rankVal}
                    onChange={(e) => { setRankVal(e.target.value) }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment>
                                {
                                    addLoading ? <CircularProgress size={30} /> :
                                        <IconButton
                                            variant="contained"
                                            color="primary"
                                            onClick={rankAddHandler}
                                        >
                                            <Add />
                                        </IconButton>
                                }
                            </InputAdornment>
                        )
                    }}
                />
            </div>

            {
                loading ? <CircularLoader /> :
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="list">
                            {provided => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}>
                                    <Rank ranks={ranksData?.ranks} docId={ranksData?.docId} type={ranksData?.type} />
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
            }
        </>
    );
}