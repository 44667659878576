import { CURRENT_PROFILE, UPDATE_PROFILE } from "../constant";

const initialState = {
  userProfile: {},
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        userProfile: { ...state?.userProfile, ...action.payload }
      };
    default:
      return state;
  }
};

export default profileReducer;
