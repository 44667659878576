import {
  SUCCESS_ALERT,
  ERROR_ALERT,
  GET_LEAVE_POLICY,
  UPDATE_LEAVE_POLICY,
} from "../constants/leavePolicyConstant";
import { db } from "../../../../config/firebase";
export const updateLeavePolicy = (data, setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    await db.collection("leavePolicy").doc("KVBA6gjODn71xONYvXNR").set(data);
    dispatch({ type: SUCCESS_ALERT, payload: "Leave Policy Updated" });
    dispatch({ type: UPDATE_LEAVE_POLICY, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setLoading(false);
  }
};
export const getLeavePolicy = (setLoading, id) => async (dispatch) => {
  try {
    setLoading(true);
    let doc = await db
      .collection("leavePolicy")
      .doc("KVBA6gjODn71xONYvXNR")
      .get();
    if (doc?.exists) {
      dispatch({ type: GET_LEAVE_POLICY, payload: doc.data() });
    } else {
      dispatch({ type: GET_LEAVE_POLICY, payload: {} });
    }
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setLoading(false);
  }
};
