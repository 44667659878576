import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularLoader from "../utils/circularProgress/index";
import TableComponent from "./table";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import { getPaystubAction } from "./redux/actions/payStubAction";
import { getUserData } from "../../commonRedux/common/action";
import { DatePicker } from "antd";
import moment from "moment";
import usePaystub from "./components/usepayStub";
import useStyle from "./style";

export default function PayStub() {
  const classes = useStyle();
  const users = useSelector((state) => state.commonReducer.allEmployees);
  const [loading, setloading] = useState(false);
  const [userloading, setUserloading] = useState(true);
  const [eventsLoading, setEventsLoading] = useState(false);
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const { exportPaystub, exportTax, paystubs, loadings, handleSendTaxEmail, handleDownloadQuarterlyTax, handleEndMonthChange,handleStartMonthChange, startMonth, endMonth } = usePaystub();

  const fetchPaystubs = async (selectedDate) => {
    setloading(true);
    dispatch(getPaystubAction(setloading, selectedDate));
  };

  useEffect(() => {
    if (paystubs.length < 1 && !loading) {
      fetchPaystubs(new Date());
    }
  }, [users]);

  useEffect(() => {
    if (users.length < 1) {
      dispatch(getUserData(setUserloading));
    } else {
      setUserloading(false);
    }
  }, []);

  const handleDateUpdate = (a) => {
    const selectedDate = moment(a).toDate();
    setDate(selectedDate);
    fetchPaystubs(selectedDate);
  };

  return loading || userloading || eventsLoading ? (
    <CircularLoader />
  ) : (
    <>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item md={2} xs={4}>
          <PageTitle title="Paystub" />
        </Grid>
        {paystubs.length > 0 && (
          <Grid
            item
            md={10}
            xs={8}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 5,
              paddingRight: 5,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => exportPaystub()}
            >
              Download Paystub
            </Button>
            <DatePicker
              allowClear={false}
              picker="month"
              onChange={handleStartMonthChange}
              format="MM/YYYY"
              value={startMonth}
            />
            <DatePicker
              allowClear={false}
              picker="month"
              onChange={handleEndMonthChange}
              format="MM/YYYY"
              value={endMonth}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadQuarterlyTax}
            >
             Download Tax File
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handleSendTaxEmail}
            >
              {
                loadings ? <CircularProgress size={20} color="inherit" /> : "Send Tax Email"
              }
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start" // center
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={6}>
          <DatePicker
            allowClear={false}
            onChange={(a) => handleDateUpdate(a)}
            format="DD/MM/YYYY"
            value={moment(date)}
          />
        </Grid>
        <Grid item xs={12}>
          <TableComponent />
        </Grid>
      </Grid>
    </>
  );
}
