import {
  SUCCESS_ALERT,
  ERROR_ALERT,
  RUN_PAYROLL,
  GET_PAYSTUB,
  UPDATE_PAYSTUB,
  QUARTERLY_TAX,
} from "../constant/index";

const initialState = {
  payStub: [],
  quarterlyTax: [],
  successMessage: "",
  errorMessage: "",
};

const payStubReducer = (state = initialState, action) => {
  switch (action.type) {
    case RUN_PAYROLL:
      return {
        ...state,
        // payStub: action.payload,
      };
    case GET_PAYSTUB:
      // console.log("reducer", action.payload);
      return {
        ...state,
        payStub: action.payload,
      };
    case UPDATE_PAYSTUB:
      let latestPayStub = state.payStub?.map((item) => {
        if (item?.id === action.payload?.docId) {
          return {
            ...item,
            paymentSend: true

          }
        }
          return item
      })
      return {
        ...state,
        payStub: latestPayStub,
      };
    case QUARTERLY_TAX:
      return {
        ...state,
        quarterlyTax: action.payload,
      };
    case SUCCESS_ALERT:
      return {
        ...state,
        successMessage: action.payload,
      };
    case ERROR_ALERT:
      return {
        ...state,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default payStubReducer;
