import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useJobBoard } from "./customHooks/useJobBoard";
import { DragDropContext } from "react-beautiful-dnd";
import Button from "@material-ui/core/Button";

import "./style.css";
import {
  getUpdatedArr,
  sortCategory,
  updateStatusAfterDragnDrop,
} from "./redux/actions/jobsBoardAction";
import { useDispatch } from "react-redux";
import {
  ERROR_ALERT,
  SORT_CARDS,
  SUCCESS_ALERT,
} from "../jobsRequests/redux/constant/index";
import Category from "./components/category";
import { db } from "../../config/firebase";
import {
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Filter, FilterList, Search } from "@material-ui/icons";
import { useRef } from "react";
import { useEffect } from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  AddSearchDiv: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
  },
  searchField: {
    marginTop: "5px",
  },

  categoriesContainer: {
    display: "flex",
    gap: "20px",
  },
}));
const validateCardAction = (result, dispatch) => {
  if (
    result.source.droppableId == "declined" &&
    result.destination.droppableId != "applied"
  ) {
    dispatch({
      type: ERROR_ALERT,
      payload: "Card is only moveable in Applied Board",
    });
    return false;
  }
  if (
    result.source.droppableId == "future" &&
    result.destination.droppableId != "applied" &&
    result.destination.droppableId != "declined"
  ) {
    dispatch({
      type: ERROR_ALERT,
      payload: "Card is only moveable in Applied and Declined Board",
    });
    return false;
  }

  if (
    result.source.droppableId == "interviewed" &&
    result.destination.droppableId != "declined" &&
    result.destination.droppableId != "future"
  ) {
    dispatch({
      type: ERROR_ALERT,
      payload: "Card is only moveable in Declined and Future Board",
    });
    return false;
  }
  if (
    (result.source.droppableId == "onboarding" &&
      result.destination.droppableId != "declined") ||
    (result.source.droppableId == "hired" &&
      result.destination.droppableId != "declined") ||
    ((result.source.droppableId == "interviewReschedule" ||
      result.source.droppableId == "interviewSchedule" ||
      result.source.droppableId == "interviewCancel") &&
      result.destination.droppableId != "declined") ||
    (result.source.droppableId == "applied" &&
      result.destination.droppableId != "declined")
  ) {
    dispatch({
      type: ERROR_ALERT,
      payload: "Card is only moveable in Declined  Board",
    });
    return false;
  }
  return true;
};

export default function JobRequest() {
  const [open, setOpen] = useState(false);
  const [selectedJobData, setSelectedJobData] = useState({});

  const {
    filteredSearch,
    appliedJobs,
    declinedJobs,
    futureJobs,
    interviewScheduleJobs,
    interviewRescheduleJobs,
    interviewedJobs,
    interviewCancelJobs,
    hiredJobs,
    loading,
    setloading,
    onBoardJobs,
    searchValue,
    onChangeHandler,
    searchHandler,
    foucsHandler,
    allJobs,
    setJobSearchType,
    jobSearchType,
  } = useJobBoard();
  const dispatch = useDispatch();

  function getJobType(droppableId) {
    let arr;
    let jobType;
    switch (droppableId) {
      case "applied":
        arr = [...appliedJobs];
        jobType = "appliedJobs";
        break;
      case "declined":
        arr = [...declinedJobs];
        jobType = "declinedJobs";
        break;
      case "future":
        arr = [...futureJobs];
        jobType = "futureJobs";
        break;
      case "interviewSchedule":
        arr = [...interviewScheduleJobs];
        jobType = "interviewScheduleJobs";
        break;
      case "interviewReschedule":
        arr = [...interviewRescheduleJobs];
        jobType = "interviewRescheduleJobs";
        break;
      case "interviewed":
        arr = [...interviewedJobs];
        jobType = "interviewedJobs";
        break;
      case "interviewCancel":
        arr = [...interviewCancelJobs];
        jobType = "interviewCancelJobs";
        break;
      case "hired":
        arr = [...hiredJobs];
        jobType = "hiredJobs";
        break;
      case "boardingInvitation":
        arr = [...onBoardJobs];
        jobType = "onBoardJobs";
        break;
      default:
        break;
    }
    return {
      arr,
      jobType,
    };
  }
  const scrollRefDiv = useRef();

  const onDragEnd = async (result) => {
    if (!result?.destination) {
      return;
    }
    if (result.source.droppableId == result.destination.droppableId) {
      const { arr, jobType } = getJobType(result.source.droppableId);
      console.log(arr, "type array", jobType);
      dispatch(sortCategory(result, jobType, arr));
      return;
    }
    if (validateCardAction(result, dispatch)) {
      dispatch(updateStatusAfterDragnDrop(result, getJobType));
    }
  };
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const sortedAppliedJobs = appliedJobs.sort((a, b) => {
    return new Date(b?.createdAt) - new Date(a?.createdAt);
  });
  const categories = [
    {
      id: "applied",
      title: "Applied",
      data: appliedJobs,
    },
    // can be use later 
    // {
    //   id: "declined",
    //   title: "Declined",
    //   data: declinedJobs,
    // },
    {
      id: "future",
      title: "Future",
      data: futureJobs,
    },
    {
      id: "interviewSchedule",
      title: "Interview Schedule",
      data: interviewScheduleJobs,
    },
    {
      id: "interviewReschedule",
      title: "Interview ReSchedule",
      data: interviewRescheduleJobs,
    },
    {
      id: "interviewed",
      title: "Interviewed",
      data: interviewedJobs,
    },
    {
      id: "interviewCancel",
      title: "Interview Cancel",
      data: interviewCancelJobs,
    },
    //can be use later 
    // {
    //   id: "hired",
    //   title: "Hired",
    //   data: hiredJobs,
    // },
    {
      id: "boardingInvitation",
      title: "Onboarding",
      data: onBoardJobs,
    },
  ];
  const [showInp, setShowInp] = React.useState(null);

  const handleClick = (event) => {
    setShowInp(event.currentTarget);
  };

  const handleClose = (filterType) => {
    setJobSearchType(filterType);
    setShowInp(false);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          borderRadius: "5px",
          width: "fit-content",
          justifyContent: "center",
          gap: "20px",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: "5px",
            padding: "5px 15px",
          }}
        >
          <input
            autoFocus
            placeholder="Search..."
            value={searchValue}
            onFocus={foucsHandler}
            onChange={(e) => {
              onChangeHandler(e.target.value);
            }}
            style={{ border: "none" }}
          />
          <Search />
        </div>

        <Select
          value={jobSearchType}
          native
          onChange={(e) => {
            setJobSearchType(e.target.value);
          }}
        >
          <option value="">All</option>
          {allJobs.map((item, index) => (
            <option key={item + index} value={item?.jobTitle}>
              {item.jobTitle}
            </option>
          ))}
        </Select>
      </div>
      {loading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          ref={scrollRefDiv}
          style={{
            overflow: "auto",
            paddingBottom: "25px",
          }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <div className={classes.categoriesContainer}>
              {categories.map((category) => (
                <Category
                  key={category.id}
                  selectedJobData={selectedJobData}
                  setSelectedJobData={setSelectedJobData}
                  setOpen={setOpen}
                  id={category.id}
                  title={category.title}
                  items={
                    searchValue === "" && jobSearchType == ""
                      ? category.data || []
                      : filteredSearch.filter(
                          (item) => item.status === category.id,
                        ) || []
                  }
                />
              ))}
            </div>
          </DragDropContext>
        </div>
      )}
    </>
  );
}
