import {
  GET_LETTERS,
  SUCCESS_ALERT,
  ERROR_ALERT,
  ADD_LETTER,
} from "../constants/lettersConstant";
import { db } from "../../../../config/firebase";
import axiosTechloset from "../../../../config/axiosTechloset";
import gernateId from "../../../../commonUtils/gernateId";
export const getLetters = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    const res = await db.collection("letters").get();
    let letters = [];
    res.forEach((doc) => {
      letters.push({ ...doc.data(), docId: doc.id });
    });

    dispatch({ type: GET_LETTERS, payload: letters });
    setloading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setloading(false);
  }
};

export const successMessage = (message) => async (dispatch) => {
  try {
    dispatch({ type: SUCCESS_ALERT, payload: message });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};

export const errorMessage = (message) => async (dispatch) => {
  try {
    dispatch({ type: ERROR_ALERT, payload: message });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};
export const sendLetter =
  (payload, setLoading, index, setActiveIndex) => async (dispatch) => {
    try {
      setActiveIndex(index);
      setLoading(true);
      await axiosTechloset({
        method: "POST",
        url: `/emails/${payload?.docType}`,
        data: { ...payload },
      });

      dispatch(successMessage("Letter Delivered"));
    } catch (error) {
      dispatch(errorMessage(error));
    } finally {
      setLoading(false);
      setActiveIndex("");
    }
  };
export const addLetter = (setLoading, payload, toggle) => async (dispatch) => {
  try {
    setLoading(true);
    const docId = await gernateId();
    await db.collection("letters").doc(docId).set(payload);
    dispatch({ type: ADD_LETTER, payload: { ...payload, docId } });
    setLoading(false);
    if (toggle) {
      toggle();
    }
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setLoading(false);
  }
};
