import moment from "moment";

export const        recursionDates = (item) => {
  let weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var getDay = weekdays[item.start.getDay()];
  var day = moment(item.start).startOf("month").day(getDay);
  let recDates = [];
  if (day.date() > 7) day.add(7, "d");
  var month = day.month();
  while (month === day.month()) {
    recDates.push({
      ...item,
      start: moment(day).toDate(),
      end: moment(day).toDate(),
    });
    day.add(7, "d");
  }
  // console.log("recDates", recDates);
  return recDates;
};
