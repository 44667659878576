import {
  HANDLE_SIDEBAR,
  HANDLE_SELECTED_INTERVIEW,
  HANDLE_INTERVIEW_DETAIL_SIDEBAR,
  UPDATE_SELECTED_INTERVIEW,
} from "../constants/constant";
const initialState = {
  sidebar: false,
  selectedInterview: null,
};

const interviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_SIDEBAR:
      return {
        ...state,
        sidebar: action.status,
      };
    case HANDLE_INTERVIEW_DETAIL_SIDEBAR:
      return {
        ...state,
        detailSidebar: action.status,
      };
    case HANDLE_SELECTED_INTERVIEW:
      return {
        ...state,
        selectedInterview: action.event,
      };
    case UPDATE_SELECTED_INTERVIEW:
      return {
        ...state,
        selectedInterview: action.event,
      };
    default:
      return state;
  }
};

export default interviewReducer;
