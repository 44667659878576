import React from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { useStyles } from "./ManualOnboardStyle.js.js";
import UseJobApplication from "./useManualOnboard.js.js";
import { ToastContainer } from "react-toastify";
import { Button } from "reactstrap";
import { Plus } from "react-feather";

export default function AddJobApplication() {
  const classes = useStyles();
  const {
    open,
    allJobs,
    loading,
    data,
    handleClickOpen,
    handleClose,
    submitHandle,
    onChangeHandler,
    jobChangeHandler,
  } = UseJobApplication();

  return (
    <div>
      {loading ? (
        <div className={classes.dialogLoader}>
          <CircularProgress />
        </div>
      ) : (
        <Button.Ripple
          color="primary"
          onClick={handleClickOpen}
          className={classes.addButton}
        >
          <Plus size={15} />{" "}
          <span className="align-middle">Manual OnBoard Invite</span>
        </Button.Ripple>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>Manual OnBoard</DialogTitle>
        <DialogContent>
          <InputLabel for="name">Name</InputLabel>
          <TextField
            autoFocus
            name="name"
            className={classes.inputField}
            id="name"
            type="username"
            fullWidth
            value={data?.name || ""}
            onChange={onChangeHandler}
          />
          <InputLabel for="email">Email</InputLabel>
          <TextField
            className={classes.inputField}
            id="email"
            name="email"
            type="email"
            fullWidth
            value={data?.email || ""}
            onChange={onChangeHandler}
          />
          <InputLabel for="applied">OnBoard For</InputLabel>
          <Select
            id="applied"
            className={classes.inputField}
            fullWidth
            name="jobId"
            value={data?.jobId || ""}
            onChange={jobChangeHandler}
          >
            {allJobs.map((jobs, index) => (
              <MenuItem key={jobs + index} value={jobs.id}>
                {jobs.jobTitle}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={submitHandle} color="primary">
            Invite
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
