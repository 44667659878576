import {
  GET_ALL_EMPLOYEES,
  GET_ALL_BOOTCAMP_TEAM,
  GET_ALL_ONBOARDING,
  GET_ALL_BOOTCAMP_ONBOARDING,
  CURRENT_EMPLOYEE,
  CURRENT_ONBOARDING,
  SUCCESS_ALERT,
  ERROR_ALERT,
  GET_BLOCK_EMPLOYEE,
  GET_BLOCK_BOOTCAMP_TEAM,
  GET_DECLIEND_ONBOARDING,
  GET_DECLIEND_BOOTCAMP_ONBOARDING,
  GET_ALL_TEAM,
  ALL_TEAM,
  GET_MAINSITE_TEAM,
  UPDATE_MAINSITE_TEAM,
  ALL_EMPLOYEE,
} from "../constant";

const initialState = {
  allEmployees: [],
  allOnboarding: [],
  allBootcampTeam: [],
  allBootcampOnboarding: [],
  currentOnboarding: {},
  currentEmployee: {},
  successMessage: "",
  errorMessage: "",
  blockEmployee: [],
  decliendOnboarding: [],
  blockBootcampTeam: [],
  decliendBootcampOnboarding: [],
  allTeam: [],
  mainsiteTeam: [],
  allUsers: [],
};

const employeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EMPLOYEES:
      return {
        ...state,
        allEmployees: action.payload,
      };
    case GET_ALL_BOOTCAMP_TEAM:
      return {
        ...state,
        allBootcampTeam: action.payload,
      };
    case GET_ALL_BOOTCAMP_ONBOARDING:
      return {
        ...state,
        allBootcampOnboarding: action.payload,
      };
    case ALL_TEAM:
      return {
        ...state,
        allTeam: action.payload,
      };
    case GET_ALL_TEAM:
      return {
        ...state,
        allTeam: action.payload,
      };

    case GET_ALL_ONBOARDING:
      return {
        ...state,
        allOnboarding: action.payload,
      };
    case CURRENT_EMPLOYEE:
      return {
        ...state,
        currentEmployee: state.allEmployees.filter(
          (d) => d.id === action.payload,
        )[0],
      };
    case GET_BLOCK_EMPLOYEE:
      return {
        ...state,
        blockEmployee: action.payload,
      };
    case GET_DECLIEND_ONBOARDING:
      return {
        ...state,
        decliendOnboarding: action.payload,
      };
    case GET_BLOCK_BOOTCAMP_TEAM:
      return {
        ...state,
        blockBootcampTeam: action.payload,
      };
    case GET_DECLIEND_BOOTCAMP_ONBOARDING:
      return {
        ...state,
        decliendBootcampOnboarding: action.payload,
      };
    case CURRENT_ONBOARDING:
      return {
        ...state,
        currentOnboarding: state.allOnboarding.filter(
          (d) => d.id === action.payload,
        )[0],
      };
    case SUCCESS_ALERT:
      return {
        ...state,
        successMessage: action.payload,
      };
    case ERROR_ALERT:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case GET_MAINSITE_TEAM:
      return {
        ...state,
        mainsiteTeam: action.payload,
      };
    case ALL_EMPLOYEE:
      return {
        ...state,
        allUsers: action.payload,
      };
    case UPDATE_MAINSITE_TEAM:
      let team = state?.mainsiteTeam.map((item) => {
        if (item?.id === action?.payload?.id) {
          return action.payload;
        } else {
          return item;
        }
      });
      return {
        ...state,
        mainsiteTeam: team,
      };

    default:
      return state;
  }
};

export default employeesReducer;
