import {
  ERROR_ALERT,
  GET_ATTENDANCE_REPORT,
  RESET_ATTENDANCE_REPORT,
} from "../constants/reportsConstant";
import { db } from "../../../../config/firebase";
import moment from "moment";
import {
  ATTENDANCE_STATUS,
  ATTENDANCE_STATUS_OPTIONS,
} from "../../../attendance/redux/constant/attendanceConstant";
const countSundays = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  var sundays = 0;
  for (var date = start; date <= end; date.setDate(date.getDate() + 1)) {
    if (date.getDay() === 0) {
      sundays++;
    }
  }
  return sundays;
};
const countDays = (startDate, endDate, format = "YYYY-MM-DD") => {
  const start = moment(startDate, format);
  const end = moment(endDate, format);
  return end.diff(start, "days") + 1;
};
let graphData = [];
const countTotal = (data, name) => {
  let count = 0;
  if (data && data.length > 0) {
    count = data.reduce(
      (accumulator, item) => accumulator + item?.daysCount || 0,
      0,
    );
  }
  graphData.push({ count, name });
};
export const getAttendanceReport = (setLoading, params) => async (dispatch) => {
  try {
    setLoading(true);
    const { startDate, endDate, userId, fullName } = params;
    const weekEndCount = countSundays(startDate, endDate);
    const days = countDays(startDate, endDate);

    let start = new Date(startDate);
    start = new Date(start.setHours(0, 0, 0, 0));
    let end = new Date(endDate);
    end = new Date(end.setHours(23, 59, 59, 999));
    let attendanceQuery = await db
      .collection("Attendance")
      .where("userId", "==", userId)
      .where("start", ">=", start)
      .where("start", "<=", end)
      .get();

    const eventQuery = await db
      .collection("Events")
      .where("start", ">=", start)
      .where("start", "<=", end)
      .where("label", "==", "dayOff")
      .get();
    let attendance = [];
    attendanceQuery.docs.map((doc) => {
      let data = doc.data();
      const id = doc.id;
      let leave = {
        ...data,
        id,
      };
      attendance.push(leave);
    });
    const allEvents = [];
    eventQuery.docs.map((doc) => {
      let data = doc.data();
      const id = doc.id;
      let leave = {
        ...data,
        id,
      };
      allEvents.push(leave);
    });
    const leaves = attendance.reduce((acc, attendanceObj) => {
      let obj = attendanceObj;
      const type = attendanceObj.label;
      if (!acc[type]) {
        acc[type] = [];
      }
      if (obj?.start?.seconds) {
        let start = obj?.start?.toDate();
        let end = obj?.end?.toDate();
        const title =
          ATTENDANCE_STATUS_OPTIONS.find((item) => item.value === obj?.title)
            ?.title || obj?.title;
        const label =
          ATTENDANCE_STATUS_OPTIONS.find((item) => item.value === obj?.label)
            ?.title || obj?.label;
        start = moment(start)?.format("DD/MM/YYYY");
        end = moment(end)?.format("DD/MM/YYYY");
        obj["start"] = start;
        obj["end"] = end;
        let totalCount = 0;
        if (start === end) {
          totalCount = 1;
        } else {
          totalCount = countDays(start, end, "DD/MM/YYYY");
        }
        obj["daysCount"] = totalCount;
        obj["label"] = label;
        obj["title"] = title;
      }

      acc[type].push(obj);
      return acc;
    }, {});
    const {
      [ATTENDANCE_STATUS.PAID_LEAVE]: paidLeaves = [],
      [ATTENDANCE_STATUS.ABSENT]: absentLeaves = [],
      [ATTENDANCE_STATUS.LATE]: lateLeaves = [],
      [ATTENDANCE_STATUS.CASUAL_LEAVE]: casualLeaves = [],
      [ATTENDANCE_STATUS.SICK_LEAVE]: sickLeaves = [],
      [ATTENDANCE_STATUS.ANNUAL_LEAVE]: annualLeaves = [],
      [ATTENDANCE_STATUS.MATERNITY_PATERNITY_LEAVE]:
        maternityPaternityLeaves = [],
      [ATTENDANCE_STATUS.UNPAID_LEAVE]: unpaidLeaves = [],
      [ATTENDANCE_STATUS.HALF_LEAVE]: halfLeaves = [],
    } = leaves || {};
    const events = [];
    allEvents.map((obj) => {
      if (obj?.start?.seconds) {
        let start = obj?.start?.toDate();
        let end = obj?.end?.toDate();
        const title =
          ATTENDANCE_STATUS_OPTIONS.find((item) => item.value === obj?.title)
            ?.title || obj?.title;
        const label =
          ATTENDANCE_STATUS_OPTIONS.find((item) => item.value === obj?.label)
            ?.title || obj?.label;
        start = moment(start)?.format("DD/MM/YYYY");
        end = moment(end)?.format("DD/MM/YYYY");
        obj["start"] = start;
        obj["end"] = end;
        let totalCount = 0;
        if (start === end) {
          totalCount = 1;
        } else {
          totalCount = countDays(start, end, "DD/MM/YYYY");
        }
        obj["daysCount"] = totalCount;
        const event = { ...obj, title, label };
        events.push(event);
      }
    });
    let data = [
      ...paidLeaves,
      ...absentLeaves,
      ...lateLeaves,
      ...casualLeaves,
      ...sickLeaves,
      ...annualLeaves,
      ...maternityPaternityLeaves,
      ...unpaidLeaves,
      ...halfLeaves,
      ...events,
    ];
    countTotal(absentLeaves, "Absent Leaves");
    countTotal(lateLeaves, "Late");
    countTotal(casualLeaves, "Casual Leaves");
    countTotal(sickLeaves, "Sick Leaves");
    countTotal(paidLeaves, "Paid Leaves");
    countTotal(annualLeaves, "Annual Leaves");
    countTotal(unpaidLeaves, "UnPaid Leaves");
    countTotal(maternityPaternityLeaves, "Maternity Paternity Leaves");
    countTotal(halfLeaves, "Half Leaves");
    countTotal(events, "Day Off");
    const daysOffSum = graphData.reduce(
      (accumulator, item) => accumulator + item?.count || 0,
      0,
    );
    const workingDays = days - daysOffSum;
    data = data.sort(function (a, b) {
      return b.start - a.start;
    });
    // graphData.push({ name: "Sundays", count: weekEndCount });
    graphData.push({ name: "Working days", count: workingDays });
    const details = {
      workingDays,
      weekEndCount,
      data,
      title: `Attendance Report Of ${fullName} From ${moment(start)?.format(
        "DD/MM/YYYY",
      )} to ${moment(end)?.format("DD/MM/YYYY")}`,
      graphData,
      filterParams: params,
    };

    dispatch({ type: GET_ATTENDANCE_REPORT, payload: details });
    graphData = [];
  } catch (error) {
    console.error(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setLoading(false);
  }
};
export const ResetAttendanceReport = () => async (dispatch) => {
  try {
    dispatch({ type: RESET_ATTENDANCE_REPORT, payload: [] });
  } catch (error) {
    console.error(error);
  }
};
