import React from "react";
import { X, Tag } from "react-feather";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import "./css/flatpickr.scss";
import { TextareaAutosize } from "@material-ui/core";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import { DeleteForeverOutlined } from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
// import { Day } from "react-big-calendar";

const eventColors = {
  approved: "chip-success", // leave approved
  generalLeave: "chip-warning", // absent
  leave: "chip-danger", // leave request
  general: "chip-primary", // general
  dayOff: "chip-primary",
  paidLeave: "chip-primary",
};

class AddEvent extends React.Component {
  state = {
    startDate: new Date(),
    endDate: new Date(),
    title: "",
    label: null,
    // allDay: true,
    // selectable: true,
    description: "",
    id: "",
    recursion: false,
  };
  handleDateChange = (date) => {
    this.setState({
      startDate: date[0],
    });
  };

  handleEndDateChange = (date) => {
    this.setState({
      endDate: date[0],
    });
  };

  handleEndDateClose = (date) => {
    this.setState({
      endDate: date,
    });
  };
  handleStartDateClose = (date) => {
    this.setState({
      startDate: date,
    });
  };

  handleLabelChange = (label) => {
    // console.log("handle", label);
    this.setState({
      label,
    });
  };
  validate = () => {
    const start = this.state.startDate;
    const end = this.state.endDate;
    const today = new Date();
    if (moment(start).isAfter(end, "day")) {
      this.props.errorMessage("Start date should before end date");
    } else if (moment(start).isBefore(moment(today), "day")) {
      this.props.errorMessage("start date should not past date");
    } else if (moment(end).isBefore(moment(today), "day")) {
      this.props.errorMessage("End date should not past date.");
    } else return true;
    // if (moment(end).isSameOrAfter(moment(today), 'day')) {
    //   console.log('end date should not past date.');
    // }
  };

  handleAddEvent = (id) => {
    this.props.handleSidebar(false);
    // if (this.validate()) {
    this.props.addEvent({
      id: id,
      title: this.state.title,
      start: this.state.startDate,
      end: this.state.endDate,
      label: this.state.label === null ? "general" : this.state.label,
      // allDay: this.state.allDay,
      // selectable: true,
      description: this.state.description,
      recursion: this.state.recursion === null ? false : this.state.recursion,
    });
    this.setState({
      startDate: new Date(),
      endDate: new Date(),
      title: "",
      label: null,
      // allDay: true,
      // selectable: true,
      description: "",
      id,
      recursion: false,
    });
    // }
  };

  handleAddToCalendar = () => {
    let start = moment(this.state.startDate).format("YYYYMMDDTHHmmss") + "Z";
    let end = moment(this.state.endDate).format("YYYYMMDDTHHmmss") + "Z";
    const link = `http://www.google.com/calendar/render?action=TEMPLATE&text=${this.state.title}&dates=${start}/${end}&details=${this.state.description}&location=pakistan`;
    window.open(link, "_blank");
  };
  handleRemove = () => {
    this.props.handleSidebar(false);
    this.props.handleRemoveEvent(this.state.id);
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log("nextProps", nextProps.eventInfo);
    this.setState({
      title: nextProps.eventInfo === null ? "" : nextProps.eventInfo.title,
      url: nextProps.eventInfo === null ? "" : nextProps.eventInfo.url,
      startDate:
        nextProps.eventInfo === null
          ? new Date()
          : new Date(nextProps.eventInfo.start),
      endDate:
        nextProps.eventInfo === null
          ? new Date()
          : new Date(nextProps.eventInfo.end),
      label: nextProps.eventInfo === null ? null : nextProps.eventInfo.label,
      recursion:
        nextProps.eventInfo === null ? false : nextProps.eventInfo.recursion,
      // allDay: nextProps.eventInfo === null ? true : nextProps.eventInfo.allDay,
      // selectable:
      //   nextProps.eventInfo === null || undefined
      //     ? true
      //     : nextProps.eventInfo.selectable,
      description:
        nextProps.eventInfo === null ? "" : nextProps.eventInfo.description,
      id: nextProps.eventInfo === null ? "" : nextProps.eventInfo.id,
    });
  }

  render() {
    // console.log("start", this.state.startDate);
    // console.log("end", this.state.endDate);
    let newEventId = Math.floor(Math.random() * 101);
    // console.log("state", this.state);
    return (
      <>
      <div
        className={`add-event-sidebar ${
          this.props.sidebar ? "show" : "hidden"
        }`}
      >
        <div className="header d-flex justify-content-between">
          <h3 className="text-bold-600 mb-0">
            {(() => {
              // console.log("label", this.props.eventInfo?.label);
              switch (this.props.eventInfo?.label) {
                case "dayOff":
                  return this.props.eventInfo !== null &&
                    this.props.eventInfo.title.length > 0
                    ? "Update Event"
                    : "Add Event";
                case "general":
                  return this.props.eventInfo !== null &&
                    this.props.eventInfo.title.length > 0
                    ? "Update Event"
                    : "Add Event";
                case "leave":
                  return "Update Leave";
                case "generalLeave":
                  return "Update Leave";
                case "paidLeave":
                  return "Update Leave";
                case "declined":
                  return "Update Leave";
                case "approved":
                  return "Update Leave";
                // case "dayOff":
                //   return "Add Event";
                default:
                  return null;
              }
            })()}
          </h3>
          <div
            className="close-icon cursor-pointer"
            onClick={() => this.props.handleSidebar(false)}
          >
            <X size={20} />
          </div>
        </div>
        <div className="add-event-body">
          <div className="category-action d-flex justify-content-between my-50">
            <div className="event-category">
              {this.state.label !== null ? (
                <div className={`chip ${eventColors[this.state.label]}`}>
                  <div className="chip-body">
                    <div className="chip-text text-capitalize">
                      {this.state.label === "generalLeave"
                        ? "Absent"
                        : this.state.label}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="category-dropdown">
              <UncontrolledDropdown>
                <DropdownToggle tag="div" className="cursor-pointer">
                  <Tag size={18} />
                </DropdownToggle>
                {(() => {
                  switch (this.state?.label) {
                    case "paidLeave":
                      return (
                        <DropdownMenu tag="ul" right>
                          <DropdownItem
                            tag="li"
                            onClick={() => this.handleLabelChange("general")}
                          >
                            <span className="bullet bullet-primary bullet-sm mr-50"></span>
                            <span>Paid Leave</span>
                          </DropdownItem>
                        </DropdownMenu>
                      );
                    case "general":
                      console.log("case general");

                      return (
                        <DropdownMenu tag="ul" right>
                          <DropdownItem
                            tag="li"
                            onClick={() => this.handleLabelChange("dayOff")}
                          >
                            <span className="bullet bullet-primary bullet-sm mr-50"></span>
                            <span>Day Off</span>
                          </DropdownItem>
                        </DropdownMenu>
                      );
                    case "dayOff":
                      console.log("case dayoff");
                      return (
                        <DropdownMenu tag="ul" right>
                          <DropdownItem
                            tag="li"
                            onClick={() => this.handleLabelChange("general")}
                          >
                            <span className="bullet bullet-primary bullet-sm mr-50"></span>
                            <span>General</span>
                          </DropdownItem>
                        </DropdownMenu>
                      );
                    case "leave":
                      console.log("case leave");
                      return (
                        <DropdownMenu tag="ul" right>
                          <DropdownItem
                            tag="li"
                            onClick={() => this.handleLabelChange("approved")}
                          >
                            <span className="bullet bullet-success bullet-sm mr-50"></span>
                            <span>Approved</span>
                          </DropdownItem>
                          <DropdownItem
                            tag="li"
                            onClick={() => this.handleLabelChange("declined")}
                          >
                            <span className="bullet bullet-warning bullet-sm mr-50"></span>
                            <span>Declined</span>
                          </DropdownItem>
                        </DropdownMenu>
                      );
                    case "generalLeave":
                      console.log("case generalLeave");
                      return (
                        <DropdownMenu tag="ul" right>
                          <DropdownItem
                            tag="li"
                            onClick={() => this.handleLabelChange("paidLeave")}
                          >
                            <span className="bullet bullet-success bullet-sm mr-50"></span>
                            <span>PaidLeave</span>
                          </DropdownItem>
                          {/* <DropdownItem
                            tag="li"
                            onClick={() => this.handleLabelChange("declined")}
                          >
                            <span className="bullet bullet-warning bullet-sm mr-50"></span>
                            <span>Declined</span>
                          </DropdownItem> */}
                        </DropdownMenu>
                      );
                    case "declined":
                      // console.log("case declined");
                      return (
                        <DropdownMenu tag="ul" right>
                          <DropdownItem
                            tag="li"
                            onClick={() => this.handleLabelChange("approved")}
                          >
                            <span className="bullet bullet-success bullet-sm mr-50"></span>
                            <span>Approved</span>
                          </DropdownItem>
                        </DropdownMenu>
                      );
                    case "approved":
                      console.log("case approved");
                      return (
                        <DropdownMenu tag="ul" right>
                          <DropdownItem
                            tag="li"
                            onClick={() => this.handleLabelChange("declined")}
                          >
                            <span className="bullet bullet-warning bullet-sm mr-50"></span>
                            <span>Declined</span>
                          </DropdownItem>
                        </DropdownMenu>
                      );
                    default:
                      console.log("case default");
                      return (
                        <DropdownMenu tag="ul" right>
                          <DropdownItem
                            tag="li"
                            onClick={() => this.handleLabelChange("general")}
                          >
                            <span className="bullet bullet-primary bullet-sm mr-50"></span>
                            <span>General</span>
                          </DropdownItem>
                          <DropdownItem
                            tag="li"
                            onClick={() => this.handleLabelChange("dayOff")}
                          >
                            <span className="bullet bullet-primary bullet-sm mr-50"></span>
                            <span>DayOff</span>
                          </DropdownItem>
                        </DropdownMenu>
                      );
                  }
                })()}
              </UncontrolledDropdown>
            </div>
          </div>
          <div className="add-event-fields mt-2">
            <FormGroup className="form-label-group">
              <Input
                type="text"
                id="EventTitle"
                placeholder="Event Title"
                value={this.state.title}
                onChange={(e) => this.setState({ title: e.target.value })}
              />
              <Label for="EventTitle">Event Title</Label>
            </FormGroup>
            <FormGroup className="form-label-group">
              <TextareaAutosize
                style={{ width: "100%" }}
                rowsMax={4}
                aria-label="maximum height"
                id="EventTitle"
                placeholder="Event Description"
                value={this.state.description}
                onChange={(e) => this.setState({ description: e.target.value })}
              />
              <Label for="EventTitle">Event Description</Label>
            </FormGroup>
            <FormGroup>
              <Label for="startDate">Start Date</Label>
              <Flatpickr
                id="startDate"
                className="form-control"
                value={this.state.startDate}
                onChange={(date) => this.handleDateChange(date)}
                onClose={(date) => this.handleStartDateClose(date[0])}
                options={{
                  altInput: true,
                  altFormat: "F j, Y",
                  dateFormat: "Y-m-d",
                  enableTime: true,
                  hourIncrement: 1,
                  minuteIncrement: 5,
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="endDate">End Date</Label>
              <Flatpickr
                // mode="range"
                id="endDate"
                className="form-control"
                value={this.state.endDate}
                onChange={(date) => this.handleEndDateChange(date)}
                onClose={(date) => this.handleEndDateClose(date[0])}
                options={{
                  altInput: true,
                  altFormat: "F j, Y",
                  dateFormat: "Y-m-d",
                  enableTime: true,
                  hourIncrement: 1,
                  minuteIncrement: 5,
                }}
              />
            </FormGroup>
          </div>
          {this.state.label === "dayOff" ? (
            <div className="add-event-fields mt-2">
              <Checkbox
                color="primary"
                size="small"
                value={true}
                defaultChecked={this.state.recursion}
                onChange={(e) => this.setState({ recursion: e.target.checked })}
              />{" "}
              <b>Recusrion</b>
            </div>
          ) : null}

          {/* <hr className="my-2" /> */}
          <div className="add-event-actions text-right">
            {this.props?.eventInfo?.id ? (
              <DeleteForeverOutlined
                style={{ color: "red" }}
                className="ml-1"
                disabled={this.state.title.length > 0 ? false : true}
                fontSize="large"
                onClick={() => {
                  this.props.handleSidebar(false);
                  this.handleRemove();
                }}
              />
            ) : null}
            {this.state.title !== "" ? (
              <CalendarTodayOutlinedIcon
                style={{ color: "#7368F0" }}
                className="ml-1"
                disabled={this.state.title.length > 0 ? false : true}
                fontSize="large"
                onClick={() => {
                  this.props.handleSidebar(false);
                  this.handleAddToCalendar();
                }}
              />
            ) : null}
            {/* {console.log("state", this.state)} */}
            {/* {console.log("1", this.props?.eventInfo === null)}
            {console.log("2", this.state.label === null)}
            {console.log("3", this.props?.eventInfo?.label === "dayOff")}
            {console.log("4", this.props?.eventInfo?.label !== "leave")}
            {console.log("5", this.props?.eventInfo?.label !== "paidLeave")}
            {console.log("6", this.props?.eventInfo?.label !== "general")} */}
            {this.props?.eventInfo === null ||
            this.state.label === null ||
            this.props?.eventInfo?.label === "dayOff" ||
            this.props?.eventInfo?.label === "general" ||
            this.state?.label === "dayOff" ||
            this.state?.label === "general" ? (
              //    &&
              // (this.props?.eventInfo?.label !== "leave" ||
              //   this.props?.eventInfo?.label !== "paidLeave")
              <Button.Ripple
                variant="outlined"
                className="ml-1"
                color="primary"
                disabled={this.state.title.length > 0 ? false : true}
                onClick={() => {
                  this.props.handleSidebar(false);
                  if (
                    this.props.eventInfo === null ||
                    this.props.eventInfo.title.length <= 0
                  )
                    this.handleAddEvent(newEventId);
                  else
                    this.props.updateEvent({
                      id: this.props.eventInfo.id,
                      title: this.state.title,
                      label: this.state.label,
                      start: this.state.startDate,
                      end: this.state.endDate,
                      // allDay: true,
                      // selectable: true,
                      description: this.state.description,
                      recursion: this.state.recursion,
                    });
                }}
              >
                {this.props.eventInfo !== null &&
                this.props.eventInfo.title.length > 0
                  ? "Update Event"
                  : "Add Event"}
              </Button.Ripple>
            ) : (
              <Button.Ripple
                variant="outlined"
                className="ml-1"
                color="primary"
                disabled={this.state.title.length > 0 ? false : true}
                onClick={() => {
                  this.props.handleSidebar(false);
                  if (
                    this.props.eventInfo === null ||
                    this.props.eventInfo.title.length <= 0
                  )
                    this.handleAddEvent(newEventId);
                  else
                    this.props.updateEvent({
                      id: this.props.eventInfo.id,
                      title: this.state.title,
                      label: this.state.label,
                      start: this.state.startDate,
                      end: this.state.endDate,
                      // allDay: true,
                      // selectable: true,
                      description: this.state.description,
                    });
                }}
              >
                {this.props.eventInfo !== null &&
                this.props.eventInfo.title.length > 0
                  ? "Update Leave"
                  : "Add Leave"}
              </Button.Ripple>
            )}
            <Button.Ripple
              variant="outlined"
              color="primary"
              className="ml-1"
              onClick={() => {
                this.props.handleSidebar(false);
                if (this.props.handleSelectedEvent)
                  this.props.handleSelectedEvent(null);
                else return null;
              }}
            >
              Cancel
            </Button.Ripple>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default AddEvent;
