import { Checkbox, FormControlLabel, Menu, MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToInterview, removeFromInterview } from "../jobsRequests/redux/actions/jobsRequestsAction";
import useStyles from './style'

function Filters() {
  const calenderInterviews = useSelector((state) => state.jobsRequestsReducer.calenderInterviews);
  const dispatch = useDispatch();
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null);
  const [interviewDropdown, setInterviewDropdown] = useState({
    schedule: true,
    reSchedule: true,
    cancel: false,
    interviewed: false
  })
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSchedule = () => {
    setInterviewDropdown({
      ...interviewDropdown,
      schedule: !interviewDropdown.schedule
    })
    if (!interviewDropdown.schedule) {
      dispatch(addToInterview("interviewSchedule"))
    } else {
      dispatch(removeFromInterview("interviewSchedule"))
    }
  }
  const handleReschedule = () => {
    setInterviewDropdown({
      ...interviewDropdown,
      reSchedule: !interviewDropdown.reSchedule
    })
    if (!interviewDropdown.reSchedule) {
      dispatch(addToInterview("interviewReschedule"))
    } else {
      dispatch(removeFromInterview("interviewReschedule"))
    }
  }
  const handleCancel = () => {
    setInterviewDropdown({
      ...interviewDropdown,
      cancel: !interviewDropdown.cancel
    })
    if (!interviewDropdown.cancel) {
      dispatch(addToInterview("interviewCancel"))
    } else {
      dispatch(removeFromInterview("interviewCancel"))
    }
  }
  const handleInterviewed = () => {
    setInterviewDropdown({
      ...interviewDropdown,
      interviewed: !interviewDropdown.interviewed
    })
    if (!interviewDropdown.interviewed) {
      dispatch(addToInterview("interviewed"))
    } else {
      dispatch(removeFromInterview("interviewed"))
    }
  }

  useEffect(() => {
    if (calenderInterviews.length < 1) {
      dispatch(addToInterview("interviewSchedule"))
      dispatch(addToInterview("interviewReschedule"))
    }
  }, []);

  return (
    <div>
      <MenuItem
        onClick={handleClick}
        className={classes.dropdownButton}
      >
        Choose Interview type
      </MenuItem>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className={classes.dropdown}>
          <FormControlLabel
            control={
              <Checkbox
                checked={interviewDropdown.schedule}
                onChange={handleSchedule}
                color="primary"
              />
            }
            label="Scheduled"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={interviewDropdown.reSchedule}
                onChange={handleReschedule}
                color="primary"
              />
            }
            label="Rescheduled"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={interviewDropdown.cancel}
                onChange={handleCancel}
                color="primary"
              />
            }
            label="Cancelled"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={interviewDropdown.interviewed}
                onChange={handleInterviewed}
                color="primary"
              />
            }
            label="Interviewed"
          />
        </div>
      </Menu>
    </div>
  );
}
export default Filters;
