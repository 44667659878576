import {
  SUCCESS_ALERT,
  ERROR_ALERT,
  GET_CONTRACT,
  UPDATE_CONTRACT,
} from "../constants/contractConstant";
import { db } from "../../../../config/firebase";
export const updateContract = (data, setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    const { type, ...rest } = data;
    await db.collection("contract").doc(type).set(rest);
    dispatch({ type: SUCCESS_ALERT, payload: "Contract Updated" });
    dispatch({ type: UPDATE_CONTRACT, payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setLoading(false);
  }
};
export const getContact = (setloading, id) => async (dispatch) => {
  try {
    setloading(true);
    let doc = await db.collection("contract").doc(id).get();
    if (doc?.exists) {
      dispatch({ type: GET_CONTRACT, payload: doc.data() });
    } else {
      dispatch({ type: GET_CONTRACT, payload: {} });
    }
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setloading(false);
  }
};
