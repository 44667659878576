import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'table-cell',
    width: '100vw',
    height: '90vh',
    verticalAlign: 'middle',
    textAlign: 'center',
  }
}));

export default function CircularLoader() {

  const classes = useStyles();

  return (
    <div className={classes.root}>
        <CircularProgress size={80} />
    </div>
  );
}
