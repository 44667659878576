import { sidebarTabs } from "./sidebarConstant";
export const getSidebarTabs = (visibleTab = []) => {
  const tabs = sidebarTabs
    .filter((tab) => {
      if (tab.children) {
        let child = tab?.children?.some((child) =>
          visibleTab?.includes(child.protection),
        );
        return child && visibleTab?.includes(tab?.protection);
      }
      return visibleTab?.includes(tab?.protection);
    })
    .filter(Boolean);
  return tabs;
};
