import React, { useEffect, useState } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { CircularProgress } from "@material-ui/core";
import useStyles from "./style";
import Button from "@material-ui/core/Button";
import usePayroll from "./components/usePayroll";
import TableBody from "@material-ui/core/TableBody";
import Collapse from "@material-ui/core/Collapse";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { getAllLeaves } from "../leaveRequest/redux/actions/leaveRequestAction";

const DEFAULT_OBJECT = {
  title: "",
  amount: 0,
};

export default function TableRowComponent({ userId, userleaves }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setloading] = useState(false);

  const {
    payroll,
    update,
    updateLoading,
    earnings,
    setEarningData,
    removeEarning,
    addEarning,
    deduction: deductions,
    setDeductionData,
    removeDeduction,
    addDeduction,
  } = usePayroll(userId);
  useEffect(() => {
    dispatch(getAllLeaves(setloading));
  }, []);
  const userPayroll = payroll.filter((el) => el.userId === userId)[0];
  const [earning, setEarning] = useState(DEFAULT_OBJECT);
  const [deduction, setDeduction] = useState(DEFAULT_OBJECT);
  let startOfMonth;
  let endOfMonth;
  if (userPayroll?.startOfMonth.seconds) {
    startOfMonth = userPayroll?.startOfMonth?.toDate();
    endOfMonth = userPayroll?.endOfMonth?.toDate();
  } else {
    startOfMonth = userPayroll?.startOfMonth;
    endOfMonth = userPayroll?.endOfMonth;
  }
  let itm = {
    ...userPayroll,
    startOfMonth,
    endOfMonth,
  };

  return (
    <TableRow>
      <TableCell colSpan={12}>
        <Collapse in={true} timeout="auto" unmountOnExit>
          <div className={classes.gridRoot}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              spacing={3}
            >
              <Grid item colSpan={3}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item>
                    <Typography
                      align="center"
                      variant="h6"
                      // gutterBottom
                      component="span"
                    >
                      &nbsp; &nbsp; Pay Period :
                    </Typography>
                  </Grid>
                  <Typography
                    align="center"
                    variant="subtitle1"
                    // gutterBottom
                    component="span"
                  >
                    {moment(itm.startOfMonth).format("LL")} &nbsp; to &nbsp;
                  </Typography>
                  <Typography
                    align="center"
                    variant="subtitle1"
                    // gutterBottom
                    component="span"
                  >
                    {moment(itm.endOfMonth).format("LL")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item colSpan={8}>
                {/* For Earnings */}
                <h3>Earnings</h3>
                {earnings.map((n, i) => {
                  return (
                    <Grid
                      key={i}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="flex-end"
                      spacing={3}
                    >
                      <TextField
                        id="standard-basic_"
                        size="small"
                        label="Title"
                        value={n.title}
                        type="text"
                        onChange={(e) =>
                          setEarningData(i, {
                            title: e.target.value,
                          })
                        }
                        className={classes.margin}
                      />

                      <TextField
                        id="standard-basic__"
                        size="small"
                        label="Amount"
                        value={n.amount}
                        type="number"
                        onChange={(e) =>
                          setEarningData(i, {
                            amount: parseFloat(e.target.value),
                          })
                        }
                        className={classes.margin}
                      />

                      <Button
                        variant="contained"
                        size="small"
                        disabled={i == 0 || updateLoading}
                        color="primary"
                        className={classes.margin}
                        onClick={() => removeEarning(i)}
                      >
                        Remove
                      </Button>

                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        className={classes.margin}
                        onClick={() => {
                          addEarning(earning);
                          setEarning(DEFAULT_OBJECT);
                        }}
                        disabled={updateLoading}
                      >
                        Add
                      </Button>
                    </Grid>
                  );
                })}

                {/* End earning */}

                {/* For Deduction */}

                <h3 style={{ paddingTop: 20 }}>Deductions</h3>

                {deductions.map((n, i) => {
                  return (
                    <Grid
                      key={i}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="flex-end"
                      spacing={3}
                    >
                      <TextField
                        id="standard-basic_"
                        size="small"
                        label="Title"
                        value={n.title}
                        disabled={i === 0 || n.title === "Job Started Late"}
                        type="text"
                        onChange={(e) =>
                          setDeductionData(i, {
                            title: e.target.value,
                          })
                        }
                        className={classes.margin}
                      />

                      <TextField
                        id="standard-basic__"
                        size="small"
                        label="Amount"
                        value={n.amount}
                        disabled={i === 0 || n.title === "Job Started Late"}
                        type="number"
                        onChange={(e) =>
                          setDeductionData(i, {
                            amount: parseFloat(e.target.value),
                          })
                        }
                        className={classes.margin}
                      />

                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        disabled={
                          i === 0 ||
                          updateLoading ||
                          n.title === "Job Started Late"
                        }
                        className={classes.margin}
                        onClick={() => removeDeduction(i)}
                      >
                        Remove
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        className={classes.margin}
                        disabled={updateLoading}
                        onClick={() => {
                          addDeduction(deduction);
                          setDeduction(DEFAULT_OBJECT);
                        }}
                      >
                        Add
                      </Button>
                    </Grid>
                  );
                })}

                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  className={classes.margin}
                  disabled={updateLoading}
                  style={{ marginTop: 30 }}
                  onClick={() => update(userId)}
                >
                  {updateLoading ? <CircularProgress size={20} /> : "Update"}
                </Button>

                {/* End Deduction */}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item colSpan={4}>
                <Typography variant="h6" gutterBottom component="div">
                  &nbsp; &nbsp; Leaves History
                </Typography>
              </Grid>
            </Grid>
          </div>

          {userleaves.length > 0 ? (
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userleaves?.map((leave, i) => {
                  let start;
                  let end;
                  if (leave.start.seconds) {
                    start = leave.start?.toDate();
                    end = leave.end?.toDate();
                  } else {
                    start = leave.start;
                    end = leave.end;
                  }
                  let parsedStart = moment(start, "DD/MM/YYYY", true);
                  let parsedEnd = moment(end, "DD/MM/YYYY", true);         
                  let itm = {
                    ...leave,
                    start: parsedStart,
                    end: parsedEnd,
                  };
                  //show un approved leaves or not ?
                  {
                    /* if (
                    leave?.description === "leave" ||
                    leave?.description === "halfDayLeave"
                  ) {
                    return null;
                  } */
                  }
                  return (
                    <TableRow key={itm.id}>
                      <TableCell>{++i}</TableCell>
                      <TableCell>{itm?.title}</TableCell>
                      <TableCell align="left">{itm?.description}</TableCell>
                      <TableCell component="th" scope="row">
                        {moment(itm?.start).format("DD MMM YYYY")}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {moment(itm?.end).format("DD MMM YYYY")}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      // backgroundColor: "black",
                      paddingBottom: 0,
                      // marginTop: 10,
                    }}
                    colSpan={12}
                    align="center"
                  >
                    <Typography
                      align="center"
                      variant="h6"
                      gutterBottom
                      component="div"
                    >
                      No Leave
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          )}
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
