import {
  GET_ALL_APPLIED_JOBS,
  GET_ALL_DECLINED_JOBS,
  GET_ALL_FUTURE_JOBS,
  GET_ALL_INTERVIEW_SCHEDULE_JOBS,
  GET_ALL_INTERVIEW_RESCHEDULE_JOBS,
  GET_ALL_INTERVIEWED_JOBS,
  GET_ALL_ONBOARD_JOBS,
  GET_ALL_HIRED_JOBS,
  GET_ALL_INTERVIEW_CANCEL_JOBS,
  INTERVIEW_SHECDULE,
  UPDATE_STATUS,
  APPLY_FOR_JOB,
  UPDATE_STATUS_HISTORY,
  SEARCHED_JOBS,
  GET_SELECTED_INTERVIEWS,
  FILTER_SELECTED_INTERVIEWS,
  UPDATE_INTERVIEW_SHECDULE,
  SORT_CARDS,
} from "../constant/index";
const initialState = {
  calenderInterviews: [],
  searchedJobs: [],
  appliedJobs: [],
  declinedJobs: [],
  futureJobs: [],
  interviewScheduleJobs: [],
  interviewRescheduleJobs: [],
  interviewCancelJobs: [],
  interviewedJobs: [],
  onBoardJobs: [],
  hiredJobs: [],
};

const jobsRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCHED_JOBS:
      return {
        ...state,
        searchedJobs: action.payload,
      };
    case GET_SELECTED_INTERVIEWS:
      return {
        ...state,
        calenderInterviews: [...state.calenderInterviews, ...action.payload],
      };
    case UPDATE_INTERVIEW_SHECDULE:
      let updated = state.calenderInterviews.map((doc) => {
        if (doc.docId === action.payload.docId) {
          return { ...doc, ...action.payload };
        } else {
          return doc;
        }
      });
      return {
        ...state,
        calenderInterviews: updated,
      };
    case FILTER_SELECTED_INTERVIEWS:
      return {
        ...state,
        calenderInterviews: state.calenderInterviews.filter(
          (job) => job.status !== action.payload,
        ),
      };
    case GET_ALL_APPLIED_JOBS:
      return {
        ...state,
        appliedJobs: action.payload,
      };
    case APPLY_FOR_JOB:
      return {
        ...state,
        appliedJobs: [...state.appliedJobs, action.payload],
      };
    case GET_ALL_DECLINED_JOBS:
      return {
        ...state,
        declinedJobs: action.payload,
        allJobsRequests: action.payload,
      };
    case GET_ALL_FUTURE_JOBS:
      return {
        ...state,
        futureJobs: action.payload,
      };

    case GET_ALL_HIRED_JOBS:
      return {
        ...state,
        hiredJobs: action.payload,
      };

    case GET_ALL_INTERVIEWED_JOBS:
      return {
        ...state,
        interviewedJobs: action.payload,
      };
    case GET_ALL_INTERVIEW_SCHEDULE_JOBS:
      return {
        ...state,
        interviewScheduleJobs: action.payload,
      };
    case GET_ALL_INTERVIEW_RESCHEDULE_JOBS:
      return {
        ...state,
        interviewRescheduleJobs: action.payload,
      };
    case GET_ALL_INTERVIEW_CANCEL_JOBS:
      return {
        ...state,
        interviewCancelJobs: action.payload,
      };
    case GET_ALL_ONBOARD_JOBS:
      return {
        ...state,
        onBoardJobs: action.payload,
      };
    case INTERVIEW_SHECDULE:
      return {
        ...state,
        interviewScheduleJobs: [...state.interviewScheduleJobs, action.payload],
        calenderInterviews: [...state.calenderInterviews, action.payload],
      };
    case UPDATE_STATUS:
      let allJobs = [
        ...state.appliedJobs,
        ...state.declinedJobs,
        ...state.futureJobs,
        ...state.interviewScheduleJobs,
        ...state.interviewRescheduleJobs,
        ...state.interviewCancelJobs,
        ...state.interviewedJobs,
        ...state.onBoardJobs,
        ...state.hiredJobs,
      ];
      let allUpdatedJobs = allJobs.map((job) => {
        if (job.docId === action.payload.docId) {
          return action.payload;
        }
        return job;
      });
      let searchJobsUpdate = state.searchedJobs.map((job) => {
        if (job.docId === action.payload.docId) {
          return action.payload;
        }
        return job;
      });
      let calenderUpdate = state.calenderInterviews.map((job) => {
        if (job.docId === action.payload.docId) {
          return action.payload;
        }
        return job;
      });
      const updatedData = (status) => {
        return allUpdatedJobs.filter((job) => job.status === status);
      };
      let onBoardInvite = updatedData("boardingInvitation");
      let onBoarded = updatedData("onboarding");
      let data = [...onBoardInvite, ...onBoarded];
      return {
        ...state,
        calenderInterviews: calenderUpdate,
        searchedJobs: searchJobsUpdate,
        appliedJobs: updatedData("applied"),
        declinedJobs: updatedData("declined"),
        futureJobs: updatedData("future"),
        interviewScheduleJobs: updatedData("interviewSchedule"),
        interviewRescheduleJobs: updatedData("interviewReschedule"),
        interviewCancelJobs: updatedData("interviewCancel"),
        interviewedJobs: updatedData("interviewed"),
        hiredJobs: updatedData("hired"),
        onBoardJobs: data,
      };
      case SORT_CARDS:
        const { arr, jobType }=action.payload
       
         return {
           ...state,
           [jobType]: arr,
         };
    default:
      return state;
  }
};

export default jobsRequestsReducer;
