import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "100%",
    border: "8px solid grey",
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
  btn: {
    boxSizing: "border-box",
  },
  button: {
    // borderRadius: "15px",
    // fontSize: "10px",
    // marginRight: "16px",
    // margin: "0",
    // top: "50%",
    float: "right",
    // bottom: "30px",
    margin: "60px 0",
  },
  grid: {
    marginTop: "5%",
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    width: "100%",
  },
  wrapper: {
    padding: "1rem",
    border: "1px solid #ccc",
  },
  editor: {
    backgroundColor: "lightgray",
    padding: "1rem",
    border: "1px solid #ccc",
    minHeight: "25vh",
  },
  toolbar: {
    border: "1px solid #ccc",
  },
}));
