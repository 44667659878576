import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { approveLeave, getAllLeaves } from "./redux/actions/leaveRequestAction";
import CircularLoader from "../utils/circularProgress/index";
import TableComponent from "./table";
import { ERROR_ALERT, UPDATE_LEAVES } from "./redux/constant";

export default function LeaveRequest() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { allLeaves } = useSelector((state) => state.leaveRequestReducer);

  useEffect(() => {
    dispatch(getAllLeaves(setLoading));
  }, []);

  const handleMore = (i, status, setLoading) => {
    try {
      let callback=()=>{
        const approvedLeaveID = allLeaves[i].id;
        const updatedAllLeaves = allLeaves?.filter((leave) => leave.id !== approvedLeaveID);
        dispatch({
          type:UPDATE_LEAVES,
          payload:updatedAllLeaves
        })
      
      }
      dispatch(approveLeave(allLeaves[i], status, setLoading,callback))

    } catch (error) {
      dispatch({
        type: ERROR_ALERT,
        payload: error.message
      });
      
    }
  }
  

  return (
    <>
      {loading ? <CircularLoader /> : <TableComponent data={allLeaves} handleMore={handleMore} />}
    </>
  );
}
