import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import { Close as CloseDialogIcon } from "@material-ui/icons";
import avatar from "../../assets/avatar.jpg"

const ImageDilog = ({ src }) => {
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpenImageDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenImageDialog(false);
  };
  return (
    <div>
      <img
        src={src || avatar}
        alt="profile"
        style={{
          width: 50,
          height: 50,
          borderRadius: "50%",
          cursor: "pointer",
        }}
        onClick={() =>
          handleImageClick(src || avatar)
        }
      />
      <Dialog
        open={openImageDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseDialogIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img
            src={selectedImage}
            alt="profile"
            style={{ width: "100%", height: '100%'}}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ImageDilog;
