import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import useStyles from "./style";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

export default function TableRowComponent({ userId, userData }) {
  const classes = useStyles();
  return (
    <>
      <TableRow>
        <TableCell colSpan={12}>
          <Collapse in={true} timeout="auto" unmountOnExit>
            <div className={classes.gridRoot}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                spacing={3}
              >
                <Grid item colSpan={3}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item>
                      <Typography
                        align="center"
                        variant="subtitle2"
                        // gutterBottom
                        component="span"
                      >
                        <b>
                          {" "}
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                          Message &nbsp; :
                        </b>{" "}
                        &nbsp; {userData.message}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
