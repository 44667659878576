import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
export const exportToExcel = (data, filename) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const colWidths = [];
    Object.keys(worksheet).forEach((key) => {
      const colIndex = XLSX.utils.decode_cell(key).c;
      const cellValue = worksheet[key].v;
      const cellWidth = cellValue ? cellValue.toString().length * 1.05 : 10;
      colWidths[colIndex] =
        cellWidth > (colWidths[colIndex] || 0)
          ? cellWidth
          : colWidths[colIndex] || 0;
    });

    worksheet["!cols"] = colWidths.map((width) => ({ width }));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(blob, filename);
  };
