import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendPaystub, sendPayment, sendTaxEmail } from "../../payroll/redux/actions/payrollAction";
import { getDoc } from "../../../commonUtils/share";
import moment from "moment";
import { getAllBanks } from "../../banks/redux/actions/bankAction";
import { exportToExcel } from "../../../utils/exportExcel";
import { getQuarterlyTaxData } from "../redux/actions/payStubAction";
import { ERROR_ALERT } from "../redux/constant";

export default function usePaystub() {
  const dispatch = useDispatch();
  const { payStub, quarterlyTax } = useSelector((state) => state.payStubReducer);
  const {allBanks} = useSelector((state)=>state.bankReducer)
  const [rowopen, setRowOpen] = useState(false);
  const [loadings, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [startMonth, setStartMonth] = useState(null);
  const [endMonth, setEndMonth] = useState(null);
  const [shouldExport, setShouldExport] = useState(false);
  const [shouldSendEmail, setShouldSendEmail] = useState(false);
  const [open, setOpen] = useState(false);
  const paystubs = payStub || []

  useEffect(()=>{
    if(allBanks.length === 0) {
      dispatch(getAllBanks(setLoading));
    }
  },[]);
  useEffect(() => {
    if (shouldExport && quarterlyTax) {
      const quarterlyTaxData = mapQuarterlyTaxData(quarterlyTax);
      exportQuarterlyTaxData(quarterlyTaxData);
      setShouldExport(false);
    }
  }, [shouldExport, quarterlyTax]);

  useEffect(() => {
    if (shouldSendEmail && quarterlyTax) {
      const quarterlyTaxData = mapQuarterlyTaxData(quarterlyTax);
      const startOfMonth = startMonth ? startMonth: moment().subtract(1, 'month')
      const endOfMonth = endMonth ? endMonth: moment().subtract(1, 'month')
      dispatch(sendTaxEmail(quarterlyTaxData, setLoading, startOfMonth, endOfMonth));
      setShouldSendEmail(false);
    }
  }, [shouldSendEmail, quarterlyTax, dispatch, startMonth, endMonth]);
  const handlePdfSend = (data, index) => {
    dispatch(sendPaystub(data, index, setLoading, payStub, setActiveIndex));
  };

  const handlePaymentSend = (docId, authId) => {
    dispatch(sendPayment(docId, authId, setLoading));
  };

  const viewPaystub = async (data, index) => {
    try {
      setActiveIndex(index);
      const payperiod = `${moment(data?.startOfMonth?.toDate()).format(
        "LL",
      )} to ${moment(data?.endOfMonth?.toDate()).format("LL")}`;
      await getDoc(
        {
          ...data,
          payperiod,
          docType: "paystub",
        },
        setLoading,
      );
    } catch (error) {
    } finally {
      setActiveIndex(null);
    }
  };

  let totalPayAble = 0;
   // paystubs?.map((item) => { return totalPayAble += item?.netSalary });
  for (let index = 0; index < paystubs.length; index++) {
    let item = paystubs[index];
    totalPayAble += item?.netSalary; 
  }

  
  let formattedDateTime = moment(new Date()).format("DD-MMMM-YYYY-h:mm:ss");

  let fileNameDateTime = moment(new Date()).format("DD-MMMM-YYYY, h:mm:ss a");

  const lftData = paystubs?.filter((item) => item.accountName === "HBL")?.map((item) => ({
    Beneficiary_Account: item?.accountNumber || '',
    Amount: item?.netSalary || 0, 
    Beneficiary_Name: item?.accountHolderName || "",
    Reference_Number: formattedDateTime + (item?.userId?.slice(0, 7) || ""), // Defensive check for undefined userId
    IBAN: "",
    Debit_Account: "01437992570703",
  }));
  
  const handleStartMonthChange = (date) => {
    setStartMonth(date);
  };

  const handleEndMonthChange = (date) => {
    setEndMonth(date);
  };
  const mapQuarterlyTaxData = (quarterlyTaxDataMap) => {
    return Object.values(quarterlyTaxDataMap).map(
      ({ userId, totalTax, totalSalaryAmount, fullName, idCardNumber }) => ({
        Registeration_No: idCardNumber?.replace(/-/g, "") || "",
        Identification_No: userId || "",
        Reference_No: "",
        Name: fullName || "",
        Transaction_Date: moment(new Date()).format("YYYY-MM-DD") || "",
        Code: "64020003",
        Taxable_Amount: totalSalaryAmount || "",
        Exemption_Code: "",
        Description: "",
        Tax_Collectible: totalTax || "",
      }),
    );
  };

  const exportQuarterlyTaxData = (quarterlyTaxData) => {
    const startMonthToUse = startMonth ? startMonth : moment().subtract(1, 'month');
    const endMonthToUse = endMonth ? endMonth : moment().subtract(1, 'month');
    const startMonthFormatted = startMonthToUse.format("MMMM-YYYY");
    const endMonthFormatted = endMonthToUse.format("MMMM-YYYY");
  
    let fileName;
    if (startMonthFormatted === endMonthFormatted) {
      fileName = `Techloset Tax file ${startMonthFormatted}.xlsx`;
    } else {
      fileName = `Techloset Tax file ${startMonthFormatted} to ${endMonthFormatted}.xlsx`;
    }
  
    exportToExcel(quarterlyTaxData, fileName);
  };
  const handleDownloadQuarterlyTax = async () => {
    try {
      let startMonthForQuery = startMonth ? startMonth : null;
      let endMonthForQuery = endMonth ? endMonth : null;

      await dispatch(getQuarterlyTaxData(startMonthForQuery, endMonthForQuery));
      setShouldExport(true);
    } catch (error) {
      dispatch({ type: ERROR_ALERT, payload: error.message });
    }
  };

  const handleSendTaxEmail = async () => {
    try {
      let startMonthForQuery = startMonth ? startMonth : moment().subtract(1, 'month').format("YYYY-MM");
      let endMonthForQuery = endMonth ? endMonth : moment().subtract(1, 'month').format("YYYY-MM");
      const startMonthFormatted = moment(startMonthForQuery).format("MMMM YYYY");
      const endMonthFormatted = moment(endMonthForQuery).format("MMMM YYYY");
      let confirmationMessage ;
      if (startMonthFormatted === endMonthFormatted) {
        confirmationMessage = `Are you sure you want to send tax email for ${startMonthFormatted}?`;
      } else {
        confirmationMessage = `Are you sure you want to send tax email for ${startMonthFormatted} to ${endMonthFormatted}?`;
      }
      if (window.confirm(confirmationMessage)) {
        await dispatch(getQuarterlyTaxData(startMonthForQuery, endMonthForQuery));
        setShouldSendEmail(true);
      }
    } catch (error) {
      dispatch({ type: ERROR_ALERT, payload: error.message });
    }
  }

  const exportPaystub = () => {
    exportToExcel(lftData, "LFT Paystub " + fileNameDateTime + ".xlsx");
  };


  return {
    open,
    rowopen,
    setRowOpen,
    paystubs,
    handlePdfSend,
    viewPaystub,
    loadings,
    activeIndex,
    totalPayAble,
    handlePaymentSend,
    allBanks,
    exportPaystub,
    handleStartMonthChange,
    handleEndMonthChange,
    handleDownloadQuarterlyTax,
    handleSendTaxEmail,
    startMonth,
    endMonth,
    quarterlyTax,
  };
}
