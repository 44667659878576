import {
  GET_ALL_LETTERS,
  SUCCESS_ALERT,
  ERROR_ALERT,
  APPROVE_LETTER,
  DECLINE_LETTER,
} from "../constant/index";
import { db } from "../../../../config/firebase";
import axiosTechloset from "../../../../config/axiosTechloset";

import axios from "axios";

export const getAllLetters = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    let res = await db.collection("LetterRequests").get();
    let allLetters = [];
    res.forEach((doc) => {
      allLetters.push({ ...doc.data(), id: doc.id });
    });

    dispatch({ type: GET_ALL_LETTERS, payload: allLetters });
    setloading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setloading(false);
  }
};

export const approveLetter =
  (data, setloading) => async (dispatch, getState) => {
    const allMembers = getState().commonReducer.allMembers;
    try {
      setloading(true);
      const user = allMembers.filter((el) => el.id === data.userId);

      await db.collection("LetterRequests").doc(data.id).update({
        requestStatus: "approved",
      });
      dispatch({
        type: SUCCESS_ALERT,
        payload: "Request Approved Successfully.",
      });
      axiosTechloset
        .post("/emails/letterapprove", {
          name: user[0].fullName,
          email: data.email,
          letterType: data.letterType,
        })
        .then(
          (response) => {
            dispatch({ type: SUCCESS_ALERT, payload: response.data });
          },
          (error) => {
            dispatch({ type: ERROR_ALERT, payload: error.message });
            console.log(error);
          },
        );
      dispatch({
        type: APPROVE_LETTER,
        payload: { ...data, requestStatus: "approved" },
      });
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

export const declineLetter =
  (data, setloading) => async (dispatch, getState) => {
    const allMembers = getState().commonReducer.allMembers;
    try {
      setloading(true);
      await db.collection("LetterRequests").doc(data.id).delete();
      dispatch({ type: DECLINE_LETTER, payload: data.id });
      dispatch({
        type: SUCCESS_ALERT,
        payload: "Request Declined Successfully.",
      });
      const user = allMembers.filter((el) => el.id === data.userId);
      axiosTechloset
        .post(`/emails/letterdecline`, {
          email: data.email,
          letterType: data.letterType,
          name: user[0].fullName,
        })
        .then(
          (response) => {
            dispatch({ type: SUCCESS_ALERT, payload: response.data });
          },
          (error) => {
            dispatch({ type: ERROR_ALERT, payload: error.message });
            console.log(error);
          },
        );
      setloading(false);
    } catch (error) {
      console.log(error);
      dispatch({ type: ERROR_ALERT, payload: error.message });
    }
  };
export const sendLetter = (id, setloading) => async (dispatch) => {
  try {
    setloading(true);
    await db.collection("LetterRequests").doc(id).update({
      requestStatus: "send",
    });
    dispatch(successMessage("Experience Letter Send successfully"));
    setloading(false);
  } catch (error) {
    console.log(error);
    dispatch(errorMessage(error));
    setloading(false);
  }
};

export const successMessage = (message) => async (dispatch) => {
  try {
    dispatch({ type: SUCCESS_ALERT, payload: message });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};

export const errorMessage = (message) => async (dispatch) => {
  try {
    dispatch({ type: ERROR_ALERT, payload: message });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};
