import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePayroll, updateExpAction, sendTaxEmail } from "../redux/actions/payrollAction";
import { calculateMonthlyTax, updateExpense } from "../utils";
import moment from "moment";
import { exportToExcel } from "../../../utils/exportExcel";

const TEST_EARNING = [{ title: "", amount: "" }];

class BonusCollection extends Array {
  sum(key) {
    return this.reduce((a, b) => a + (parseFloat(b[key]) || 0), 0);
  }
}

export default function usePayroll(userId) {
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.commonReducer.allEmployees);
  const allEvents = useSelector((state) => state.commonReducer.allEvents);
  const payroll = useSelector((state) => state.payrollReducer.payroll);
  const [rowopen, setRowOpen] = React.useState(false);
  const [loading, setloading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [index, setindex] = useState(null);
  const [approveLoader, setapproveLoader] = useState(false);
  const [bonus, setBonus] = useState(0);
  const [travelExpdenditure, settravelExpdenditure] = useState(0);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [travelExpLoading, setTravelExpLoading] = useState(false);
  const [filteredEmployees, setfilteredEmployees] = useState();
  const [earnings, setEarning] = useState(TEST_EARNING);
  const [deduction, setDeduction] = useState(TEST_EARNING);
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    const currentMonth = moment(moment(new Date())?.toDate())
      ?.startOf("month")
      ?.toDate();

    let filteredEmployee = employees.filter(
      (el) =>
        moment(el.JobStartedAt?.toDate()).isBefore(currentMonth) &&
        el.status === "approved",
    );

    filteredEmployee.sort(
      (a, b) => parseFloat(b.basicPay) - parseFloat(a.basicPay),
    );
    setfilteredEmployees(filteredEmployee);
  }, [employees]);

  useEffect(() => {
    if (payroll.length) {
      const userPayroll = payroll.filter((el) => el.userId === userId);
      if (userPayroll?.[0]?.deductions) {
        setDeduction(userPayroll[0].deductions);
      }
      if (userPayroll?.[0]?.earnings) {
        if (userPayroll[0].earnings.length > 0) {
          setEarning(userPayroll[0].earnings);
        }
      }
    }
  }, [payroll]);

  let formattedDate = moment(new Date()).format("DD-MMMM-YYYY");
  
  let formattedDateTime = moment(new Date()).format("DD-MMMM-YYYY-h:mm:ss");

  let fileNameDateTime = moment(new Date()).format("DD-MMMM-YYYY, h:mm:ss a");

  const taxData = [
    ...payroll?.filter((item) => item?.tax > 0)?.map((item) => ({
      Registeration_No: item?.idCardNumber?.replace(/-/g, "") || "",
      Identification_No: item?.userId || "",
      Reference_No: "",
      Name: item?.fullName || "",
      Transaction_Date: moment(new Date()).format("YYYY-MM-DD") || "",
      Code: "64020003",
      Taxable_Amount: item?.totalSalary || "",
      Exemption_Code: "",
      Description: "",
      Tax_Collectible: item?.tax || "",
    })),
  ];


  const lftData = [
    ...payroll
      ?.filter((item) => item.accountName === "HBL")
      ?.map((item) => ({
        Beneficiary_Account: item?.accountNumber,
        Amount: item?.netSalary,
        Beneficiary_Name: item?.accountHolderName,
        Reference_Number: formattedDateTime + item?.userId.slice(0, 7),
        IBAN: "",
        Debit_Account: "01437992570703",
      })),
  ];

  

  const exportPayroll = () => {
    exportToExcel(lftData, "LFT Payroll " + fileNameDateTime + ".xlsx");
  };

  const exportTax = () => {
    exportToExcel(taxData, "Payroll Tax " + formattedDate + ".xlsx");
  };

  const sendTax = () => {
    dispatch(sendTaxEmail(taxData, setloading));
  };

  const handleClickOpen = (i) => {
    setindex(i);
    setOpen(true);
  };

  const handleClose = () => {
    setindex(null);
    setOpen(false);
  };

  const states = {
    approved: "approved",
    request: "request",
  };

  const handleMore = (i) => {
    setindex(i);
    // dispatch(approveLetter(allLetters[i], setapproveLoader));
  };

  const handleDecline = () => {
    setOpen(false);
    // dispatch(declineLetter(allLetters[index], setloading));
  };

  const addBonus = (id) => {
    console.log(id, "bonus");
  };
  const update = (uid) => {
    setUpdateLoading(true);
    const earningBonus = new BonusCollection(...earnings);
    const bonus = earningBonus.sum("amount");
    const filteredDeductions = deduction?.filter((item) => {
      if (item.title !== "Income Tax") {
        return item;
      }
    });
    let totalDeduction = new BonusCollection(...filteredDeductions);
    totalDeduction = totalDeduction.sum("amount");
    const userPayroll = payroll.filter((el) => el.userId === uid);
    let updated = userPayroll[0];
    let totalSalary =
      updated["basicPay"] - parseInt(totalDeduction) + parseInt(bonus);
    const monthlyTax = calculateMonthlyTax(totalSalary);
    const netSalary = totalSalary - monthlyTax;
    const newDeductions = deduction?.map((item) => {
      if (item.title === "Income Tax") {
        return {
          ...item,
          amount: monthlyTax,
        };
      }
      return item;
    });

    let newDeduction = new BonusCollection(...newDeductions);
    let deductioncount = newDeduction.sum("amount");
    setDeduction(newDeductions);

    updated["netSalary"] = netSalary;
    updated["totalSalary"] = totalSalary;
    updated["tax"] = monthlyTax;
    updated["bonus"] = bonus;
    updated["deductedSalary"] = deductioncount;
    updated["earnings"] = earnings;
    updated["deductions"] = newDeductions;
    dispatch(updatePayroll(updated, setUpdateLoading));
  };
  const updateTravelExp = (id, travelExpenditure) => {
    setTravelExpLoading(true);
    const userPayroll = payroll.filter((el) => el.userId === id);
    const updated = updateExpense(userPayroll[0], travelExpenditure);
    dispatch(updateExpAction(updated, setTravelExpLoading));
  };

  function setEarningData(index, data) {
    const earningObject = [...earnings];
    const newObject = { ...earningObject[index], ...data };
    earningObject[index] = newObject;
    setEarning([...earningObject]);
  }
  function removeEarning(index) {
    const earningObject = earnings.filter((n, i) => i !== index);
    setEarning(earningObject);
  }
  function addEarning(data) {
    const earningObject = [...earnings];
    earningObject.push(data);
    setEarning([...earningObject]);
  }

  function setDeductionData(index, data) {
    const earningObject = [...deduction];
    const newObject = { ...earningObject[index], ...data };
    earningObject[index] = newObject;
    setDeduction([...earningObject]);
  }
  function removeDeduction(index) {
    const earningObject = deduction.filter((n, i) => i !== index);
    setDeduction(earningObject);
  }
  function addDeduction(data) {
    const earningObject = [...deduction];
    earningObject.push(data);
    setDeduction([...earningObject]);
  }
  let totalPayAble = 0;
  payroll.map((item) => (totalPayAble += item.netSalary));
  return {
    open,
    loading,
    index,
    approveLoader,
    handleClickOpen,
    handleClose,
    states,
    handleMore,
    handleDecline,
    bonus,
    setBonus,
    addBonus,
    rowopen,
    setRowOpen,
    filteredEmployees,
    allEvents,
    updateTravelExp,
    travelExpdenditure,
    settravelExpdenditure,
    travelExpLoading,
    setTravelExpLoading,
    update,
    updateLoading,
    earnings,
    setEarningData,
    removeEarning,
    addEarning,
    deduction,
    setDeductionData,
    removeDeduction,
    addDeduction,
    totalPayAble,
    setPreview,
    preview,
    payroll,
    exportTax,
    sendTax,
    exportPayroll,
  };
}
