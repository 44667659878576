import { routes } from "../routes";
import envConfig from "../constants/envConfig/envConfig";

export const isAllowed = (visibleTabs = [], path) => {
  if (visibleTabs?.length && visibleTabs?.includes(path)) {
    return true;
  } else {
    return false;
  }
};
export const getUser = () => {
  let data = localStorage.getItem("visibleTabs");
  let user = JSON.parse(data);
  return {
    uid: user?.uid,
    userId: user?.id,
  };
};

export const getFirstTab = (visibleTabs) => {
  if (visibleTabs?.length && visibleTabs?.includes("dashboard")) {
    return "/dashboard";
  } else if (visibleTabs?.length) {
    const filteredRoute = routes.find(
      (route) => route.protection === visibleTabs[0],
    );
    return filteredRoute?.path;
  } else return "/notfound";
};

export const getENVConfig = () => {
  const env = process.env.REACT_APP_ENVIRONMENT || "dev";
  return envConfig?.[env];
};
