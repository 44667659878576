import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CircularLoader from "../utils/circularProgress/index";
import TableComponent from "./table";
import { Grid } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import { contactRequestsAction } from "./redux/actions/contactActions";

export default function ContactRequest() {
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(contactRequestsAction(setloading));
  }, []);

  return loading ? <CircularLoader /> :
    (
      <>
        <PageTitle title={"Contact Requests"} />
        <Grid
          container
          direction="row"
          justify="flex-start" // center
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12}>
            <TableComponent />
          </Grid>
        </Grid>
      </>
    );
}
