import React from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import useContactRequests from "./components/useContactRequest";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TableRowComponent from "./tableRow";
import moment from "moment";
import { Chip } from "@material-ui/core";
import useStyles from "./style";
import Select from "@material-ui/core/Select";
import { CircularProgress } from "@material-ui/core";

const ContactRequestsTable = () => {
  const [open, rowopen, setRowOpen, requests, handleChange, loading, index] =
    useContactRequests();
  const classes = useStyles();

  // console.log("requests", requests);
  // console.log("users", users);
  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let name = requests?.[dataIndex]?.name?.toUpperCase();
          return <span>{name}</span>;
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let email = requests?.[dataIndex]?.email?.toLowerCase();
          return <span>{email}</span>;
        },
      },
    },
    {
      name: "purpose",
      label: "Purpose",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let purpose = requests?.[dataIndex]?.purpose;
          return <span>{purpose}</span>;
        },
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = requests?.[dataIndex]?.status;
          return (
            <Chip
              label={status?.toUpperCase()}
              classes={{
                root: classes?.[status],
              }}
            />
          );
        },
      },
    },
    {
      name: "status",
      label: "Update Status",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          const userStatus = requests?.[dataIndex]?.status;
          return loading && index === dataIndex ? (
            <CircularProgress size={25} />
          ) : (
            <Select
              native
              value={userStatus}
              onChange={(e) =>
                handleChange(e.target.value, requests?.[dataIndex], dataIndex)
              }
            >
              <option value={"requested"}>Requested</option>
              <option value={"contacted"}>Contacted</option>
              <option value={"declined"}>Decline</option>
              <option value={"useful"}>Useful</option>
            </Select>
          );
        },
      },
    },
    {
      name: "seconds",
      label: "Apply Date",
      options: {
        // filter: true,
        sort: true,
        sortDirection: "asc",
        customBodyRenderLite: (dataIndex) => {
          const createdAt = requests?.[dataIndex]?.createdAt;
          const hours = createdAt?.toDate()?.getHours();
          const min = createdAt?.toDate()?.getMinutes();
          return (
            <span>
              {moment(createdAt?.toDate()).format("L")} &nbsp; &nbsp; &nbsp;
              {hours} : {min}
            </span>
          );
        },
      },
    },
  ];

  const options = {
    searchPlaceholder: "Search here...",
    filter: false,
    searchAlwaysOpen: true,
    responsive: "standard",
    expandableRows: true,
    expandableRowsHeader: false,
    // expandableRowsOnClick: true,
    selectableRows: false,
    isRowExpandable: (dataIndex, expandedRows) => {
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const userData = requests[rowMeta?.dataIndex];

      const userId = userData.id;

      return <TableRowComponent userId={userId} userData={userData} />;
    },
  };

  const theme = createTheme({
    overrides: {
      MUIDataTableHeadCell: {
        sortAction: {
          fontSize: "1rem",
          fontWeight: "bolder",
        },
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          visibility: "hidden",
        },
      },
    },
  });

  const components = (
    <IconButton
      aria-label="expand row"
      size="small"
      onClick={() => setRowOpen(!rowopen)}
    >
      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );

  return (
    <MuiThemeProvider theme={theme}>
      <MUIDataTable
        className="mainTable"
        data={requests}
        columns={columns} // paystub ? columns : []
        options={options}
        components={components}
      />
    </MuiThemeProvider>
  );
};

export default ContactRequestsTable;
