import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  input: {
    display: "none",
  },
  circularProgress: {
    marginLeft: 0,
    color: "#fff",
  },

  btnMargin: {
    marginLeft: "5px;",
  },
  cancelIcon: {
    color: "red",
  },
}));
