import {
  ADD_CONTRACT_TYPES,
  DELETE_CONTRACT_TYPES,
  GET_ALL_CONTRACT_TYPES,
  UPDATE_CONTRACT_TYPES,
  SUCCESS_ALERT,
  ERROR_ALERT,
} from "../constants/contractConstant";

const initialState = {
  allContractTypes: [],
  successMessage: "",
  errorMessage: "",
};

const contractTypesContract = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CONTRACT_TYPES:
      return {
        ...state,
        allContractTypes: action?.payload,
      };
    case ADD_CONTRACT_TYPES:
      return {
        ...state,
        allContractTypes: [...state.allContractTypes, action?.payload],
      };
    case UPDATE_CONTRACT_TYPES:
      const data = action.payload;
      var foundIndex = state.allContractTypes.findIndex(
        (email) => email.id === data.id,
      );
      state.allContractTypes[foundIndex] = data;
      return {
        ...state,
      };
    case DELETE_CONTRACT_TYPES:
      const updated = state.allContractTypes.filter(
        (el) => el.id !== action.payload,
      );
      return {
        allContractTypes: updated,
      };
    case SUCCESS_ALERT:
      return {
        ...state,
        successMessage: action.payload,
      };
    case ERROR_ALERT:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};
export default contractTypesContract;
