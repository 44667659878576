import {
  GET_LETTERS,
  SUCCESS_ALERT,
  ERROR_ALERT,
  ADD_LETTER,
} from "../constants/lettersConstant";
const initialState = {
  letters: [],
  successMessage: "",
  errorMessage: "",
};
const lettersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LETTERS:
      return {
        ...state,
        letters: action.payload,
      };
    case ADD_LETTER:
      return {
        ...state,
        letters: [action?.payload, ...state?.letters],
      };
    case SUCCESS_ALERT:
      return {
        ...state,
        successMessage: action.payload,
      };
    case ERROR_ALERT:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};
export default lettersReducer;
