import {
  SUCCESS_ALERT,
  ERROR_ALERT,
  CURRENT_PROFILE,
  UPDATE_PROFILE,
} from "../constant/index";
import { db } from "../../../../config/firebase";
import AxiosTechloset from "../../../../config/axiosTechloset";
import moment from "moment";
import { createEvent } from "../../../update/redux/actions/updateAction";

export const changeStatus =
  (id, data, status, setLoading, type) => async (dispatch) => {
    try {
      const Collection = type === "onboard" ? "Onboarding" : "UserData";
      setLoading(true);
      if (status === "blocked") {
        await AxiosTechloset({
          method: "PUT",
          url: "/users/update",
          data: {
            email: data.email,
            disabled: true,
          },
        });
      }
      if (status === "approved") {
        const birthdayEventId = await createEvent(data, "Birthday");
        const AniversaryEventId = await createEvent(data, "Anniversary");

        await AxiosTechloset({
          method: "PUT",
          url: "/users/update",
          data: {
            email: data.email,
            disabled: false,
            events: [{ birthdayEventId, AniversaryEventId }],
          },
        });
      }
      if (!data.employStatusHistory) {
        let employStatusHistory = [
          {
            date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
            status: status,
          },
        ];

        await db.collection(Collection).doc(id).update({
          status: status,
          employStatusHistory,
        });

        dispatch({
          type: UPDATE_PROFILE,
          payload: { employStatusHistory, status },
        });
      } else {
        let employStatusHistory = [
          {
            date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
            status: status,
          },
          ...data.employStatusHistory,
        ];

        await db.collection(Collection).doc(id).update({
          status: status,
          employStatusHistory,
        });

        dispatch({
          type: UPDATE_PROFILE,
          payload: { employStatusHistory, status },
        });
      }
      dispatch({
        type: SUCCESS_ALERT,
        payload: "Status updated successfully!",
      });
      setLoading(false);

      if (status === "blocked") {
        if (data?.events) {
          await AxiosTechloset({
            method: "DELETE",
            url: "/event/delete",
            data: { events: data?.events },
          });
        }
        await AxiosTechloset({
          method: "DELETE",
          url: "/member/remove",
          data: {
            email: data.email,
            gusername: data.github.split("/")[3],
            tusername: data.tusername,
            name: data.fullName,
          },
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        });
      } else {
        await AxiosTechloset({
          method: "PUT",
          url: "/member/add",
          data: {
            username: data.github.split("/")[3],
          },
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        });
      }
    } catch (error) {
      dispatch({ type: ERROR_ALERT, payload: error.message });
      setLoading(false);
    }
  };

export const getById = (id, setloading, type) => async (dispatch) => {
  try {
    let CollectionName = type === "onboard" ? "Onboarding" : "UserData";
    setloading(true);
    let doc = await db.collection(CollectionName).doc(id).get();

    if (doc.exists) {
      dispatch({ type: CURRENT_PROFILE, payload: doc.data() });
    } else {
      dispatch({ type: ERROR_ALERT, payload: "No such document!" });
    }
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setloading(false);
  }
};

export const addContract = (data, setLoading, toggle) => async (dispatch) => {
  try {
    setLoading(true);
    const { userId, ...rest } = data;
    await db.collection("contracts").doc(userId).set(rest);
    dispatch({ type: SUCCESS_ALERT, payload: "Added Contract" });
    toggle();
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setLoading(false);
  }
};

// export const createBankAccount = (createAccountDetail, setLoading) => async(dispatch) => {
//   try {
//     setLoading(true);
//     await AxiosTechloset({
//       method: "POST",
//       url: "/emails/bankFile",
//       data: {createAccountDetail},
//     });
//     dispatch({
//       type: SUCCESS_ALERT,
//       payload: "Tax Email send successfully",
//     });
//   } catch (error) {
//     dispatch({
//       type: ERROR_ALERT,
//       payload: error.message,
//     });
//   }
//   finally {
//     setLoading(false);
//   }
// }
