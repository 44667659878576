import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Box,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import { useStyles } from "../../../teamRank/style";
import { useDispatch, useSelector } from "react-redux";
import { ERROR_ALERT } from "../../redux/constant";
import {
  addJobField,
  deleteField,
  getAllFields,
  updateJobField,
  updateSortBy,
} from "../../redux/actions/jobsActions";
import {
  sectionOptions,
  fieldTypeOptions,
} from "../../../../commonRedux/common/constants";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const JobApplicationFields = ({ jobFields, setJobFileds }) => {
  const classes = useStyles();
  const AllFields = useSelector((state) => state.jobsReducer.jobFields);

  const dispatch = useDispatch();
  const [addFieldLoading, setAddFieldLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [key, setKey] = useState("");
  const [section, setSection] = useState("");
  const [fieldType, setFieldType] = useState("");
  const [required, setRequired] = useState(true);
  const [loading, setLoading] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [selectOptions, setSelectOptions] = useState({
    0: { title: "", key: "" },
  });

  useEffect(() => {
    dispatch(getAllFields(setLoading));
  }, []);

  const handleOnClose = () => {
    setIsModalVisible(false);
    setTitle("");
    setPlaceholder("");
    setKey("");
    setSection("");
    setFieldType("");
    setRequired(true);
    setSelectOptions({ 0: { title: "", key: "" } });
    setModalMode("");
  };
  const handleAddField = () => {
    if (!title || !key || !placeholder || !section || !fieldType) {
      return dispatch({
        type: ERROR_ALERT,
        payload: "ALL Fields Are Required!",
      });
    } else if (fieldType === "select") {
      if (!selectOptions[0]?.key || !selectOptions[0]?.title) {
        return dispatch({
          type: ERROR_ALERT,
          payload: "Please add select option",
        });
      }
    }
    const data = {
      title,
      placeholder,
      key,
      section,
      fieldType,
      required,
    };
    if (fieldType === "select") {
      data.options = Object.values(selectOptions);
    }
    setAddFieldLoading(true);
    dispatch(addJobField(data, setAddFieldLoading, setIsModalVisible));
  };

  const handleUpdateField = (docId) => {
    if (!title || !key || !placeholder || !section || !fieldType) {
      return dispatch({
        type: ERROR_ALERT,
        payload: "ALL Fields Are Required!",
      });
    } else if (fieldType === "select") {
      if (!selectOptions[0]?.key || !selectOptions[0]?.title) {
        return dispatch({
          type: ERROR_ALERT,
          payload: "Please add select option",
        });
      }
    }
    const data = {
      title,
      placeholder,
      key,
      section,
      fieldType,
      required,
    };
    if (fieldType === "select") {
      data.options = Object.values(selectOptions);
    }
    setAddFieldLoading(true);
    dispatch(
      updateJobField(docId, data, setAddFieldLoading, setIsModalVisible),
    );
  };

  const handleDeleteJobField = (docId) => {
    dispatch(updateSortBy(sortedArray.filter((item) => item?.id !== docId)));
    dispatch(deleteField(docId));
  };

  const handleEdit = (doc) => {
    setIsModalVisible(true);
    setTitle(doc?.title);
    setPlaceholder(doc?.placeholder);
    setKey(doc?.key);
    setSection(doc?.section);
    setFieldType(doc?.fieldType);
    setRequired(doc?.required);
    setDocumentId(doc?.id);
    setModalMode("edit");
    if (doc?.fieldType === "select" && doc?.options && doc.options.length > 0) {
      const initialOptions = {};
      doc.options.forEach((item, index) => {
        initialOptions[index] = {
          title: item?.title,
          key: item?.key,
        };
      });
      setSelectOptions(initialOptions);
    }
  };

  const handleRemoveOption = (indexToRemove) => {
    const newOptions = { ...selectOptions };
    delete newOptions[indexToRemove];
    const updatedOptions = {};
    Object.keys(newOptions).forEach((index, i) => {
      updatedOptions[i] = newOptions[index];
    });
    setSelectOptions(updatedOptions);
  };

  const handleSelectField = (id) => {
    let fields = jobFields;
    if (fields?.includes(id)) {
      fields = fields?.filter((item) => item !== id);
    } else {
      fields = [...fields, id];
    }
    setJobFileds(fields);
  };
  const isALLSelected = AllFields && jobFields.length === AllFields.length;
  const selectAll = () => {
    let fields = [];
    if (isALLSelected) {
      setJobFileds([]);
    } else {
      AllFields.map((item) => (fields = [...fields, item?.id]));
      setJobFileds(fields);
    }
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const sortedList = reorder(
      AllFields,
      result.source.index,
      result.destination.index,
    );

    let arrangedList = sortedList?.map((doc, index) => {
      return {
        ...doc,
        sortBy: index + 1,
      };
    });

    dispatch(updateSortBy(arrangedList));
  }

  const sortedArray = AllFields?.sort(function (a, b) {
    return Number(a?.sortBy) - Number(b?.sortBy);
  });

  const RenderTable = React.memo(({ list }) => {
    return (
      <>
        <div className={classes.Table}>
          <header className={classes.header}>
            <strong className={classes.firstCeil}>
              <Checkbox
                color="primary"
                checked={isALLSelected}
                onChange={() => selectAll()}
              />
            </strong>
            <strong className={classes.secondCeil}>Name</strong>
            <strong className={classes.third}>Field Section</strong>
            <strong className={classes.fourth}>Field Type</strong>
            <strong className={classes.five}>Key</strong>
            <strong className={classes.six}>Actions</strong>
          </header>
          <section>
            {list?.map((doc, index) => {
              const isChecked = jobFields?.includes(doc?.id);
              return (
                <Draggable draggableId={doc?.id?.toString()} index={index}>
                  {(provided) => (
                    <div
                      key={doc.id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={classes.bodyRow}
                    >
                      <div className={classes.firstCeil}>
                        <Checkbox
                          color="primary"
                          checked={isChecked}
                          onChange={() => handleSelectField(doc?.id)}
                        />
                      </div>
                      <div className={classes.secondCeil}>{doc?.title}</div>
                      <div className={classes.third}>
                        {
                          sectionOptions.filter(
                            (item) => item.value === doc?.section,
                          )?.[0]?.title
                        }
                      </div>
                      <div className={classes.fourth}>{doc?.fieldType}</div>
                      <div className={classes.five}>{doc?.key}</div>
                      <div className={`${classes.six}`}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{ marginRight: "10px" }}
                          onClick={() => handleEdit(doc)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={() => handleDeleteJobField(doc?.id)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  )}
                </Draggable>
              );
            })}
          </section>
        </div>
      </>
    );
  });
  return (
    <div>
      <h1 className="Details">Job Application Fields</h1>
      <Button
        variant="contained"
        color="primary"
        component="label"
        onClick={() => {
          setIsModalVisible(true);
          setModalMode("add");
        }}
      >
        Add Field
      </Button>
      {loading ? (
        "Loading ... "
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <RenderTable list={sortedArray} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      <Modal
        open={isModalVisible}
        onClose={handleOnClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div style={{ maxHeight: 600, overflowY: "auto" }}>
          <Box sx={{ ...style }}>
            <h2 id="child-modal-title">
              {modalMode === "add" ? "Add new field" : "Edit Field"}
            </h2>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="outlined-basic"
                  label="Title"
                  variant="standard"
                  fullWidth
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="outlined-basic"
                  label="Placeholder"
                  variant="standard"
                  fullWidth
                  value={placeholder}
                  onChange={(e) => setPlaceholder(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="outlined-basic"
                  label="Key"
                  variant="standard"
                  fullWidth
                  value={key}
                  onChange={(e) => setKey(e.target.value.replace(/\s/g, "-"))}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Section</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={section}
                    onChange={(e) => setSection(e.target.value)}
                    label="Section"
                  >
                    {sectionOptions.map((item) => (
                      <MenuItem value={item?.value}>{item?.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Field Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={fieldType}
                    label="Age"
                    onChange={(e) => setFieldType(e.target.value)}
                  >
                    {fieldTypeOptions.map((item) => (
                      <MenuItem value={item?.value}>{item?.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={required}
                      onChange={() => setRequired(!required)}
                      color="primary"
                    />
                  }
                  label="Required"
                />
              </Grid>
            </Grid>
            {fieldType === "select" ? (
              <div>
                <Box
                  my={"20px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <h4>Add Options</h4>
                  <Button
                    color={"primary"}
                    onClick={() => {
                      const optionsLength = Object.keys(selectOptions).length;
                      if (optionsLength === 0) {
                        setSelectOptions({ 0: { title: "", key: "" } });
                      } else {
                        const lastOption = selectOptions?.[optionsLength - 1];
                        if (!lastOption?.key || !lastOption.title) {
                          return dispatch({
                            type: ERROR_ALERT,
                            payload: "Please fill the previous option first",
                          });
                        }
                        setSelectOptions((prev) => ({
                          ...prev,
                          [optionsLength]: { title: "", key: "" },
                        }));
                      }
                    }}
                  >
                    Add
                  </Button>
                </Box>
                <div
                  style={{
                    height: "100%",
                    maxHeight: 140,
                    width: "100%",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    scrollbarWidth: "thin",
                  }}
                >
                  {Object?.keys(selectOptions).map((item, index) => (
                    <Grid container spacing={2} justifyContent="space-between" alignItems="flex-end">
                      <Grid item xs={12} md={5}>
                        <TextField
                          id="outlined-basic"
                          label="Title"
                          variant="standard"
                          fullWidth
                          value={selectOptions?.[index]?.title}
                          onChange={(e) =>
                            setSelectOptions({
                              ...selectOptions,
                              [index]: {
                                ...selectOptions?.[index],
                                title: e.target.value,
                              },
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id="outlined-basic"
                          label="Key"
                          variant="standard"
                          fullWidth
                          value={selectOptions?.[index]?.key}
                          onChange={(e) =>
                            setSelectOptions({
                              ...selectOptions,
                              [index]: {
                                ...selectOptions?.[index],
                                key: e.target.value,
                              },
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={1} justifyContent="flex-end">
                        <CloseOutlined
                        variant="contained"
                          color="secondary"
                          onClick={() => handleRemoveOption(index)}
                          style={{ cursor: "pointer" }}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </div>
            ) : null}
            <Box display={"flex"} justifyContent={"flex-end"} mt={"20px"}>
              <Button
                onClick={handleOnClose}
                variant="contained"
                color="danger"
                style={{ marginRight: "20px" }}
              >
                Cancle
              </Button>
              <Button
                onClick={() =>
                  modalMode === "add"
                    ? handleAddField()
                    : handleUpdateField(documentId)
                }
                variant="contained"
                color="primary"
              >
                {addFieldLoading
                  ? "Loading"
                  : modalMode === "add"
                  ? "Add"
                  : "Update"}
              </Button>
            </Box>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

// #endregion

export default JobApplicationFields;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
