import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularLoader from "../utils/circularProgress/index";
import TableComponent from "./table";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  createPayrollAction,
  getAllPayroll,
  runPaystubAction,
  deletePayRoll,
} from "./redux/actions/payrollAction";
// import { fetchAllEvents, getUserData } from "../../commonRedux/common/action";
import useStyle from "./style";
// payroll.jpg
import payrollIcon from "../../assets/payroll.png";
import usePayroll from "./components/usePayroll";
export default function Payroll() {
  const classes = useStyle();
  const [loadings, setloadings] = useState(false);
  const [payrollLoader, setPayrollLoader] = useState(false);
  const [deletePayrollLoader, setDeletePayrollLoader] = useState(false);
  const { setPreview, preview, payroll, exportTax, sendTax, exportPayroll, loading} = usePayroll();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (payroll.length < 1) {
      dispatch(getAllPayroll(setloadings));
    }
  }, []);
  useEffect(() => {
    if (preview) {
      let filtered = payroll.filter((item) => item.basicPay > 0);
      setData(filtered);
    } else {
      setData(payroll);
    }
  }, [preview, payroll]);
  return (
    <>
      <PageTitle title={"Pay Roll"} />
      {loadings ? (
        <CircularLoader />
      ) : (
        <Grid
          container
          direction="row"
          justify="flex-start" // center
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12}>
            {payroll.length < 1 ? (
              <>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={
                    payrollLoader ? classes.loadingButton : classes.button
                  }
                  onClick={() =>
                    dispatch(createPayrollAction(setPayrollLoader))
                  }
                >
                  {payrollLoader ? (
                    <CircularProgress
                      size={20}
                      color="primary"
                      className={classes.progress}
                    />
                  ) : (
                    "Create Payroll"
                  )}
                </Button>

                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <img
                    src={payrollIcon}
                    alt={payrollIcon}
                    width="35%"
                    height="35%"
                  />
                </Grid>
              </>
            ) : deletePayrollLoader ? (
              <CircularProgress size={50} color="secondary" />
            ) : (
              <>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classes.button}
                  onClick={() => setPreview(!preview)}
                >
                  {preview ? "Reset" : "Preview"}
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={
                    payrollLoader ? classes.loadingButton : classes.button
                  }
                  onClick={() => dispatch(runPaystubAction(setPayrollLoader))}
                >
                  {payrollLoader ? (
                    <CircularProgress
                      size={20}
                      color="primary"
                      className={classes.progress}
                    />
                  ) : (
                    "Run Payroll"
                  )}
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  color="secondary"
                  className={classes.deleteButton}
                  disabled={payrollLoader}
                  onClick={() =>
                    dispatch(deletePayRoll(setDeletePayrollLoader))
                  }
                >
                  Delete Payroll
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classes.button}
                  onClick={exportTax}
                >
                  Download Tax File
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classes.button}
                  onClick={sendTax}
                >
                 {loading ? (
                    <CircularProgress
                      size={20}
                      color="primary"
                      className={classes.progress}
                    />
                  ) : (
                    "Send Tax File"
                  )
                  }
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classes.button}
                  onClick={exportPayroll}
                >
                  Download Payroll File
                </Button>
                <TableComponent payroll={data} />
              </>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}
