import React from "react";
import { Button } from "reactstrap";
import { Plus } from "react-feather";
import { connect, useDispatch, useSelector } from "react-redux";
import { getAll } from "../../employees/redux/actions/employeeAction";
import {
  handleSidebar,
  handleSelectedEvent,
} from "./redux/calendarAction/index";
import { useEffect } from "react";
import { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createEvent } from "../../update/redux/actions/updateAction";
import { db } from "../../../config/firebase";
import { ERROR_ALERT, SUCCESS_ALERT } from "./redux/constants/constant";
const AddEventButtons = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { allEmployees } = useSelector((state) => state.employeesReducer);
  useEffect(() => {
    dispatch(getAll(setLoading));
  }, []);
  const syncEventWithGoogleCalendar = async () => {
    setLoading(true);
    try {
    let batch = db.batch();

    for (const data of allEmployees) {
      let docRefUser = db.collection("UserData").doc(data?.id);
  
      if (!data?.events?.birthdayEventId) {
        const birthdayEventId = await createEvent(data, "Birthday");
        batch.update(docRefUser, {
          events: {
            ...data.events,
            birthdayEventId,
          },
        });
      } 
       if (!data?.events?.AniversaryEventId) {
        const AniversaryEventId = await createEvent(data, "Anniversary");
        batch.update(docRefUser, {
          events: {
            ...data.events,
            AniversaryEventId,
          },
        });
      }
    }
    
      await batch.commit();
      dispatch({ type: SUCCESS_ALERT, payload: "Event Created Successfully" });
    } catch (err) {
      dispatch({ type: ERROR_ALERT, payload: "Events Creation Failed" });
    }
    setLoading(false);
  };
  return (
    <div style={{ display: "flex", gap: 10, marginBottom: 20 }}>
      <Button.Ripple
        color="primary"
        onClick={() => {
          props.handleSidebar(true);
          props.handleSelectedEvent(null);
        }}
        className="d-sm-block d-none"
      >
        {" "}
        <Plus size={15} /> <span className="align-middle">Add</span>
      </Button.Ripple>
      {loading ? (
        <CircularProgress size={40} />
      ) : (
        <Button.Ripple
          color="primary"
          onClick={() => {
            syncEventWithGoogleCalendar();
          }}
          className="d-sm-block d-none m "
        >
          {" "}
          <Plus size={15} />{" "}
          <span className="align-middle">Sync With Google Calendar</span>
        </Button.Ripple>
      )}
    </div>
  );
};

export default connect(null, { handleSidebar, handleSelectedEvent })(
  AddEventButtons,
);
