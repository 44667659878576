export const GET_ALL_ATTENDANCE = "GET_ALL_ATTENDANCE";
export const GET_ALL_ONBOARDING = "GET_ALL_ONBOARDING";
export const CURRENT_EMPLOYEE = "CURRENT_EMPLOYEE";
export const CURRENT_ONBOARDING = "CURRENT_ONBOARDING";
export const SUCCESS_ALERT = "SUCCESS_ALERT";
export const ERROR_ALERT = "ERROR_ALERT";
export const UPDATE_ATTENDANCE_STATUS = "UPDATE_ATTENDANCE_STATUS";
export const UPDATE_ATTENDANCE_STATUS_BOOTCAMP = "UPDATE_ATTENDANCE_STATUS_BOOTCAMP";
export const GET_ALL_USER = "GET_ALL_USER";
export const GET_BOOTCAMP_USER = "GET_BOOTCAMP_USER";
export const DELETE_ATTENDANCE = "DELETE_ATTENDANCE";
export const DELETE_ATTENDANCE_BOOTCAMP = "DELETE_ATTENDANCE_BOOTCAMP";
export const ALL_EMPLOYEE = "ALL_EMPLOYEE";
export const ALL_BOOTCAMPER = "ALL_BOOTCAMPER";
export const CALENDAR_EVENTS = "CALENDAR_EVENTS";

export const ATTENDANCE_STATUS = {
  PRESENT: "present",
  ABSENT: "generalLeave",
  LATE: "late",
  PAID_LEAVE: "paidLeave",
  CASUAL_LEAVE: "casualLeave",
  SICK_LEAVE: "sickLeave",
  ANNUAL_LEAVE: "annualLeave",
  MATERNITY_PATERNITY_LEAVE: "maternityPaternityLeave",
  UNPAID_LEAVE: "unpaidLeave",
  HALF_LEAVE: "halfLeave",
  REMOTE: "remote",
};

export const ATTENDANCE_STATUS_OPTIONS = [
  {
    title: "Present",
    value: ATTENDANCE_STATUS?.PRESENT,
  },
  {
    title: "Absent",
    value: ATTENDANCE_STATUS?.ABSENT,
  },
  {
    title: "Late",
    value: ATTENDANCE_STATUS?.LATE,
  },
  {
    title: "Paid Leave",
    value: ATTENDANCE_STATUS?.PAID_LEAVE,
  },

  {
    title: "Casual Leave",
    value: ATTENDANCE_STATUS?.CASUAL_LEAVE,
  },
  {
    title: "Sick Leave",
    value: ATTENDANCE_STATUS?.SICK_LEAVE,
  },
  {
    title: "Annual Leave",
    value: ATTENDANCE_STATUS?.ANNUAL_LEAVE,
  },
  {
    title: "Maternity Paternity Leave",
    value: ATTENDANCE_STATUS?.MATERNITY_PATERNITY_LEAVE,
  },
  {
    title: "Unpaid Leave",
    value: ATTENDANCE_STATUS?.UNPAID_LEAVE,
  },
  {
    title: "Half Leave",
    value: ATTENDANCE_STATUS?.HALF_LEAVE,
  },
  {
    title: "Remote",
    value: ATTENDANCE_STATUS?.REMOTE,
  },
  {
    title: "Day Off",
    value: "dayOff",
  },
];

export const ATTENDANCE_COLORS = {
  [ATTENDANCE_STATUS?.ABSENT]: "red",
  [ATTENDANCE_STATUS?.PRESENT]: "green",
  [ATTENDANCE_STATUS?.LATE]: "orange",
  [ATTENDANCE_STATUS?.CASUAL_LEAVE]: "#39bfb9",
  [ATTENDANCE_STATUS?.SICK_LEAVE]: "#c74273",
  [ATTENDANCE_STATUS?.PAID_LEAVE]: "#81b879",
  [ATTENDANCE_STATUS?.ANNUAL_LEAVE]: "#4872a3",
  [ATTENDANCE_STATUS?.MATERNITY_PATERNITY_LEAVE]: "#6f32a8",
  [ATTENDANCE_STATUS?.UNPAID_LEAVE]: "#7a213f",
  [ATTENDANCE_STATUS?.REMOTE]: 'blue'
};
