import {
  GET_ALL_LETTERS,
  APPROVE_LETTER,
  DECLINE_LETTER,
  SUCCESS_ALERT,
  ERROR_ALERT,
} from "../constant/index";

const initialState = {
  allLetters: [],
  successMessage: "",
  errorMessage: "",
};

const lettersRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LETTERS:
      return {
        ...state,
        allLetters: action.payload,
      };

    case APPROVE_LETTER:
      const data = action.payload;
      // console.log("data", data);
      var foundIndex = state.allLetters.findIndex((el) => el.id === data.id);
      state.allLetters[foundIndex] = data;
      return {
        ...state,
      };
    case DECLINE_LETTER:
      const updated = state.allLetters.filter((el) => el.id !== action.payload);
      // console.log("updated", updated);
      return {
        allLetters: updated,
      };
    case SUCCESS_ALERT:
      return {
        ...state,
        successMessage: action.payload,
      };
    case ERROR_ALERT:
      return {
        ...state,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default lettersRequestReducer;
