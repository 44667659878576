import {
  GET_LEAVE_POLICY,
  UPDATE_LEAVE_POLICY,
} from "../constants/leavePolicyConstant";
const initialState = {
  leavePolicy: {},
};
const leavePolicyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEAVE_POLICY:
      return {
        ...state,
        leavePolicy: action?.payload,
      };
    case UPDATE_LEAVE_POLICY:
      return {
        ...state,
        leavePolicy: action?.payload,
      };
    default:
      return state;
  }
};

export default leavePolicyReducer;
