import React from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import usePaystub from "./components/usepayStub";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TableRowComponent from "./tableRow";
import { Button } from "antd";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomFooter from "../payroll/payRollTableFooter";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ImageDilog from "../../components/imageDilog/ImageDilog";
const PaystubTable = () => {
  const {
    open,
    rowopen,
    setRowOpen,
    paystubs,
    handlePdfSend,
    viewPaystub,
    loading,
    activeIndex,
    totalPayAble,
    handlePaymentSend,
    allBanks,
  } = usePaystub();

  const columns = [
    {
      name:'profilePhoto',
      label: "Photo",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          let photo = paystubs[dataIndex].profilephoto;
          return (
            <ImageDilog
            src={photo}
            />
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let name = paystubs[dataIndex].fullName.toUpperCase();
          return <span>{name}</span>;
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let email = paystubs[dataIndex].email.toLowerCase();
          return <span>{email}</span>;
        },
      },
    },
    {
      name: "Basic Pay",
      label: "Basic Pay",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let basicPay = paystubs[dataIndex].basicPay;
          return <span>{basicPay}</span>;
        },
      },
    },
    {
      name: "Deduction",
      label: "Deduction",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let id = paystubs[dataIndex].id;
          // const user = paystub?.filter((el) => el.userId === id);
          const user = paystubs[dataIndex];
          return <span>{user.deductedSalary}</span>;
        },
      },
    },
    {
      name: "bonus",
      label: "Bonus",
      options: {
        customBodyRenderLite: (dataIndex) => {
          let id = paystubs[dataIndex].id;
          // const user = paystub?.filter((el) => el.userId === id);
          const user = paystubs[dataIndex];
          return (
            <span style={{ display: "flex", alignItems: "center" }}>
              {user.bonus}
            </span>
          );
        },
      },
    },
    {
      name: "travelExpenditure",
      label: "Travel Exp.",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const user = paystubs[dataIndex];
          return (
            <span style={{ display: "flex", alignItems: "center" }}>
              {user.travelExpenditure}
            </span>
          );
        },
      },
    },
    {
      name: "Bank Name",
      label: "Bank Name",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const user = paystubs[dataIndex];
          const bankName =
            allBanks.find((bank) => bank?.bankABR === user?.user?.accountName)
              ?.bankName || "";
          return (
            <span style={{ display: "flex", alignItems: "center" }}>
              {bankName}
            </span>
          );
        },
      },
    },
    {
      name: "Net Salaray",
      label: "Net Salaray",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let id = paystubs[dataIndex].id;
          // const user = paystub?.filter((el) => el.userId === id);
          const user = paystubs[dataIndex];
          return <span>Rs. {user.netSalary}</span>;
        },
      },
    },
    {
      name: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const sendCheck = paystubs[dataIndex]?.slipSended;
          const sendPayment = paystubs[dataIndex]?.paymentSend || false;
          return (
            <>
              {loading && activeIndex === dataIndex ? (
                <CircularProgress size={30} />
              ) : (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      handlePdfSend(paystubs[dataIndex], dataIndex)
                    }
                  >
                    {sendCheck ? "Send Again" : "Send Pdf"}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => viewPaystub(paystubs[dataIndex], dataIndex)}
                  >
                    View PayStub
                  </Button>
                  {sendPayment ? (
                    <IconButton aria-label="expand row" size="small" disabled>
                      <CheckCircleIcon style={{ color: "green" }} />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        handlePaymentSend(paystubs[dataIndex]?.id, paystubs[dataIndex]?.authId);
                        handlePdfSend(paystubs[dataIndex], dataIndex);
                      }}
                    >
                      <CancelIcon style={{ color: "red" }} />
                    </IconButton>
                  )}
                </>
              )}
            </>
          );
        },
      },
    },
  ];

  const options = {
    searchPlaceholder: "Search here...",
    filter: false,
    searchAlwaysOpen: true,
    responsive: "standard",
    expandableRows: true,
    expandableRowsHeader: false,
    // expandableRowsOnClick: true,
    selectableRows: false,
    isRowExpandable: (dataIndex, expandedRows) => {
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const userData = paystubs[rowMeta.dataIndex];
      const { generalLeaveDetails, paidLeavesDetails, userId } = userData;
      const userleaves = [...generalLeaveDetails, ...paidLeavesDetails];
      return (
        <TableRowComponent
          userId={userId}
          userleaves={userleaves}
          data={userData}
        />
      );
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <CustomFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={(event) => changeRowsPerPage(event.target.value)}
          onChangePage={(_, page) => changePage(page)}
          total={totalPayAble}
        />
      );
    },
  };
  const theme = createTheme({
    overrides: {
      MUIDataTableHeadCell: {
        sortAction: {
          fontSize: "1rem",
          fontWeight: "bolder",
        },
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          visibility: "hidden",
        },
      },
    },
  });
  const components = (
    <IconButton
      aria-label="expand row"
      size="small"
      onClick={() => setRowOpen(!rowopen)}
    >
      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );
  return (
    <MuiThemeProvider theme={theme}>
      <MUIDataTable
        className="mainTable"
        data={paystubs?.sort((a, b) => b.netSalary - a.netSalary)} // paystub ? users : []
        columns={paystubs ? columns : []} // paystub ? columns : []
        options={options}
        components={components}
      />
    </MuiThemeProvider>
  );
};
export default PaystubTable;
