import {
  GET_ALL_LEAVES,
  GET_BOOTCAMP_LEAVES,
  APPROVE_LEAVE,
  SUCCESS_ALERT,
  ERROR_ALERT,
  DECLINE_LEAVE,
  UPDATE_LEAVES,
  UPDATE_BOOTCAMP_LEAVES,
} from "../constant/index";

const initialState = {
  allLeaves: [],
  bootcampLeaves: [],
  successMessage: "",
  errorMessage: "",
};

const leaveRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LEAVES:
      console.log(action.payload, "action payload");
      return {
        ...state,
        allLeaves: action.payload,
      };
    case GET_BOOTCAMP_LEAVES:
      return {
        ...state,
        bootcampLeaves: action.payload,
      };
    case APPROVE_LEAVE:
      const data = action.payload;
      // console.log("data", data);
      var foundIndex = state.allLeaves.findIndex((el) => el.id === data.id);
      state.allLeaves[foundIndex] = data;
      return {
        ...state,
      };
    case DECLINE_LEAVE:
      const updated = state.allLeaves.filter((el) => el.id !== action.payload);
      // console.log("updated", updated);
      return {
        allLeaves: updated,
      };
    case SUCCESS_ALERT:
      return {
        ...state,
        successMessage: action.payload,
      };
    case ERROR_ALERT:
      return {
        ...state,
        errorMessage: action.payload,
      };
case UPDATE_LEAVES:
  return {
    ...state,
    allLeaves:action.payload

  };
  case UPDATE_BOOTCAMP_LEAVES:
    return {
      ...state,
      bootcampLeaves:action.payload
    };
    default:
      return state;
  }
};

export default leaveRequestReducer;
