import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isAllowed } from "../utils/share";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, protection, ...rest }) => {
  const { loggedIn, details } = useSelector((state) => state.userReducer);
  if (!details?.visibleTabs) {
    return null;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn ? (
          !protection ||
          (protection && isAllowed(details?.visibleTabs, protection)) ? (
            <Component {...props} />
          ) : (
            <Redirect to="/notfound" />
          )
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
