import React, { useState } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import useStyles from "./BankStyle";
import Button from "@material-ui/core/Button";
import { BankModal, ConfirmModal } from "./components/modals/Modals";
import { deleteBank } from "./redux/actions/bankAction";
const BankTable = () => {
  const { allBanks } = useSelector((state) => state.bankReducer);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [details, setDetails] = useState({});
  const [index, setIndex] = useState(null);
  const toggleModal = (data) => {
    setModal(!modal);
    if (data) {
      setDetails(data);
    } else {
      setDetails({});
    }
  };

  const toggleDeleteModal = (i) => {
    setDeleteModal(!deleteModal);
    setIndex(i);
  };

  const handleRemove = () => {
    setDeleteModal(false);
    dispatch(deleteBank(allBanks?.[index]?.id, setLoading));
  };

  const columns = [
    {
      name: "bankName",
      label: "Bank Name",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let bank = allBanks[dataIndex]?.bankName;
          return <span>{bank}</span>;
        },
      },
    },
    {
      name: "bankAbr",
      label: "Bank Abrevation",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let bankABR = allBanks[dataIndex]?.bankABR;
          return <span>{bankABR}</span>;
        },
      },
    },
    {
      name: "Actions",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const bank = allBanks?.[dataIndex];
          return (
            <>
              {loading && index === dataIndex ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => toggleDeleteModal(dataIndex)}
                    className={classes.btnMargin}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => toggleModal(bank)}
                    className={classes.btnMargin}
                  >
                    Edit
                  </Button>
                </>
              )}
            </>
          );
        },
      },
    },
  ];

  const options = {
    searchPlaceholder: "Search here...",
    filter: false,
    searchAlwaysOpen: true,
    responsive: "standard",
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    selectableRows: false,
  };

  const theme = createTheme({
    overrides: {
      MUIDataTableHeadCell: {
        sortAction: {
          fontSize: "1rem",
          fontWeight: "bolder",
        },
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          // Soft hide the button.
          visibility: "hidden",
        },
      },
    },
  });

  return (
    <>
      <Button
        className={"mb-2"}
        variant="contained"
        color="primary"
        onClick={() => toggleModal()}
      >
        Add Bank
      </Button>
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          className="mainTable"
          data={allBanks}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
      <BankModal modal={modal} toggle={toggleModal} data={details} />
      <ConfirmModal
        message="Are you sure for delete this bank?"
        modal={deleteModal}
        toggle={toggleDeleteModal}
        action={handleRemove}
      />
    </>
  );
};

export default BankTable;
