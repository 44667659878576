import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    mainDiv: {
        margin: "50px auto 0",
        width: "65%",
    },
    singleInterview: {
        display:'flex',
        flexWrap:'wrap',
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
        padding: '20px',
        borderRadius: "10px",
        marginBottom: "20px",
    },
    singleQuestions: {
        margin: '20px'
    }
}));
