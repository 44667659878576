import { db } from "../config/firebase";
export default async function gernateId() {
  const res = await db
    .collection("letters")
    .orderBy("timestamp", "desc")
    .limit(1)
    .get();
  let docs = [];
  let id = "";
  res.forEach((doc) => {
    docs.push({ ...doc.data(), docId: doc.id });
  });
  const lastDocId = docs?.[0]?.docId || "";
  if (lastDocId) {
    const splitedId = lastDocId.split("-");
    const placeholder = splitedId[0];
    let number = splitedId[1];
    let incre = Number(number) + 1;
    id = `${placeholder}-${incre}`;
  } else {
    const d = new Date();
    const year = d.getFullYear();
    id = `TSPL-${year}0001`;
  }
  return id;
}
