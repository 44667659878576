import moment from "moment";

export const getTimeDiff = (date, start, end) => {
  var todayDate = moment(new Date(date)).format("MM-DD-YYYY");
  var startDate = new Date(`${todayDate} ${start}`);
  var endDate = new Date(`${todayDate} ${end}`);
  var timeDiff = Math.abs(startDate.getTime() - endDate.getTime());

  var hh = Math.floor(timeDiff / 1000 / 60 / 60);
  hh = ("0" + hh).slice(-2);

  timeDiff -= hh * 1000 * 60 * 60;
  var mm = Math.floor(timeDiff / 1000 / 60);
  mm = ("0" + mm).slice(-2);

  timeDiff -= mm * 1000 * 60;
  var ss = Math.floor(timeDiff / 1000);
  ss = ("0" + ss).slice(-2);
  // alert("Time Diff- " + hh + ":" + mm + ":" + ss);
  return `${hh}:${mm}`;
};

export const states = {
  present: "present",
  presentIcon: "presentIcon",
  absent: "absent",
  late: "late",
  absentIcon: "absentIcon",
  lateIcon: "lateIcon",
  leaveIcon: "leaveIcon",
  paidLeave: "paidLeave",
  generalLeave: "generalLeave",
  disabled: "disabled",
  iconDisabled: "iconDisabled",
};

export const options = {
  searchPlaceholder: "Search here...",
  searchAlwaysOpen: true,
  filter: false,
  responsive: "standard",
  selectableRows: false,
};
