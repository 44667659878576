import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { mdiSkypeBusiness } from "@mdi/js";
import { Link as MuiLink, Select, TableHead, Tooltip } from "@material-ui/core";
import {
  AccessAlarm,
  AssignmentTurnedIn,
  Fullscreen,
  GitHub,
  Receipt,
  Visibility,
} from "@material-ui/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import { SUCCESS_ALERT } from "../redux/constant";
import { useDispatch } from "react-redux";
import Icon from "@mdi/react";
import { Link } from "react-router-dom";
import {
  getTimeSuggestion,
  updateStatus,
} from "../redux/actions/jobsRequestsAction";
import ConfirmDialog from "./ConfirmDialog";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
  actionButtons: {
    display: "flex",
    flexDirection: "row",
  },
});

export default function AllSearchTable({ searchedJobs }) {
  const classes = useStyles2();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [copyValue, setCopyValue] = useState("");
  const [copy, setCopy] = useState(false);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, searchedJobs.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (copy) {
      dispatch({ type: SUCCESS_ALERT, payload: `copied to clickboard` });
    }
  }, [copy, copyValue]);

  const handleCopy = (value) => {
    setCopyValue(value);
    setCopy(true);
  };

  const getTime = (name, email, appliedFor) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to get time suggestion?",
      subTitle: "You can't undo this operation",
      onConfirm: () => {
        dispatch(getTimeSuggestion(name, email, appliedFor));
      },
    });
  };
  const startBoarding = (status, jobData, setloading) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to invite onBoarding?",
      subTitle: "You can't undo this operation",
      onConfirm: () => {
        dispatch(updateStatus(status, jobData, setloading));
      },
    });
  };
  const setStatus = (status, jobData, setloading) => {
    dispatch(updateStatus(status, jobData, setloading));
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <TableCell>
              <h6>
                <strong>Name</strong>
              </h6>
            </TableCell>
            <TableCell>
              <h6>
                <strong>Email</strong>
              </h6>
            </TableCell>
            <TableCell>
              <h6>
                <strong>Number</strong>
              </h6>
            </TableCell>
            <TableCell>
              <h6>
                <strong>Github</strong>
              </h6>
            </TableCell>
            <TableCell>
              <h6>
                <strong>CV</strong>
              </h6>
            </TableCell>
            <TableCell>
              <h6>
                <strong>Skype</strong>
              </h6>
            </TableCell>
            <TableCell>
              <h6>
                <strong>Applied For</strong>
              </h6>
            </TableCell>
            <TableCell>
              <h6>
                <strong>Status</strong>
              </h6>
            </TableCell>
            <TableCell>
              <h6>
                <strong>Source</strong>
              </h6>
            </TableCell>
            <TableCell>
              <h6>
                <strong>Actions</strong>
              </h6>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? searchedJobs.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
              )
            : searchedJobs
          ).map((doc, index) => (
            <TableRow key={doc.appliedFor + index}>
              <TableCell component="th" scope="row">
                {doc?.fname}
              </TableCell>
              <TableCell style={{ width: 160 }}>{doc?.email}</TableCell>
              <TableCell style={{ width: 160 }}>{doc?.phone}</TableCell>
              <TableCell style={{ width: 160 }}>
                <CopyToClipboard
                  text={doc?.gitHub}
                  onCopy={() => handleCopy(doc?.gitHub, "github link")}
                >
                  <span className="gitIcon">
                    <GitHub />
                  </span>
                </CopyToClipboard>
              </TableCell>
              <TableCell style={{ width: 160 }}>
                <MuiLink target="_blank" href={doc?.CV}>
                  <Visibility color="default" />
                </MuiLink>
              </TableCell>
              <TableCell style={{ width: 160 }}>
                <CopyToClipboard
                  text={doc?.skype}
                  onCopy={() => handleCopy(doc?.skype, "skype link")}
                >
                  <span className="skypeIcon">
                    <Icon
                      path={mdiSkypeBusiness}
                      size={1}
                      horizontal
                      vertical
                    />
                  </span>
                </CopyToClipboard>
              </TableCell>
              <TableCell style={{ width: 160 }}>
                {doc?.appliedFor ? doc?.appliedFor : ""}
              </TableCell>
              <TableCell style={{ width: 160 }}>
                <Select
                  value={doc?.status}
                  native
                  onChange={(e) => setStatus(e.target.value, doc, setloading)}
                >
                  <option disabled value="">
                    Update Status
                  </option>
                  <option disabled value="applied">
                    Applied
                  </option>
                  <option value="declined">Declined</option>
                  <option value="future">Future</option>
                  <option disabled value="interviewSchedule">
                    Schedule
                  </option>
                  <option disabled value="interviewReschedule">
                    Reschedule
                  </option>
                  <option disabled value="interviewCancel">
                    Cancelled
                  </option>
                  <option disabled value="interviewed">
                    Interviewed
                  </option>
                  <option disabled value="boardingInvitation">
                    boardingInvitation
                  </option>
                  <option disabled value="onboarding">
                    Boarded
                  </option>
                  <option disabled value="hired">
                    Hired
                  </option>
                </Select>
              </TableCell>
              <TableCell style={{ width: 160 }}>
                {doc?.source ? doc?.source : "Main Site"}
              </TableCell>
              <TableCell style={{ width: 160 }}>
                <div className={classes.actionButtons}>
                  <Tooltip title="Get Time Suggestion">
                    <IconButton
                      onClick={() =>
                        getTime(doc?.fname, doc?.email, doc?.appliedFor)
                      }
                    >
                      <AccessAlarm color="default" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Interview Schedule">
                    <Link to={{ pathname: `/interviews/${doc?.docId}` }}>
                      <IconButton aria-label="Interview Invitation">
                        <Receipt color="primary" />
                      </IconButton>
                    </Link>
                  </Tooltip>
                  <Tooltip title="Onboarding Invitation">
                    <IconButton
                      onClick={() =>
                        startBoarding("boardingInvitation", doc, setloading)
                      }
                      color="secondary"
                    >
                      <AssignmentTurnedIn />
                    </IconButton>
                  </Tooltip>
                  <CopyToClipboard
                    text={doc?.docId}
                    onCopy={() => handleCopy(doc?.docId, "docid")}
                  >
                    <Link to={`/JobProfile/${doc?.docId}`}>
                      <Tooltip title="More Details">
                        <IconButton variant="outlined" color="#0000">
                          <Fullscreen />
                        </IconButton>
                      </Tooltip>
                    </Link>
                  </CopyToClipboard>
                </div>
              </TableCell>
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={3}
              count={searchedJobs.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </TableContainer>
  );
}
