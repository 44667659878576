import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllJobs } from "../../../jobs/redux/actions/jobsActions";
import { addJobApplication } from "../../redux/actions/jobsRequestsAction";

export default function UseJobApplication() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    status: "applied",
  });

  const [cv, setCv] = useState();
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const allJobs = useSelector((state) => state.jobsReducer.allJobs);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const onChangeHandler = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;

    setData({
      ...data,
      [name]: value,
    });
  };
  const jobChangeHandler = (e) => {
    const id = e?.target?.value;
    const job = allJobs?.find((item) => item?.id === id);
    setData({
      ...data,
      appliedFor: job?.jobTitle,
      contractType: job?.contractType,
      jobId: id,
    });
  };

  const cvHandler = (e) => {
    const myFile = e.target.files[0];
    const value = e.target.value;
    var ext = myFile?.name.split(".").pop();
    if (ext == "pdf" || ext == "docx" || ext == "doc") {
      setData({
        ...data,
        viewURL: value,
      });
      setCv(myFile);
    } else {
      toastError("Only Pdf and word files are allowed");
    }
  };
  const handleClose = () => {
    setOpen(false);
    setData({ status: "applied" });
  };

  const toastError = (val) =>
    toast.error(val, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const submitHandle = () => {
    const { name, email, appliedFor, source, number, status, jobId, contractType } =
      data;
    if (!name) {
      toastError("Name is Required");
    } else if (!email) {
      toastError("Email is Required");
    } else if (email && !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      toastError("Enter a Valid Email");
    }
    else if (!cv) {
      toastError("CV is Required");
    }
    else if (!number) {
      toastError("Number is Required");
    } else if (!appliedFor) {
      toastError("Applied for is Required");
    } else if (!source) {
      toastError("Source is  Required");
    } else if (!contractType) {
      toastError("contractType is  Required");
    } else {
      const payload = {
        createdAt: moment().format("MMMM D YYYY, h:mm a"),
        name,
        email,
        appliedFor,
        source,
        status,
        number,
        contractType,
        jobId,
      };
      dispatch(addJobApplication(payload, cv, setloading));
      handleClose();
    }
  };

  useEffect(() => {
    dispatch(getAllJobs(setloading));
  }, []);
  return {
    open,
    allJobs,
    loading,
    data,
    handleClickOpen,
    handleClose,
    submitHandle,
    cvHandler,
    onChangeHandler,
    jobChangeHandler,
  };
}
