import React from "react";
import AttendanceTable from "./attendanceTable";
import useBootcampAttendance from "./useBootcampAttendance";

const BootcampAttendance = () => {
  const { 
    loading,
    allBootcampUser,
    date,
    handleDateUpdate,
    handleStatusUpdate,
    updateLoading,
    handleCancel,
    handleClickOpen,
    isModalVisible,
    index,
   } =
    useBootcampAttendance();
  return (
    <AttendanceTable
    pageTitle="Bootcamp Attendance"
    data={allBootcampUser}
    date={date}
    loading={loading}
    dateUpdate={handleDateUpdate}
    handleStatusUpdate={handleStatusUpdate}
    updateLoading={updateLoading}
    handleCancel={handleCancel}
    handleClickOpen={handleClickOpen}
    isModalVisible={isModalVisible}
    index={index}
    />
  );
};

export default BootcampAttendance;
