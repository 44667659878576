import React from "react";
import useAttendance from "./useAttendance";
import AttendanceTable from "./attendanceTable";

export default function Attendance() {
  const [
    loading,
    allUser,
    date,
    handleDateUpdate,
    handleStatusUpdate,
    updateLoading,
    handleCancel,
    handleClickOpen,
    isModalVisible,
    index,
  ] = useAttendance();
 
  return (
    <AttendanceTable
    pageTitle="Attendance" 
    data={allUser}
    date={date}
    loading={loading}
    dateUpdate={handleDateUpdate}
    handleStatusUpdate={handleStatusUpdate}
    updateLoading={updateLoading}
    handleCancel={handleCancel}
    handleClickOpen={handleClickOpen}
    isModalVisible={isModalVisible}
    index={index}
    />
    
  )
}
