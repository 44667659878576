import { Button } from "@material-ui/core";
import React from "react";
import {Link} from 'react-router-dom'
export default function NotFound() {
  return (
    <div
      style={{
        height: '100vh',
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >

      <Link to='/' >Go to Home</Link>
    </div>
  );
}
