import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBootcamp,
  getDeclineBootcampOnboard,
} from "./redux/actions/employeeAction";
import TeamListTableController from "./TeamListTableController";

export default function BootcampOnboarding({ location, history }) {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const { allBootcampOnboarding, decliendBootcampOnboarding } = useSelector(
    (state) => state.employeesReducer,
  );

  useEffect(() => {
    if (allBootcampOnboarding.length < 1) {
      dispatch(getAllBootcamp(setloading));
    } else setloading(false);
  }, []);

  useEffect(() => {
    if (decliendBootcampOnboarding.length < 1) {
      dispatch(getDeclineBootcampOnboard(setloading));
    } else setloading(false);
  }, []);

  const handleMore = (i) => {
    const id = allBootcampOnboarding[i]?.id;
    history.push({
      pathname: `/profile/onboard/${id}`,
      id: id,
    });
  };

  return (
    <TeamListTableController
      data={allBootcampOnboarding}
      handleMore={handleMore}
      loading={loading}
      location={location}
      history={history}
      appBarTitle="Declined"
      blockTitle="Decliend Members"
      title="Bootcamp Onboarding"
      blocks={decliendBootcampOnboarding}
    />
  );
}
