import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  // .MuiCircularProgress-determinate
  gridRoot: {
    flexGrow: 1,
    marginBottom: "20px",
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  approved: {
    backgroundColor: theme.palette.success.main,
    color: "#fff",
  },
  progress: {
    color: "white",
    // backgroundColor: "blue",
    pointerEvents: "none",
    // cursor: "not-allowed",
  },
  request: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  runPayrollButton: {
    margin: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
  circularProgress: {
    marginLeft: 0,
    color: "#fff",
  },
}));
