import React, { useState } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { deleteEmail } from "./redux/actions/meetingEmailAction";
import { CircularProgress } from "@material-ui/core";
import useStyles from "./EmailTableStyle";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { updateEmail } from "./redux/actions/meetingEmailAction";
import { SUCCESS_ALERT } from "./redux/constant";
const EmailTable = () => {
  const { allEmails } = useSelector((state) => state.meetingEmailReducer);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [copyValue, setCopyValue] = useState("");
  const [copy, setCopy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [details, setDetails] = useState({});
  const [index, setIndex] = useState(null);
  const toggleModal = (data) => {
    setModal(!modal);
    if (data) {
      setDetails(data);
    } else {
      setDetails({});
    }
  };

  const toggleDeleteModal = (i) => {
    setDeleteModal(!deleteModal);
    setIndex(i);
  };

  const handleCopy = (value) => {
    setCopyValue(value);
    setCopy(true);
    dispatch({
      type: SUCCESS_ALERT,
      payload: `${value} copied.`,
    });
  };

  const handleRemove = () => {
    setDeleteModal(false);
    dispatch(deleteEmail(allEmails?.[index]?.id, setLoading));
  };

  const columns = [
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let email = allEmails[dataIndex]?.email?.toLowerCase();
          return (
            <CopyToClipboard
              text={email}
              onCopy={() => handleCopy(email, "email")}
            >
              <span>{email}</span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "calendarId",
      label: "Calendar Id",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let calendarId = allEmails[dataIndex]?.calendarId;
          return (
            <CopyToClipboard
              text={calendarId}
              onCopy={() => handleCopy(calendarId, "calendarId")}
            >
              <span>{calendarId}</span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "Actions",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const email = allEmails?.[dataIndex];
          return (
            <>
              {loading && index === dataIndex ? (
                <CircularProgress
                  // className={classes.circularProgress}
                  size={25}
                />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => toggleDeleteModal(dataIndex)}
                    className={classes.btnMargin}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => toggleModal(email)}
                    className={classes.btnMargin}
                  >
                    Edit
                  </Button>
                  {email?.default ? (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        dispatch(
                          updateEmail({ ...email, default: false }, setLoading),
                        )
                      }
                    >
                      <CheckCircleIcon className={classes.checkIcon} />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        setIndex(dataIndex);
                        dispatch(
                          updateEmail({ ...email, default: true }, setLoading),
                        );
                      }}
                    >
                      <CancelIcon className={classes.cancelIcon} />
                    </IconButton>
                  )}
                </>
              )}
            </>
          );
        },
      },
    },
  ];

  const options = {
    searchPlaceholder: "Search here...",
    filter: false,
    searchAlwaysOpen: true,
    responsive: "standard",
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    selectableRows: false,
  };

  const theme = createTheme({
    overrides: {
      MUIDataTableHeadCell: {
        sortAction: {
          fontSize: "1rem",
          fontWeight: "bolder",
        },
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          // Soft hide the button.
          visibility: "hidden",
        },
      },
    },
  });

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          className="mainTable"
          data={allEmails}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </>
  );
};

export default EmailTable;
