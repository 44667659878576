import { db } from "../../../../config/firebase";
import { UPDATE_INTERVIEW_SHECDULE } from "../../../jobsRequests/redux/constant";
import {
  HANDLE_SIDEBAR,
  HANDLE_SELECTED_INTERVIEW,
  HANDLE_INTERVIEW_DETAIL_SIDEBAR,
  UPDATE_SELECTED_INTERVIEW,
} from "../constants/constant";

export const handleSidebar = (bool) => {
  return (dispatch) => dispatch({ type: HANDLE_SIDEBAR, status: bool });
};
export const handleInterviewDetailSidebar = (bool) => {
  return (dispatch) =>
    dispatch({ type: HANDLE_INTERVIEW_DETAIL_SIDEBAR, status: bool });
};

export const handleSelectedInterview = (event) => {
  return (dispatch) => dispatch({ type: HANDLE_SELECTED_INTERVIEW, event });
};

export const addQuestionAnswers = (data, questions) => async (dispatch) => {
  try {
    await db
      .collection("JobApplication")
      .doc(data.id)
      .update({
        questions
      });

    dispatch({
      type: UPDATE_INTERVIEW_SHECDULE,
      payload: {
        questions,
        docId: data.id
      },
    });

  } catch (error) {
    console.log(error.message);
  }
}