import React, { useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import {
  IconButton,
  InputAdornment,
  Link as MuiLink,
  TextField,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core/styles";
import { AssignmentInd, Call, Email, GitHub, Search } from "@material-ui/icons";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#F1F2F4",
    borderRadius: "10px",
    height: "fit-content",
  },
  category: {
    // marginLeft: "10px",
    minWidth: "270px",
    height: "fit-content",
    maxHeight: "70vh",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "10px",
  },
  listItem: {
    width: "270px",
    backgroundColor: "white",
    marginBottom: "10px",
    borderRadius: "5px",
    overflow: "hidden",
    paddingBottom: "10px",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginBottom: "8px",
  },
  textWeight: {
    fontWeight: "bolder",
  },
  blueColor: {
    color: "#536DFE",
  },
  margin: {
    margin: 0,
  },
}));

const Category = ({ id, title, items, setOpen, setSelectedJobData }) => {
  const classes = useStyles();
  const [categorySearchInp, setCategorySearchInp] = useState("");
  function setValue(category, value) {
    setCategorySearchInp(value);
  }
  const data =
    categorySearchInp == ""
      ? items
      : items?.filter((job) => {
          if (
            job?.fname
              ?.toLowerCase()
              ?.includes(categorySearchInp?.toLowerCase()) ||
            job?.email
              ?.toLowerCase()
              ?.includes(categorySearchInp?.toLowerCase())
          ) {
            return job;
          }
        });
  return (
    <div className={classes.container}>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            padding: "10px 20px 0px 10px",
          }}
        >
          <p
            style={{
              whiteSpace: "nowrap",
              fontWeight: "bold",
              fontSize: "1.4rem",
              marginTop: "10px",
            }}
          >
            {title}
          </p>
          <p
            className={classes.blueColor}
            style={{
              fontWeight: "bold",
              fontSize: "1.2rem",
              paddingLeft: "10px",
              margin: 0,
            }}
          >
            {items?.length}
          </p>
        </div>
      </div>
      <div className={classes.category}>
        <Droppable droppableId={id}>
          {(provided) => (
            <div
              className="list"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                minHeight: "50px",
              }}
            >
              {data
                ?.sort((a, b) => {
                  return a?.position - b?.position;
                })
                ?.map((item, index) => (
                  <Draggable
                    key={item?.docId}
                    draggableId={item?.docId}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        className={classes.listItem}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <p
                          style={{
                            textTransform: "capitalize",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            paddingTop: "15px",
                            paddingLeft: "10px",
                          }}
                        >
                          {item.fname}
                        </p>
                        <div
                          style={{
                            marginBottom: "20px",
                            padding: "0px 10px",
                          }}
                        >
                          <div className={classes.iconContainer}>
                            <Email style={{ color: "#536DFE" }} />
                            <p
                              className={classes.textWeight}
                              style={{
                                margin: 0,
                              }}
                            >
                              {item?.email ? item?.email : "NA"}
                            </p>
                          </div>
                          <div className={classes.iconContainer}>
                            <Call style={{ color: "#536DFE" }} />
                            <p
                              className={classes.textWeight}
                              style={{
                                margin: 0,
                              }}
                            >
                              {item?.phone ? item?.phone : "NA"}
                            </p>
                          </div>
                          <div className={classes.iconContainer}>
                            <GitHub style={{ color: "#536DFE" }} />
                            <p
                              className={classes.textWeight}
                              style={{
                                margin: 0,
                              }}
                            >
                              {item?.gitHub ? item?.gitHub : "NA"}
                            </p>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            justifyContent: "space-between",
                            position: "relative",
                            paddingTop: "10px",
                            borderTop: "1px solid rgb(204 193 193 / 30%)",
                          }}
                        >
                          <div
                            style={{
                              paddingLeft: "10px",
                            }}
                          >
                            <p
                              className={classes.margin}
                              style={{
                                color: "#c5baba",
                                fontSize: "1rem",
                              }}
                            >
                              Applied Via
                            </p>

                            <p
                              className={classes.textWeight}
                              style={{
                                margin: 0,
                                textAlign: "center",
                                fontSize: "0.85rem",
                              }}
                            >
                              {item?.source ? item?.source : "Main Site"}
                            </p>
                          </div>
                          <MuiLink target="_blank" href={item?.CV}>
                            <div
                              style={{
                                backgroundColor: "#536DFE",
                                display: "flex",
                                justifyContent: "center",
                                borderRadius: "50% 50% 0 0",
                                marginBottom: "-30px",
                                height: "70px",
                                width: "70px",
                                position: "absolute",
                                bottom: "-20px",
                                left: "33%",
                              }}
                            >
                              <AssignmentInd
                                style={{ color: "white", marginTop: "6.5px" }}
                              />
                            </div>
                          </MuiLink>

                          <div
                            style={{
                              paddingRight: "10px",
                            }}
                          >
                            <p
                              className={classes.margin}
                              style={{
                                color: "#c5baba",
                                fontSize: "1rem",
                              }}
                            >
                              Date Applied
                            </p>
                            <p
                              className={classes.textWeight}
                              style={{
                                margin: 0,
                                textAlign: "center",
                                fontSize: "0.85rem",
                              }}
                            >
                              {moment(item?.createdAt).format("MMMM D, YYYY")}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};

export default Category;
