import React from "react";
import classnames from "classnames";
// styles
import useStyles from "./styles";

// components
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import Footer from "./Footer";
import Content from "./TheContent";
import { useLayoutState } from "../context/LayoutContext";
function TheLayout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <div className={classes.app}>
            <Content />
          </div>
          <Footer />
        </div>
      </>
    </div>
  );
}

export default TheLayout;
