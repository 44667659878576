import {
  SET_USER,
  LOG_OUT,
  SUCCESS_ALERT,
  ERROR_ALERT,
  SET_DETAILS,
  SET_LOADING,
  RESET_USER_REDUCER,
} from "../constant/index";

import { auth, db } from "../../../../config/firebase";
import { useHistory } from "react-router-dom";

import {
  fetchAllEvents,
  getUserData,
} from "../../../../commonRedux/common/action";
import { getSidebarTabs } from "../../../../constants/sidebarConfig/sidebarConfig";
export const getCurrentUser = (setloading) => async (dispatch) => {
  try {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        dispatch({ type: SET_USER, payload: { user } });
        let data = localStorage.getItem("visibleTabs");
        data = JSON.parse(data);
        dispatch({
          type: SET_DETAILS,
          payload: data,
        });
        dispatch({ type: SUCCESS_ALERT, payload: "Welcome Back!" });
        dispatch(fetchAllEvents(setloading));
        dispatch(getUserData(setloading));
        setloading(false);
      }
      setloading(false);
    });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setloading(false);
  }
};

export const signIn = (data, setLoading, history) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true
    })
    let user;
  
    user = await auth.signInWithEmailAndPassword(data.email, data.password);
    setLoading(true);
    let adminQuery = await db
      .collection("Admins")
      .where("email", "==", data.email)
      .get();
    let admin = [];
    adminQuery.forEach((doc) => {
      admin.push({ ...doc.data(), id: doc.id });
    });
    const currentUser = admin?.[0];
    dispatch({
      type: SET_DETAILS,
      payload: { ...currentUser,uid: user.user.uid}
    });
    if (admin.length === 1) {
      if (user) {
        dispatch({
          type: SET_DETAILS,
          payload: { ...currentUser,uid: user.user.uid}
        });
      }
      getClientInfo(currentUser, history);
    } else {
      dispatch({
        type: ERROR_ALERT,
        payload: "Email not Matched.",
      });
    }
    setLoading(false);
  } catch (error) {
    console.log(error)
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setLoading(false);
  } finally {
    dispatch({
      type: SET_LOADING,
      payload: false
    })
  }
};

export const logout = (history) => async (dispatch) => {
  try {
    await auth.signOut();
    localStorage.removeItem("visibleTabs");
    dispatch({ type: LOG_OUT });
    history.replace("/login");
   dispatch({
    type:RESET_USER_REDUCER,
    
   })
    dispatch({ type: SUCCESS_ALERT, payload: "Logout successfully!" });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};

const getClientInfo = (user, history) => {
  if (user?.visibleTabs && !user.visibleTabs.includes("dashboard")) {
    const dashboard = getSidebarTabs(user?.visibleTabs || []);
    let path = dashboard[0]?.link || "";
    if (path) {
      history.push(path);
    } else {
      history.push("/notfound");
    }
  } else {
    history.push("/dashboard");
  }
};
