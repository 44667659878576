import {
  GET_ATTENDANCE_REPORT,
  RESET_ATTENDANCE_REPORT,
} from "../constants/reportsConstant";
const initialState = {
  attendance: [],
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ATTENDANCE_REPORT:
      return {
        ...state,
        attendance: action?.payload,
      };
    case RESET_ATTENDANCE_REPORT:
      return {
        ...state,
        attendance: [],
      };
    default:
      return state;
  }
};

export default reportsReducer;
