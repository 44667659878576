import React, { useState } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { successMessage } from "./redux/actions/lettersAction";
import { CircularProgress } from "@material-ui/core";
import useStyles from "./LettersStyle";
import Button from "@material-ui/core/Button";
import { getDoc } from "../../commonUtils/share";
import { sendLetter } from "./redux/actions/lettersAction";
import AddLetter from "./addLetter/Addletter";

const Table = () => {
  const { letters } = useSelector((state) => state.lettersReducer);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [copyValue, setCopyValue] = useState("");
  const [copy, setCopy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState();
  const [open, setOpen] = useState(false);
  const toggleModal = () => {
    setOpen(!open);
  };

  const handleCopy = (value, fieldName) => {
    setCopyValue(value);
    setCopy(true);
    dispatch(successMessage(`${value} copied.`));
  };

  const columns = [
    {
      name: "docId",
      label: "Ref #:",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          const docId = letters[dataIndex]?.docId;
          return (
            <CopyToClipboard
              text={docId}
              onCopy={() => handleCopy(docId, "referenceId")}
            >
              <span>{docId}</span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          const date = letters[dataIndex]?.date;
          return <span>{date}</span>;
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          const name = letters[dataIndex]?.name;
          return <span>{name}</span>;
        },
      },
    },
    {
      name: "fatherName",
      label: "Father Name",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          const fatherName = letters[dataIndex]?.fatherName || "";
          return <span>{fatherName}</span>;
        },
      },
    },
    {
      name: "cnic",
      label: "CNIC",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          const cnic = letters[dataIndex]?.cnic || "";
          return <span>{cnic}</span>;
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let email = letters[dataIndex]?.email?.toLowerCase();
          return (
            <CopyToClipboard
              text={email}
              onCopy={() => handleCopy(email, "email")}
            >
              <span>{email}</span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "docType",
      label: "Type",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          const fullName = letters[dataIndex]?.docType;
          return <span>{fullName}</span>;
        },
      },
    },

    {
      name: "Actions/DOC URL",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const details = letters[dataIndex];
          const url = details?.url || "NA";
          return loading && activeIndex === details?.docId ? (
            <CircularProgress />
          ) : details?.isAuto ? (
            <>
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={() => {
                  getDoc(details, setLoading, details?.docId, setActiveIndex);
                }}
              >
                View
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  dispatch(
                    sendLetter(
                      details,
                      setLoading,
                      details?.docId,
                      setActiveIndex,
                    ),
                  )
                }
                className={classes.btnMargin}
              >
                Resend
              </Button>
            </>
          ) : url === "NA" ? (
            url
          ) : (
            <CopyToClipboard
              text={url}
              onCopy={() => handleCopy(details?.url || "url")}
            >
              <span>URL</span>
            </CopyToClipboard>
          );
        },
      },
    },
  ];

  const options = {
    searchPlaceholder: "Search here...",
    filter: false,
    searchAlwaysOpen: true,
    responsive: "standard",
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    selectableRows: false, // <===== will turn off checkboxes in rows

    isRowExpandable: (dataIndex, expandedRows) => {
      return false;
    },
  };

  const theme = createTheme({
    overrides: {
      MUIDataTableHeadCell: {
        sortAction: {
          fontSize: "1rem",
          fontWeight: "bolder",
        },
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          // Soft hide the button.
          visibility: "hidden",
        },
      },
    },
  });

  const components = {};

  return (
    <>
      <Button
        className={"mb-2"}
        variant="contained"
        color="primary"
        onClick={() => toggleModal()}
      >
        Add Letter
      </Button>
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          className="mainTable"
          data={letters}
          columns={columns}
          options={options}
          components={components}
        />
      </MuiThemeProvider>
      <AddLetter open={open} toggle={toggleModal} />
    </>
  );
};

export default Table;
