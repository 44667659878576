import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, TextField } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { useStyles } from "./leavePolicyStyle";
import { CircularProgress } from "@material-ui/core";
import { convertToHTML } from "draft-convert";
import DraftPasteProcessor from "draft-js/lib/DraftPasteProcessor";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import {
  updateLeavePolicy,
  getLeavePolicy,
} from "./redux/actions/leavePolicyAction";
import { getDefaultLeavePolicy } from "./DefaultLeavePolicy";
const LeavePolicy = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { leavePolicy } = useSelector((state) => state?.leavePolicyReducer);
  const [date, setDate] = useState({});
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const [convertedContent, setConvertedContent] = useState(``);
  useEffect(() => {
    dispatch(getLeavePolicy(setLoading));
  }, []);
  const setEditorContent = (policy) => {
    let editorState;
    const setProcessedHTML = DraftPasteProcessor.processHTML(policy);
    const setContentState = ContentState.createFromBlockArray(setProcessedHTML);
    editorState = EditorState.createWithContent(setContentState);
    editorState = EditorState.moveFocusToEnd(editorState);
    setEditorState(editorState);
  };

  useEffect(() => {
    if (leavePolicy !== null) {
      if (leavePolicy?.date && leavePolicy?.policy !== "") {
        setEditorContent(leavePolicy?.policy);
        setDate(leavePolicy?.date);
      } else {
        const policy = getDefaultLeavePolicy();
        setEditorContent(policy);
      }
    }
  }, [leavePolicy]);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };
  const onChangeHandler = (e) => {
    const value = e?.target?.value;
    setDate(value);
  };
  const submitHandler = async () => {
    try {
      dispatch(
        updateLeavePolicy({ date, policy: convertedContent }, setLoading),
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        <h1 className="Details">Leave Policy Details</h1>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item xs={12} md={12} sm={12}>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item md={12}>
                <TextField
                  id="modifiedDate"
                  name="modifiedDate"
                  label="*Modified date"
                  type="date"
                  className={classes.formControl}
                  fullWidth
                  value={date}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={onChangeHandler}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Editor
                  editorState={editorState}
                  // className={classes.input}
                  wrapperClassName={classes.wrapper}
                  editorClassName={classes.editor}
                  toolbarClassName={classes.toolbar}
                  onEditorStateChange={handleEditorChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item md={12} xs={12}>
            {loading ? (
              <CircularProgress className={classes.loader} />
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={submitHandler}
                  className={classes.button}
                >
                  Update
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default LeavePolicy;
