import React, { useState } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { useSelector } from "react-redux";

const AttendanceReportTable = () => {
  const { attendance } = useSelector((state) => state?.reportsReducer);
  const data = attendance?.data;
  const title = attendance?.title;
  const columns = [
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let title = data[dataIndex]?.title;
          return <span>{title}</span>;
        },
      },
    },
    {
      name: "label",
      label: "Leave Type",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let label = data[dataIndex]?.label;
          return <span>{label}</span>;
        },
      },
    },
    {
      name: "start",
      label: "Start date",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let start = data[dataIndex]?.start;
          return <span>{start}</span>;
        },
      },
    },
    {
      name: "end",
      label: "End date",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let end = data[dataIndex]?.end;
          return <span>{end}</span>;
        },
      },
    },
    {
      name: "daysCount",
      label: "Days",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let daysCount = data[dataIndex]?.daysCount;
          return <span>{daysCount}</span>;
        },
      },
    },
  ];

  const options = {
    searchPlaceholder: "Search here...",
    filter: false,
    searchAlwaysOpen: false,
    responsive: "standard",
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    selectableRows: false,
    title: true,
  };

  const theme = createTheme({
    overrides: {
      MUIDataTableHeadCell: {
        sortAction: {
          fontSize: "1rem",
          fontWeight: "bolder",
        },
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          // Soft hide the button.
          visibility: "hidden",
        },
      },
    },
  });
  return (
    <>
      {data?.length ? (
        <MuiThemeProvider theme={theme}>
          <MUIDataTable
            title={title}
            className="mainTable"
            data={data}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      ) : null}
    </>
  );
};

export default AttendanceReportTable;
