import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { Menu, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useStyles } from './addJobType/style';
import { updateJobType } from '../redux/actions';

export default function UpdateDialog({ item }) {
    const [updateDialog, setUpdateDialog] = useState(null);
    const [updateitemValue, setUpdateitemValue] = useState("");
    const dispatch = useDispatch()
    const classes = useStyles();

    const openUpdateDialog = (e, val) => {
        setUpdateDialog(e.currentTarget)
        setUpdateitemValue(val)
    }

    const ItemUpdateHandler = (id) => {
        dispatch(updateJobType(id, updateitemValue))
        setUpdateDialog(null)
        setUpdateitemValue('')
    }
    return (
        <div className={classes.item}>
            <Button
                size="small"
                color="primary"
                onClick={(e) => { openUpdateDialog(e, item.type) }}
            >
                Update
            </Button>
            <div>
                <Menu
                    id="simple-menu"
                    anchorEl={updateDialog}
                    keepMounted
                    open={Boolean(updateDialog)}
                    onClose={() => { setUpdateDialog(null) }}
                >
                    <div>
                        <TextField
                            autoFocus
                            value={updateitemValue}
                            className={classes.textField}
                            label="Update Job Type"
                            onChange={(e) => { setUpdateitemValue(e.target.value) }}
                        />
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.addButton}
                            onClick={() => { ItemUpdateHandler(item.docId) }}
                        >
                            Update
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.addButton}
                            onClick={() => { setUpdateDialog(null) }}
                        >
                            Cancel
                        </Button>
                    </div>
                </Menu>
            </div>
        </div>
    )
}
