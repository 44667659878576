export const ROUTE_NAME = {
  DASHBOARD: "dashboard",
  ONBOARDING: "onboarding",
  BOOTCAMP_ONBOARDING: "bootcampOnboarding",
  RANKS: "ranks",
  INTERVIEW_QUESTIONS: "interviewQuestions",
  TEAM: "team",
  BOOTCAMP_TEAM: "bootcampTeam",
  TEAM_INFO: "teamInfo",
  TEAM_RANKS: "teamRanks",
  USER_PROFILE: "userProfile",
  JOBS: "jobs",
  EVENTS: "events",
  LETTERS: "letters",
  LEAVE_REQUEST: "leaverequest",
  BOOTCAMP_LEAVE_REQUEST: "bootcampLeaveRequest",
  CONTACT_REQUEST: "contactRequest",
  JOB_REQUEST: "jobRequest",
  JOBS_BOARD: "JobsBoard",
  ATTENDANCE: "attendance",
  BOOTCAMP_ATTENDANCE: "bootcampAttendance",
  PAYROLL: "payroll",
  PAYSTUB: "paystub",
  INTERVIEWS: "interviews",
  MEETINGS: "meetings",
  BANKS: "banks",
  CONTRACT: "contract",
  ADMINS: "admins",
  LEAVE_POLICY: "leavePolicy",
  ATTENDANCE_REPORT: "attendanceReport",
};
export const visibleTabOptions = [
  { title: "Dashboard", value: ROUTE_NAME?.DASHBOARD },
  { title: "OnBoarding", value: ROUTE_NAME?.ONBOARDING },
  { title: "Bootcamp OnBoarding", value: ROUTE_NAME?.BOOTCAMP_ONBOARDING},
  { title: "Ranks", value: ROUTE_NAME?.RANKS },
  { title: "Interview Questions", value: ROUTE_NAME?.INTERVIEW_QUESTIONS },
  { title: "Team", value: ROUTE_NAME?.TEAM },
  { title: "Bootcamp Team", value: ROUTE_NAME?.BOOTCAMP_TEAM },
  { title: "Team Info", value: ROUTE_NAME?.TEAM_INFO },
  { title: "Team Ranks", value: ROUTE_NAME?.TEAM_RANKS },
  { title: "User Profile", value: ROUTE_NAME?.USER_PROFILE },
  { title: "Jobs", value: ROUTE_NAME?.JOBS },
  { title: "Events", value: ROUTE_NAME?.EVENTS },
  { title: "Letters", value: ROUTE_NAME?.LETTERS },
  { title: "Leave Request", value: ROUTE_NAME?.LEAVE_REQUEST },
  { title: "Bootcamp Leave Request", value: ROUTE_NAME?.BOOTCAMP_LEAVE_REQUEST },
  { title: "Contact Request", value: ROUTE_NAME?.CONTACT_REQUEST },
  { title: "Job Application", value: ROUTE_NAME?.JOB_REQUEST },
  // { title: "Jobs Board", value: ROUTE_NAME?.JOBS_BOARD },
  { title: "Attendance", value: ROUTE_NAME?.ATTENDANCE },
  { title: "Bootcamp Attendance", value: ROUTE_NAME?.BOOTCAMP_ATTENDANCE},
  { title: "Payroll", value: ROUTE_NAME?.PAYROLL },
  { title: "Paystub", value: ROUTE_NAME?.PAYSTUB },
  { title: "Interviews", value: ROUTE_NAME?.INTERVIEWS },
  { title: "Meetings", value: ROUTE_NAME?.MEETINGS },
  { title: "Banks", value: ROUTE_NAME?.BANKS },
  { title: "Contract", value: ROUTE_NAME?.CONTRACT },
  { title: "Admins", value: ROUTE_NAME?.ADMINS },
  { title: "Leave Policy", value: ROUTE_NAME?.LEAVE_POLICY },
  { title: "Attendance Report", value: ROUTE_NAME?.ATTENDANCE_REPORT },
];
