import {
  GET_USER_DATA,
  GET_BOOTCAMPER_DATA,
  ERROR_ALERT,
  FETCH_ALL_EVENTS,
  SET_EMPLOYEES,
  SET_BOOTCAMPER_TEAM,
} from "../common/constants";

const initialState = {
  allEmployees: [],
  allBootcampTeam: [],
  errorMessage: "",
  allEvents: [],
  allMembers: [],
  bootcampMembers: [],
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DATA:
      return {
        ...state,
        allMembers: action.payload,
      };
    case GET_BOOTCAMPER_DATA:
      return {
        ...state,
        bootcampMembers: action.payload,
      };
    case SET_EMPLOYEES:
      return {
        ...state,
        allEmployees: action.payload,
      };
    case SET_BOOTCAMPER_TEAM:
      return {
        ...state,
        allBootcampTeam: action.payload,
      };
    case ERROR_ALERT:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case FETCH_ALL_EVENTS:
      return {
        ...state,
        allEvents: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
