import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  tdc: {
    // color: "grey",
    // fontWeight: "bolder",
    // fontSize: "16px",
    // alignItems: "center",
  },
  addButton: {
    marginLeft: theme.spacing(2),
    float: "left",
    textAlign: "left",
    marginBottom: "10px",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  bttn: {
    marginTop: "12px",
    borderRadius: "15px",
    marginLeft: "8px",
    fontSize: "10px",
    marginRight: "16px",
  },
  Table: {
    width: "100%",
    fontSize: "14px",
    display: "flex",
    flexDirection: "column",
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    backgroundColor: "white",
    overflow: "scroll",
    height: "100vh",
    marginBottom: '60px',
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "20px 0",
    borderBottom: "1px solid #e8e8e8",
  },
  bodyRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 0",
    backgroundColor: "white",
    borderBottom: "1px solid #e8e8e8",
  },
  Image: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
  },
  firstCeil: {
    minWidth: "80px",
    textAlign: "center",
    // border:'ridge'
  },
  secondCeil: {
    minWidth: "180px",
    // border:'ridge'
  },
  third: {
    minWidth: "150px",
    // border:'ridge'
  },
  fourth: {
    minWidth: "200px",
    // border:'ridge'
  },
  five: {
    minWidth: "100px",
    textAlign: "center",
  },
  six: {
    minWidth: "100px",
    textAlign: "center",
  },
}));
