import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Select, Typography } from "antd";
import { Grid, TextField } from "@material-ui/core";
import { updateJob } from "../redux/actions/jobsActions";
import { getAllJobTypes } from "../../ranks/redux/actions";

export default function JobUpdateModal({ visible, setVisible, job }) {
  const [data, setData] = useState(job);
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const jobTypes = useSelector((state) => state.ranksReducer.jobTypes);
  const setState = (value) => {
    setData({
      ...data,
      ...value,
    });
  };
  useEffect(() => {
    dispatch(getAllJobTypes());
  }, []);

  const handleOk = () => {
    // console.log("data", data);
    dispatch(updateJob(data, setLoading));
    setVisible(false);
  };

  function onSearch(val) {
    // console.log("search:", val);
  }

  return (
    <Modal
      title={"Update Record"}
      centered
      visible={visible}
      onOk={handleOk}
      onCancel={() => setVisible(false)}
      width={600}
      okText="Update"
    >
      {!loading ? (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12} md={12} sm={12}>
            <TextField
              size="small"
              style={{ width: 300, marginBottom: "10px" }}
              label="Job Title"
              value={data.jobTitle}
              variant="outlined"
              onChange={(e) => setState({ jobTitle: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <Select
              showSearch
              style={{ width: 300, marginBottom: "10px" }}
              placeholder="Select Job Type"
              optionFilterProp="children"
              onChange={(e) => setState({ jobType: e })}
              onSearch={onSearch}
              defaultValue={data.jobType}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {jobTypes?.map((job, index) => (
              <Option value={job.type} key={job.type + index}>
                {job.type}
              </Option>
            ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <Select
              showSearch
              style={{ width: 300, marginBottom: "10px" }}
              placeholder="Choose Job Category"
              optionFilterProp="children"
              onChange={(e) => setState({ jobCategory: e })}
              defaultValue={data.jobCategory}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="Onsite">Onsite</Option>
              <Option value="Remote">Remote</Option>
            </Select>
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <Select
              showSearch
              style={{ width: 300, marginBottom: "10px" }}
              placeholder="Select Job Location"
              optionFilterProp="children"
              defaultValue={data.jobLocation}
              onChange={(e) => setState({ jobLocation: e })}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="Faisalabad">Faisalabad</Option>
            </Select>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <Grid item xs={12} md={12} sm={12}>
            <Typography component="div" align="center">
              Please Wait...
            </Typography>
          </Grid>
        </Grid>
      )}
    </Modal>
  );
}
