import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  approved: {
    textTransform: 'Capitalize',
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  blocked: {
    textTransform: 'Capitalize',
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  declined: {
    textTransform: 'Capitalize',
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  },
  onboarding: {
    textTransform: 'Capitalize',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  moreButton: {
    textTransform: 'Capitalize',
    padding: "0px 12px",
    borderRadius: "16px"
  },
  MUIDataTableBodyCell:{
    backgroundColor: "#FF0000",
  }
}));
