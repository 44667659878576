import React, { useState, useEffect } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import { deleteMeeting } from "./redux/actions/meetingAction";
import { getAllEmails } from "./redux/actions/meetingEmailAction";
import { CircularProgress } from "@material-ui/core";
import useStyles from "./MeetingTableStyle";
import { Button, Link } from "@material-ui/core/";
import { ConfirmModal, MeetingModal } from "./components/modals/Modals";
const MeetingTable = () => {
  const { allMeetings } = useSelector((state) => state.meetingReducer);
  const { allEmails } = useSelector((state) => state.meetingEmailReducer);
  let defaultEmails = [];
  allEmails.map((item) => {
    if (item?.default) {
      defaultEmails.push(item?.calendarId);
    }
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [index, setIndex] = useState(null);
  const [modal, setModal] = useState(false);
  const [details, setDetails] = useState({});

  useEffect(() => {
    if (!allEmails?.length) {
      dispatch(getAllEmails(setLoading));
    }
  });
  const toggleModal = (data) => {
    setModal(!modal);
    if (data) {
      setDetails(data);
    } else {
      setDetails({});
    }
  };

  const toggleDeleteModal = (i) => {
    setDeleteModal(!deleteModal);
    setIndex(i);
  };

  const handleRemove = () => {
    setDeleteModal(false);
    dispatch(deleteMeeting(allMeetings?.[index], setLoading));
  };

  const columns = [
    {
      name: "summary",
      label: "Title",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let summary = allMeetings[dataIndex]?.summary;
          return <span>{summary}</span>;
        },
      },
    },
    {
      name: "startDate",
      label: "Start Time",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const startDate = allMeetings[dataIndex]?.startDate;
          return <span>{startDate}</span>;
        },
      },
    },
    {
      name: "endDate",
      label: "End Time",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const endDate = allMeetings[dataIndex]?.endDate;
          return <span>{endDate}</span>;
        },
      },
    },
    {
      name: "joiningLink",
      label: "Joining Link",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const pathname = allMeetings[dataIndex]?.joiningLink;
          return (
            <Link href={pathname} target={"_blank"}>
              Joining Link
            </Link>
          );
        },
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const description = allMeetings[dataIndex]?.description;
          return <span>{description}</span>;
        },
      },
    },

    {
      name: "Actions",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              {loading && index === dataIndex ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => toggleDeleteModal(dataIndex)}
                    className={classes.btnMargin}
                  >
                    Delete
                  </Button>
                </>
              )}
            </>
          );
        },
      },
    },
  ];

  const options = {
    searchPlaceholder: "Search here...",
    filter: false,
    searchAlwaysOpen: true,
    responsive: "standard",
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    selectableRows: false,
  };

  const theme = createTheme({
    overrides: {
      MUIDataTableHeadCell: {
        sortAction: {
          fontSize: "1rem",
          fontWeight: "bolder",
        },
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          // Soft hide the button.
          visibility: "hidden",
        },
      },
    },
  });

  return (
    <>
      <Button
        className={"mb-2"}
        variant="contained"
        color="primary"
        onClick={() => toggleModal()}
      >
        Add Meeting
      </Button>
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          className="mainTable"
          data={allMeetings}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
      <ConfirmModal
        message="Are you sure for delete this meeting ?"
        modal={deleteModal}
        toggle={toggleDeleteModal}
        action={handleRemove}
      />
      <MeetingModal
        modal={modal}
        toggle={toggleModal}
        data={details}
        emailOptions={allEmails}
        defaultEmails={defaultEmails}
      />
    </>
  );
};

export default MeetingTable;
