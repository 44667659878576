import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    approved: {
        textTransform: 'Capitalize',
        backgroundColor: theme.palette.success.main,
        color: '#fff',
    },
    level: {
        textTransform: 'Capitalize',
        backgroundColor: '#536dfe',
        color: '#fff',
    },
    table: {
        width: '100%',
        fontSize: '14px',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
        backgroundColor: 'white',
        overflow: 'scroll',
        height:'100vh',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '20px 0',
        borderBottom: '1px solid #e8e8e8',
    },
    bodyRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 0',
        backgroundColor: 'white',
        borderBottom: '1px solid #e8e8e8',
    },
    Image: {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
    },
    firstCeil: {
        minWidth: '80px',
        textAlign: 'center',
        // border:'ridge'
    },
    secondCeil: {
        minWidth: '180px',
        // border:'ridge'
    },
    third: {
        minWidth: '150px',
        // border:'ridge'
    },
    fourth: {
        minWidth: '150px',
        // border:'ridge'
    },
    five: {
        minWidth: '100px',
        textAlign: 'center',
    },
    six: {
        minWidth: '150px',
        textAlign: 'center',
    },
}));
