export const GET_ALL_JOBS = "GET_ALL_JOBS";
export const ADD_JOB = "ADD_JOB";
export const EDIT_JOB = "EDIT_JOB";
export const DELETE_JOB = "DELETE_JOB";
export const SUCCESS_ALERT = "SUCCESS_ALERT";
export const ERROR_ALERT = "ERROR_ALERT";
export const UPDATE_JOB = "UPDATE_JOB";
export const ADD_JOB_FIELD = "ADD_JOB_FIELD";
export const DELETE_JOB_FIELD = "DELETE_JOB_FIELD";
export const EDIT_JOB_FIELD = "EDIT_JOB_FIELD";
export const GET_ALL_FIELDS = "GET_ALL_FIELDS";
