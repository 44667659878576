import React, { useEffect, useState } from "react";
import CalendarApp from "./Calendar";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function Interviews(props) {
  const {
    searchedJobs,
    appliedJobs,
    declinedJobs,
    futureJobs,
    interviewScheduleJobs,
    interviewRescheduleJobs,
    interviewCancelJobs,
    interviewedJobs,
    hiredJobs,
  } = useSelector((state) => state.jobsRequestsReducer);
  let Jobs = [
    ...searchedJobs,
    ...appliedJobs,
    ...declinedJobs,
    ...futureJobs,
    ...interviewScheduleJobs,
    ...interviewRescheduleJobs,
    ...interviewCancelJobs,
    ...interviewedJobs,
    ...hiredJobs,
  ];

  const { id } = useParams();

  let user = null;
  if (typeof id !== "undefined") {
    user = Jobs?.filter((job) => job.docId === id)[0];
  }

  return (
    <div>
      <>
        <CalendarApp user={user} />
      </>
    </div>
  );
}
export default Interviews;
