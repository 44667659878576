import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  // Fab,
  // Link,
} from "@material-ui/core";

import { Menu as MenuIcon, Person as AccountIcon } from "@material-ui/icons";

import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";
import Notification from "../Notification/Notification";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
// import UserAvatar from "../UserAvatar/UserAvatar";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

import { useSelector, useDispatch } from "react-redux";

import {} from "react-redux";
import { useHistory } from "react-router-dom";
import SuccessAlert from "../alert/successAlert";
import ErrorAlert from "../alert/errorAlert";
import { logout } from "../../module/auth/redux/action";

const notifications = [
  { id: 0, color: "warning", message: "Check out this awesome ticket" },
  {
    id: 1,
    color: "success",
    type: "info",
    message: "What is the best way to get ...",
  },
  {
    id: 2,
    color: "secondary",
    type: "notification",
    message: "This is just a simple notification",
  },
  {
    id: 3,
    color: "primary",
    type: "e-commerce",
    message: "12 new orders has arrived today",
  },
];

export default function Header(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  // var userDispatch = useUserDispatch();
  const { details } = useSelector((state) => state.userReducer);
  const { errorMessage, successMessage } = useSelector(
    (state) => state.employeesReducer,
  );

  // local
  // var [mailMenu, setMailMenu] = useState(null);
  // var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [profileMenu, setProfileMenu] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const signout = () => {
    dispatch(logout(history));
  };

  return (
    <>
      {errorMessage ? <ErrorAlert message={errorMessage} /> : null}
      {successMessage ? <SuccessAlert message={successMessage} /> : null}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            onClick={() => toggleSidebar(layoutDispatch)}
            className={classNames(
              classes.headerMenuButtonSandwich,
              classes.headerMenuButtonCollapse,
            )}
          >
            {layoutState.isSidebarOpened ? (
              <MenuOpenIcon
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse,
                  ),
                }}
              />
            ) : (
              <MenuIcon
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse,
                  ),
                }}
              />
            )}
          </IconButton>
          <Link to="/">
            <img src={logo} alt="Logo" width={"150px"} />
          </Link>
          <div className={classes.grow} />

          <IconButton
            aria-haspopup="true"
            color="inherit"
            className={classes.headerMenuButton}
            aria-controls="profile-menu"
            onClick={(e) => setProfileMenu(e.currentTarget)}
          >
            <AccountIcon classes={{ root: classes.headerIcon }} />
          </IconButton>

          <Menu
            id="notifications-menu"
            open={Boolean(notificationsMenu)}
            anchorEl={notificationsMenu}
            onClose={() => setNotificationsMenu(null)}
            className={classes.headerMenu}
            disableAutoFocusItem
          >
            {notifications.map((notification) => (
              <MenuItem
                key={notification.id}
                onClick={() => setNotificationsMenu(null)}
                className={classes.headerMenuItem}
              >
                <Notification {...notification} typographyVariant="inherit" />
              </MenuItem>
            ))}
          </Menu>
          <Menu
            id="profile-menu"
            open={Boolean(profileMenu)}
            anchorEl={profileMenu}
            onClose={() => setProfileMenu(null)}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >
            <div className={classes.profileMenuUser}>
              <Typography variant="h4" weight="medium">
                {details?.name || ""}
              </Typography>
              <Typography className={classes.profileMenuLink} color="success">
                {details?.email || ""}
              </Typography>
            </div>
            <div className={classes.profileMenuUser}>
              <Typography
                className={classes.profileMenuLink}
                color="primary"
                onClick={signout}
              >
                Sign Out
              </Typography>
            </div>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
}
