import {
  ERROR_ALERT,
  GET_PAYSTUB,
  QUARTERLY_TAX,
} from "../constant/index";
import { db } from "../../../../config/firebase";
import moment from "moment";

export const getPaystubAction = (setLoading, date) => async (dispatch) => {
  let start = new Date(date.setHours(0, 0, 0, 0));
  let end = new Date(date.setHours(23, 59, 59, 999));
  try {
    setLoading(true);
    const startOfMonth = moment(start).startOf("month").toDate();
    const endOfMonth = moment(end).endOf("month").toDate();
    const paystubData = [];
    const paystubDocs = await db.collectionGroup('UserPaystubs')
      .where("createdAt", ">=", startOfMonth)
      .where("createdAt", "<=", endOfMonth)
      .get();

    paystubDocs.forEach((doc) => {
      const data = doc?.data();
      const id = doc?.id;
      const authId = doc.ref?.parent?.parent?.id;
      paystubData.push({ ...data, id, userId: authId });
    });
    dispatch({
      type: GET_PAYSTUB,
      payload: paystubData,
    });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setLoading(false);
  }
};

export const getQuarterlyTaxData = (startMonth, endMonth) => async (dispatch) => {
  try {
    let startDate, endDate;
    if (!startMonth) {
      startDate = moment().subtract(1, 'months').startOf('month').toDate();
    } else {
      startDate = moment(startMonth, 'YYYY-MM').startOf('month').toDate();
    }
    if (!endMonth) {
      endDate = moment().subtract(1, 'months').endOf('month').toDate();
    } else {
      endDate = moment(endMonth, 'YYYY-MM').endOf('month').toDate();
    }

    const taxQuery = await db.collectionGroup('UserPaystubs')
      .where("startOfMonth", ">=", startDate)
      .where("startOfMonth", "<=", endDate)
      .get();
      
    const quarterlyTaxDataMap = {};

    taxQuery.forEach((doc) => {
      const data = doc.data();
      const userId = data?.userId;
      const fullName = data?.fullName;
      const idCardNumber = data?.idCardNumber;
      const tax = data?.tax || 0;
      const totalSalary = data?.totalSalary || 0;
      
      if (tax > 0) {
        if (!quarterlyTaxDataMap[userId]) {
          quarterlyTaxDataMap[userId] = {
            userId,
            fullName,
            idCardNumber: idCardNumber || null,
            totalTax: 0,
            totalSalaryAmount: 0,
          };
        } else if (idCardNumber && !quarterlyTaxDataMap[userId].idCardNumber) {
          quarterlyTaxDataMap[userId].idCardNumber = idCardNumber;
        }

        quarterlyTaxDataMap[userId].totalTax += tax;
        quarterlyTaxDataMap[userId].totalSalaryAmount += totalSalary;
      }
    });
    dispatch({
      type: QUARTERLY_TAX,
      payload: quarterlyTaxDataMap,
    });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};