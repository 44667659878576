import {
  GET_ALL_LEAVES,
  GET_BOOTCAMP_LEAVES,
  SUCCESS_ALERT,
  ERROR_ALERT,
  APPROVE_LEAVE,
  DECLINE_LEAVE,
} from "../constant/index";
import { db } from "../../../../config/firebase";
import axiosTechloset from "../../../../config/axiosTechloset";
import moment from "moment";

export const getAllLeaves = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    const attendence = await db
      .collection("Attendance")
      .where("label", "in", ["leave", "halfDayLeave"])
      .get()
    const listItems = attendence.docs?.map((doc) => ({
      id: doc?.id,
      ...doc?.data(),
    }));

    const promises = listItems?.map(async (el) => {
      const id = el?.userId;
      if (id) {
        const userDoc = await db.collection("UserData").doc(id).get();
        if (userDoc.exists) {
          const userData = userDoc.data();
          if (userData.employmentType !== "Bootcamp") {
            const { email, fullName } = userData;
            return {
              ...el,
              email,
              name: fullName,
            };
          }
        }
      }
      return null;
    });
    const resolvedData = await Promise.all(promises);
    const data = resolvedData.filter((item) => item !== null);

    dispatch({ type: GET_ALL_LEAVES, payload: data });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setloading(false);
  }
};

export const getBootcampLeaves = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    const attendence = await db
      .collection("Attendance")
      .where("label", "in", ["leave", "halfDayLeave"])
      .get();
    const listItems = attendence.docs?.map((doc) => ({
      id: doc?.id,
      ...doc?.data(),
    }));

    const promises = listItems?.map(async (el) => {
      const id = el?.userId;
      if (id) {
        const userDoc = await db.collection("UserData").doc(id).get();
        if (userDoc.exists) {
          const userData = userDoc.data();
          if (userData.employmentType === "Bootcamp") {
            const { email, fullName } = userData;
            return {
              ...el,
              email,
              name: fullName,
            };
          }
        }
      }
      return null;
    });
    const resolvedData = await Promise.all(promises);
    const data = resolvedData.filter((item) => item !== null);
    dispatch({ type: GET_BOOTCAMP_LEAVES, payload: data });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setloading(false);
  }
};

export const approveLeave = (data, status, setloading,cb) => async (dispatch) => {
  try {
    let { id, email, ...rest } = data;
    setloading(true);
    // let startDate = moment(data.start.toDate()).format("lll");
    // let endDate = moment(data.end.toDate()).format("lll");
    await db.collection("Attendance").doc(data.id).update({ label: status });
    dispatch({ type: SUCCESS_ALERT, payload: "Leave Approved Successfully." });
    cb()
    // axiosTechloset
    //   .post(`/emails/leave`, {
    //     email: data.email,
    //     name: data.name,
    //     startDate,
    //     endDate,
    //     label: status,
    //   })
    //   .then(
    //     (response) => {
    //       dispatch({ type: SUCCESS_ALERT, payload: response.data });
    //     },
    //     (error) => {
    //       dispatch({ type: ERROR_ALERT, payload: error.message });
    //       console.log(error);
    //     },
    //   );

    // let send = {
    //   userId: data.userId,
    //   attendanceStatus: status,
    //   date: new Date(),
    // };
    // const docRef = db.collection("Attendance").doc();
    // await docRef.set(send);

    dispatch({ type: APPROVE_LEAVE, payload: { ...data, label: status } });
    setloading(false);
  } catch (error) {
    console.log(error);
    setloading(false);
  }
};

export const declineLeave = (data, setloading) => async (dispatch) => {
  try {
    setloading(true);
    await db.collection("Attendance").doc(data.id).delete();
    dispatch({ type: DECLINE_LEAVE, payload: data.id });
    dispatch({ type: SUCCESS_ALERT, payload: "Leave Declined Successfully." });
    // axiosTechloset
    //   .post(`/emails/declineleave`, {
    //     email: data.email,
    //     name: data.name,
    //   })
    //   .then(
    //     (response) => {
    //       dispatch({ type: SUCCESS_ALERT, payload: response.data });
    //     },
    //     (error) => {
    //       dispatch({ type: ERROR_ALERT, payload: error.message });
    //       console.log(error);
    //     },
    //   );
    setloading(false);
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};

export const successMessage = (message) => async (dispatch) => {
  try {
    dispatch({ type: SUCCESS_ALERT, payload: message });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};

export const errorMessage = (message) => async (dispatch) => {
  try {
    dispatch({ type: ERROR_ALERT, payload: message });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};
