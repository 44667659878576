import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { convertToHTML } from "draft-convert";
import DraftPasteProcessor from "draft-js/lib/DraftPasteProcessor";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import firebase from "firebase";
import { Grid, Paper, Button, TextField } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import SelectMaterial from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ERROR_ALERT } from "../../../../commonUtils/share";
import { documentHandler } from "../Hooks";
import {
  addJobAction,
  getJobById,
  updateJob,
  setCurrentJob,
} from "../../redux/actions/jobsActions.js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useStyles } from "./style";
import "../../style.css";
import CircularLoader from "../../../utils/circularProgress";
import { getAllJobTypes } from "../../../ranks/redux/actions";
import { getAllContractTypes } from "../../../contract/redux/actions/contractTypeAction";
import moment from "moment";
import JobApplicationFields from "../jobApplicationFields/JobApplicationFields";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateJobComponent = ({ location }) => {
  const { currentJob } = useSelector((state) => state.jobsReducer);
  const { jobTypes } = useSelector((state) => state.ranksReducer);
  const { allContractTypes } = useSelector(
    (state) => state?.contractTypeReducer,
  );
  const AllFields = useSelector((state) => state.jobsReducer.jobFields);

  const [loading, setloading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [jobImage, setJobImage] = useState("");
  const [jobFields, setJobFileds] = useState([]);
  const [open, setOpen] = React.useState(true);
  const [job, setJob] = useState({});
  const history = useHistory();
  const { pathname } = location;
  const { id } = useParams();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const [convertedContent, setConvertedContent] = useState(null);

  const handleClose = () => {
    setOpen(false);

    history.replace({
      pathname: "/jobs",
    });
    dispatch(setCurrentJob());
  };

  useEffect(() => {
    let editorState;
    if (currentJob !== undefined) {
      console.log("currentJob", currentJob);
      if (Object?.keys(currentJob)?.length) {
        let fields = [];
        // eslint-disable-next-line no-unused-expressions
        currentJob?.jobFields?.map((item) => fields.push(item.id));
        setJob(currentJob);
        setJobFileds(fields);
        setJobImage(currentJob.jobImage);
        if (currentJob?.convertedContent) {
          const setprocessedHTML = DraftPasteProcessor.processHTML(
            currentJob.convertedContent,
          );
          const setcontentState =
            ContentState.createFromBlockArray(setprocessedHTML);
          editorState = EditorState.createWithContent(setcontentState);
          editorState = EditorState.moveFocusToEnd(editorState);
        } else {
          editorState = EditorState.createEmpty();
        }
        setEditorState(editorState);
      }
    }
  }, [currentJob]);

  useEffect(() => {
    if (id) {
      dispatch(getJobById(id, setloading));
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getAllJobTypes());
    if (allContractTypes.length === 0)
      dispatch(getAllContractTypes(setloading));
  }, []);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  const setState = (value) => {
    let updatedData = {
      ...job,
      ...value,
    };
    setJob(updatedData);
    console.log("updatedData", updatedData);
  };

  const convertToSlug = (Text) => {
    return Text?.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  const updateJobHandler = () => {
    if (
      job?.jobTitle &&
      job?.jobType &&
      job?.jobCategory &&
      job?.jobPosition &&
      job?.jobLocation &&
      job?.openAt &&
      job?.contractType &&
      job?.status &&
      jobFields.length
    ) {
      let jobJson = jobFields.map(
        (doc) => AllFields.filter((item) => item?.id === doc)?.[0],
      );
      let updatedData = {
        ...job,
        jobImage,
        convertedContent,
        slug: convertToSlug(job?.jobTitle),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        jobFields: jobJson,
      };
      if (pathname.includes("edit")) {
        dispatch(updateJob(id, updatedData, setloading));
      } else {
        dispatch(addJobAction(updatedData, setloading));
      }
      setJob({});
      history.push("/jobs");
    } else {
      dispatch({
        type: ERROR_ALERT,
        payload: "ALL Fields Are Required!",
      });
    }
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </AppBar>

        {loading ? <CircularLoader /> : null}

        <Container maxWidth="md">
          <h1 className="Details">Job Details</h1>
          <Grid container justify="center" alignItems="center" spacing={2}>
            <Grid item xs={3} md={3} sm={3}>
              <Grid item xs={12} md={12} sm={12}>
                <Paper style={{ hieght: "100%" }}>
                  <Button
                    variant="text"
                    color="primary"
                    component="label"
                    disabled={imgLoading}
                    className={classes.input}
                  >
                    <input
                      type="file"
                      hidden
                      onChange={(e) =>
                        documentHandler(
                          e.target.files[0],
                          setJobImage,
                          setImgLoading,
                        )
                      }
                    />
                    {imgLoading ? (
                      <CircularProgress />
                    ) : jobImage ? (
                      <img
                        src={jobImage}
                        width="100%"
                        height="100%"
                        alt="jobImage"
                      />
                    ) : (
                      <Button
                        variant="text"
                        color="primary"
                        component="label"
                        className={classes.input}
                      >
                        <input
                          type="file"
                          hidden
                          onChange={(e) =>
                            documentHandler(
                              e.target.files[0],
                              setJobImage,
                              setImgLoading,
                            )
                          }
                        />
                        Add Job Image
                      </Button>
                    )}
                  </Button>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={9} md={9} sm={9}>
              <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    className={classes.formControl}
                    fullWidth
                    // placeholder="Job Title"
                    id="outlined-basic"
                    label="JobTitle*"
                    variant="outlined"
                    defaultValue={job.jobTitle}
                    onChange={(e) => setState({ jobTitle: e.target.value })}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="jobType">Job Type*</InputLabel>

                    <SelectMaterial
                      native
                      value={job.jobType}
                      onChange={(e) => setState({ jobType: e.target.value })}
                      label="JobType"
                      inputProps={{
                        name: "jobType",
                        id: "jobType",
                      }}
                    >
                      <option value=""></option>
                      {jobTypes?.map((job, index) => (
                        <option value={job.type} key={job.type + index}>
                          {job.type}
                        </option>
                      ))}
                    </SelectMaterial>
                  </FormControl>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="jobCategory">Job Category*</InputLabel>
                    <SelectMaterial
                      native
                      defaultValue={job.jobCategory}
                      onChange={(e) =>
                        setState({ jobCategory: e.target.value })
                      }
                      label="JobCategory"
                      inputProps={{
                        name: "jobCategory",
                        id: "jobCategory",
                      }}
                    >
                      <option value=""></option>
                      <option value="Onsite">Onsite</option>
                      <option value="Remote">Remote</option>
                    </SelectMaterial>
                  </FormControl>
                  <TextField
                    className={classes.formControl}
                    fullWidth
                    // placeholder="Job Title"
                    id="outlined-basic"
                    label="No. of Position*"
                    type="number"
                    variant="outlined"
                    defaultValue={job.jobPosition}
                    onChange={(e) => setState({ jobPosition: e.target.value })}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="JobLocation">JobLocation*</InputLabel>
                    <SelectMaterial
                      native
                      onChange={(e) =>
                        setState({ jobLocation: e.target.value })
                      }
                      label="JobLocation"
                      inputProps={{
                        name: "JobLocation",
                        id: "JobLocation",
                      }}
                      defaultValue={job.jobLocation}
                    >
                      <option value=""></option>
                      <option value="Faisalabad">Faisalabad</option>
                    </SelectMaterial>
                  </FormControl>
                  <TextField
                    className={classes.formControl}
                    fullWidth
                    id="outlined-basic"
                    // label="Open At"
                    value={
                      job.openAt ? moment(job.openAt).format("YYYY-MM-DD") : ""
                    }
                    type="date"
                    variant="outlined"
                    defaultValue={
                      job.openAt ? moment(job.openAt).format("YYYY-MM-DD") : ""
                    }
                    onChange={(e) =>
                      setState({
                        openAt: moment(e.target.value).format(
                          "MMMM D YYYY, h:mm a",
                        ),
                      })
                    }
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="jobType">Contract Type*</InputLabel>

                    <SelectMaterial
                      native
                      value={job.contractType}
                      onChange={(e) =>
                        setState({ contractType: e.target.value })
                      }
                      label="Contract Type"
                      inputProps={{
                        name: "Contract Type",
                        id: "contractType",
                      }}
                    >
                      <option value=""></option>
                      {allContractTypes?.map((contract, index) => (
                        <option
                          value={contract?.id}
                          key={contract?.type + index}
                        >
                          {contract?.type}
                        </option>
                      ))}
                    </SelectMaterial>
                  </FormControl>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="status">Status* </InputLabel>
                    <SelectMaterial
                      native
                      onChange={(e) => setState({ status: e.target.value })}
                      label="Status"
                      inputProps={{
                        name: "status",
                        id: "status",
                      }}
                      defaultValue={job.status}
                    >
                      <option value=""></option>
                      <option value="active">Active</option>
                      <option value="expired">Expired</option>
                      <option value="private">Private</option>
                    </SelectMaterial>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Editor
                editorState={editorState}
                // className={classes.input}
                wrapperClassName={classes.wrapper}
                editorClassName={classes.editor}
                toolbarClassName={classes.toolbar}
                onEditorStateChange={handleEditorChange}
              />
            </Grid>
          </Grid>
          <JobApplicationFields
            jobFields={jobFields}
            setJobFileds={setJobFileds}
          />
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                // disabled={!jobLocation}
                onClick={updateJobHandler}
                className={classes.button}
              >
                {pathname.includes("edit") ? "Update" : "Add Job"}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </>
  );
};

export default UpdateJobComponent;
