import {
    ADD_INTERVIEW_QUESTION,
    ADD_INTERVIEW_TYPE, DELETE_INTERVIEW_TYPE, EMPTY_INTERVIEW_QUESTION, GET_INTERVIEW_QUESTION, GET_INTERVIEW_TYPE, INDEX_CHANGE, UPDATE_INTERVIEW_QUESTION, UPDATE_INTERVIEW_TYPE
} from './constants'

let initialState = {
    interviewAllData: [],
    interviewQuestions: {}
}

export default function InterviewQuestionsReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_INTERVIEW_TYPE:
            return {
                ...state,
                interviewAllData: [...state.interviewAllData, action.payload]
            };
        case GET_INTERVIEW_TYPE:
            return {
                ...state,
                interviewAllData: [...action.payload]
            };
        case DELETE_INTERVIEW_TYPE:
            let filteredList = state.interviewAllData.filter((doc) => doc.docId !== action.payload)
            return {
                ...state,
                interviewAllData: filteredList
            };
        case UPDATE_INTERVIEW_TYPE:
            let updatedList = state.interviewAllData.map((doc) => {
                if (doc.docId === action.payload.docId) {
                    return action.payload
                } else {
                    return doc
                }
            })
            return {
                ...state,
                interviewAllData: updatedList
            };
        case GET_INTERVIEW_QUESTION:
        case ADD_INTERVIEW_QUESTION:
        case UPDATE_INTERVIEW_QUESTION:
        case EMPTY_INTERVIEW_QUESTION:
            return {
                ...state,
                interviewQuestions: action.payload
            };
        case INDEX_CHANGE:
            return {
                ...state,
                interviewQuestions: { ...state.interviewQuestions, questions: action.payload }
            };

        default:
            return state;
    }
}