import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllMeetings } from "./redux/actions/meetingAction";
import CircularLoader from "../utils/circularProgress/index";
import { Tabs, Tab, Typography, Box } from '@material-ui/core';
import TableComponent from "./MeetingTable";
import PropTypes from 'prop-types';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `meeting-tab-${index}`,
    'aria-controls': `meeting-tabpanel-${index}`,
  };
}

export default function Email() {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [value, setValue] = useState(0);
  useEffect(() => {
    dispatch(getAllMeetings(setloading,value));
  }, [value]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return <>
    {loading ? <CircularLoader /> :
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="Meeting tabs">
            <Tab label="Upcoming Meetings" {...a11yProps(0)} />
            <Tab label="Previous meetings" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <TableComponent />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TableComponent />
        </TabPanel>
      </Box>
    }
  </>
}
