import { GET_ALL_REQUESTS, UPDATE_CONTACT_STATUS } from "../constant/index";
const initialState = {
  requests: [],
};

const contactRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REQUESTS:
      // console.log("reducer", action.payload);
      return {
        ...state,
        requests: action.payload,
      };
    case UPDATE_CONTACT_STATUS: {
      const { id } = action.payload;
      let updateContacts = state.requests.map((contactRequest) => {
        if (contactRequest.id === id) {
          return action.payload;
        }
        return contactRequest;
      });
      return {
        ...state,
        requests: updateContacts,
      };
    }
    default:
      return state;
  }
};

export default contactRequestsReducer;
