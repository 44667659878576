import {
  GET_ALL_BANKS,
  SUCCESS_ALERT,
  ERROR_ALERT,
  ADD_BANK,
  DELETE_BANK,
  UPDATE_BANK
} from "../constant/index";
import { db } from "../../../../config/firebase";

export const getAllBanks = (setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    let res = await db.collection("SupportedBanks").get();
    let allBanks = [];
    res.forEach((doc) => {
      allBanks.push({ ...doc.data(), id: doc.id });
    });
    dispatch({ type: GET_ALL_BANKS, payload: allBanks });
    setLoading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setLoading(false);
  }
};
export const addBank = (data, setLoading, toggle) => async (dispatch) => {
  try {
    setLoading(true);
  const docRef=  await db.collection("SupportedBanks").add({
      ...data,
    });
    const id=docRef?.id;
    dispatch({ type: ADD_BANK, payload: {...data,id} });
    dispatch({ type: SUCCESS_ALERT, payload: "Bank Added Successfully." });
    toggle();
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
  finally {
    setLoading(false);
  }
};
export const updateBank = (data, setLoading, toggle) => async (dispatch) => {
  try {
    setLoading(true);
    const { id, ...rest } = data;
    await db
      .collection("SupportedBanks")
      .doc(id)
      .update({
        ...rest,
      });
    dispatch({ type: UPDATE_BANK, payload: { ...rest, id } });
    dispatch({ type: SUCCESS_ALERT, payload: "Bank updated Successfully." });
    if (toggle) {
      toggle();
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
  finally {
    setLoading(false);
  }
};
export const deleteBank =
  (id, setLoading) => async (dispatch) => {
    try {
      setLoading(true);
      await db.collection("SupportedBanks").doc(id).delete();
      dispatch({ type: DELETE_BANK, payload: id });
      dispatch({
        type: SUCCESS_ALERT,
        payload: "Bank Deleted  Successfully.",
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: ERROR_ALERT, payload: error.message });
    }
    finally {
      setLoading(false);
    }
  };

