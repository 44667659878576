import { GET_CONTRACT, UPDATE_CONTRACT } from "../constants/contractConstant";
const initialState = {
  contract: {},
};
const contractReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTRACT:
      return {
        ...state,
        contract: action?.payload,
      };
    case UPDATE_CONTRACT:
      return {
        ...state,
        contract: action?.payload,
      };
    default:
      return state;
  }
};

export default contractReducer;
