import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Chip,
  Menu,
  InputLabel,
  Input,
  MenuItem,
  TextField,
} from "@material-ui/core";
import useStyles from "./style";
import DoneIcon from "@material-ui/icons/Done";
import UpdateIcon from "@material-ui/icons/Update";
import CloseIcon from "@material-ui/icons/Close";
import ScheduleIcon from "@material-ui/icons/Schedule";
import {
  handleInterviewDetailSidebar,
  handleSelectedInterview,
  handleSidebar,
} from "./redux/interviewAction/interviewAction";
import {
  cancelinterview,
  interviewed,
} from "../jobsRequests/redux/actions/jobsRequestsAction";
import { X } from "react-feather";
import { Row, Col, Button } from "reactstrap";

import moment from "moment";
import QuestionsList from "./questions/QuestionsList";

function InterviewDetail() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [myNote, setNote] = useState("");
  const dispatch = useDispatch();
  const classes = useStyles();
  const { selectedInterview, detailSidebar } = useSelector(
    (state) => state.interviewReducer,
  );
  let location = selectedInterview?.location || "";
  if (selectedInterview !== null) {
    const {
      fname,
      start,
      email,
      meetingId,
      start_url,
      join_url,
      appliedFor,
      note,
      password,
      title,
      user,
      status,
    } = selectedInterview;
    const handleClose = () => {
      setAnchorEl(null);
      setNote("");
    };

    return (
      <div
        className={`interview-detail-sidebar ${
          detailSidebar ? "show" : "hidden"
        }`}
      >
        <div className="header d-flex justify-content-between">
          <h3 className="text-bold-600 mb-0"></h3>

          <div
            className="close-icon cursor-pointer"
            onClick={() => dispatch(handleInterviewDetailSidebar(false))}
          >
            <X size={20} />
          </div>
        </div>
        <div className="interview-detail-body">
          <div className="add-event-actions d-flex">
            {
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <center>
                  <div className={classes.everyItem}>
                    <TextField
                      autoFocus
                      id="note"
                      variant="filled"
                      multiline
                      rows={4}
                      value={myNote}
                      onChange={(e) => setNote(e.target.value)}
                    />
                  </div>
                  <div className={classes.everyItem}>
                    <Button.Ripple variant="outlined" onClick={handleClose}>
                      Cancel
                    </Button.Ripple>
                    <Button.Ripple
                      variant="outlined"
                      color="primary"
                      className="ml-1"
                      onClick={() => {
                        handleClose();
                        dispatch(handleInterviewDetailSidebar(false));
                        dispatch(
                          cancelinterview({
                            googleCalendarInterviewEventId: selectedInterview.googleCalendarInterviewEventId,
                            user,
                            title,
                            start,
                            appliedFor,
                            location,
                            meetingId,
                            note: myNote,
                          }),
                        );
                        setNote("");
                      }}
                    >
                      Save
                    </Button.Ripple>
                  </div>
                </center>
              </Menu>
            }
            <Chip
              variant="outlined"
              size="medium"
              icon={
                <ScheduleIcon
                  classes={{
                    root: classes[
                      status === "interviewSchedule"
                        ? "scheduleIcon"
                        : "iconDisabled"
                    ],
                  }}
                />
              }
              clickable={false}
              // disabled={status === "present" || updateLoading}
              label={"Schedule"}
              classes={{
                root: classes[
                  status === "interviewSchedule" ? "schedule" : "disabled"
                ],
              }}
            />
            <Chip
              variant="outlined"
              size="medium"
              onClick={() => {
                dispatch(handleInterviewDetailSidebar(false));
                dispatch(handleSidebar(true));
              }}
              icon={
                <UpdateIcon
                  classes={{
                    root: classes[
                      status === "interviewReschedule"
                        ? "rescheduleIcon"
                        : "iconDisabled"
                    ],
                  }}
                />
              }
              clickable={
                status == "interviewSchedule" || status == "interviewReschedule"
              }
              label={"Reschedule"}
              classes={{
                root: classes[
                  status === "interviewReschedule" ? "reschedule" : "disabled"
                ],
              }}
            />
            <Chip
              variant="outlined"
              size="medium"
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
              icon={
                <CloseIcon
                  classes={{
                    root: classes[
                      status === "interviewCancel"
                        ? "cancelIcon"
                        : "iconDisabled"
                      // status === "absent" ? "absentIcon" : "iconDisabled"
                    ],
                  }}
                />
              }
              clickable={
                status !== "interviewCancel" || status !== "interviewed"
              }
              // clickable={status !== "absent" || !updateLoading}
              label={"Cancelled"}
              classes={{
                root: classes[
                  status === "interviewCancel" ? "cancel" : "disabled"
                ],
              }}
            />
            <Chip
              variant="outlined"
              size="medium"
              icon={
                <DoneIcon
                  classes={{
                    root: classes[
                      status === "interviewed"
                        ? "interviewedIcon"
                        : "iconDisabled"
                    ],
                  }}
                />
              }
              clickable={
                status !== "interviewCancel" || status !== "interviewed"
              }
              label={"Interviewed"}
              classes={{
                root: classes[
                  status === "interviewed" ? "interviewed" : "disabled"
                ],
              }}
            />
          </div>
          <div className="details mt-5">
            <Row>
              <Col xs="4">
                <p>
                  <b>Title: </b>
                </p>
              </Col>
              <Col xs="8">
                <p> {`${title}`}</p>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <p>
                  <b>Location: </b>
                </p>
              </Col>
              <Col xs="8">
                <p> {`${location.toUpperCase()}`}</p>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <p>
                  {" "}
                  <b>Time: </b>
                </p>
              </Col>
              <Col xs="8">
                <p>{moment(start).format("MMMM Do YYYY, h:mm a")}</p>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <p>
                  <b>Name :</b>
                </p>
              </Col>
              <Col xs="8">
                <p> {`${fname}`}</p>
              </Col>
            </Row>
            {status === "interviewCancel" ? (
              <Row>
                <Col xs="4">
                  <p>
                    <b>Note :</b>
                  </p>
                </Col>
                <Col xs="8">
                  <p> {`${note}`}</p>
                </Col>
              </Row>
            ) : (
              ""
            )}
            {location === "remote" ? (
              <>
                <Row>
                  <Col xs="4">
                    <p>
                      {" "}
                      <b>Meeting Id:</b>{" "}
                    </p>
                  </Col>
                  <Col xs="8">
                    <p> {`${meetingId} `}</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs="4">
                    <p>
                      {" "}
                      <b>Password:</b>
                    </p>
                  </Col>
                  <Col xs="8">
                    <p>{password}</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs="4">
                    <p>
                      {" "}
                      <b> Join Meeting: </b>
                    </p>
                  </Col>
                  <Col xs="8">
                    <p>
                      <a target={"_blank"} href={join_url}>
                        Join Meeting
                      </a>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs="4">
                    <p>
                      {" "}
                      <b> Start Meeting: </b>
                    </p>
                  </Col>
                  <Col xs="8">
                    <p>
                      <a target={"_blank"} href={start_url}>
                        Start Meeting
                      </a>
                    </p>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}

            {status === "interviewSchedule" ||
            status === "interviewReschedule" ? (
              <Row>
                <Col xs="4">
                  <p>
                    {" "}
                    <b> Start Interview: </b>
                  </p>
                </Col>
                <Col xs="8">
                  <QuestionsList data={selectedInterview} />
                </Col>
              </Row>
            ) : (
              ""
            )}
          </div>

          <div className="add-event-actions text-right">
            <Button.Ripple
              variant="outlined"
              color="primary"
              className="ml-1"
              onClick={() => {
                dispatch(handleInterviewDetailSidebar(false));
                dispatch(handleSelectedInterview(null));
              }}
            >
              Cancel
            </Button.Ripple>
          </div>
        </div>
      </div>
    );
  } else return <> </>;
}

export default InterviewDetail;
//  dispatch(
// updateInterview({
//   id: props.interviewInfo.id,
//   user: props.interviewInfo.user,

//   title: state.title,
//   label: state.label,
//   start: state.startDate,

//   fname: state.fname,
//   email: state.email,
//   meetingId: props.interviewInfo.meetingId,
//   start_url: props.interviewInfo.start_url,
//   join_url: props.interviewInfo.join_url,
//   password: props.interviewInfo.password,
// }),
