import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAll, getDeclineOnboard } from "./redux/actions/employeeAction";
import TeamListTableController from "./TeamListTableController";

export default function Onboarding({ location, history }) {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const { allOnboarding, decliendOnboarding } = useSelector((state) => state.employeesReducer);

  useEffect(() => {
    if (allOnboarding.length < 1) {
      dispatch(getAll(setloading));
    } else setloading(false);
  }, []);

  useEffect(() =>{ 
    if(decliendOnboarding.length < 1){
      dispatch(getDeclineOnboard(setloading));
    } else setloading(false);
  }, []);

  const handleMore = (i) => {
    const id = allOnboarding[i]?.id;
    history.push({
      pathname: `/profile/onboard/${id}`,
      id: id,
    });
  };

  return (
    <TeamListTableController
      data={allOnboarding}
      handleMore={handleMore}
      loading={loading}
      location={location}
      history={history}
      appBarTitle="Declined"
      blockTitle="Decliend Members"
      title="Onboarding"
      blocks={decliendOnboarding}
    />
  );
}
