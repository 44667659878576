import {
  SUCCESS_ALERT,
  ERROR_ALERT,
  GET_ALL_ADMINS,
  ADD_ADMIN,
  DELETE_ADMIN,
  UPDATE_ADMIN,
} from "../constant/adminConstant";
import { db } from "../../../../config/firebase";

export const getAllAdmins = (setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    const res = await db.collection("Admins").get();
    let allAdmins = [];
    res.forEach((doc) => {
      allAdmins.push({ ...doc.data(), id: doc.id });
    });
    dispatch({ type: GET_ALL_ADMINS, payload: allAdmins });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setLoading(false);
  }
};

export const addAdmin = (data, setLoading, toggle) => async (dispatch) => {
  try {
    setLoading(true);
    const docRef = await db.collection("Admins").add({
      ...data,
    });
    const id = docRef?.id;
    dispatch({
      type: ADD_ADMIN,
      payload: { ...data, id },
    });
    dispatch({ type: SUCCESS_ALERT, payload: "Admin Added Successfully." });
    toggle();
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setLoading(false);
  }
};
export const updateAdmin = (data, setLoading, toggle) => async (dispatch) => {
  try {
    setLoading(true);
    const { id, ...rest } = data;
    await db
      .collection("Admins")
      .doc(id)
      .update({
        ...rest,
      });
    dispatch({ type: UPDATE_ADMIN, payload: { ...rest, id } });
    dispatch({ type: SUCCESS_ALERT, payload: "Admin updated Successfully." });
    if (toggle) {
      toggle();
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setLoading(false);
  }
};
export const deleteAdmin = (data, setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    const { id } = data;
    await db.collection("Admins").doc(id).delete();
    dispatch({ type: DELETE_ADMIN, payload: id });
    dispatch({
      type: SUCCESS_ALERT,
      payload: "Admin Deleted  Successfully.",
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  } finally {
    setLoading(false);
  }
};
