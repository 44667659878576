import {
  GET_ALL_ATTENDANCE,
  SUCCESS_ALERT,
  ERROR_ALERT,
  UPDATE_ATTENDANCE_STATUS,
  UPDATE_ATTENDANCE_STATUS_BOOTCAMP,
  GET_ALL_USER,
  GET_BOOTCAMP_USER,
  DELETE_ATTENDANCE,
  DELETE_ATTENDANCE_BOOTCAMP,
  ALL_EMPLOYEE,
  ALL_BOOTCAMPER,
  CALENDAR_EVENTS,
} from "../constant/attendanceConstant";

const initialState = {
  successMessage: "",
  errorMessage: "",
  allAttendances: [],
  allUser: [],
  allBootcampUser: [],
  allEmployee: [],
  allBootcamper: [],
  events: [],
};

const attendanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_EMPLOYEE:
      return {
        ...state,
        allEmployee: action.payload,
      };
    case ALL_BOOTCAMPER:
      return {
        ...state,
        allBootcamper: action.payload,
      };
    case GET_ALL_USER:
      return {
        ...state,
        allUser: action.payload,
      };
    case GET_BOOTCAMP_USER:
      return {
        ...state,
        allBootcampUser: action.payload,
      };
    case GET_ALL_ATTENDANCE:
      return {
        ...state,
        allAttendances: action.payload,
      };
    case CALENDAR_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case UPDATE_ATTENDANCE_STATUS:
      const data = action.payload;
      // console.log("data", data);
      let foundIndex = state.allUser.findIndex((att) => att.id === data.id);
      // console.log("foundIndex", foundIndex);
      state.allUser[foundIndex] = data;
      return {
        ...state,
      };
    case UPDATE_ATTENDANCE_STATUS_BOOTCAMP:
      const bootcampData = action.payload;
      let bootcampFoundIndex = state.allBootcampUser.findIndex(
        (att) => att.id === bootcampData.id
      );
      state.allBootcampUser[bootcampFoundIndex] = bootcampData;
      return {
        ...state,
      };
    case DELETE_ATTENDANCE:
      let AttId = action.payload;
      const allUser = state.allUser;
      var doc = allUser.filter((user) => user.attendanceId === AttId);
      const { label, userId, attendanceId, date, ...rest } = doc[0];
      let index = allUser.findIndex((user) => user.attendanceId === AttId);
      allUser[index] = rest;
      return {
        ...state,
        // allUser: allUser,
      };
    case DELETE_ATTENDANCE_BOOTCAMP:
      let bootcampAttId = action.payload;
      const allBootcampUser = state.allBootcampUser;
      var bootcampDoc = allBootcampUser.filter(
        (user) => user.attendanceId === bootcampAttId
      );
      const {
        label: bootcampLabel,
        userId: bootcampUserId,
        attendanceId: bootcampAttendanceId,
        date: bootcampDate,
        ...bootcampRest
      } = bootcampDoc[0];
      let bootcampIndex = allBootcampUser.findIndex(
        (user) => user.attendanceId === bootcampAttId
      );
      allBootcampUser[bootcampIndex] = bootcampRest;
      return {
        ...state,
        // allBootcampUser: allBootcampUser,
      };
    case SUCCESS_ALERT:
      return {
        ...state,
        successMessage: action.payload,
      };
    case ERROR_ALERT:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default attendanceReducer;
