import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, TextField } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import SelectMaterial from "@material-ui/core/Select";
import { ERROR_ALERT } from "../../../commonUtils/share";
import { useStyles } from "./style";
import CircularLoader from "../../utils/circularProgress";
import { getUserData } from "../../../commonRedux/common/action";
import { addLetter } from "../redux/actions/lettersAction";
import firebase from "firebase";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddLetter = ({ toggle, open, isEdit }) => {
  const [loading, setLoading] = useState(false);
  const users = useSelector((state) => state.commonReducer.allEmployees);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [letter, setLetter] = useState({});

  const updateJobHandler = async () => {
    if (
      letter?.name &&
      letter?.fatherName &&
      letter?.docType &&
      letter?.cnic &&
      letter?.date
    ) {
      const payload = {
        ...letter,
        date: moment(letter?.date)?.format("DD/MM/YYYY"),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      };
      dispatch(addLetter(setLoading, payload, toggle));
    } else {
      dispatch({
        type: ERROR_ALERT,
        payload: "* ALL Fields Are Required!",
      });
    }
  };
  useEffect(() => {
    if (users.length === 0) {
      dispatch(getUserData(setLoading));
    }
  }, []);
  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const updatedData = {
      ...letter,
      [name]: value,
    };
    setLetter(updatedData);
  };
  const onUserChangeHandler = (e) => {
    const value = e.target.value;
    const filteredUser = users.find((user) => user?.id === value);
    const userData = {
      ...letter,
      name: filteredUser?.fullName || "",
      fatherName: filteredUser?.fatherName || "",
      cnic: filteredUser?.idCardNumber || "",
      email: filteredUser?.email || "",
      userId: value,
    };
    setLetter(userData);
  };
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => toggle()}
        TransitionComponent={Transition}
      >
        <AppBar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => toggle()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </AppBar>

        {loading ? <CircularLoader /> : null}

        <Container maxWidth="md">
          <h1 className="Details">Document Details</h1>
          <Grid container justify="center" alignItems="center" spacing={2}>
            <Grid item xs={12} md={12} sm={12}>
              <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid item md={6} sm={6} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="choose User">Choose User</InputLabel>

                    <SelectMaterial
                      native
                      value={letter?.userId || ""}
                      onChange={onUserChangeHandler}
                      label="userId"
                      inputProps={{
                        name: "userId",
                        id: "userId",
                      }}
                    >
                      <option value=""></option>
                      {users?.map((user) => (
                        <option value={user.id} key={user.id}>
                          {user.fullName}
                        </option>
                      ))}
                    </SelectMaterial>
                  </FormControl>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="letterType">Letter Type*</InputLabel>
                    <SelectMaterial
                      native
                      defaultValue={letter?.docType || ""}
                      onChange={onChangeHandler}
                      label="letterType"
                      inputProps={{
                        name: "docType",
                        id: "docType",
                      }}
                    >
                      <option value=""></option>
                      <option value="offerLetter">Offer Letter</option>
                      <option value="experienceLetter">
                        Experience Letter
                      </option>
                    </SelectMaterial>
                  </FormControl>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    className={classes.formControl}
                    fullWidth
                    id="outlined-basic"
                    label="Name*"
                    variant="outlined"
                    name="name"
                    value={letter?.name || ""}
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    className={classes.formControl}
                    fullWidth
                    id="outlined-basic"
                    label="Father Name*"
                    type="text"
                    variant="outlined"
                    value={letter?.fatherName || ""}
                    name="fatherName"
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    className={classes.formControl}
                    fullWidth
                    id="outlined-basic"
                    label="CNIC*"
                    type="text"
                    variant="outlined"
                    value={letter.cnic || ""}
                    name="cnic"
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    className={classes.formControl}
                    fullWidth
                    id="outlined-basic"
                    label="Email"
                    type="text"
                    variant="outlined"
                    value={letter?.email || ""}
                    name="email"
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    className={classes.formControl}
                    fullWidth
                    id="outlined-basic"
                    label="Letter URL"
                    type="text"
                    variant="outlined"
                    value={letter.url || ""}
                    name="url"
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    className={classes.formControl}
                    fullWidth
                    id="outlined-basic"
                    type="date"
                    variant="outlined"
                    name="date"
                    value={letter.date || ""}
                    onChange={onChangeHandler}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.grid}>
            <Grid item md={12} xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={updateJobHandler}
                className={classes.button}
                disabled={loading}
              >
                {isEdit ? "Update Letter" : "Add Letter"}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </>
  );
};

export default AddLetter;
