import React, { useEffect, useState } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import usePayroll from "./components/usePayroll";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TableRowComponent from "./tableRow";
import CustomFooter from "./payRollTableFooter";
import { useDispatch, useSelector } from "react-redux";
import { getAllLeaves } from "../leaveRequest/redux/actions/leaveRequestAction";
import ImageDilog from "../../components/imageDilog/ImageDilog";
const PayrollTable = ({ payroll }) => {
  const dispatch = useDispatch();
  const { rowopen, setRowOpen, totalPayAble } = usePayroll();
  const allLeaves = useSelector(
    (store) => store?.leaveRequestReducer?.allLeaves,
  );

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(getAllLeaves(setLoading));
  }, []);

  const columns = [
    {
      name:"profilePhoto",
      label: "Photo",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          let photo = payroll[dataIndex].profilephoto;
          return (
            <ImageDilog
            src={photo}
            />
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let name = payroll?.[dataIndex]?.fullName;
          return <span>{name}</span>;
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let email = payroll?.[dataIndex]?.email;
          return <span>{email}</span>;
        },
      },
    },
    {
      name: "Leaves",
      label: "Leaves",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          return <span>{payroll?.[dataIndex]?.leaves}</span>;
        },
      },
    },
    {
      name: "Basic Salaray",
      label: "Basic Salaray",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          return <span>Rs. {payroll?.[dataIndex]?.basicPay}</span>;
        },
      },
    },
    {
      name: "bonus",
      label: "Bonus",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <span style={{ display: "flex", alignItems: "center" }}>
              {payroll[dataIndex]?.bonus}
            </span>
          );
        },
      },
    },
    {
      name: "Travel",
      label: "Travel",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <span style={{ display: "flex", alignItems: "center" }}>
              {payroll?.[dataIndex]?.travelExpenditure || 0}
            </span>
          );
        },
      },
    },
    {
      name: "Deduction",
      label: "Deduction",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          return <span>{payroll[dataIndex]?.deductedSalary}</span>;
        },
      },
    },
    {
      name: "Tax",
      label: "Tax",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          return <span>{payroll[dataIndex]?.tax || 0}</span>;
        },
      },
    },
    {
      name: "Net Salaray",
      label: "Net Salaray",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          return <span>Rs. {payroll?.[dataIndex]?.netSalary}</span>;
        },
      },
    },
  ];

  const options = {
    searchPlaceholder: "Search here...",
    filter: false,
    searchAlwaysOpen: true,
    responsive: "standard",
    expandableRows: true,
    expandableRowsHeader: false,
    onSearchChange: (e) => {
      console.log(e, "search");
    },

    selectableRows: false,
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <CustomFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={(event) => changeRowsPerPage(event.target.value)}
          onChangePage={(_, page) => changePage(page)}
          total={totalPayAble}
        />
      );
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const userPayRoll = payroll[rowMeta.dataIndex];

      const userId = userPayRoll.userId;
      const { generalLeaveDetails, paidLeavesDetails } = userPayRoll;

      const userLeaves = [
        ...generalLeaveDetails,
        ...paidLeavesDetails,
        ...(userPayRoll?.halfLeavesDetails || []),
      ];
      return <TableRowComponent userId={userId} userleaves={userLeaves} />;
    },
  };

  const theme = createTheme({
    overrides: {
      MUIDataTableHeadCell: {
        sortAction: {
          fontSize: "1rem",
          fontWeight: "bolder",
        },
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          visibility: "hidden",
        },
      },
    },
  });

  const components = (
    <IconButton
      aria-label="expand row"
      size="small"
      onClick={() => setRowOpen(!rowopen)}
    >
      {rowopen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );

  return (
    <MuiThemeProvider theme={theme}>
      <>
        <MUIDataTable
          data={payroll?.sort((a, b) => b.netSalary - a.netSalary)}
          columns={columns}
          options={options}
          components={components}
        />
      </>
    </MuiThemeProvider>
  );
};

export default PayrollTable;
