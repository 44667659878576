import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllBanks } from "./redux/actions/bankAction";
import CircularLoader from "../utils/circularProgress/index";
import TableComponent from "./BankTable";

export default function Email() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(getAllBanks(setLoading));
  }, []);
  return <>{loading ? <CircularLoader /> : <TableComponent />}</>;
}
