import { createTheme } from '@material-ui/core';

export const GetMuiTheme = () => createTheme({
    overrides: {
        MUIDataTableHeadCell: {
            sortAction: {
                fontSize: "1rem",
                fontWeight: "bolder"
            }
        }
    },
})