import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Button, TextField } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { useStyles } from "./OfferLetterStyle";
import { CircularProgress } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import axiosTechloset from "../../config/axiosTechloset";
import { getDoc, SUCCESS_ALERT, ERROR_ALERT } from "../../commonUtils/share";
import { Send } from "@material-ui/icons";
import gernateId from "../../commonUtils/gernateId";
import { db } from "../../config/firebase";
import firebase from "firebase";
import InputMask from "react-input-mask";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OfferLetter = ({ data }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [letterDetails, setLetterDetails] = useState({});
  const [docLoading, setDocLoading] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (data?.team) {
      setLetterDetails({
        name: `${data?.name || ""} `,
        cnic: data?.idCardNumber || "",
        jobTitle: data?.rankTitle || "",
        email: data?.email,
        address: `${data?.address || ""}`,
        city: data?.city || "",
        salary: data?.basicPay || 0,
        userId: data?.id || "",
        authId: data?.authId || "",
        fatherName: data?.fatherName || "",
      });
    } else {
      setLetterDetails({
        name: `${data?.fname || ""} ${data?.lname || ""}`,
        cnic: data?.cnic || "",
        jobTitle: data?.appliedFor,
        email: data?.email,
        address: `${data?.addressOne || ""} ${data?.addressTwo || ""}`,
        city: data?.city || "",
        salary: 0,
        userId: data?.docId || "",
      });
    }
  }, []);
  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLetterDetails({
      ...letterDetails,
      [name]: value,
    });
  };
  const letterHandler = async (type) => {
    try {
      if (
        letterDetails?.name &&
        letterDetails?.cnic.toString().length===15 &&
        letterDetails?.date &&
        letterDetails?.startDate &&
        letterDetails?.joiningDate &&
        letterDetails?.salary >= 0 &&
        letterDetails?.jobTitle
      ) {
        const docId = await gernateId();
        const payload = {
          ...letterDetails,
          startDate: moment(letterDetails?.startDate)?.format("DD/MM/YYYY"),
          joiningDate: moment(letterDetails?.joiningDate)?.format("DD/MM/YYYY"),
          date: moment(letterDetails?.date)?.format("DD/MM/YYYY"),
          docType: "offerLetter",
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        };
        if (type === "view") {
          await getDoc({ ...payload, docId }, setDocLoading);
        } else {
          setDocLoading(true);

          await db
            .collection("letters")
            .doc(docId)
            .set({ ...payload, isAuto: true });
          const team = data?.team || false;
          const collection = team ? "UserData" : "JobApplication";
          await db.collection(collection).doc(payload?.userId).update({
            offerSend: true,
          });

          await axiosTechloset({
            method: "POST",
            url: "/emails/offerLetter",
            data: { ...payload, docId },
          });
          setDocLoading(false);
          dispatch({
            type: SUCCESS_ALERT,
            payload: "Offer Letter Send successfully!",
          });
        }
      } else {
        if(letterDetails?.cnic.toString().length!==15){
          dispatch({
            type: ERROR_ALERT,
            payload: "Please enter valid CNIC",
          });
        }else{
          dispatch({
            type: ERROR_ALERT,
            payload: "All fields are required except Address and City.",
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDocLoading(false);
    }
  };
  return (
    <>
      {data?.team ? (
        <>
          <Button
            onClick={handleClickOpen}
            variant="contained"
            color="primary"
            disabled={data?.offerSend}
          >
            Send Offer
          </Button>
        </>
      ) : (
        <Tooltip title="Send Offer Letter">
          <IconButton
            color="primary"
            onClick={handleClickOpen}
            disabled={data?.offerSend}
          >
            <Send />
          </IconButton>
        </Tooltip>
      )}

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </AppBar>
        <Container maxWidth="md" className={classes.container}>
          <h1 className="Details">Offer Letter Details</h1>
          <Grid container justify="center" alignItems="center" spacing={2}>
            <Grid item xs={9} md={9} sm={9}>
              <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    name="name"
                    className={classes.formControl}
                    fullWidth
                    placeholder="Full Name"
                    id="outlined-basic"
                    label="Full Name"
                    variant="outlined"
                    value={letterDetails?.name || ""}
                    onChange={onChangeHandler}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  {/* <TextField
                    name="cnic"
                    className={classes.formControl}
                    fullWidth
                    placeholder="CNIC"
                    id="outlined-basic"
                    label="CNIC"
                    variant="outlined"
                    value={letterDetails?.cnic || ""}
                    onChange={onChangeHandler}
                    InputLabelProps={{ shrink: true }}
                  /> */}
                  <InputMask
                    name="cnic"
                    maskChar={0}
                    mask="99999-9999999-9"
                    value={letterDetails?.cnic || ""}
                    onChange={onChangeHandler}
                    >
                    {(props) => <TextField
                      {...props}
                      className={classes.formControl}
                      fullWidth
                      id="outlined-basic"
                      label="CNIC"
                      variant="outlined"
                      placeholder="CNIC e.g. 42101-1111111-1"
                      InputLabelProps={{ shrink: true }}
                    />
                    }
                  </InputMask>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    name="jobTitle"
                    className={classes.formControl}
                    fullWidth
                    placeholder="Job  Title"
                    id="outlined-basic"
                    label="Job Title"
                    variant="outlined"
                    value={letterDetails?.jobTitle || ""}
                    onChange={onChangeHandler}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    name="salary"
                    type="number"
                    className={classes.formControl}
                    fullWidth
                    placeholder="Salary Rs"
                    id="outlined-basic"
                    label="Salary Rs"
                    variant="outlined"
                    value={letterDetails?.salary}
                    onChange={onChangeHandler}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    className={classes.formControl}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    id="outlined-basic"
                    type="date"
                    name="date"
                    label="Issuance Date"
                    variant="outlined"
                    value={letterDetails?.date}
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    className={classes.formControl}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    id="outlined-basic"
                    type="date"
                    label="Start Date"
                    variant="outlined"
                    value={letterDetails?.startDate}
                    name="startDate"
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    className={classes.formControl}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    id="outlined-basic"
                    type="date"
                    label="Joining Date"
                    variant="outlined"
                    value={letterDetails?.joiningDate || ""}
                    name="joiningDate"
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    name="city"
                    className={classes.formControl}
                    fullWidth
                    placeholder="City"
                    id="outlined-basic"
                    label="City"
                    variant="outlined"
                    value={letterDetails?.city || ""}
                    onChange={onChangeHandler}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={12} sm={6} xs={12}>
                  <TextField
                    name="address"
                    className={classes.formControl}
                    fullWidth
                    placeholder="Address"
                    id="outlined-basic"
                    label="Address"
                    variant="outlined"
                    value={letterDetails?.address || ""}
                    onChange={onChangeHandler}
                    InputLabelProps={{ shrink: true }}
                    minRows={3}
                    multiline
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3} className={classes.grid}>
            <Grid item md={12} xs={12}>
              {docLoading ? (
                <CircularProgress className={classes.loader} />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={() => letterHandler("send")}
                    className={classes.button}
                  >
                    Send
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => letterHandler("view")}
                    className={classes.button}
                  >
                    View
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </>
  );
};

export default OfferLetter;
