import { combineReducers } from "redux";
import userReducer from "../../module/auth/redux/reducer/creditional";
import employeesReducer from "../../module/employees/redux/reducers/employeeReducer";
import profileReducer from "../../module/profile/redux/reducers/profileReducer";
import jobsReducer from "../../module/jobs/redux/reducers/jobReducer";
import userProfileReducer from "../../module/update/redux/reducers/userReducer";
import calenderReducer from "../../module/events/calendar/redux/calendarReducer/index";
import leaveRequestReducer from "../../module/leaveRequest/redux/reducers/leaveRequestReducer";
import lettersRequestReducer from "../../module/lettersRequest/redux/reducers/lettersRequestReducer";
import attendanceReducer from "../../module/attendance/redux/reducers/attendanceReducer";
import commonReducer from "./reducer";
import payrollReducer from "../../module/payroll/redux/reducers/payrollReducer";
import payStubReducer from "../../module/payStub/redux/reducers/payStubReducer";
import contactRequestsReducer from "../../module/contactRequests/redux/reducers/contactReducer";
import jobsRequestsReducer from "../../module/jobsRequests/redux/reducers/jobsRequestsReducer";
import interviewReducer from "../../module/interviews/redux/interviewReducer/interviewReducer";
import ranksReducer from "../../module/ranks/redux/reducer";
import InterviewQuestionsReducer from "../../module/interviewQuestions/redux/reducer";
import galleryReducer from "../../pages/gallery/redux/reducers/galleryReducer";
import lettersReducer from "../../module/letters/redux/reducers/lettersReducer";
import meetingEmailReducer from "../../module/meetings/redux/reducers/meetingEmailReducer";
import meetingReducer from "../../module/meetings/redux/reducers/meetingReducer";
import bankReducer from "../../module/banks/redux/reducers/banksReducer";
import contractReducer from "../../module/contract/redux/reducer/contractReducer";
import contractTypeReducer from "../../module/contract/redux/reducer/contractTypesReducer";
import adminReducer from "../../module/admins/redux/reducers/adminReducer";
import leavePolicyReducer from "../../module/leavePolicy/redux/reducer/leavePolicyReducer";
import reportsReducer from "../../module/reports/redux/reducer/reportsReducer";
const rootReducer = combineReducers({
  commonReducer,
  userReducer,
  employeesReducer,
  profileReducer,
  userProfileReducer,
  jobsReducer,
  calenderReducer,
  leaveRequestReducer,
  lettersRequestReducer,
  attendanceReducer,
  payrollReducer,
  payStubReducer,
  contactRequestsReducer,
  jobsRequestsReducer,
  interviewReducer,
  ranksReducer,
  InterviewQuestionsReducer,
  galleryReducer,
  lettersReducer,
  meetingEmailReducer,
  meetingReducer,
  bankReducer,
  contractReducer,
  contractTypeReducer,
  adminReducer,
  leavePolicyReducer,
  reportsReducer,
});

export default rootReducer;
