import React, { useState } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import { deleteContractType } from "./redux/actions/contractTypeAction";
import { CircularProgress } from "@material-ui/core";
import useStyles from "./ContractTypeStyle";
import Button from "@material-ui/core/Button";
import { ContractTypeModal, ConfirmModal } from "./components/modals/Modals";
const ContractTypeTable = () => {
  const { allContractTypes } = useSelector(
    (state) => state?.contractTypeReducer,
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [details, setDetails] = useState({});
  const [index, setIndex] = useState(null);
  const toggleModal = (data) => {
    setModal(!modal);
    if (data) {
      setDetails(data);
    } else {
      setDetails({});
    }
  };

  const toggleDeleteModal = (i) => {
    setDeleteModal(!deleteModal);
    setIndex(i);
  };

  const handleRemove = () => {
    setDeleteModal(false);
    dispatch(deleteContractType(allContractTypes?.[index]?.id, setLoading));
  };

  const columns = [
    {
      name: "type",
      label: "Title",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let type = allContractTypes[dataIndex]?.type;
          return <span>{type}</span>;
        },
      },
    },
    {
      name: "emailSubject",
      label: "Contract Subject",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let type = allContractTypes[dataIndex]?.emailSubject || "";
          return <span>{type}</span>;
        },
      },
    },
    {
      name: "emailMessage",
      label: "Contract Message",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let type = allContractTypes[dataIndex]?.emailMessage;
          return <span>{type}</span>;
        },
      },
    },
    {
      name: "Actions",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const contract = allContractTypes?.[dataIndex];
          return (
            <>
              {loading && index === dataIndex ? (
                <CircularProgress
                  // className={classes.circularProgress}
                  size={25}
                />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => toggleDeleteModal(dataIndex)}
                    className={classes.btnMargin}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => toggleModal(contract)}
                    className={classes.btnMargin}
                  >
                    Edit
                  </Button>
                </>
              )}
            </>
          );
        },
      },
    },
  ];

  const options = {
    searchPlaceholder: "Search here...",
    filter: false,
    searchAlwaysOpen: true,
    responsive: "standard",
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    selectableRows: false,
  };

  const theme = createTheme({
    overrides: {
      MUIDataTableHeadCell: {
        sortAction: {
          fontSize: "1rem",
          fontWeight: "bolder",
        },
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          // Soft hide the button.
          visibility: "hidden",
        },
      },
    },
  });

  return (
    <>
      <Button
        className={"mb-2"}
        variant="contained"
        color="primary"
        onClick={() => toggleModal()}
      >
        Add Contract Type
      </Button>
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          className="mainTable"
          data={allContractTypes}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
      <ContractTypeModal modal={modal} toggle={toggleModal} data={details} />
      <ConfirmModal
        message="Are you sure for delete this contract type?"
        modal={deleteModal}
        toggle={toggleDeleteModal}
        action={handleRemove}
      />
    </>
  );
};

export default ContractTypeTable;
