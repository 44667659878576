import {
  GET_ALL_BANKS,
  DELETE_BANK,
  SUCCESS_ALERT,
  ERROR_ALERT,
  ADD_BANK,
  UPDATE_BANK,
} from "../constant/index";

const initialState = {
  allBanks: [],
  successMessage: "",
  errorMessage: "",
};

const bankReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BANKS:
      return {
        ...state,
        allBanks: action.payload,
      };
    case ADD_BANK:
      return {
        ...state,
        allBanks: [...state.allBanks, action.payload],
      };
    case UPDATE_BANK:
      const data = action.payload;
      var foundIndex = state.allBanks.findIndex((bank) => bank.id === data.id);
      state.allBanks[foundIndex] = data;
      return {
        ...state,
      };
    case DELETE_BANK:
      const updated = state.allBanks.filter((el) => el.id !== action.payload);
      return {
        allBanks: updated,
      };
    case SUCCESS_ALERT:
      return {
        ...state,
        successMessage: action.payload,
      };
    case ERROR_ALERT:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};
export default bankReducer;
