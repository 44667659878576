import {
  GET_ALL_EMAILS,
  DELETE_EMAIL,
  SUCCESS_ALERT,
  ERROR_ALERT,
  ADD_EMAIL,
  UPDATE_EMAIL,
} from "../constant/index";

const initialState = {
  allEmails: [],
  successMessage: "",
  errorMessage: "",
};

const meetingEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EMAILS:
      return {
        ...state,
        allEmails: action.payload,
      };
    case ADD_EMAIL:
      return {
        ...state,
        allEmails: [...state.allEmails, action.payload],
      };
    case UPDATE_EMAIL:
      const data = action.payload;
      var foundIndex = state.allEmails.findIndex((email) => email.id === data.id);
      state.allEmails[foundIndex] = data;
      return {
        ...state,
      };
    case DELETE_EMAIL:
      const updated = state.allEmails.filter((el) => el.id !== action.payload);
      return {
        allEmails: updated,
      };
    case SUCCESS_ALERT:
      return {
        ...state,
        successMessage: action.payload,
      };
    case ERROR_ALERT:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};
export default meetingEmailReducer;
