import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Grid, Chip, MuiThemeProvider } from "@material-ui/core";
import { successMessage } from "./redux/actions/employeeAction";
import { useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { GitHub } from "@material-ui/icons";
import { mdiSkypeBusiness } from "@mdi/js";
import Icon from "@mdi/react";
import useStyles from "./style";
import { GetMuiTheme } from "../utils/TableHeadStyle/style";
import { Button } from "antd";
import ImageDilog from "../../components/imageDilog/ImageDilog";

const Table = (props) => {
  const dispatch = useDispatch();
  const [copyValue, setCopyValue] = useState("");
  const [copy, setCopy] = useState(false);
  const classes = useStyles();
  const path = props.location.pathname;

  const handleMore = (i) => {
    let id = props.blocks[i].docId;
    props.history.push({
      pathname:
        path === "/employees" || path === "/bootcampTeam"
          ? `/profile/employee/${id}`
          : path === "/onboarding" || path === "/bootcampOnboarding"
          ? `/profile/onboard/${id}`
          : `/teamProfile/${id}`,
      id: id,
    });
  };
  const states = {
    approved: "approved",
    blocked: "blocked",
    onboarding: "onboarding",
    declined: "declined",
  };
  const handleCopy = (value, fieldName) => {
    setCopyValue(value);
    setCopy(true);
    dispatch(successMessage(`${value} copied.`));
  };

  const columns = [
    {
      name: "",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let ID = props.blocks[dataIndex].docId;
          return (
            <CopyToClipboard text={ID} onCopy={() => handleCopy(ID, "id")}>
              <span>#</span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "profilePhoto",
      label: "Photo",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let profilePhoto = props.blocks[dataIndex].profilephoto;
          return (
           <ImageDilog src={profilePhoto} />
          );
        },
      },
    },
    {
      name: "fullName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let fullName = props.blocks[dataIndex].fullName.toUpperCase();
          return <span>{fullName}</span>;
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let email = props.blocks[dataIndex].email.toLowerCase();
          return (
            <CopyToClipboard
              text={email}
              onCopy={() => handleCopy(email, "email")}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                {email}
              </span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "phoneNumber",
      label: "Mobile No.",
      indexColumn: "id",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let phoneNumber = props.blocks[dataIndex].phoneNumber;
          return (
            <CopyToClipboard
              text={phoneNumber}
              onCopy={() => handleCopy(phoneNumber, "mobileNumber")}
            >
              <span>{phoneNumber}</span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "github",
      label: "Github",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let github = props.blocks[dataIndex].github?.split("/").pop();
          return (
            <CopyToClipboard
              text={github}
              onCopy={() => handleCopy(github, "github username")}
            >
              <span className="gitIcon">
                <GitHub />
              </span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "skype",
      label: "Skype",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          let skype = props.blocks[dataIndex].skype;
          return (
            <CopyToClipboard
              text={skype}
              onCopy={() => handleCopy(skype, "skype link")}
            >
              <span className="skypeIcon">
                <Icon
                  path={mdiSkypeBusiness}
                  size={1}
                  horizontal
                  vertical
                  // rotate={90}
                  // color="red"
                />
              </span>
            </CopyToClipboard>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          let status = props.blocks[dataIndex].status;
          return (
            <Chip
              label={status}
              classes={{
                root: classes[states[status]],
              }}
            />
          );
        },
      },
    },
    {
      name: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <Button
              className={classes.moreButton}
              variant="outlined"
              color="primary"
              onClick={() => handleMore(dataIndex)}
            >
              More
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    searchPlaceholder: "Search here...",
    searchAlwaysOpen: true,
    filter: false,
    responsive: "standard",
    selectableRows: false,
  };

  return (
    <Grid container spacing={3} style={{ marginTop: "30px" }}>
      <Grid items xs={12}>
        <MuiThemeProvider theme={GetMuiTheme}>
          <MUIDataTable
            className="mainTable"
            data={props.blocks}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Grid>
    </Grid>
  );
};

export default Table;
