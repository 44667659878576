import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./style";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  updateSortBy,
  getTeam,
  updateMainsiteTeam,
  getAll,
  getAllTeam,
} from "../employees/redux/actions/employeeAction";
import { Button, Chip, Switch } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import CircularLoader from "../utils/circularProgress";
import { Checkbox } from "@material-ui/core";

export default function TeamRank() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const teamData = useSelector((state) => state.employeesReducer.mainsiteTeam);
  const allEmployees = useSelector((state) => state.employeesReducer.allUsers);
  useEffect(() => {
    dispatch(getAll(setLoading));
    dispatch(getAllTeam(setLoading));
  }, []);

  const addTeam = () => {
    dispatch(getTeam({ setLoading, teamData, allEmployees }));
  };

  const onChangeStatus = (e, doc) => {
    dispatch(
      updateMainsiteTeam({
        ...doc,
        [e?.target?.value]: !doc?.[[e?.target?.value]],
      }),
    );
  };
  const RenderTable = React.memo(({ list }) => {
    return (
      <>
        <div style={{ marginBottom: "2%" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => addTeam()}
            disabled={loading}
          >
            Sync Data
          </Button>
        </div>
        <div className={classes.table}>
          <header className={classes.header}>
            <strong className={classes.firstCeil}>&nbsp;</strong>
            <strong className={classes.secondCeil}>Name</strong>
            <strong className={classes.third}>Job Type</strong>
            <strong className={classes.fourth}>Current Rank</strong>
            <strong className={classes.five}>Status</strong>
            <strong className={classes.six}>Level</strong>
            <strong className={classes.six}>Hide</strong>
            <strong className={classes.six}>Executive</strong>
          </header>
          <section>
            {list?.map((doc, index) => (
              <Draggable draggableId={doc?.id?.toString()} key={index} index={index}>
                {(provided) => (
                  <div
                    key={doc.id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classes.bodyRow}
                  >
                    <div className={classes.firstCeil}>
                      <img
                        src={doc?.profilephoto}
                        className={classes.Image}
                        alt="profile"
                      />
                    </div>
                    <div className={classes.secondCeil}>{doc?.fullName}</div>
                    <div className={classes.third}>{doc?.jobType}</div>
                    <div className={classes.fourth}>{doc?.rankTitle}</div>
                    <div className={classes.five}>
                      <Chip
                        label={doc?.status}
                        classes={{
                          root: classes.approved,
                        }}
                      />
                    </div>
                    <div className={classes.six}>
                      <Chip
                        label={`Level ${doc?.sortBy}`}
                        classes={{
                          root: classes.level,
                        }}
                      />
                    </div>
                    <div className={classes.six}>
                      <Checkbox
                        color="primary"
                        checked={doc?.hide}
                        value={"hide"}
                        onChange={(e) => onChangeStatus(e, doc)}
                      />
                    </div>
                    <div className={classes.six}>
                      <Checkbox
                        color="primary"
                        value={"isExecutive"}
                        checked={doc?.isExecutive}
                        onChange={(e) => onChangeStatus(e, doc)}
                      />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
          </section>
        </div>
      </>
    );
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const sortedList = reorder(
      teamData,
      result.source.index,
      result.destination.index,
    );

    let arrangedList = sortedList?.map((doc, index) => {
      return {
        ...doc,
        sortBy: index + 1,
      };
    });

    dispatch(updateSortBy(arrangedList));
  }

  const sortedArray = teamData?.sort(function (a, b) {
    return Number(a?.sortBy) - Number(b?.sortBy);
  });

  return (
    <>
      <PageTitle title={"Techloset Team Rank"} />
      {loading ? (
        <CircularLoader />
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <RenderTable list={sortedArray} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
}
