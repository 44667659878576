import moment from "moment";
import {
  ATTENDANCE_STATUS_OPTIONS,
  ATTENDANCE_STATUS,
} from "../attendance/redux/constant/attendanceConstant";
const monthDiff = (d1, d2) => {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};
const countDays = (startDate, endDate, format = "YYYY-MM-DD") => {
  const start = moment(startDate, format);
  const end = moment(endDate, format);
  return end.diff(start, "days") + 1;
};
const countTotal = (data) => {
  let count = 0;
  if (data && data.length > 0) {
    count = data.reduce(
      (accumulator, item) => accumulator + item?.daysCount || 0,
      0,
    );
  }
  return count;
};


export const calculateMonthlyTax = (monthlyNetSalary) => {
  let tax = 0;

  if (monthlyNetSalary <= 50000) {
    tax = 0;
  } else if (monthlyNetSalary > 50000 && monthlyNetSalary <= 100000) {
    tax = (monthlyNetSalary - 50000) * 0.05;
  } else if (monthlyNetSalary > 100000 && monthlyNetSalary <= 183333) {
    tax = 2500 + (monthlyNetSalary - 100000) * 0.15;
  } else if (monthlyNetSalary > 183333 && monthlyNetSalary <= 266667) {
    tax = 15000 + (monthlyNetSalary - 183333) * 0.25;
  } else if (monthlyNetSalary > 266667 && monthlyNetSalary <= 341667) {
    tax = 35833 + (monthlyNetSalary - 266667) * 0.3;
  } else if (monthlyNetSalary > 341667) {
    tax = 58333 + (monthlyNetSalary - 341667) * 0.35;
  }

  return Math.round(tax);
};



export const getSalary = (
  allAttendance,
  userItem,
  endOfPrevMonth,
) => {
  const attendance = allAttendance.filter((el) => el.userId === userItem.id);
  const leaves = attendance.reduce((acc, attendanceObj) => {
    let obj = attendanceObj;
    const type = attendanceObj.label;
    if (!acc[type]) {
      acc[type] = [];
    }
    if (obj?.start?.seconds) {
      let start = obj?.start?.toDate();
      let end = obj?.end?.toDate();
      const title =
        ATTENDANCE_STATUS_OPTIONS.find((item) => item.value === obj?.title)
          ?.title || obj?.title;
      const label =
        ATTENDANCE_STATUS_OPTIONS.find((item) => item.value === obj?.label)
          ?.title || obj?.label;
      start = moment(start)?.format("DD/MM/YYYY");
      end = moment(end)?.format("DD/MM/YYYY");
      obj["start"] = start;
      obj["end"] = end;
      let totalCount = 0;
      if (start === end) {
        totalCount = 1;
      } else {
        totalCount = countDays(start, end, "DD/MM/YYYY");
      }
      obj["daysCount"] = totalCount;
      obj["label"] = label;
      obj["title"] = title;
    }

    acc[type].push(obj);
    return acc;
  }, {});
  const events = [];
  const {
    [ATTENDANCE_STATUS.PAID_LEAVE]: paidLeaves = [],
    [ATTENDANCE_STATUS.ABSENT]: absentLeaves = [],
    [ATTENDANCE_STATUS.LATE]: lateLeaves = [],
    [ATTENDANCE_STATUS.CASUAL_LEAVE]: casualLeaves = [],
    [ATTENDANCE_STATUS.SICK_LEAVE]: sickLeaves = [],
    [ATTENDANCE_STATUS.ANNUAL_LEAVE]: annualLeaves = [],
    [ATTENDANCE_STATUS.MATERNITY_PATERNITY_LEAVE]:
      maternityPaternityLeaves = [],
    [ATTENDANCE_STATUS.UNPAID_LEAVE]: unpaidLeaves = [],
    [ATTENDANCE_STATUS.HALF_LEAVE]: halfLeaves = [],
  } = leaves || {};
  const lastincrement = userItem?.increamentHistory?.[0] || "NA";
  let oldSalary;
  if (lastincrement !== "NA") {
    const date = moment(endOfPrevMonth).format("YYYY/MM/DD-hh:mm a");
    const increamentDate = moment(
      lastincrement?.increamentAt,
      "MMMM Do YYYY, h:mm a",
    ).format("YYYY/MM/DD-hh:mm a");
    if (increamentDate > date) {
      oldSalary = lastincrement?.oldSalary;
    }
  }

  const paidLeavesDetails = [
    ...paidLeaves,
    ...lateLeaves,
    ...casualLeaves,
    ...sickLeaves,
    ...annualLeaves,
    ...maternityPaternityLeaves,
  ];
  const unpaidLeavesDetails = [...absentLeaves, ...unpaidLeaves];
  const halfLeavesDetails = halfLeaves;
  const totalPaidLeaves = countTotal(paidLeavesDetails);
  const totalUnpaidLeaves = countTotal(unpaidLeavesDetails);
  const totalHalfLeaves = countTotal(halfLeavesDetails);
  let daysOff = totalUnpaidLeaves + totalPaidLeaves;
  let halfDayLeave = 0;
  if (totalHalfLeaves >= 2) {
    const days = Math.floor((totalHalfLeaves - 1) / 2);
    daysOff = daysOff + days;
    halfDayLeave = days;
  }
  let prevMonthStartedJobDays = 0;
  let pay = userItem?.basicPay;
  if (oldSalary >= 0) {
    pay = oldSalary;
  }
  const basicPay = Number(pay);
  var daysOfMonth = moment(new Date()).subtract(1, "months").daysInMonth();
  const startOfMonth = moment(new Date())
    .subtract(1, "months")
    .startOf("month")
    .toDate();
  const endOfMonth = moment(new Date())
    .subtract(1, "months")
    .endOf("month")
    .toDate();

  const jobStarted = userItem.JobStartedAt?.toDate();

  if (new Date(jobStarted).getMonth() === new Date().getMonth() - 1) {
    prevMonthStartedJobDays = 31 - jobStarted.getDate();
  }

  let lateDayCount = 0;
  let monthDif = monthDiff(new Date(jobStarted), new Date());
  const perDaySalary = Math.floor(basicPay / 30);
  if (monthDif === 1) {
    lateDayCount = parseInt(jobStarted.getDate() - 1);
  }
  const totalDayoff = parseInt(daysOff) + parseInt(lateDayCount);
  const workingDays = Number(daysOfMonth) - totalDayoff;
  let lateJoiningDeduction = lateDayCount * parseInt(perDaySalary);
  const unpaidLeavesDeduction =
    parseInt(totalUnpaidLeaves) * parseInt(perDaySalary);
  const deductedSalary = lateJoiningDeduction;
  const totalSalary =
    basicPay - deductedSalary;

  const deductions = [
    {
      title: "Unpaid Leaves",
      amount: unpaidLeavesDeduction,
    },
  ];

  const earnings = [
    {
      title: "Paid Leaves",
      amount: unpaidLeavesDeduction,
    },
  ];
  if (lateJoiningDeduction) {
    deductions.push({
      title: "Job Started Late",
      amount: lateJoiningDeduction,
    });
  }

  const monthlyTax = calculateMonthlyTax(totalSalary);
  deductions.push({
    title: "Income Tax",
    amount: monthlyTax,
  });

  const netSalary =
  totalSalary - monthlyTax;

  const data = {
    netSalary,
    totalSalary,
    tax:monthlyTax,
    bonus: 0,
    workingDays,
    totalDayoff,
    deductions,
    earnings,
    userId: userItem?.id,
    leaves: Number(totalPaidLeaves + totalUnpaidLeaves + halfDayLeave),
    travelExpenditure: parseInt(userItem.travelExpenditure),
    deductedSalary,
    startOfMonth,
    endOfMonth,
    createdAt: new Date(),
    paidLeaves: totalPaidLeaves,
    basicPay,
    fullName: userItem?.fullName,
    profilephoto: userItem?.profilephoto,
    email: userItem?.email,
    accountHolderName: userItem?.accountHolderName,
    accountName: userItem?.accountName,
    accountNumber: userItem?.accountNumber,
    accountIbn: userItem?.accountIbn,
    idCardNumber: userItem?.idCardNumber,
    generalLeaveDetails: unpaidLeavesDetails,
    paidLeavesDetails: paidLeavesDetails,
    halfLeavesDetails,
  };
  return data;
};

export const addBonusInSalary = (payrollItem, bonus) => {
  const netSalary = parseInt(payrollItem.netSalary) + parseInt(bonus);
  return {
    ...payrollItem,
    netSalary,
    bonus,
  };
};

export const updateExpense = (payrollItem, expense) => {
  const netSalary =
    parseInt(payrollItem.netSalary) -
    parseInt(payrollItem.travelExpenditure) +
    parseInt(expense);
  return {
    ...payrollItem,
    netSalary,
    travelExpenditure: expense,
  };
};
