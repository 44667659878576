import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "100%",
  },
  progress: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(5),
    },
  },
  noDetailImage: {
    width: "80%"
  },
  noDetailText: {
    fontSize: "5rem"
  },
  Item: {
    "&:hover": {
      borderBottom: '4px solid white!important'
    }
  },
  active: {
    borderBottom: '4px solid white!important'
  }
}));

export const style = {
  button: {
    marginRight: "10px",
  },
  BackBtn: {
    marginRight: "10px",
    color: "white",
    backgroundColor: "rgb(164,164,168)",
  },
  approveBtn: {
    marginRight: "10px",
    color: "white",
    backgroundColor: "rgb(76,175,80)",
  },
  blockBtn: {
    marginRight: "10px",
    color: "white",
  },
  updateBtn: {
    marginRight: "10px",
    color: "white",
  },
  imageGrid: { height: "40vh" },
  text: {
    paddingBottom: "40px",
    paddingLeft: "20px",
    paddingTop: "30px",
    fontWeight: "300",
    letterSpacing: "0.05rem",
    fontSize: "1.32rem",
    color: "#2c2c2c",
    fontFamily: "Helvetica",
  },
  image: {
    width: "112px",
    height: "112px",
    borderRadius: "0.5rem",
    marginLeft: "20px",
    marginBottom: "30px",
  },
  title: {
    fontWeight: "600",
    fontSize: "1rem",
    lineHieght: "1.45",
    color: "#626262",
    marginBottom: "9px",
  },
  titleIn: {
    fontWeight: "600",
    fontSize: "1rem",
    lineHieght: "1.45",
    color: "#626262",
    marginLeft: "20px",
    marginBottom: "9px",
  },
  detail: {
    marginBottom: "10px",
  },

  bgcolor: {
    left: "0px",
    right: "0px",
    paddingLeft: "33px",
    marginTop: "50px",
  },

  heading: {
    color: "black",
    fontSize: "36px",
    lineHeight: "1.4",
    fontWeight: "700",
    marginBottom: "20px",
  },
  para: {
    fontWeight: "300",
    letterSpacing: "2px",
    fontSize: "16px",
    marginBottom: "100px",
  },

  texting: {
    textAlign: "center",
    fontWeight: "300",
    letterSpacing: "2px",
    fontSize: "16px",
  },
  appBarTitle: {
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "20px",
    marginLeft:"20px"
  }
};
