import {
  SUCCESS_ALERT,
  ERROR_ALERT,
  ADD_ADMIN,
  GET_ALL_ADMINS,
  DELETE_ADMIN,
  UPDATE_ADMIN,
} from "../constant/adminConstant";

const initialState = {
  allAdmins: [],
  successMessage: "",
  errorMessage: "",
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ADMINS:
      return {
        ...state,
        allAdmins: action.payload,
      };
    case ADD_ADMIN:
      return {
        ...state,
        allAdmins: [action.payload, ...state.allAdmins],
      };
    case UPDATE_ADMIN:
      const data = action.payload;
      var foundIndex = state.allAdmins.findIndex(
        (email) => email.id === data.id,
      );
      state.allAdmins[foundIndex] = data;
      return {
        ...state,
      };
    case DELETE_ADMIN:
      const updated = state.allAdmins.filter((el) => el.id !== action.payload);
      return {
        allAdmins: updated,
      };
    case SUCCESS_ALERT:
      return {
        ...state,
        successMessage: action.payload,
      };
    case ERROR_ALERT:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};
export default adminReducer;
