import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
    inputField: {
        marginBottom: "20px",
        borderRadius: 0,
        border: "none"
    },
    addButton: {
        display: "flex",
        flexDirection: "row",
        marginRight: "30px"
    },
    dialogLoader: {
        color: "#7367f0",
        marginRight: "30px"
    }
}))