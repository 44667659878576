import { SET_USER, LOG_OUT, MANAGE_LOADING, SET_DETAILS, SET_LOADING,RESET_USER_REDUCER } from "../constant";
import axiosTechloset from "../../../../config/axiosTechloset";
const initialState = {
  user: {},
  loading: true,
  loggedIn: false,
  details: {},
  SignInOutLoader:false

};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        loggedIn: true,
        user: action?.payload,
        loading: false,
      };
    case SET_DETAILS:
      axiosTechloset.defaults.headers.common["uid"] = action?.payload?.uid;
      axiosTechloset.defaults.headers.common["userId"] = action?.payload?.id;
      localStorage.setItem(
        "visibleTabs",
        JSON.stringify({ ...action?.payload }),
      );
      return {
        ...state,
        details: action?.payload,
        loading: false,
      };
    case SET_LOADING: {
      return {
        ...state,
        SignInOutLoader: action?.payload || false
      };
    }
    case LOG_OUT: {
      return {
        ...state,
        loggedIn: false,
        user: {},
        details: {},
        loading: false,
      };
    }
    case MANAGE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case RESET_USER_REDUCER:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
