import React from "react";
import {
  NotificationsNone as NotificationsIcon,
  Group,
  Work as WorkIcon,
  EventAvailableOutlined,
  EmailOutlined,
  DoneOutline,
  Star,
  QuestionAnswer,
  Home as HomeIcon,
  DashboardRounded
} from "@material-ui/icons";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import { ROUTE_NAME } from "../constants";
const sidebarTabs = [
  {
    id: 0,
    label: "Dashboard",
    link: "/dashboard",
    from: "dashboard",
    icon: <HomeIcon />,
    protection: ROUTE_NAME?.DASHBOARD,
  },
  {
    id: 1,
    label: "Team",
    link: "/employees",
    from: "employee",
    icon: <Group />,
    protection: ROUTE_NAME?.TEAM,
  },
  {
    id: 1,
    label: "Team Info",
    link: "/teamMembers",
    from: "teamMembers",
    icon: <Group />,
    protection: ROUTE_NAME?.TEAM_INFO,
  },
  {
    id: 1,
    label: "Bootcamp Team",
    link: "/bootcampTeam",
    from: "bootcampTeam",
    icon: <Group />,
    protection: ROUTE_NAME?.BOOTCAMP_TEAM,
  },
  {
    id: 2,
    label: "Onboarding",
    link: "/onboarding",
    from: "onboarding",
    icon: <EmojiPeopleIcon />,
    protection: ROUTE_NAME?.ONBOARDING,
  },
  {
    id: 2,
    label: "Bootcamp Onboarding",
    link: "/bootcampOnboarding",
    from: "bootcampOnboarding",
    icon: <EmojiPeopleIcon />,
    protection: ROUTE_NAME?.BOOTCAMP_ONBOARDING,
  },
  {
    id: 3,
    label: "Attendance",
    link: "/attendance",
    icon: <DoneOutline />,
    protection: ROUTE_NAME?.ATTENDANCE,
  },
  {
    id: 3,
    label: "Bootcamp Attendance",
    link: "/bootcampAttendance",
    icon: <DoneOutline />,
    protection: ROUTE_NAME?.BOOTCAMP_ATTENDANCE,
  },
  {
    id: 4,
    label: "Leaves Requests",
    link: "/leaverequest",
    icon: <NotificationsIcon />,
    protection: ROUTE_NAME?.LEAVE_REQUEST,
  },
  {
    id: 4,
    label: "Bootcamp Leaves Requests",
    link: "/bootcampLeaveRequest",
    icon: <NotificationsIcon />,
    protection: ROUTE_NAME?.BOOTCAMP_LEAVE_REQUEST,
  },
  {
    id: 5,
    label: "Payroll",
    link: "/payroll",
    icon: <AccountBalanceIcon />,
    protection: ROUTE_NAME?.PAYROLL,
  },
  {
    id: 6,
    label: "PayStub",
    link: "/paystub",
    icon: <AssignmentIcon />,
    protection: ROUTE_NAME?.PAYSTUB,
  },
  // {
  //   id: 6,
  //   label: "Jobs Board",
  //   link: "/jobBoard",
  //   icon: <DashboardRounded />,
  //   protection: ROUTE_NAME?.JOB_REQUEST,
  // },
  {
    id: 7,
    label: "Job Applications",
    link: "/jobrequests",
    icon: <AssignmentIcon />,
    protection: ROUTE_NAME?.JOB_REQUEST,
  },

  {
    id: 8,
    label: "Interviews",
    link: "/interviews",
    icon: <AssignmentTurnedInOutlinedIcon />,
    protection: ROUTE_NAME?.INTERVIEWS,
  },
  {
    id: 9,
    label: "Meetings",
    icon: <EmojiPeopleIcon />,
    protection: ROUTE_NAME?.MEETINGS,
    children: [
      {
        id: 1,
        label: "Meeting",
        link: "/meeting",
        protection: ROUTE_NAME?.MEETINGS,
      },
      {
        id: 2,
        label: "Meeting Emails",
        link: "/meetingEmail",
        protection: ROUTE_NAME?.MEETINGS,
      },
    ],
  },
  {
    id: 10,
    label: "Contact Us",
    link: "/contactrequest",
    icon: <ContactSupportOutlinedIcon />,
    protection: ROUTE_NAME?.CONTACT_REQUEST,
  },
  {
    id: 11,
    divider: true,
  },
  {
    id: 11,
    label: "Ranks",
    link: "/positions",
    from: "ranks",
    icon: <Star />,
    protection: ROUTE_NAME?.RANKS,
  },
  {
    id: 12,
    label: "Team Rank",
    link: "/teamRank",
    from: "employee",
    icon: <Group />,
    protection: ROUTE_NAME?.TEAM_RANKS,
  },
  {
    id: 13,
    label: "Jobs",
    link: "/jobs",
    icon: <WorkIcon />,
    protection: ROUTE_NAME?.JOBS,
  },

  {
    id: 14,
    label: "Events",
    link: "/events",
    icon: <EventAvailableOutlined />,
    protection: ROUTE_NAME?.EVENTS,
  },
  {
    id: 15,
    label: "Letters",
    icon: <EmailOutlined />,
    protection: ROUTE_NAME?.LETTERS,
    children: [
      {
        id: 1,
        label: "Letters",
        link: "/letters",
        protection: ROUTE_NAME?.LETTERS,
      },
      {
        id: 9,
        label: "Letters Requests",
        link: "/lettersrequest",
        protection: ROUTE_NAME?.LETTERS,
      },
    ],
  },
  {
    id: 16,
    label: "Questions",
    link: "/interviewtype",
    from: "interviewtype",
    icon: <QuestionAnswer />,
    protection: ROUTE_NAME?.INTERVIEW_QUESTIONS,
  },
  {
    id: 17,
    label: "Banks",
    link: "/banks",
    icon: <WorkIcon />,
    protection: ROUTE_NAME?.BANKS,
  },
  {
    id: 18,
    label: "Leave Policy",
    link: "/leavePolicy",
    icon: <AssignmentIcon />,
    protection: ROUTE_NAME?.LEAVE_POLICY,
  },
  {
    id: 19,
    label: "Attendance Report",
    link: "/reports/attendance",
    icon: <ShowChartIcon />,
    protection: ROUTE_NAME?.ATTENDANCE_REPORT,
  },
  {
    id: 20,
    label: "Contract",
    icon: <AssignmentIcon />,
    protection: ROUTE_NAME?.CONTRACT,
    children: [
      {
        id: 1,
        label: "Contract Type",
        link: "/contractType",
        protection: ROUTE_NAME?.CONTRACT,
      },
      {
        id: 2,
        label: "Contract",
        link: "/contract",
        protection: ROUTE_NAME?.CONTRACT,
      },
    ],
  },
  {
    id: 21,
    label: "Admin",
    link: "/admins",
    from: "admin",
    icon: <Group />,
    protection: ROUTE_NAME?.ADMINS,
  },
];
export { sidebarTabs };
