import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { useStyles } from "./ContractStyle";
import { CircularProgress } from "@material-ui/core";
import { convertToHTML } from "draft-convert";
import DraftPasteProcessor from "draft-js/lib/DraftPasteProcessor";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import { updateContract, getContact } from "./redux/actions/contractAction";
import { getAllContractTypes } from "./redux/actions/contractTypeAction";
import { getDefaultContract } from "./defaultContract";

const Contract = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { contract } = useSelector((state) => state.contractReducer);
  const { allContractTypes } = useSelector(
    (state) => state?.contractTypeReducer,
  );
  const [date, setDate] = useState({});
  const [loading, setLoading] = useState(false);
  const [contractType, setContractType] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const [convertedContent, setConvertedContent] = useState(``);
  useEffect(() => {
    if (contractType) dispatch(getContact(setLoading, contractType));
  }, [contractType]);
  useEffect(() => {
    if (allContractTypes?.length === 0)
      dispatch(getAllContractTypes(setLoading));
  }, []);
  const setEditorContent = (contract) => {
    let editorState;
    const setprocessedHTML = DraftPasteProcessor.processHTML(contract);
    const setcontentState = ContentState.createFromBlockArray(setprocessedHTML);
    editorState = EditorState.createWithContent(setcontentState);
    editorState = EditorState.moveFocusToEnd(editorState);
    setEditorState(editorState);
  };

  useEffect(() => {
    if (contract !== null) {
      if (contract?.contract && contract?.contract !== "") {
        setEditorContent(contract?.contract);
        setDate(contract?.date);
      } else {
        const contract = getDefaultContract();
        setEditorContent(contract);
      }
    }
  }, [contract]);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };
  const onChangeHandler = (e) => {
    const value = e?.target?.value;
    setDate(value);
  };
  const submitHandler = async () => {
    try {
      dispatch(
        updateContract(
          { date, contract: convertedContent, type: contractType },
          setLoading,
        ),
      );
    } catch (error) {}
  };
  const handleChange = (event) => {
    const id = event.target.value;
    setContractType(id);
  };
  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        <h1 className="Details">Contract Details</h1>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item xs={12} md={12} sm={12}>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item md={12}>
                <TextField
                  id="modifiedDate"
                  name="modifiedDate"
                  label="*Modified date"
                  type="date"
                  className={classes.formControl}
                  fullWidth
                  value={date}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={onChangeHandler}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Select Contract Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={contractType}
                    onChange={handleChange}
                  >
                    {allContractTypes?.map((item) => {
                      return <MenuItem value={item?.id}>{item?.type}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <Editor
                  editorState={editorState}
                  // className={classes.input}
                  wrapperClassName={classes.wrapper}
                  editorClassName={classes.editor}
                  toolbarClassName={classes.toolbar}
                  onEditorStateChange={handleEditorChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item md={12} xs={12}>
            {loading ? (
              <CircularProgress className={classes.loader} />
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={submitHandler}
                  className={classes.button}
                >
                  Update
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Contract;
