import {
  SUCCESS_ALERT,
  ERROR_ALERT,
  GET_ALL_PAYROLL,
  UPDATE_PAYROLL,
} from "../constant/index";

const initialState = {
  payroll: [],
  successMessage: "",
  errorMessage: "",
  paystubs: [],
};

const payrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PAYROLL:
      return {
        ...state,
        payroll: action.payload,
      };
    case UPDATE_PAYROLL:
      const data = action.payload;
      let foundIndex = state.payroll.findIndex(
        (payroll) => payroll.userId === data.userId,
      );
      const newArr = [...state.payroll];
      newArr[foundIndex] = data;
      return {
        ...state,
        payroll: newArr,
      };
    case SUCCESS_ALERT:
      return {
        ...state,
        successMessage: action.payload,
      };
    case ERROR_ALERT:
      return {
        ...state,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default payrollReducer;
