import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCalendarForBootcamp,
  fetchForCalendar,
} from "../redux/actions/attendanceAction";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { ATTENDANCE_COLORS } from "../redux/constant/attendanceConstant";
import { useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: "-10px 5px",
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
}));

export default function Toolbar(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const allEmployee =
    useSelector((state) => state?.attendanceReducer?.allEmployee) || [];
  const allBootcamper =
    useSelector((state) => state?.attendanceReducer?.allBootcamper) || [];

  const [date, setdate] = useState(new Date());
  const [userId, setUserId] = useState("");

  const handleChange = (event) => {
    const id = event.target.value;
    setUserId(id);
    path === "/attendance"
      ? dispatch(fetchForCalendar(props.date || new Date(), id))
      : dispatch(fetchCalendarForBootcamp(props.date || new Date(), id));
  };
  const classes = useStyles();
  const handleNavigate = (e) => {
    props.onNavigate(e);
    let d = props.date;
    let month = d.getMonth();
    if (e === "PREV") {
      setdate(d.setMonth(month - 1));
    } else if (e === "NEXT") {
      setdate(d.setMonth(month + 1));
    } else setdate(new Date());
    path === "/attendance"
      ? dispatch(
          fetchForCalendar(e === "TODAY" ? new Date() : props.date, userId),
        )
      : dispatch(
          fetchCalendarForBootcamp(
            e === "TODAY" ? new Date() : props.date,
            userId,
          ),
        );
  };
  return (
    <div className="rbc-toolbar">
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Select User</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={userId}
          onChange={handleChange}
        >
          {
            path === "/attendance" ? 
            allEmployee?.map((emp) => {
              return <MenuItem value={emp?.id}>{emp?.fullName}</MenuItem>;
            }) :
            allBootcamper?.map((emp) => {
              return <MenuItem value={emp?.id}>{emp?.fullName}</MenuItem>;
            })
          }
        </Select>
      </FormControl>
      <span className="rbc-btn-group">
        <button type="button" onClick={() => handleNavigate("TODAY")}>
          Today
        </button>
        <button type="button" onClick={() => handleNavigate("PREV")}>
          Back
        </button>
        <button type="button" onClick={() => handleNavigate("NEXT")}>
          Next
        </button>
      </span>
      <span className="rbc-toolbar-label">{props.label}</span>
      <div className="event-tags d-none d-sm-flex justify-content-end mt-1">
        <div className="tag mr-1">
          <span
            style={{ backgroundColor: ATTENDANCE_COLORS?.present }}
            className="bullet  bullet-sm mr-50"
          ></span>
          <span>Present </span>
        </div>
        <div className="tag mr-1">
          <span
            style={{ backgroundColor: ATTENDANCE_COLORS?.generalLeave }}
            className="bullet  bullet-sm mr-50"
          ></span>
          <span>Absent </span>
        </div>
        <div className="tag mr-1">
          <span
            style={{ backgroundColor: ATTENDANCE_COLORS?.late }}
            className="bullet bullet-sm mr-50"
          ></span>
          <span>Late</span>
        </div>

        <div className="tag mr-1">
          <span
            style={{ backgroundColor: ATTENDANCE_COLORS?.casualLeave }}
            className="bullet  bullet-sm mr-50"
          ></span>
          <span>Casual Leave </span>
        </div>
        <div className="tag mr-1">
          <span
            style={{ backgroundColor: ATTENDANCE_COLORS?.sickLeave }}
            className="bullet  bullet-sm mr-50"
          ></span>
          <span>Sick Leave </span>
        </div>
        <div className="tag mr-1">
          <span
            style={{ backgroundColor: ATTENDANCE_COLORS?.paidLeave }}
            className="bullet  bullet-sm mr-50"
          ></span>
          <span>Paid Leave </span>
        </div>
        <div className="tag mr-1">
          <span
            style={{ backgroundColor: ATTENDANCE_COLORS?.annualLeave }}
            className="bullet  bullet-sm mr-50"
          ></span>
          <span>Annual Leave </span>
        </div>
        <div className="tag mr-1">
          <span
            style={{
              backgroundColor: ATTENDANCE_COLORS?.maternityPaternityLeave,
            }}
            className="bullet  bullet-sm mr-50"
          ></span>
          <span>Maternity Paternity Leave </span>
        </div>
        <div className="tag mr-1">
          <span
            style={{ backgroundColor: ATTENDANCE_COLORS?.remote }}
            className="bullet  bullet-sm mr-50"
          ></span>
          <span>Remote </span>
        </div>
        <div className="tag mr-1">
          <span
            style={{ backgroundColor: ATTENDANCE_COLORS?.unpaidLeave }}
            className="bullet  bullet-sm mr-50"
          ></span>
          <span>UnPaid Leave</span>
        </div>
      </div>
    </div>
  );
}
