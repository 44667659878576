import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppliedJobs,
  getDeclinedJobs,
  getHiredJobs,
  getInterViewedJobs,
  getFutureJobs,
  getInterViewScheduleJobs,
  getInterViewReSheduleJobs,
  getInterViewCancelJobs,
  getOnBoardingJobs,
  getSearchedJobs,
} from "../redux/actions/jobsRequestsAction";

export function useJobRequest() {
  const {
    searchedJobs,
    appliedJobs,
    declinedJobs,
    futureJobs,
    interviewScheduleJobs,
    interviewRescheduleJobs,
    interviewedJobs,
    interviewCancelJobs,
    hiredJobs,
    onBoardJobs,
  } = useSelector((state) => state.jobsRequestsReducer);
  const [loading, setloading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredSearch, setFilteredSearch] = useState([]);
  // const [loading, setloading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (appliedJobs?.length < 1) {
      dispatch(getAppliedJobs(setloading));
    } else setloading(false);
  }, []);

  const showDeclinedJob = () => {
    if (declinedJobs?.length < 1) {
      dispatch(getDeclinedJobs(setloading));
    } else setloading(false);
  };

  const showHiredJob = () => {
    if (hiredJobs?.length < 1) {
      dispatch(getHiredJobs(setloading));
    } else setloading(false);
  };
  const showFutureJob = () => {
    if (futureJobs?.length < 1) {
      dispatch(getFutureJobs(setloading));
    } else setloading(false);
  };
  const showOnBoardJob = () => {
    if (onBoardJobs?.length < 1) {
      dispatch(getOnBoardingJobs(setloading));
    } else setloading(false);
  };

  const showInterviewedJob = () => {
    if (interviewedJobs?.length < 1) {
      dispatch(getInterViewedJobs(setloading));
    } else setloading(false);
  };
  const showCancelInterviewJob = () => {
    if (interviewCancelJobs?.length < 1) {
      dispatch(getInterViewCancelJobs(setloading));
    } else setloading(false);
  };
  const showInterviewScheduleJob = () => {
    if (interviewScheduleJobs?.length < 1) {
      dispatch(getInterViewScheduleJobs(setloading));
    } else setloading(false);
  };
  const showInterviewRescheduleJob = () => {
    if (interviewRescheduleJobs?.length < 1) {
      dispatch(getInterViewReSheduleJobs(setloading));
    } else setloading(false);
  };

  const searchHandler = () => {
    let filter = searchedJobs.filter((job) => {
      if (
        job.fname?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
        job.email?.toLowerCase()?.includes(searchValue?.toLowerCase())
      ) {
        return job;
      }
    });
    setFilteredSearch(filter?.length ? filter : searchedJobs);
  };

  const onChangeHandler = (val) => {
    setSearchValue(val);
    searchHandler();
  };

  const foucsHandler = () => {
    dispatch(getSearchedJobs());
  };

  return {
    showDeclinedJob,
    showFutureJob,
    showInterviewScheduleJob,
    showInterviewRescheduleJob,
    showCancelInterviewJob,
    showInterviewedJob,
    showHiredJob,
    showOnBoardJob,
    filteredSearch,
    appliedJobs,
    declinedJobs,
    futureJobs,
    interviewScheduleJobs,
    interviewRescheduleJobs,
    interviewedJobs,
    interviewCancelJobs,
    hiredJobs,
    loading,
    onBoardJobs,
    searchValue,
    onChangeHandler,
    searchHandler,
    foucsHandler,
  };
}
