import {
  GET_ALL_JOBS,
  ADD_JOB,
  EDIT_JOB,
  UPDATE_JOB,
  DELETE_JOB,
  GET_ALL_JOB_TYPES,
  ADD_JOB_TYPE,
  DELETE_JOB_TYPE,
  UPDATE_JOB_TYPE,
  ADD_JOB_FIELD,
  GET_ALL_FIELDS,
} from "../constant";

const initialState = {
  allJobs: [],
  currentJob: {},
  jobTypes: [],
  jobFields: [],
};

const jobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_JOBS:
      return {
        ...state,
        allJobs: action.payload,
      };
    case ADD_JOB:
      return {
        ...state,
        allJobs: [...state.allJobs, action.payload],
      };
    case EDIT_JOB:
      return {
        ...state,
        currentJob: action.payload,
      };
    case UPDATE_JOB:
      const data = action.payload;
      // console.log("data", data);
      var foundIndex = state.allJobs.findIndex((job) => job.id === data.id);
      state.allJobs[foundIndex] = data;
      return {
        ...state,
      };
    case DELETE_JOB:
      const updated = state.allJobs.filter((job) => job.id !== action.payload);
      // console.log("updated", updated);
      return {
        ...state,
        allJobs: updated,
      };
    case ADD_JOB_FIELD:
      return {
        ...state,
        jobFields: [...state.jobFields, action.payload],
      };
    case GET_ALL_FIELDS:
      return {
        ...state,
        jobFields: action.payload,
      };
    default:
      return state;
  }
};

export default jobsReducer;
