import moment from "moment";
import {
  SUCCESS_ALERT,
  ERROR_ALERT,
  GET_ALL_APPLIED_JOBS,
  GET_ALL_DECLINED_JOBS,
  GET_ALL_FUTURE_JOBS,
  GET_ALL_INTERVIEW_SCHEDULE_JOBS,
  GET_ALL_INTERVIEW_RESCHEDULE_JOBS,
  GET_ALL_INTERVIEWED_JOBS,
  GET_ALL_HIRED_JOBS,
  UPDATE_STATUS,
  GET_ALL_INTERVIEW_CANCEL_JOBS,
  INTERVIEW_SHECDULE,
  GET_ALL_ONBOARD_JOBS,
  APPLY_FOR_JOB,
  SEARCHED_JOBS,
  GET_SELECTED_INTERVIEWS,
  FILTER_SELECTED_INTERVIEWS,
} from "../constant/index";
import AxiosTechloset from "../../../../config/axiosTechloset";
import { createEvent } from "../../../update/redux/actions/updateAction";
import axiosTechloset from "../../../../config/axiosTechloset";
import { db, storage } from "../../../../config/firebase";
import { updateGoogleEvent } from "../../../update/redux/actions/updateAction";
const getJobs = async (status) => {
  let Jobs = await db
    .collection("JobApplication")
    .where("status", "==", `${status}`)
    .get();

  let data = [];
  Jobs.forEach((item) => {
    data.push({ docId: item.id, ...item.data() });
  });
  return data.sort((a, b) => {
    return new Date(b?.createdAt) - new Date(a?.createdAt);
  });
};

export const addToInterview = (status) => async (dispatch) => {
  let data = await getJobs(status);
  dispatch({
    type: GET_SELECTED_INTERVIEWS,
    payload: data,
  });
};
export const removeFromInterview = (status) => async (dispatch) => {
  dispatch({
    type: FILTER_SELECTED_INTERVIEWS,
    payload: status,
  });
};

export const getSearchedJobs = (searchValue) => async (dispatch) => {
  try {
    let res = await db.collection("JobApplication").get();
    let jobList = [];
    res.forEach((item) => {
      jobList.push({ docId: item.id, ...item.data() });
    });

    dispatch({
      type: SEARCHED_JOBS,
      payload: jobList,
    });
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};

export const getAppliedJobs = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    let data = await getJobs("applied");
    dispatch({ type: GET_ALL_APPLIED_JOBS, payload: data });
    setloading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });

    setloading(false);
  } finally {
    setloading(false);
  }
};

export const getDeclinedJobs = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    let data = await getJobs("declined");

    dispatch({ type: GET_ALL_DECLINED_JOBS, payload: data });
    setloading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });

    setloading(false);
  } finally {
    setloading(false);
  }
};

export const getHiredJobs = (setloading) => async (dispatch) => {
  try {
    setloading(true);

    let data = await getJobs("hired");

    dispatch({ type: GET_ALL_HIRED_JOBS, payload: data });
    setloading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });

    setloading(false);
  } finally {
    setloading(false);
  }
};
export const getFutureJobs = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    let data = await getJobs("future");

    dispatch({ type: GET_ALL_FUTURE_JOBS, payload: data });
    setloading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });

    setloading(false);
  } finally {
    setloading(false);
  }
};
export const getInterViewedJobs = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    let data = await getJobs("interviewed");

    dispatch({ type: GET_ALL_INTERVIEWED_JOBS, payload: data });
    setloading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });

    setloading(false);
  } finally {
    setloading(false);
  }
};
export const getInterViewScheduleJobs = (setloading) => async (dispatch) => {
  try {
    setloading(true);

    let data = await getJobs("interviewSchedule");
    dispatch({ type: GET_ALL_INTERVIEW_SCHEDULE_JOBS, payload: data });
    setloading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });

    setloading(false);
  } finally {
    setloading(false);
  }
};
export const getInterViewReSheduleJobs = (setloading) => async (dispatch) => {
  try {
    setloading(true);

    let data = await getJobs("interviewReschedule");
    dispatch({ type: GET_ALL_INTERVIEW_RESCHEDULE_JOBS, payload: data });
    setloading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });

    setloading(false);
  } finally {
    setloading(false);
  }
};
export const getInterViewCancelJobs = (setloading) => async (dispatch) => {
  try {
    setloading(true);

    let data = await getJobs("interviewCancel");
    dispatch({ type: GET_ALL_INTERVIEW_CANCEL_JOBS, payload: data });
    setloading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });

    setloading(false);
  } finally {
    setloading(false);
  }
};
export const getOnBoardingJobs = (setloading) => async (dispatch) => {
  try {
    setloading(true);
    let d1 = await getJobs("onboarding");
    let d2 = await getJobs("boardingInvitation");
    let data = [...d1, ...d2];
    dispatch({ type: GET_ALL_ONBOARD_JOBS, payload: data });
    setloading(false);
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });

    setloading(false);
  } finally {
    setloading(false);
  }
};

export const updateAppliedFor =
  (updateData, jobData, setLoading) => async (dispatch) => {
    try {
      setLoading(true);

      await db
        .collection("JobApplication")
        .doc(jobData.docId)
        .update({ ...updateData });

      let data = {
        ...jobData,
        ...updateData,
      };
      dispatch({ type: UPDATE_STATUS, payload: data });

      dispatch({
        type: SUCCESS_ALERT,
        payload: "AppliedFor updated successfully.",
      });
    } catch (error) {
      dispatch({
        type: ERROR_ALERT,
        payload: `${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

export const updateStatus =
  (status, jobData, setloading) => async (dispatch) => {
    try {
      setloading && setloading(true);
      if (!jobData?.jobStatusHistory) {
        let jobStatusHistory = [
          {
            date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
            status,
          },
        ];
        let firebaseData = {
          status,
          jobStatusHistory,
        };
        await db
          .collection("JobApplication")
          .doc(jobData.docId)
          .update({ ...firebaseData });
        let data = {
          ...jobData,
          status: status,
          jobStatusHistory,
        };
        dispatch({ type: UPDATE_STATUS, payload: data });
      } else {
        let jobStatusHistory = [
          {
            date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
            status,
          },
          ...jobData?.jobStatusHistory,
        ];
        let firebaseData = {
          status,
          jobStatusHistory,
        };
        await db
          .collection("JobApplication")
          .doc(jobData.docId)
          .update({ ...firebaseData });
        let data = {
          ...jobData,
          status: status,
          jobStatusHistory,
        };
        dispatch({ type: UPDATE_STATUS, payload: data });
      }

      dispatch({
        type: SUCCESS_ALERT,
        payload: "Job's Status updated successfully.",
      });

      setloading && setloading(false);
      if (status === "future") {
        try {
          const res = await axiosTechloset.post("emails/futureJob", {
            email: jobData.email,
            name: `${jobData.fname} `,
            title: `${jobData.appliedFor}`,
          });
          dispatch({ type: SUCCESS_ALERT, payload: `${res.data}` });
        } catch (error) {
          dispatch({
            type: ERROR_ALERT,
            payload: `Email Not sended ${error.message} `,
          });
        }
      } else if (status === "boardingInvitation") {
        try {
          const res = await axiosTechloset.post("emails/startOnBoarding", {
            email: jobData.email,
            name: jobData.fname,
            appliedFor: jobData?.appliedFor,
          });
          dispatch({ type: SUCCESS_ALERT, payload: `${res.data}` });
        } catch (error) {
          dispatch({
            type: ERROR_ALERT,
            payload: `Email not send ${error.message}`,
          });
        }
      }
    } catch (error) {
      dispatch({ type: ERROR_ALERT, payload: error.message });
    }
  };
export const getTimeSuggestion =
  (name, email, appliedFor) => async (dispatch) => {
    try {
      const res = await axiosTechloset.post("emails/timeSuggestion", {
        email: email,
        name: `${name}`,
        appliedFor: `${appliedFor}`,
      });
      dispatch({ type: SUCCESS_ALERT, payload: `${res.data}` });
    } catch (error) {
      dispatch({
        type: ERROR_ALERT,
        payload: `Email not send ${error.message}`,
      });
      // console.log(error.message);
    }
  };

const uploadCv = async (file, name) => {
  const uploadPromise = new Promise(function (resolve, reject) {
    let idCardRef = storage.ref().child(`CVS/${name}`);
    idCardRef.put(file).then(function (snapshot) {
      snapshot.ref
        .getDownloadURL()
        .then(function (downloadURL) {
          resolve({
            status: "success",
            link: downloadURL,
          });
        })
        .catch((err) => {
          reject(err?.message);
        });
    });
  });
  return uploadPromise;
};
export const addJobApplication = (data, cv, setloading) => async (dispatch) => {
  try {
    setloading(true);
    let userName = data?.name ? data?.name : "";
    let name = `${userName}`.replace(/\s/g, "");
    const unique_id = parseInt(Math.random() * new Date().getTime());
    const fileName = `${name}-${data?.email}-${unique_id}-${cv.name}`;
    const CVRes = await uploadCv(cv, fileName, setloading);
    let CVLink;
    if (CVRes?.status === "success") {
      CVLink = CVRes?.link;
    }
    let JobApplication = await db
      .collection("JobApplication")
      .where("email", "==", `${data.email.toLowerCase()}`)
      .get();
    let details = [];
    JobApplication.forEach((item) => {
      details.push({ docId: item.id, ...item.data() });
    });
    if (details.length == 0) {
      let jobAppliedHistory = [
        {
          date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
          appliedFor: data?.appliedFor,
          jobId: data?.jobId,
          CV: CVLink,
        },
      ];
      let jobStatusHistory = [
        {
          date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
          status: data?.status,
        },
      ];

      let firebaseData = {
        createdAt: data?.createdAt,
        fname: data.name ? data?.name : "",
        email: data.email ? data.email.toLowerCase() : "",
        phone: data.number ? data?.number : "",
        gitHub: "",
        skype: "",
        appliedFor: data.appliedFor ? data?.appliedFor : "",
        jobId: data.jobId ? data?.jobId : "",
        contractType: data.contractType ? data?.contractType : "",
        source: data.source ? data?.source : "",
        status: data.status ? data?.status : "",
        CV: CVLink,
        jobAppliedHistory,
        jobStatusHistory,
      };
      let res = await db.collection("JobApplication").add({
        ...firebaseData,
      });
      let docId = res.id;

      dispatch({
        type: APPLY_FOR_JOB,
        payload: { ...firebaseData, docId },
      });
      dispatch({ type: SUCCESS_ALERT, payload: "Applied Successfully" });
      setloading(false);

      let axiosData = {
        email: data.email,
        phone: data.number,
        skype: "",
        name: data?.name || "",
        resume: CVLink,
        title: data?.appliedFor,
        source: data?.source,
      };
      let slackRes = await axiosTechloset({
        url: "/slack/job",
        method: "POST",
        data: axiosData,
      });
      dispatch({ type: SUCCESS_ALERT, payload: slackRes.data });
    } else {
      const userDetails = details[0];
      let check = userDetails?.jobAppliedHistory?.filter(
        (job) => job.appliedFor == data.appliedFor,
      );

      if (check?.length == 0 || !userDetails?.jobAppliedHistory) {
        if (!userDetails?.jobAppliedHistory || !userDetails?.jobStatusHistory) {
          if (userDetails?.jobStatusHistory) {
            var jobStatusHistory = [
              {
                date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
                status: data.status,
              },
              ...userDetails?.jobStatusHistory,
            ];
          } else {
            var jobStatusHistory = [
              {
                date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
                status: data.status,
              },
            ];
          }
          let jobAppliedHistory = [
            {
              date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
              appliedFor: data.appliedFor,
              CV: CVLink,
            },
          ];

          let firebaseData = {
            createdAt: data?.createdAt,
            fname: data.name
              ? data.name
              : userDetails?.fname
              ? userDetails?.fname
              : "",
            email: data.email?.toLowerCase(),
            gitHub: userDetails?.gitHub ? userDetails?.gitHub : "",
            skype: userDetails?.skype ? userDetails?.skype : "",
            phone: userDetails?.phone ? userDetails?.phone : "",
            appliedFor: data.appliedFor,
            source: data.source,
            status: data.status,
            docId: userDetails?.docId,
            CV: CVLink,
            jobAppliedHistory,
            jobStatusHistory,
          };
          await db
            .collection("JobApplication")
            .doc(userDetails.docId)
            .update({ ...firebaseData });

          dispatch({
            type: UPDATE_STATUS,
            payload: {
              ...firebaseData,
            },
          });
        } else {
          let jobAppliedHistory = [
            {
              date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
              appliedFor: data.appliedFor,
              CV: CVLink,
            },
            ...userDetails?.jobAppliedHistory,
          ];
          let jobStatusHistory = [
            {
              date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
              status: data.status,
            },
            ...userDetails?.jobStatusHistory,
          ];

          let firebaseData = {
            createdAt: data?.createdAt,
            fname: data.name
              ? data.name
              : userDetails?.fname
              ? userDetails?.fname
              : "",
            email: data.email?.toLowerCase(),
            gitHub: userDetails?.gitHub ? userDetails?.gitHub : "",
            skype: userDetails?.skype ? userDetails?.skype : "",
            phone: userDetails?.phone ? userDetails?.phone : "",
            appliedFor: data.appliedFor,
            source: data.source,
            status: data.status,
            docId: userDetails?.docId,
            CV: CVLink,
            jobAppliedHistory,
            jobStatusHistory,
          };
          await db
            .collection("JobApplication")
            .doc(userDetails.docId)
            .update({ ...firebaseData });

          dispatch({
            type: UPDATE_STATUS,
            payload: {
              ...firebaseData,
            },
          });
        }
        dispatch({
          type: SUCCESS_ALERT,
          payload: "Job Application Updated Successfully",
        });
        setloading(false);

        let axiosData = {
          skype: "",
          email: data.email,
          phone: "",
          resume: CVLink,
          name: data.name ? data.name : "",
          title: data.appliedFor,
          source: data?.source || "",
        };

        let slackRes = await axiosTechloset({
          url: "/slack/job",
          method: "POST",
          data: axiosData,
        });
        dispatch({ type: SUCCESS_ALERT, payload: slackRes.data });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: "Already Applied For This Job",
        });
        setloading(false);
      }
    }
  } catch (error) {
    dispatch({ type: ERROR_ALERT, payload: error.message });
    setloading(false);
  }
};

export const addInterview = (data, user) => async (dispatch) => {
  try {
    const status = "interviewSchedule";
    const { title, fname, appliedFor, email, start, location } = data;
    // const googleCalendarInterviewEventId = await createEvent(data, "Interview");
    let values = {
      title,
      location,
      start,
      appliedFor: appliedFor ? appliedFor : "",
      // googleCalendarInterviewEventId,
    };
    if (location === "remote") {
      const zoom = await axiosTechloset.post("/zoom/createMeeting", {
        title: `${fname}`,
        startTime: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
      });
      const { start_url, join_url, id, password } = zoom.data;

      values = {
        ...values,
        start_url,
        join_url,
        password: password,
        meetingId: id,
      };
    }

    if (!user?.jobStatusHistory) {
      let jobStatusHistory = [
        {
          date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
          status,
        },
      ];

      await db
        .collection("JobApplication")
        .doc(user.docId)
        .update({
          ...user,
          status: status,
          interview: values,
          jobStatusHistory,
        });

      dispatch({
        type: INTERVIEW_SHECDULE,
        payload: {
          ...user,
          status: status,
          interview: values,
          jobStatusHistory,
        },
      });
      dispatch({
        type: UPDATE_STATUS,
        payload: {
          ...user,
          status: status,
          interview: values,
          jobStatusHistory,
        },
      });
    } else {
      let jobStatusHistory = [
        {
          date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
          status,
        },
        ...user?.jobStatusHistory,
      ];

      await db
        .collection("JobApplication")
        .doc(user.docId)
        .update({
          ...user,
          status: status,
          interview: values,
          jobStatusHistory,
        });

      dispatch({
        type: INTERVIEW_SHECDULE,
        payload: {
          ...user,
          status: status,
          interview: values,
          jobStatusHistory,
        },
      });
      dispatch({
        type: UPDATE_STATUS,
        payload: {
          ...user,
          status: status,
          interview: values,
          jobStatusHistory,
        },
      });
    }

    dispatch({
      type: SUCCESS_ALERT,
      payload: "Interviews Schedule Successfully.",
    });
    if (location === "remote") {
      await axiosTechloset.post("/emails/interview", {
        email: email,
        name: `${fname}`,
        join_url: values?.join_url,
        password: values?.password,
        id: values?.meetingId,
        date: moment(data.start).format("MMMM Do YYYY, h:mm a"),
      });
    } else {
      await axiosTechloset.post("/emails/interviewOnsite", {
        email: email,
        name: `${fname}`,
        jobTitle: appliedFor,
        date: moment(data.start).format("MMMM Do YYYY"),
        time: moment(data.start).format("h:mm a"),
      });
    }
    let slackValues = {
      title: title,
      name: fname,
      email: email,
      date: moment(start).format("MMMM Do YYYY, h:mm a"),
      appliedFor: appliedFor ? appliedFor : "",
      type: status,
      location: location,
      resume: user?.CV,
    };
    if (location === "remote") {
      slackValues = {
        ...slackValues,
        startUrl: values?.start_url,
        joinUrl: values?.join_url,
      };
    }
    await axiosTechloset
      .post(`/slack/interviews`, {
        ...slackValues,
      })
      .then(
        (response) => {
          dispatch({ type: SUCCESS_ALERT, payload: response.data });
        },
        (error) => {
          dispatch({ type: ERROR_ALERT, payload: error.message });
          console.log(error);
        },
      );
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};
export const cancelinterview = (data) => async (dispatch) => {
  try {
    const status = "interviewCancel";
    // await AxiosTechloset({
    //   method: "DELETE",
    //   url: "/event/delete",
    //   data: { events: { id: data?.googleCalendarInterviewEventId } },
    // });
    const { title, appliedFor, user, start, meetingId, note, location } = data;
    if (!user?.jobStatusHistory) {
      let jobStatusHistory = [
        {
          date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
          status,
          note,
        },
      ];

      await db
        .collection("JobApplication")
        .doc(user.docId)
        .update({
          status: status,
          interview: {
            title,
            appliedFor: appliedFor ? appliedFor : "",
            start,
            location,
            meetingId: "",
            start_url: "",
            join_url: "",
            password: "",
            note: note,
          },
          questions: [],
          jobStatusHistory,
        });

      dispatch({
        type: UPDATE_STATUS,
        payload: {
          ...user,
          status: status,
          interview: {
            appliedFor: appliedFor ? appliedFor : "",
            title,
            start,
            location,
            meetingId: "",
            start_url: "",
            join_url: "",
            password: "",
            note: note,
          },
          questions: [],
          jobStatusHistory,
        },
      });
    } else {
      let jobStatusHistory = [
        {
          date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
          status,
          note,
        },
        ...user?.jobStatusHistory,
      ];

      await db
        .collection("JobApplication")
        .doc(user.docId)
        .update({
          status: status,
          interview: {
            title,
            appliedFor: appliedFor ? appliedFor : "",
            start,
            location,
            meetingId: "",
            start_url: "",
            join_url: "",
            password: "",
            note: note,
          },
          questions: [],
          jobStatusHistory,
        });

      dispatch({
        type: UPDATE_STATUS,
        payload: {
          ...user,
          status: status,
          interview: {
            appliedFor: appliedFor ? appliedFor : "",
            title,
            start,
            location,
            meetingId: "",
            start_url: "",
            join_url: "",
            password: "",
            note: note,
          },
          questions: [],
          jobStatusHistory,
        },
      });
    }
    dispatch({
      type: SUCCESS_ALERT,
      payload: "Interview Cancelled Successfully.",
    });

    await axiosTechloset.post("/emails/cancelinterview", {
      email: user.email,
      name: `${user.fname}`,
    });
    const response = await axiosTechloset.post(`/slack/interviews`, {
      title: title,
      name: user.fname,
      email: user.email,
      appliedFor: appliedFor ? appliedFor : "",
      date: moment(start).format("MMMM Do YYYY, h:mm a"),
      type: status,
      resume: user.CV,
      location: location,
    });
    dispatch({ type: SUCCESS_ALERT, payload: response.data });
    if (location === "remote") {
      await axiosTechloset.delete("/zoom/removeMeeting", {
        data: { id: meetingId },
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};
export const interviewed = (data, questions) => async (dispatch) => {
  try {
    const status = "interviewed";
    const { title, start, appliedFor, meetingId, user, note, location } = data;
    if (!user?.jobStatusHistory) {
      let jobStatusHistory = [
        {
          date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
          status,
          note,
          questions: questions ? questions : "",
        },
      ];

      await db
        .collection("JobApplication")
        .doc(user.docId)
        .update({
          status: status,
          interview: {
            appliedFor: appliedFor ? appliedFor : "",
            title,
            start,
            location,
            meetingId: "",
            start_url: "",
            join_url: "",
            password: "",
            note: note ? note : "",
          },
          questions: [],
          jobStatusHistory,
        });
      dispatch({
        type: UPDATE_STATUS,
        payload: {
          ...user,
          status: status,
          interview: {
            appliedFor: appliedFor ? appliedFor : "",
            title,
            location,
            start: "",
            meetingId: "",
            start_url: "",
            join_url: "",
            password: "",
            note: note ? note : "",
          },
          questions: [],
          jobStatusHistory,
        },
      });
    } else {
      let jobStatusHistory = [
        {
          date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
          status,
          note: note ? note : "",
          questions: questions ? questions : "",
        },
        ...user?.jobStatusHistory,
      ];

      await db
        .collection("JobApplication")
        .doc(user.docId)
        .update({
          status: status,
          interview: {
            title,
            appliedFor: appliedFor ? appliedFor : "",
            start,
            location,
            meetingId: "",
            start_url: "",
            join_url: "",
            password: "",
            note: note ? note : "",
          },
          questions: [],
          jobStatusHistory,
        });
      dispatch({
        type: UPDATE_STATUS,
        payload: {
          ...user,
          status: status,
          interview: {
            appliedFor: appliedFor ? appliedFor : "",
            title,
            location,
            start: "",
            meetingId: "",
            start_url: "",
            join_url: "",
            password: "",
            note: note ? note : "",
          },
          questions: [],
          jobStatusHistory,
        },
      });
    }
    dispatch({
      type: SUCCESS_ALERT,
      payload: "Interviewed Successfully.",
    });
    if (location === "remote") {
      await axiosTechloset.delete("/zoom/removeMeeting", {
        data: { id: meetingId },
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};

export const endInterviewSlack = (data, questions) => async (dispatch) => {
  try {
    const { appliedFor, user } = data;

    await axiosTechloset.post("/slack/endInterview", {
      fname: user.fname,
      email: user.email,
      appliedFor: appliedFor ? appliedFor : "",
      CV: user.CV,
      questionAnswers: questions,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};

export const interviewReschedule = (data) => async (dispatch) => {
  try {
    const status = "interviewReschedule";
    const { title, start, appliedFor, user, fname, email, location } = data;
    // const id = await updateGoogleEvent(data, "Interview Reschedule");
    let values = {
      title,
      location,
      start,
      appliedFor: appliedFor ? appliedFor : "",
      // googleCalendarInterviewEventId: id,
    };
    if (location === "remote") {
      const zoom = await axiosTechloset.post("/zoom/createMeeting", {
        title: `${fname}`,
        startTime: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
      });
      const { start_url, join_url, id, password } = zoom.data;
      values = {
        ...values,
        start_url,
        join_url,
        password,
        meetingId: id,
      };
    }
    if (!user?.jobStatusHistory) {
      let jobStatusHistory = [
        {
          date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
          status,
        },
      ];
      await db
        .collection("JobApplication")
        .doc(user.docId)
        .update({
          status: status,
          interview: {
            ...values,
          },
          jobStatusHistory,
        });
      dispatch({
        type: UPDATE_STATUS,
        payload: {
          ...user,
          status: status,
          interview: {
            ...values,
          },
          jobStatusHistory,
        },
      });
    } else {
      let jobStatusHistory = [
        {
          date: moment(new Date())?.format("MMMM Do YYYY, h:mm a"),
          status,
        },
        ...user?.jobStatusHistory,
      ];

      await db
        .collection("JobApplication")
        .doc(user.docId)
        .update({
          status: status,
          interview: {
            ...values,
          },
          jobStatusHistory,
        });
      dispatch({
        type: UPDATE_STATUS,
        payload: {
          ...user,
          status: status,
          interview: {
            ...values,
          },
          jobStatusHistory,
        },
      });
    }
    dispatch({
      type: SUCCESS_ALERT,
      payload: "Interview ReSchedule Successfully.",
    });
    if (location === "remote") {
      await axiosTechloset.post("/emails/interviewReschedule", {
        email: email,
        name: fname,
        join_url: values?.join_url,
        password: values?.password,
        id: values?.meetingId,
        date: moment(start).format("MMMM Do YYYY, h:mm a"),
      });
    } else {
      await axiosTechloset.post("/emails/interviewRescheduleOnsite", {
        email: email,
        name: fname,
        jobTitle: appliedFor ? appliedFor : "",
        date: moment(start).format("MMMM Do YYYY"),
        time: moment(start).format("h:mm a"),
      });
    }
    let slackValues = {
      title: title,
      location,
      name: fname,
      email: email,
      date: moment(start).format("MMMM Do YYYY, h:mm a"),
      type: status,
      appliedFor: appliedFor ? appliedFor : "",
      resume: user.CV,
    };
    if (location === "remote") {
      slackValues = {
        ...slackValues,
        startUrl: values?.start_url,
        joinUrl: values?.join_url,
      };
    }

    await axiosTechloset.post(`/slack/interviews`, { ...slackValues }).then(
      (response) => {
        dispatch({ type: SUCCESS_ALERT, payload: response.data });
      },
      (error) => {
        dispatch({ type: ERROR_ALERT, payload: error.message });
        console.log(error);
      },
    );
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR_ALERT, payload: error.message });
  }
};
