import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  generalLeave: {
    // backgroundColor: theme.palette.success.main,
    backgroundColor: "#4caf50",
    "&:hover": {
      background: "#4caf50",
    },
    color: "#fff",
  },
  paidLeave: {
    backgroundColor: "#673ab7",
    "&:hover": {
      background: "#673ab7",
    },
    color: "#fff",
  },
  declined: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
  },
  leave: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  circularProgress: {
    marginLeft: 0,
    color: "#fff",
  },
}));
