import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import useStyles from './style';
import {
  QuestionAnswer
} from "@material-ui/icons";
import { Tooltip } from '@material-ui/core';
import techlosetImage from '../../assets/JobApplication.png'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function QuestionsHistory({ questionsHistory }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const SingleIntro = ({ questions }) => {
    return (
      <>
        {
          questions?.questions ?
            <div className={classes.singleInterview}>
              {
                questions?.questions?.map((question, index) => {
                  return (
                    <div key={index} className={classes.singleQuestions}>
                      <h5><strong>Question: </strong>{question.question}</h5>
                      <h5><strong>Answer: </strong>{question.answer}</h5>
                    </div>
                  )
                })
              }
            </div> : ''
        }
      </>
    )
  }

  let check = questionsHistory?.find(doc => doc?.questions)

  return (
    <div>
      <Tooltip title="Questions History">
        <IconButton color="primary" onClick={handleClickOpen}>
          <QuestionAnswer />
        </IconButton>
      </Tooltip>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </AppBar>
        <div className={classes.mainDiv}>
          {
            check ? <>
              {
                questionsHistory?.map((questions) => {
                  return <SingleIntro questions={questions} />
                })
              }
            </> :
              <center>
                <img src={techlosetImage} alt='Techloset Image' />
                <h1>No Questions History!!!</h1>
              </center>
          }
        </div>
      </Dialog>
    </div>
  );
}
