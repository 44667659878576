import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  approveLeave,
  getBootcampLeaves,
} from "./redux/actions/leaveRequestAction";
import CircularLoader from "../utils/circularProgress/index";
import TableComponent from "./table";
import { ERROR_ALERT, UPDATE_BOOTCAMP_LEAVES, UPDATE_LEAVES } from "./redux/constant";

export default function BootcampLeaveRequest() {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const { bootcampLeaves } = useSelector(
    (state) => state.leaveRequestReducer);
  useEffect(() => {
    dispatch(getBootcampLeaves(setloading));
  }, []);

  const handleMore = (i, status, setLoading) => {
    try {
      let callback=()=>{
        const approvedLeaveID = bootcampLeaves[i].id;
        const updatedAllLeaves = bootcampLeaves?.filter((leave) => leave.id !== approvedLeaveID);
        dispatch({
          type:UPDATE_BOOTCAMP_LEAVES,
          payload:updatedAllLeaves
        })
      }
      dispatch(approveLeave(bootcampLeaves[i], status, setLoading,callback));
    } catch (error) {
      dispatch({
        type: ERROR_ALERT,
        payload: error.message,
      });
    }
    
  };

  return (
    <>
      {loading ? (
        <CircularLoader />
      ) : (
        <TableComponent data={bootcampLeaves} handleMore={handleMore} />
      )}
    </>
  );
}
