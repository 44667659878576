import axiosTechloset from "../config/axiosTechloset";
export const SUCCESS_ALERT = "SUCCESS_ALERT";
export const ERROR_ALERT = "ERROR_ALERT";

export const getDoc = async (payload, setLoading, index = "", setIndex) => {
  try {
    setLoading(true);
    if (index) {
      setIndex(index);
    }
    const response = await axiosTechloset({
      method: "POST",
      url: "/docs/getDocUrl",
      data: { ...payload },
    });
    window.open(response?.data).focus();
  } catch (error) {
  } finally {
    setLoading(false);
    if (index) {
      setIndex(index);
    }
  }
};
