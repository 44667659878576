import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useObjectState } from "../../commonUtils/commonState";
import AddInterViewSidebar from "./AddInterviewSidebar";
import InterviewDetailSideBar from "./InterviewDetailSideBar";
import { Card, CardBody, Button, ButtonGroup } from "reactstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { ChevronLeft, ChevronRight } from "react-feather";
import moment from "moment";
import Filters from "./Filters";
import {
  handleSidebar,
  handleSelectedInterview,
  handleInterviewDetailSidebar,
} from "./redux/interviewAction/interviewAction";
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./css/calender.scss";
import { getInterviewType } from "../interviewQuestions/redux/action";
const DragAndDropCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment);
const eventColors = {
  readyToStart: "bg-primary",
  incoming: "bg-warning",
  inProgress: "bg-success",
  past: "bg-danger",
};

function ToolBar(props) {
  return (
    <div className="calendar-header mb-2 d-flex justify-content-between flex-wrap">
      <Filters />
      <div className="text-center view-options mt-1 mt-sm-0 ml-lg-5 ml-0">
        <ButtonGroup>
          <button
            className={`btn ${
              props.view === "month"
                ? "btn-primary"
                : "btn-outline-primary text-primary"
            }`}
            onClick={() => {
              props.onView("month");
            }}
          >
            Month
          </button>
          <button
            className={`btn ${
              props.view === "week"
                ? "btn-primary"
                : "btn-outline-primary text-primary"
            }`}
            onClick={() => {
              props.onView("week");
            }}
          >
            Week
          </button>
          <button
            className={`btn ${
              props.view === "day"
                ? "btn-primary"
                : "btn-outline-primary text-primary"
            }`}
            onClick={() => {
              props.onView("day");
            }}
          >
            Day
          </button>
        </ButtonGroup>
      </div>
      <div className="month-label d-flex flex-column text-center text-md-right mt-1 mt-md-0">
        <div className="calendar-navigation">
          <Button.Ripple
            className="btn-icon rounded-circle"
            size="sm"
            color="primary"
            onClick={() => props.onNavigate("PREV")}
          >
            <ChevronLeft size={15} />
          </Button.Ripple>
          <div className="month d-inline-block mx-75 text-bold-500 font-medium-2 align-middle">
            {props.label}
          </div>
          <Button.Ripple
            className="btn-icon rounded-circle"
            size="sm"
            color="primary"
            onClick={() => props.onNavigate("NEXT")}
          >
            <ChevronRight size={15} />
          </Button.Ripple>
        </div>
        <div className="event-tags d-none d-sm-flex justify-content-end mt-1">
          <div className="tag mr-1">
            <span className="bullet bullet-success  bullet-sm mr-50"></span>
            <span>In Progess</span>
          </div>
          <div className="tag mr-1">
            <span className="bullet bullet-primary bullet-sm mr-50"></span>
            <span>Ready to Start</span>
          </div>
          <div className="tag mr-1">
            <span className="bullet bullet-warning bullet-sm mr-50"></span>
            <span>Incoming </span>
          </div>
          <div className="tag mr-1">
            <span className="bullet bullet-danger bullet-sm mr-50"></span>
            <span>Past</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function CalendarApp(props) {
  const { sidebar, selectedInterview } = useSelector(
    (state) => state.interviewReducer,
  );
  const [interviews, setInterviews] = useState([]);
  const allInterview = useSelector(
    (state) => state.jobsRequestsReducer.calenderInterviews,
  );
  useEffect(() => {
    let unique = [];
    allInterview.forEach((x) => {
      if (!unique.some((a) => a.docId === x.docId)) {
        unique.push(x);
      }
    });
    setInterviews(unique);
  }, [allInterview]);
  const [state, setState] = useObjectState({
    interviews: interviews,
    views: {
      month: true,
      week: true,
      day: true,
    },
    interviewInfo: null,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (interviews.length > 0) {
      var updated = [];
      interviews.forEach((item) => {
        if (item.interview === undefined) {
          return;
        }
        try {
          let start = "";
          let end = "";

          if (item?.interview?.start?.seconds) {
            start = item?.interview?.start?.toDate();
            end = item?.interview?.start?.toDate();
            // console.log("start", start);
          } else {
            start = item.interview.start;
            end = item.interview.start;
          }
          const interviewDate = new Date(start);
          const todayDate = new Date();
          let diff = interviewDate.getTime() - todayDate.getTime();

          let minutes = Math.floor(diff / 1000 / 60);
          let label = "nill";
          if (interviewDate < todayDate) {
            label = "past";
          } else {
            if (minutes == 0) {
              label = "inProgress";
            } else if (minutes <= 60) {
              label = "readyToStart";
            } else if (minutes > 60) {
              label = "incoming";
            }
          }

          return updated.push({
            ...item.interview,
            id: item.docId,
            user: { ...item },
            email: item.email,
            fname: item.fname,
            status: item.status,
            start,
            end,
            label,
          });
        } catch (error) {
          console.log("error", error);
        }
      });

      setState({
        interviews: updated,
        sidebar: sidebar,
        interviewInfo: selectedInterview,
      });
    } else {
      setState({
        interviews: interviews,
        sidebar: sidebar,
        interviewInfo: selectedInterview,
      });
    }
  }, [interviews]);

  useEffect(() => {
    dispatch(getInterviewType());
  }, []);

  const handleEventColors = (event) => {
    return { className: eventColors[event.label] };
  };

  const handleSelectInterview = (interview) => {
    let filteredState = state.interviews.filter((i) => i.id === interview.id);
    // console.log(filteredState[0], "filter");
    dispatch(handleInterviewDetailSidebar(true));

    dispatch(handleSelectedInterview(filteredState[0]));
    setState({
      interviewInfo: filteredState[0],
    });
  };

  const { views } = state;

  return (
    <div className="app-calendar position-relative">
      <div
        className={`app-content-overlay ${sidebar ? "show" : "hidden"}`}
        onClick={() => {
          dispatch(handleSidebar(false));
          dispatch(handleSelectedInterview(null));
        }}
      ></div>
      <Card>
        <CardBody>
          <DragAndDropCalendar
            localizer={localizer}
            events={state.interviews}
            startAccessor="start"
            endAccessor="end"
            resourceAccessor="url"
            views={views}
            components={{
              toolbar: (props) => <ToolBar {...props} />,
            }}
            eventPropGetter={handleEventColors}
            popup={true}
            onSelectEvent={(event) => {
              handleSelectInterview(event);
            }}
            onSelectSlot={({ start }) => {
              if (
                props?.user &&
                !(
                  props?.user?.status === "interviewReschedule" ||
                  props?.user?.status === "interviewSchedule"
                )
              ) {
                dispatch(handleSidebar(true));
                dispatch(
                  handleSelectedInterview({
                    start: new Date(start),
                    title: "",
                    fname: props?.user !== null ? props?.user?.fname : "",
                    email: props?.user !== null ? props?.user?.email : "",
                  }),
                );
              }
            }}
            selectable={true}
          />
        </CardBody>
      </Card>
      <InterviewDetailSideBar
        events={interviews}
        interviewInfo={state.interviewInfo}
        user={props.user}
        resizable
      />

      <AddInterViewSidebar
        events={interviews}
        interviewInfo={state.interviewInfo}
        user={props.user}
        resizable
      />
    </div>
  );
}

export default CalendarApp;
