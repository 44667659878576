import React from "react";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
const defaultFooterStyles = {
  total: {
    width: "63.5%",
    textAlign: "center",
  },
};

function CustomFooter(props) {
  const {
    classes,
    count,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    total,
  } = props;

  return (
    <TableFooter>
      <TableRow>
        <TableCell className={classes.total}>
          <h6> Total Payable = {total}Rs </h6>
        </TableCell>

        <TableCell className={classes.root}>
          <TablePagination
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
}

export default withStyles(defaultFooterStyles, { name: "CustomFooter" })(
  CustomFooter,
);
