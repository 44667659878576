import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Button, TextField } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { getById } from "../../../profile/redux/actions/profileAction";
import { useStyles } from "../ExperienceLetterStyle";
import CircularLoader from "../../../utils/circularProgress";
import moment from "moment";
import axiosTechloset from "../../../../config/axiosTechloset";
import { CircularProgress } from "@material-ui/core";
import { getDoc, ERROR_ALERT } from "../../../../commonUtils/share";
import gernateId from "../../../../commonUtils/gernateId";
import { db } from "../../../../config/firebase";
import firebase from "firebase";
import { sendLetter } from "../../redux/actions/lettersRequestAction";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ExperienceLetter = () => {
  const { userProfile } = useSelector((state) => state.profileReducer);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [letterDetails, setLetterDetails] = useState({});
  const history = useHistory();
  const { id, rId } = useParams();
  const [docLoading, setDocLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);

    history.replace({
      pathname: "/lettersrequest",
    });
  };

  useEffect(() => {
    if (id) {
      dispatch(getById(id, setloading));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!loading) {
      setLetterDetails({
        name: userProfile?.fullName,
        jobTitle: userProfile?.rankTitle,
        gender: userProfile?.gender,
        jobStartedAt: moment(userProfile?.JobStartedAt?.toDate())?.format(
          "YYYY-MM-DD",
        ),
        email: userProfile?.email,
        userId: id,
        fatherName: userProfile?.fatherName || "",
      });
    }
  }, [loading]);

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLetterDetails({
      ...letterDetails,
      [name]: value,
    });
  };
  const letterHandler = async (type) => {
    try {
      if (
        letterDetails?.jobEndedAt &&
        letterDetails?.jobStartedAt &&
        letterDetails?.date &&
        letterDetails?.name &&
        letterDetails?.jobTitle &&
        letterDetails?.gender
      ) {
        const docId = await gernateId();
        const payload = {
          ...letterDetails,
          jobStartedAt: moment(letterDetails?.jobStartedAt)?.format(
            "DD/MM/YYYY",
          ),
          jobEndedAt: moment(letterDetails?.jobEndedAt)?.format("DD/MM/YYYY"),
          date: moment(letterDetails?.date)?.format("DD/MM/YYYY"),
          docType: "experienceLetter",
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        };
        if (type === "view") {
          getDoc({ ...payload, docId }, setDocLoading);
        } else {
          setDocLoading(true);
          await db
            .collection("letters")
            .doc(docId)
            .set({ ...payload, isAuto: true });
          await axiosTechloset({
            method: "POST",
            url: "/emails/experienceLetter",
            data: { ...payload, docId },
          });
          dispatch(sendLetter(rId, setDocLoading));
        }
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: "ALL Fields Are Required!",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </AppBar>

        {loading ? <CircularLoader /> : null}

        <Container maxWidth="md" className={classes.container}>
          <h1 className="Details">Experience Letter Details</h1>
          <Grid container justify="center" alignItems="center" spacing={2}>
            <Grid item xs={9} md={9} sm={9}>
              <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    name="name"
                    className={classes.formControl}
                    fullWidth
                    placeholder="Employee Name"
                    id="outlined-basic"
                    label="Employee Name"
                    variant="outlined"
                    value={letterDetails?.name || ""}
                    onChange={onChangeHandler}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    name="jobTitle"
                    className={classes.formControl}
                    fullWidth
                    placeholder="Job  Title"
                    id="outlined-basic"
                    label="Job Title"
                    variant="outlined"
                    value={letterDetails?.jobTitle || ""}
                    onChange={onChangeHandler}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    className={classes.formControl}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    id="outlined-basic"
                    type="date"
                    name="date"
                    label="Issuance Date"
                    variant="outlined"
                    defaultValue={letterDetails?.date || new Date()}
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    className={classes.formControl}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    id="outlined-basic"
                    type="date"
                    label="Start Date"
                    variant="outlined"
                    value={letterDetails?.jobStartedAt}
                    name="jobStartedAt"
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    className={classes.formControl}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    id="outlined-basic"
                    type="date"
                    label="End Date"
                    variant="outlined"
                    value={letterDetails?.jobEndedAt || ""}
                    name="jobEndedAt"
                    onChange={onChangeHandler}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Gender</FormLabel>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="gender"
                      onChange={onChangeHandler}
                      value={letterDetails?.gender || "Male"}
                    >
                      <FormControlLabel
                        value="Male"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="Female"
                        control={<Radio />}
                        label="Female"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3} className={classes.grid}>
            <Grid item md={12} xs={12}>
              {docLoading ? (
                <CircularProgress className={classes.loader} />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={() => letterHandler("send")}
                    className={classes.button}
                  >
                    Send
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => letterHandler("view")}
                    className={classes.button}
                  >
                    View
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </>
  );
};

export default ExperienceLetter;
