import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
// import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useSelector } from "react-redux";
import { getSidebarTabs } from "../../constants/sidebarConfig/sidebarConfig";
function Sidebar({ location }) {
  const [searchKey, setSearchKey] = useState("");
  var classes = useStyles();
  var theme = useTheme();
  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });
  const { details } = useSelector((state) => state?.userReducer);
  const visibleTabs = details?.visibleTabs;
  const tabs = getSidebarTabs(visibleTabs);
  const filterData = isSidebarOpened
    ? tabs?.filter((item) =>
        item?.label?.toLowerCase()?.includes(searchKey?.toLowerCase()),
      )
    : tabs;
  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <div style={{ width: "90%", marginLeft: 10 }}>
        <input
          onChange={(e) => setSearchKey(e.target.value)}
          placeholder="Search a Tab"
          value={searchKey}
          style={{
            border: "2px solid grey",
            borderRadius: 20,
            marginTop: 20,
            marginBottom: 20,
            display: isSidebarOpened ? "block" : "none",
          }}
        />
      </div>
      <List className={classes.sidebarList}>
        {filterData?.map((item, index) => (
          <SidebarLink
            divider={item?.divider}
            key={index}
            label={item?.label}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...item}
          />
        ))}
      </List>
    </Drawer>
  );
  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
